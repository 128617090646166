import bugsnagReducer from "../redux/reducers/bugsnag";
import { IStoreApi } from "../redux/configure";
import config, { APP_ENV } from "../config";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

const createBugsnag = (_store: IStoreApi, user: any) => {
  if (user) {
    Bugsnag.start({
      apiKey: config.bugsnagKey,
      plugins: [new BugsnagPluginReact()],
      releaseStage: APP_ENV,
      onError: function (event) {
        event.setUser(
          user?.id,
          user?.email,
          `${user?.firstname} ${user?.lastname}`
        );
      },
    });
  }
  return;
};

const createBugsnagMiddleware = () => {
  return (store: IStoreApi) => {
    return bugsnagReducer(store, createBugsnag);
  };
};

export default createBugsnagMiddleware;

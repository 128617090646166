import React, { ReactType } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import useSelector from "../redux/useSelector";
import Layout from "../components/Layout/";
import config from "../config";
import * as authActions from "../Containers/Auth/actions";

const PrivateRoute = ({
  Component,
  path,
  ...rest
}: {
  Component: ReactType;
  path: any;
}) => {
  const dispatch = useDispatch();
  const reducers = useSelector((state) => state);
  if (
    reducers.authReducer?.user &&
    reducers.authReducer?.frontVersion !== config.frontVersion
  ) {
    dispatch(authActions.signOutUser());
    return (
      <Route
        {...rest}
        render={(props) => (
          <Redirect
            to={`/login?redirect_url=${props.location.pathname}${props.location.search}`}
          />
        )}
      />
    );
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!reducers._persist?.rehydrated) return null;

        if (reducers.authReducer?.user) {
          return (
            <Layout
              removeSidebar={config.removeSidebarPages.includes(
                props.location.pathname
              )}
            >
              <Component {...props} />
            </Layout>
          );
        }
        return (
          <Redirect
            to={`/login?redirect_url=${props.location.pathname}${props.location.search}`}
          />
        );
      }}
    />
  );
};

const mapStateToProps = (state: any) => {
  return {};
};

export default connect(mapStateToProps, {})(PrivateRoute);

import React, { useState, useEffect } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import {
  IdentityNewBar,
  IdentityActionsBar,
  IdentityPast,
  IdentityPastLoading,
  Paginate,
} from "@lagrowthmachine/lgmwebapp";
import LeadASide from "../../../../components/LeadASide";
import config from "../../../../config";
import { useDispatch } from "react-redux";
import * as actions from "../../actions";
import * as globalActions from "../../../../redux/actions";
import * as authActions from "../../../Auth/actions";
import types from "../../types";
import useSelector from "../../../../redux/useSelector";
import Modal, { types as modalTypes } from "../Modals";
import analyticsCall from "../../../../helpers/analyticsCall";

const IdentitiesComponent = (props: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params: any = useParams();
  const authReducer = useSelector((state) => state.authReducer);
  const identitiesReducer = useSelector((state) => state.identitiesReducer);
  const modalsReducer = useSelector((state) => state.modalsReducer);
  const loadingReducer = useSelector((state) => state.loadingReducer);
  const socketReducer = useSelector((state) => state.socketReducer);

  const totalPaginate =
    Math.ceil(identitiesReducer?.paginateL?.total / config.limitCampaigns) || 1;

  const [leadAside, setLeadASide] = useState({ id: "", mode: "" });
  const closeLeadAside = () => {
    if (leadAside.id !== "" || leadAside.mode !== "") {
      setLeadASide({ id: "", mode: "" });
    }
  };

  const updateLogs = () => {
    dispatch(
      actions.loadLogs({
        query: {},
        params: { identityId: params.id },
      })
    );
  };

  useEffect(() => {
    if (params.id)
      dispatch(
        actions.paginateLogsUpdate({
          current: 0,
          total: 0,
        })
      );
  }, [dispatch, params.id]);

  useEffect(() => {
    if (params.id)
      dispatch(
        actions.loadLogs({
          query: {},
          params: { identityId: params.id },
        })
      );
  }, [dispatch, params.id, identitiesReducer.paginateL?.current]);

  const handleOpenLead = (id: string) => {
    setLeadASide({ id: id, mode: "show" });
  };

  const urlIdentityId = params.id;

  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    setFirstLoad(false);
  }, []);

  useEffect(() => {
    analyticsCall("identities", "identities past, id: " + urlIdentityId);
  }, [urlIdentityId]);

  useEffect(() => {
    dispatch(authActions.loadUser({}));
    dispatch(globalActions.scrollToTop());
  }, [dispatch]);

  useEffect(() => {
    dispatch(actions.loadIdentities({ query: {} }));
  }, [dispatch]);

  const identity = identitiesReducer.list.find(
    (identity: { id: string }) => identity.id === urlIdentityId
  );

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(globalActions.socketIsWidgetAvailable());
    }, 4000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [dispatch, socketReducer.widgetAvailable]);

  const handleModal = ({ name, data }: { name: string; data: any }) => {
    dispatch(globalActions.modalUpdate({ name, data }));
  };

  const handleGotoCampaign = (campaignId: string) => {
    if (campaignId) history.push(`/campaigns/${campaignId}/overview`);
  };

  const handleChangePage = (page: number) => {
    dispatch(
      actions.paginateLogsUpdate({
        current: page,
        total: identitiesReducer.paginateL.total,
      })
    );
  };

  return (
    <React.Fragment>
      <LeadASide
        id={leadAside.id}
        mode={leadAside.mode}
        audiences={[]} //audiences in which a new lead should be added to
        onClose={closeLeadAside}
        callback={updateLogs}
      />
      <div className="identities_past_container">
        <Modal
          name={modalsReducer.name}
          data={modalsReducer.data}
          onRequestClose={() =>
            handleModal({ name: modalTypes.reset, data: {} })
          }
          //filters={{}}
          // createCustom={() => setModal("newAudienceCreate")}
        />
        <IdentityNewBar
          identities={identitiesReducer.list.map((identity: any) => {
            let label = identity.firstname || "";
            label = " " + (label + (identity.lastname || ""));
            if (label.trim() === "") {
              label = "Identity missing name";
            }
            return {
              value: identity.id,
              label: label,
              picture: identity.profilePicture,
              error: (Object.values(identity.reconnect) as boolean[]).every(
                (currentValue) => currentValue === false
              )
                ? null
                : "reconnect",
            };
          })}
          subPage="details"
          rightBtn={authReducer.user?.plan === "TRIAL" ? "upgrade" : "newId"}
          onUpgrade={() => history.push("/settings/pricing")}
          identityId={urlIdentityId}
          onSelectIdentity={(page, identityId) =>
            history.push("/identities/" + identityId + "/past")
          }
          identityLoading={
            loadingReducer.dataTypesLoaders[types.REQUESTS_DATA.LOAD_IDENTITIES]
          }
          goToIdentitiesHome={() => history.push("/identities")}
          onNewIdentity={() => history.push("/settings/payment/addid")}
        />
        <IdentityActionsBar
          linkC={Link}
          identityId={urlIdentityId}
          page="past"
        />

        {!loadingReducer.dataTypesLoaders[
          types.REQUESTS_DATA.LOAD_IDENTITY_LOGS
        ] && !firstLoad ? (
          identity && (
            <IdentityPast
              actions={identitiesReducer.logs.map((action: any) => ({
                id: action.id,
                leadId: action.leadId,
                firstName: action.lead?.firstname,
                lastName: action.lead?.lastname,
                avatar: action.lead?.profilePicture,
                companyName: action.lead?.companyName,
                subtitle: action.lead?.note,
                deleted: action.lead?.deleted,
                detail: {
                  channel: action.channel,
                  name: action.type
                    .replace("GOOGLE_", "EMAIL_")
                    .replace("EMAIL_SEND_EMAIL", "SEND_EMAIL"),
                  isSuccess: action.status === "SUCCESS",
                  message: action.message,
                },
                status:
                  action.lead?.lastActions?.lastStatus.find(
                    (status: any) => status.campaignId === action.campaignId
                  )?.status || "",
                campaignName: action.campaign?.name,
                campaignId: action.campaignId,
                when: action.createdAt,
                website: action.lead?.companyUrl,
              }))}
              goToCampaign={handleGotoCampaign}
              openLead={handleOpenLead}
            />
          )
        ) : (
          <IdentityPastLoading />
        )}
        {!loadingReducer.dataTypesLoaders.LOAD_IDENTITY_LOGS && !firstLoad && (
          <Paginate
            goToPage={handleChangePage}
            current={identitiesReducer.paginateL?.current}
            total={totalPaginate > 0 ? totalPaginate : 1}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default IdentitiesComponent;

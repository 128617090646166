import React, { useState, useEffect } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import {
  IdentityNewBar,
  IdentityActionsBar,
  IdentityDetails,
  IdentityCA,
  IdentityDetailsLoading,
  IdentityCALoading,
} from "@lagrowthmachine/lgmwebapp";
import { useDispatch } from "react-redux";
import * as actions from "../../actions";
import * as globalActions from "../../../../redux/actions";
import * as authActions from "../../../Auth/actions";
import types from "../../types";
import useSelector from "../../../../redux/useSelector";
import Modal, { types as modalTypes } from "../Modals";
import analyticsCall from "../../../../helpers/analyticsCall";

const IdentitiesComponent = (props: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const authReducer = useSelector((state) => state.authReducer);
  const identitiesReducer = useSelector((state) => state.identitiesReducer);
  const modalsReducer = useSelector((state) => state.modalsReducer);
  const loadingReducer = useSelector((state) => state.loadingReducer);
  const socketReducer = useSelector((state) => state.socketReducer);
  const params: any = useParams();
  const urlIdentityId = params.id;

  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    setFirstLoad(false);
  }, []);

  useEffect(() => {
    dispatch(authActions.loadUser({}));
    dispatch(globalActions.scrollToTop());
  }, [dispatch]);

  useEffect(() => {
    analyticsCall("identities", "identities details, id: " + urlIdentityId);
  }, [urlIdentityId]);

  useEffect(() => {
    dispatch(actions.loadIdentities({ query: {} }));
  }, [dispatch, urlIdentityId]);

  const identity = identitiesReducer.list.find(
    (identity: { id: string }) => identity.id === urlIdentityId
  );

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(globalActions.socketIsWidgetAvailable());
    }, 4000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [dispatch, socketReducer.widgetAvailable]);

  const handleSave = (obj: { id: string; state: any }) => {
    let myData = { ...identity, ...obj.state };
    dispatch(actions.editIdentity({ body: myData, params: { id: obj.id } }));
  };

  const handleSave2 = (obj: { id: string; state: any }) => {
    let myData = { ...identity, ...obj.state };
    dispatch(actions.editIdentity2({ body: myData, params: { id: obj.id } }));
  };

  const handleModal = ({ name, data }: { name: string; data: any }) => {
    dispatch(globalActions.modalUpdate({ name, data }));
  };

  const loading =
    loadingReducer.btnTypesLoaders.EDIT_IDENTITY ||
    loadingReducer.dataTypesLoaders[types.REQUESTS_DATA.LOAD_IDENTITIES] ||
    firstLoad;

  return (
    <div className="identities_details_container">
      <IdentityNewBar
        identities={identitiesReducer.list.map((identity: any) => {
          let label = identity.firstname || "";
          label = " " + (label + (identity.lastname || ""));
          if (label.trim() === "") {
            label = "Identity missing name";
          }
          return {
            value: identity.id,
            label: label,
            picture: identity.profilePicture,
            error: (Object.values(identity.reconnect) as boolean[]).every(
              (currentValue) => currentValue === false
            )
              ? null
              : "reconnect",
          };
        })}
        subPage="details"
        rightBtn={authReducer.user?.plan === "TRIAL" ? "upgrade" : "newId"}
        onUpgrade={() => history.push("/settings/pricing")}
        identityId={urlIdentityId}
        onSelectIdentity={(page, identityId) =>
          history.push("/identities/" + identityId + "/details")
        }
        identityLoading={
          loadingReducer.dataTypesLoaders[types.REQUESTS_DATA.LOAD_IDENTITIES]
        }
        goToIdentitiesHome={() => history.push("/identities")}
        onNewIdentity={() => history.push("/settings/payment/addid")}
      />
      <IdentityActionsBar
        linkC={Link}
        identityId={urlIdentityId}
        page="details"
      />

      <Modal
        name={modalsReducer.name}
        data={modalsReducer.data}
        onRequestClose={() => handleModal({ name: modalTypes.reset, data: {} })}
        // createCustom={() => setModal("newAudienceCreate")}
      />

      <div className="identity_details">
        {!loading ? (
          identity && (
            <IdentityDetails
              id={identity.id}
              firstName={identity.firstname}
              lastName={identity.lastname}
              jobTitle={identity.jobTitle}
              location={identity.location}
              companyName={identity.companyName}
              companyURL={identity.companyUrl}
              gender={identity.gender}
              identityId={identity.id}
              save={handleSave}
              loading={loading}
            />
          )
        ) : (
          <IdentityDetailsLoading />
        )}
        {!loading ? (
          identity && (
            <IdentityCA
              id={identity.id}
              customAttribute1={identity.customAttribute1}
              customAttribute2={identity.customAttribute2}
              customAttribute3={identity.customAttribute3}
              customAttribute4={identity.customAttribute4}
              customAttribute5={identity.customAttribute5}
              customAttribute6={identity.customAttribute6}
              customAttribute7={identity.customAttribute7}
              customAttribute8={identity.customAttribute8}
              customAttribute9={identity.customAttribute9}
              customAttribute10={identity.customAttribute10}
              save={handleSave2}
              loading={loading}
            />
          )
        ) : (
          <IdentityCALoading />
        )}
      </div>
    </div>
  );
};

export default IdentitiesComponent;

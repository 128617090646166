import SocketIOClient, { Socket } from "socket.io-client";
import types from "../types";
import { Action, Dispatch } from "redux";
import { IStoreApi } from "../configure";
import * as globalActions from "../actions";

interface ActionType extends Action {
  type: string;
  payload?: any;
}

export default (
  storeAPI: IStoreApi,
  createMyWebsocket: (
    storeAPI: IStoreApi,
    apikey?: string,
    userId?: string,
    memberId?: string
  ) => SocketIOClient.Socket | null
) => {
  let socket: any = null;

  // if (socket instanceof WebSocket) {
  return (next: Dispatch<ActionType>) => (action: ActionType) => {
    const user = storeAPI.getState().authReducer.user;
    /*
    const socketNotAvailable =
      String(socket?.readyState) !== "0" || String(socket?.readyState) !== "1";*/

    let socketio = socket as SocketIOClient.Socket;

    switch (action.type) {
      case types.SOCKET_INIT:
        if (user && !socketio?.connected) {
          socket = createMyWebsocket(
            storeAPI,
            user.apikey,
            user.id,
            user.memberId
          );
        }
        break;
      case types.SOCKET_DESTROY:
        if (socketio) {
          socketio.close();
        }
        break;
      case types.SOCKET_WIDGET_IS_AVAILABLE:
        if (socketio?.connected) {
          socketio?.send(
            JSON.stringify({
              action: "interaction",
              payload: { type: "IS_WIDGET_AVAILABLE" },
            })
          );
        }
        break;
      case types.SOCKET_CONNECT_CHANNEL:
        socketio?.send(
          JSON.stringify({
            action: "interaction",
            payload: {
              type: "CONNECT",
              identityId: action.payload.identityId,
              channel: action.payload.channel,
            },
          })
        );
        break;
      case types.SOCKET_DISCONNECT_CHANNEL:
        socketio?.send(
          JSON.stringify({
            action: "interaction",
            payload: {
              type: "DISCONNECT",
              identityId: action.payload.identityId,
              channel: action.payload.channel,
            },
          })
        );
        break;
      case types.SOCKET_OPEN_BROWSER:
        if (storeAPI.getState().socketReducer.widgetAvailable) {
          socketio?.send(
            JSON.stringify({
              action: "interaction",
              payload: {
                type: "LAUNCH_BROWSER",
              },
            })
          );
        } else {
          storeAPI.dispatch(
            globalActions.setNotificationSmall({
              notification: {
                type: "error",
                text: "Your LGM Widget needs to be opened",
              },
              format: "basic",
            })
          );
        }
        break;
      case types.SOCKET_ONBOARDING_FINISHED:
        socketio?.send(
          JSON.stringify({
            action: "interaction",
            payload: {
              type: "ONBOARDING_FINISHED",
            },
          })
        );
        break;
      default:
        break;
    }

    return next(action);
  };
};

const initialState = {
  connected: false,
  widgetAvailable: false,
};

export const socketReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.SOCKET_INIT:
      return {
        ...state,
        connected: true,
      };
    case types.SOCKET_DESTROY:
      return {
        ...state,
        connected: false,
      };
    case types.WIDGET_AVAILABLE:
      return {
        ...state,
        widgetAvailable: true,
      };
    case types.WIDGET_NOT_AVAILABLE:
      return {
        ...state,
        widgetAvailable: false,
      };
    default:
      return state;
  }
};

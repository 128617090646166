import Home from "./Home/";
import Overview from "./Overview/";
import Engagement from "./Engagement/";
import Actions from "./Actions/";
import Edit from "./Edit/";
import Recommended from "./Recommended";
import "./style.scss";

const Views = {
  Home,
  Overview,
  Engagement,
  Actions,
  Edit,
  Recommended,
};

export default Views;

import {
  useSelector as useReduxSelector,
  TypedUseSelectorHook,
} from "react-redux";
import { combinedReducersWithRouter } from "./configure";

type RootState = ReturnType<typeof combinedReducersWithRouter>;

const useSelector: TypedUseSelectorHook<
  RootState & { _persist: any }
> = useReduxSelector;

export default useSelector;

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Downgrade } from "@lagrowthmachine/lgmwebapp";
import { useDispatch } from "react-redux";
import * as authActions from "../../../Auth/actions";
import * as actions from "../../../Settings/actions";
import useSelector from "../../../../redux/useSelector";
import analyticsCall from "../../../../helpers/analyticsCall";

const SettingsDowngradeComponent = (props: any) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const loadingReducer = useSelector((state) => state.loadingReducer);
  const authReducer = useSelector((state) => state.authReducer);
  //const settingsReducer = useSelector((state) => state.settingsReducer);

  const planDowngrade = props.location?.state?.plan || "STARTER";

  useEffect(() => {
    dispatch(authActions.loadUser({ query: {} }));
  }, [dispatch]);

  useEffect(() => {
    analyticsCall("settings", "settings: downgrade confirmation");
  }, []);

  const [downgrade, setDowngrade] = useState(null);
  useEffect(() => {
    dispatch(
      actions.loadDowngrade({
        query: { plan: planDowngrade.toUpperCase() },
        setDowngrade: setDowngrade,
      })
    );
  }, [dispatch]);
  /*
  const pricesBody = () => {
    return {
      type: "changePlan",
      //coupon: undefined,
      planId: settingsReducer.plans[planDowngrade]["monthly"].id, //le back retrouve la plan qui va bien à partir de l'id du monthly. Il respectera les billing de chaque Id qui sont downgraded
      //identities: [], //identities et count sont sans objet ici puisqu'on downgrade ttes les identités
      //count: 1,
    };
  };
  */
  const onDowngrade = (/*stripe: any*/) => {
    //(window as any).Intercom("showNewMessage", "Hello I want to downgrade to "+planDowngrade+". Can you help me?");
    /*
    const downgradeEffectiveOn = (downgrade as any)?.downgradeEffectiveOn
      ? "on " +
        format(
          new Date((downgrade as any)?.downgradeEffectiveOn || 0),
          "yyyy MMMM dd"
        )
      : "as early as your identities billing cycle ends";
    dispatch(
      actions.processPayment({
        body: pricesBody(),
        afterPayment: (data: any) => {
          alert(
            "We have downgraded your plan to " +
              planDowngrade +
              ". The downgrade will be effective " +
              downgradeEffectiveOn +
              "."
          );
          history.push("/");
        },
      })
    );
    */
  };

  if (authReducer.user?.plan === planDowngrade) {
    history.push("/settings/pricing");
    return null;
  }

  const onContact = () => {
    (window as any).Intercom("showNewMessage");
  };

  return (
    <div className="settings_downgrade_container">
      {downgrade && (
        <Downgrade
          plan={planDowngrade}
          maxCampaigns={(downgrade as any)?.maxCampaigns || 0}
          pausedCampaigns={(downgrade as any)?.campaignsPaused?.names || []}
          leadsInPausedCampaigns={(downgrade as any)?.campaigns?.leads || 0}
          workflowBlocked={(downgrade as any)?.campaignsCustom?.names || []}
          leadsInWorkflowBlocked={
            (downgrade as any)?.campaignsCustom?.leads || 0
          }
          /*
          billedYearlyWarning={
            (downgrade as any)?.billedYearlyWarning || false
          }
          */
          billedYearlyWarning={false}
          crmWarning={(downgrade as any)?.crmWarning || false}
          enrichWarning={(downgrade as any)?.enrichWarning || false}
          externalApikeyWarning={
            (downgrade as any)?.externalApikeyWarning || false
          }
          backToSafety={() => history.goBack()}
          downgradeTo={onDowngrade}
          contact={onContact}
          loading={loadingReducer.btnTypesLoaders.PROCESS_PAYMENT}
          disabled={
            loadingReducer.dataTypesLoaders.LOAD_USER ||
            loadingReducer.btnTypesLoaders.PROCESS_PAYMENT
          }
        />
      )}
    </div>
  );
};

export default SettingsDowngradeComponent;

const barNavItems = (authReducer: any) => {
  /*
  let permissions: string[] = [];
  if (
    authReducer?.user?.permissions?.includes("pipedrive") ||
    authReducer?.user?.permissions?.includes("hubspot")
  ) {
    permissions = permissions.concat(["crm"]);
  }
  const bar: any = ["user", "campaign", "billing"]
    .concat(permissions)
    .concat(["api", "webhooks", "widget"]);
  return bar;
  */
  return [
    "member",
    "campaign",
    "crm",
    "api",
    "webhooks",
    "widget",
    "user",
    "teammates",
    "billing",
  ] as any;
};

export default barNavItems;

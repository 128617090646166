import React from "react";

import CampaignInfo from "./CampaignInfo";
import CampaignStop from "./CampaignStop";
import CampaignDelete from "./CampaignDelete";
import ConfirmLeadStatus from "./ConfirmLeadStatus";
import AddToAudience from "./AddToAudience";
import EditTemplate from "./EditTemplate";
import NewTemplate from "./NewTemplate";
import EditLeadConfirm from "../../../../components/Modals/EditLeadConfirm";
import PaymentSuccess from "../../../Settings/views/Modals/PaymentSuccess";
import PreviewGraph from "./PreviewGraph";
import NewAudienceCreate from "./NewAudienceCreate";
import Webhook from "../../../Settings/views/Modals/Webhook";
import ConfirmationDelete from "../../../../components/Modals/ConfirmationDelete";
import CampaignStart from "./CampaignStart";
import CampaignAudienceNew from "./CampaignAudienceNew";
import CampaignAudienceChoice from "./CampaignAudienceChoice";
import CampaignStopped from "./CampaignStopped";
import ImportAudienceCSV from "./ImportAudienceCSV";
import ImportLinkedin from "./ImportLinkedin";

import { types as settingsTypes } from "../../../Settings/views/Modals";

export const types = {
  campaignInfo: "campaignInfo",
  campaignDelete: "campaignDelete",
  campaignStop: "campaignStop",
  confirmLeadStatus: "confirmLeadStatus",
  campaignNewAudience: "campaignNewAudience",
  edit_lead_confirm: "edit_lead_confirm",
  paymentSuccess: "paymentSuccess",
  previewGraph: "previewGraph",
  newTemplate: "newTemplate",
  editTemplate: "editTemplate",
  newAudience: "newAudience",
  editAudience: "editAudience",
  confirmationDelete: "confirmationDelete",
  campaignStart: "campaignStart",
  newCampaignAudience: "newCampaignAudience",
  campaignAudienceChoice: "campaignAudienceChoice",
  campaignStopped: "campaignStopped",
  importAudienceCSV: "importAudienceCSV",
  importLinkedin: "importLinkedin",
  reset: "",
};

function Modals(props: any) {
  switch (props.name) {
    case types.campaignNewAudience:
      return <AddToAudience {...props} />;
    case types.campaignInfo:
      return <CampaignInfo {...props} />;
    case types.campaignDelete:
      return <CampaignDelete {...props} />;
    case types.campaignStop:
      return <CampaignStop {...props} />;
    case types.confirmLeadStatus:
      return <ConfirmLeadStatus {...props} />;
    case types.confirmationDelete:
      return <ConfirmationDelete {...props} />;
    case types.previewGraph:
      return <PreviewGraph {...props} />;
    case types.edit_lead_confirm:
      return <EditLeadConfirm {...props} />;
    case types.paymentSuccess:
      return <PaymentSuccess {...props} />;
    case types.campaignStart:
      return <CampaignStart {...props} />;
    case types.newTemplate:
      return <NewTemplate {...props} />;
    case types.editTemplate:
      return <EditTemplate {...props} />;
    case types.newAudience:
    case types.editAudience:
      return <NewAudienceCreate {...props} />;
    case settingsTypes.createWebhook:
      return <Webhook {...props} />;
    case settingsTypes.editWebhook:
      return <Webhook {...props} />;
    case types.newCampaignAudience:
      return <CampaignAudienceNew {...props} />;
    case types.campaignAudienceChoice:
      return <CampaignAudienceChoice {...props} />;
    case types.importAudienceCSV:
      return <ImportAudienceCSV {...props} />;
    case types.importLinkedin:
      return <ImportLinkedin {...props} />;
    case types.campaignStopped:
      return <CampaignStopped {...props} />;
    default:
      return null;
  }
}

export default Modals;

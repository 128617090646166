import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import ModalWrapper from "../../../../components/ModalWrapper";
import { GraphPreview } from "@lagrowthmachine/lgmwebapp";
import { attributes } from "../../data/configGraph";
import useSelector from "../../../../redux/useSelector";
import * as actions from "../../actions";
import { ICampaignState } from "../../interfaces/IState";
import { IStateWebhooks, IStateAudiences } from "../../../../interfaces/IState";
import * as adapterMsgTemplate from "../../adapters/messageTemplate";
import * as adapterWebhook from "../../adapters/webhook";
import * as adapterAudience from "../../adapters/audience";
import * as adaptersSequence from "../../adapters/sequence";

import types from "../../types";
import analyticsCall from "../../../../helpers/analyticsCall";
import analyticsTrack from "../../../../helpers/analyticsTrack";
import { useHistory } from "react-router-dom";

function Modal(props: any) {
  const history = useHistory();
  const dispatch = useDispatch();
  const campaignId = props.data.campaignId;
  const loadingReducer = useSelector((state) => state.loadingReducer);
  const campaignReducer: ICampaignState = useSelector(
    (state) => state.campaignReducer
  );
  const webhookReducer: IStateWebhooks = useSelector(
    (state) => state.webhookReducer
  );
  const audiencesReducer: IStateAudiences = useSelector(
    (state) => state.audienceReducer
  );

  const templates: any =
    adapterMsgTemplate.arrayOfGraphTemplate(campaignReducer.templates, "IN") ||
    [];
  const webhooks =
    adapterWebhook.arrayOfGraphWebhooks(webhookReducer?.list, "IN") || [];
  const audiences =
    adapterAudience.arrayOfGraphAudiences(audiencesReducer?.list, "IN") || [];

  useEffect(() => {
    dispatch(actions.loadCampaign({ query: {}, params: { id: campaignId } }));
    dispatch(
      actions.loadCampaignTemplates({ query: {}, params: { id: campaignId } })
    );
  }, [dispatch, campaignId]);

  const campaign = campaignReducer.selected;

  useEffect(() => {
    const _type = campaign.isPublic ? "template preview" : "campaign preview";
    if (campaign?.name)
      analyticsCall("campaign preview", `campaign preview: ${_type}`);
  }, [campaign]);

  const onAction = () => {
    if (campaign.isPublic) {
      analyticsTrack(`${campaign.name} created`);
      return dispatch(
        actions.duplicateCampaign({
          query: {},
          params: { id: campaign.id },
        })
      );
    } else {
      history.push(`/campaigns/${campaign.id}/edit`);
      props.onRequestClose();
    }
  };

  const position = campaign?.sequence?.seqV2?.position || {
    pan: { x: 0, y: 0 },
    scale: 1,
  };
  const graph = campaign?.sequence?.seqV2
    ? campaign?.sequence?.seqV2?.graph
    : adaptersSequence.seqV1ToV2(campaign?.sequence?.seqV1);
  const graphFrom = campaign?.sequence?.seqV2 ? "v2" : "v1";
  return (
    <ModalWrapper>
      {graph && !loadingReducer.dataTypesLoaders.LOAD_CAMPAIGN && (
        <GraphPreview
          hideErrors={campaign.isPublic}
          previewMode={
            graphFrom === "v2"
              ? campaign.isPublic
                ? "DUPLICATE"
                : "EDIT"
              : "NOTHING"
          }
          sequence={{
            name: campaign?.name,
            ending: campaign?.sequence?.ending || "none",
            customEnding: campaign?.sequence?.customEnding || [],
            language: campaign.language === "french" ? "fr" : "en",
            description: campaign?.description,
            enrich: false,
          }}
          templates={templates}
          attributes={attributes}
          webhooks={webhooks}
          audiences={audiences}
          graph={graph}
          position={position}
          onAction={onAction}
          cancel={props.onRequestClose}
          loading={
            loadingReducer.btnTypesLoaders[
              types.REQUESTS_ACTIONS.DUPLICATE_CAMPAIGN
            ]
          }
          graphFrom={graphFrom}
        />
      )}
    </ModalWrapper>
  );
}
export default Modal;

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  NewLeadsAside,
  LeadsEdit,
  LeadsNew,
  LeadsAsideLoading,
} from "@lagrowthmachine/lgmwebapp";
import * as leadActions from "../../Containers/Leads/actions";
import * as globalActions from "../../redux/actions";
import { useDispatch } from "react-redux";
import useSelector from "../../redux/useSelector";
import analyticsCall from "../../helpers/analyticsCall";
import useDebounce from "../../helpers/useDebounce";

function LeadASide(props: any) {
  const dispatch = useDispatch();
  const history = useHistory();
  const loadingReducer = useSelector((state) => state.loadingReducer);
  const authReducer = useSelector((state) => state.authReducer);

  const hubspot = authReducer?.user?.crm?.find(
    (crm: any) => crm.type === "HUBSPOT"
  );
  const pipedrive = authReducer?.user?.crm?.find(
    (crm: any) => crm.type === "PIPEDRIVE"
  );

  const [hubspotSearch, setHubspotSearch] = useState("");
  const debouncedHubspotSearch = useDebounce(hubspotSearch, 1000);
  const [hubspotOptions, setHubspotOptions] = useState([]);
  const [hubspotLoading, setHubspotLoading] = useState(false);

  const [pipedriveSearch, setPipedriveSearch] = useState("");
  const debouncedPipedriveSearch = useDebounce(hubspotSearch, 1000);
  const [pipedriveOptions, setPipedriveOptions] = useState([]);
  const [pipedriveLoading, setPipedriveLoading] = useState(false);

  const [lead, setLead] = useState(null as any);
  const [logs, setLogs] = useState({});

  const [mode, setMode] = useState(props.mode);

  //CHANGE MODE
  useEffect(() => {
    if (mode === "") {
      setLead(null as any);
    }
    setMode(props.mode);
  }, [props.mode]);

  //ON EDIT
  const onEdit = () => {
    if (lead?.id) {
      setMode("edit");
    }
  };

  //ON CLOSE
  const onClose = () => {
    props.onClose();
  };

  //LOAD LEAD + RESET LOGS
  useEffect(() => {
    if (props.id !== "") {
      setLogs({});
      dispatch(
        leadActions.loadLead({
          query: {},
          params: { leadId: props.id },
          setLead: setLead,
        })
      );
    }
  }, [dispatch, props.id]);

  //ANALYTICS
  useEffect(() => {
    if (mode === "new") {
      analyticsCall("leads new", `leads new one`);
    } else if (mode === "show" && lead) {
      analyticsCall(
        "lead",
        `lead details: ${lead?.firstName || "none"} ${lead?.lastName || "none"}`
      );
    } else if (mode === "edit" && lead) {
      analyticsCall(
        "lead",
        `lead edit: ${lead?.firstName || "none"} ${lead?.lastName || "none"}`
      );
    }
  }, [props.id, mode]);

  //NOTE SAVE
  const handleEditNote = (note: string) => {
    //if (!lead.note && note === "") return;
    if (lead.note === note) return;
    dispatch(
      leadActions.editLead({
        query: {},
        params: { leadId: props.id },
        body: {
          lead: {
            note,
          },
        },
        options: {
          preventNotification: true,
        },
        callback: props.callback,
      })
    );
  };

  //CREATE LEAD
  const createLead = async (state: any, forceCreate?: boolean) => {
    if (
      forceCreate !== true &&
      (state.hubspot?.lead?.id || state.pipedrive?.lead?.id)
    ) {
      dispatch(
        globalActions.modalUpdate({
          name: "edit_lead_confirm",
          data: {
            hubspot: state.hubspot?.lead?.id,
            pipedrive: state.pipedrive?.lead?.id,
            lead: {
              ...state,
              audiences: props.audiences || [],
            },
            confirmAction: () => createLead(state, true),
          },
        })
      );
    } else {
      dispatch(
        leadActions.createLead({
          query: { shouldNotify: true },
          body: {
            lead: {
              ...state,
              audiences: props.audiences || [],
            },
          },
          callback: props.callback,
        })
      );
      onClose();
    }
  };

  //EDIT LEAD
  const editLead = async (state: any, forceEdit?: boolean) => {
    if (
      forceEdit !== true &&
      ((state.hubspot?.lead?.id && state.hubspot?.lead?.id !== props.id) ||
        (state.pipedrive?.lead?.id && state.pipedrive?.lead?.id !== props.id))
    ) {
      dispatch(
        globalActions.modalUpdate({
          name: "edit_lead_confirm",
          data: {
            hubspot:
              state.hubspot?.lead?.id && state.hubspot?.lead?.id !== props.id,
            pipedrive:
              state.pipedrive?.lead?.id &&
              state.pipedrive?.lead?.id !== props.id,
            lead: state,
            confirmAction: () => editLead(state, true),
          },
        })
      );
    } else {
      dispatch(
        leadActions.editLead({
          params: {
            leadId: props.id,
          },
          body: { lead: state },
          callback: props.callback,
        })
      );
      onClose();
    }
  };

  const getCampaignsInvolved = (involvedCampaigns: any) => {
    if (!involvedCampaigns || involvedCampaigns.length === 0) return [];
    return involvedCampaigns.map(
      (campaign: {
        name: string;
        status: string;
        _id: string;
        leadStatus: string;
      }) => ({
        name: campaign.name,
        id: campaign._id,
        status: campaign.status,
        leadStatus:
          lead.lastActions.lastStatus.find(
            (c: any) => c.campaignId === campaign._id
          )?.status || null,
      })
    );
  };

  //LOAD MORE LOGS
  const onLoadMore = (obj: { campaignId: string; skip: number }) => {
    let _logs = logs;
    if (_logs[obj.campaignId]) {
      _logs[obj.campaignId] = {
        ..._logs[obj.campaignId],
        ...{ loading: true },
      };
    } else {
      _logs[obj.campaignId] = {
        campaignId: obj.campaignId,
        total: 0,
        logs: [],
        loading: true,
      };
    }
    setLogs(_logs);
    //fix
    dispatch(
      leadActions.loadLeadCampaignLogs({
        params: {
          leadId: props.id,
        },
        query: {
          campaignId: obj.campaignId,
          skip: obj.skip,
        },
        logs: logs,
        setLogs: setLogs,
        campaignId: obj.campaignId,
      })
    );
  };

  //CLOSE CAMPAIGN LOGS
  const onCloseCampaign = (campaignId: string) => {
    let _logs = logs;
    _logs[campaignId] = {
      campaignId: campaignId,
      total: _logs[campaignId].total,
      logs: [],
      loading: false,
    };
    setLogs(_logs);
  };

  //GO TO CAMPAIGN
  const goToCampaign = (campaignId: string) => {
    history.push(`/campaigns/${campaignId}/overview`);
  };

  //HUBSPOT SEARCH
  useEffect(() => {
    if (debouncedHubspotSearch !== hubspotSearch && hubspotSearch !== "") {
      setHubspotLoading(true);
      dispatch(
        leadActions.loadHubspotLeads({
          q: hubspotSearch,
          setHubspotOptions: setHubspotOptions,
          setHubspotLoading,
        })
      );
    }
  }, [dispatch, debouncedHubspotSearch, hubspotSearch]);

  //PIPEDRIVE SEARCH
  useEffect(() => {
    if (
      debouncedPipedriveSearch !== pipedriveSearch &&
      pipedriveSearch !== ""
    ) {
      setPipedriveLoading(true);
      dispatch(
        leadActions.loadPipedriveLeads({
          q: pipedriveSearch,
          setPipedriveOptions: setPipedriveOptions,
          setPipedriveLoading,
        })
      );
    }
  }, [dispatch, debouncedPipedriveSearch, pipedriveSearch]);

  switch (mode) {
    case "show":
      if (!lead) {
        return (
          <div className="lead-aside-container" key="leads-aside">
            <LeadsAsideLoading />
          </div>
        );
      }

      return (
        <div className="lead-aside-container" key="leads-aside">
          <NewLeadsAside
            key={lead.id}
            id={props.id}
            {...lead}
            save={handleEditNote}
            edit={onEdit}
            close={onClose}
            campaigns={getCampaignsInvolved(lead.involvedCampaigns)}
            loadMore={onLoadMore}
            logs={logs}
            audiences={
              lead.audiencesWithName?.map(
                (aud: { name: string }) => aud.name
              ) || []
            }
            hubspot={typeof hubspot === "object" ? lead.hubspot : null}
            pipedrive={typeof pipedrive === "object" ? lead.pipedrive : null}
            onCloseCampaign={onCloseCampaign}
            goToCampaign={goToCampaign}
          />
        </div>
      );
    case "edit":
      return (
        <div className="lead-aside-container" key="leads-aside">
          <LeadsEdit
            {...lead}
            campaigns={[]}
            save={editLead}
            close={onClose}
            btnSaveDisabled={
              loadingReducer.btnTypesLoaders.EDIT_LEAD ||
              loadingReducer.btnTypesLoaders.EDIT_CAMPAIGN_LEAD
            }
            btnSaveIsLoading={
              loadingReducer.btnTypesLoaders.EDIT_LEAD ||
              loadingReducer.btnTypesLoaders.EDIT_CAMPAIGN_LEAD
            }
            hubspotLoading={hubspotLoading}
            hubspotOptions={hubspotOptions}
            onSearchHubspot={setHubspotSearch}
            hubspot={typeof hubspot === "object" ? lead?.hubspot || true : null}
            pipedriveLoading={pipedriveLoading}
            pipedriveOptions={pipedriveOptions}
            onSearchPipedrive={setPipedriveSearch}
            pipedrive={
              typeof pipedrive === "object" ? lead?.pipedrive || true : null
            }
          />
        </div>
      );
    case "new":
      return (
        <div className="lead-aside-container" key="leads-aside">
          <LeadsNew
            save={createLead}
            close={onClose}
            btnSaveDisabled={loadingReducer.btnTypesLoaders.CREATE_LEAD}
            btnSaveIsLoading={loadingReducer.btnTypesLoaders.CREATE_LEAD}
            hubspotLoading={hubspotLoading}
            hubspotOptions={hubspotOptions}
            onSearchHubspot={setHubspotSearch}
            hubspot={typeof hubspot === "object"}
            pipedriveLoading={pipedriveLoading}
            pipedriveOptions={pipedriveOptions}
            onSearchPipedrive={setPipedriveSearch}
            pipedrive={typeof pipedrive === "object"}
          />
        </div>
      );
    default:
      return (
        <div
          key="leads-aside"
          style={{ transform: "translateX(519px)" }}
          className="lead-aside-container"
        >
          <div className="leads_aside" style={{ boxShadow: "none" }}>
            <div
              style={{ boxShadow: "none" }}
              className="leads_aside_wrapper"
            ></div>
          </div>
        </div>
      );
  }
}
export default LeadASide;

import config, { APP_ENV } from "../../../config";
import { encodeQuery } from "../../../helpers/encodeQuery";
import { IEndpointParams } from "../../../interfaces/IEndpoint";
import MethodType from "../../../interfaces/IMethod";
import getApiUrl from "../../../helpers/getApiUrl";

const defaultHeader = {
  "Content-Type": "application/json",
};
export default function getEndpoint({
  name,
  body,
  query,
  params,
  apiVersion,
}: IEndpointParams): {
  url: string;
  method: MethodType;
  headers?: { [name: string]: string };
  body?: any;
  crossDomain?: boolean;
} {
  let queryEncoded: any = "";

  if (query) {
    queryEncoded = encodeQuery(query);
  }
  switch (name) {
    case "setupIntent":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.users
        }setupintent?${queryEncoded}`,
        method: "POST",
        headers: defaultHeader,
        body,
      };
    case "loadIdentities":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.identities
        }?${queryEncoded}`,
        method: "GET",
      };
    case "loadIdentityLimits":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${config.endpoints.identities}${
          params.identityId
        }/activity?${queryEncoded}`,
        method: "GET",
      };
    case "loadLogs":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${config.endpoints.identities}${
          params.identityId
        }/logs?${queryEncoded}`,
        method: "GET",
      };
    case "createSubscriptions":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.identities
        }?${queryEncoded}`,
        method: "POST",
        headers: defaultHeader,
        body,
      };
    case "editIdentity":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${config.endpoints.identities}${
          params.id
        }?${queryEncoded}`,
        method: "PUT",
        headers: defaultHeader,
        body,
      };
    case "loadLoginUrl":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${config.endpoints.identities}${
          params.identityId
        }/${params.channel}/loginurl?${queryEncoded}`,
        method: "GET",
      };
    case "autodiscover":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${config.endpoints.identities}${
          params.identityId
        }/${params.channel}/autodiscover?${queryEncoded}`,
        method: "POST",
        headers: defaultHeader,
        body,
      };
    case "verifyImapSmpt":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${config.endpoints.identities}${
          params.identityId
        }/${params.channel}/verify?${queryEncoded}`,
        method: "POST",
        headers: defaultHeader,
        body,
      };
    case "addImapSmpt":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${config.endpoints.identities}${
          params.identityId
        }/${params.channel}/addtoken?${queryEncoded}`,
        method: "POST",
        headers: defaultHeader,
        body,
      };
    case "revokeImapSmtp":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${config.endpoints.identities}${
          params.identityId
        }/${params.channel}/revoke?${queryEncoded}`,
        method: "POST",
        headers: defaultHeader,
        body: {},
      };
    case "refreshSpfDkim":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${config.endpoints.identities}${
          params.identityId
        }/smtp/verifydns?${queryEncoded}`,
        method: "GET",
      };
    case "reassignWidget":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${config.endpoints.identities}${
          params?.identityId
        }/reassignwidget?${queryEncoded}`,
        method: "PUT",
        headers: defaultHeader,
        body,
      };
    default:
      return {
        url: ``,
        method: "GET",
        crossDomain: true,
      };
  }
}

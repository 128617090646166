import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  SettingsTopBar,
  SettingsActionsBar,
  WebhookHeader,
  WebhookItem,
} from "@lagrowthmachine/lgmwebapp";

import Modal, { types as modalTypes } from "../Modals";
import { useDispatch } from "react-redux";
import * as globalActions from "../../../../redux/actions";
import * as authActions from "../../../Auth/actions";
import * as actions from "../../actions";
import analyticsCall from "../../../../helpers/analyticsCall";
import { IHandleModal } from "../../interfaces/IModal";
import useSelector from "../../../../redux/useSelector";
// import SequenceItems from "./SequenceItems";
import barNavItems from "../../helpers/barNavItems";

const SettingsWebhooksComponent = (props: any) => {
  const dispatch = useDispatch();
  const loadingReducer = useSelector((state) => state.loadingReducer);
  const webhookReducer = useSelector((state) => state.webhookReducer);
  const modalsReducer = useSelector((state) => state.modalsReducer);
  const authReducer = useSelector((state) => state.authReducer);

  const bar = barNavItems(authReducer);

  // const blurContainer = sequencesReducer?.list?.length === 0;

  useEffect(() => {
    analyticsCall("settings", "settings: webhooks index");
  }, []);

  useEffect(() => {
    dispatch(globalActions.scrollToTop());
  }, [dispatch]);

  useEffect(() => {
    dispatch(globalActions.loadWebhooks({ query: {} }));
  }, [dispatch]);

  const handleModal = ({ name, data = {} }: IHandleModal) => {
    dispatch(globalActions.modalUpdate({ name, data }));
  };

  const handleActions = (action: string, data?: any) => {
    switch (action) {
      case "input":
        dispatch(authActions.signOutUser());
        break;
      case "create":
        handleModal({ name: modalTypes.createWebhook, data: {} });
        break;
      case "edit":
        handleModal({ name: modalTypes.editWebhook, data });
        break;
      case "delete":
        dispatch(actions.openConfirmationDeleteWebhook({ webhook: data }));
        break;
      case "learnMore":
        window.open(
          `https://help.lagrowthmachine.com/en/articles/3346913-webhooks`,
          "_blank"
        );
        break;
      default:
        break;
    }
  };
  const handleModalActions = (action: string, data?: any) => {
    switch (action) {
      case "createWebhook":
        dispatch(
          globalActions.createWebhook({
            query: {},
            body: data.webhook,
          })
        );
        break;
      case "close":
        handleModal({ name: modalTypes.reset, data: {} });
        break;
      case "editWebhook":
        dispatch(
          globalActions.editWebhook({
            query: {},
            params: { id: data.id },
            body: data.webhook,
          })
        );
        break;

      case "testWebhook":
        dispatch(
          globalActions.simulateWebhook({
            query: {},
            body: data,
          })
        );
        break;
      default:
        break;
    }
  };

  const handleModalDeleteConfirmation = (props: any) => {
    switch (props.name) {
      case "webhookDelete":
        return dispatch(
          globalActions.removeWebhook({
            query: { confirm: true },
            params: { id: props.webhook.id },
          })
        );
      default:
        break;
    }
  };

  return (
    <div className="settings_webhooks_container">
      <SettingsTopBar onAction={handleActions} />
      <div className="content_wrapper">
        <SettingsActionsBar
          tabs={bar}
          page={"webhooks"}
          linkC={Link}
          direction="vertical"
        />
        <div className="in">
          <Modal
            name={modalsReducer.name}
            data={modalsReducer.data}
            onRequestClose={() => handleModal({ name: modalTypes.reset })}
            onAction={handleModalActions}
            onDelete={handleModalDeleteConfirmation}
            btnDisabled={loadingReducer.btnTypesLoaders.REMOVE_WEBHOOK}
            btnIsLoading={loadingReducer.btnTypesLoaders.REMOVE_WEBHOOK}
          />
          <WebhookHeader
            onAction={handleActions}
            new={!(webhookReducer?.list?.length > 0)}
          />
          <div className="webhooks_container">
            {webhookReducer?.list?.map((webhook: any, key: number) => (
              <WebhookItem
                onAction={(action) => handleActions(action, webhook)}
                key={key}
                name={webhook.name}
                description={webhook.description}
                url={webhook.url}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsWebhooksComponent;

import React from "react";
import ReactModal from "react-modal";
import useSelector from "../../redux/useSelector";
import { ModalBasic } from "@lagrowthmachine/lgmwebapp";
import { useDispatch } from "react-redux";
import types from "../../Containers/Leads/types";
import { modalUpdate } from "../../redux/actions";

ReactModal.setAppElement("#root");

function Modal(props: any) {
  const dispatch = useDispatch();
  const loadingReducer = useSelector((state) => state.loadingReducer);

  const { data } = props;

  const onRequestClose = () => {
    dispatch(modalUpdate({ name: "reset", data: {} }));
  };

  return (
    <ReactModal
      isOpen={props.name ? true : false}
      // onRequestClose={props.onRequestClose}
      contentLabel="Modal"
    >
      <ModalBasic
        type="warning"
        title="Contact already linked"
        subtitle={
          (data.hubspot
            ? "This hubspot id is already linked to <strong>" +
              (data.lead.hubspot?.lead?.firstname || "firstname not set") +
              " " +
              (data.lead.hubspot?.lead?.lastname || "lastname not set") +
              " " +
              (data.lead.hubspot?.lead?.company || "Company not set")
            : "") +
          (data.pipedrive
            ? "This hubspot id is already linked to <strong>" +
              (data.lead.hubspot?.lead?.firstname || "firstname not set") +
              " " +
              (data.lead.hubspot?.lead?.lastname || "lastname not set") +
              " " +
              (data.lead.hubspot?.lead?.company || "Company not set")
            : "") +
          "</strong>.<br/>Saving this association will merge both leads according to your <a target='_blank' href='/settings'>duplicate settings</a>."
        }
        onAction={data.confirmAction}
        onCancel={onRequestClose}
        actionTextBtn="Save association"
        cancelTextBtn="Cancel"
        btnIsLoading={
          loadingReducer.btnTypesLoaders[types.REQUESTS_ACTIONS.EDIT_LEAD]
        }
        btnDisabled={
          loadingReducer.btnTypesLoaders[types.REQUESTS_ACTIONS.EDIT_LEAD]
        }
      />
    </ReactModal>
  );
}
export default Modal;

import React from "react";
import ModalWrapper from "../../../../components/ModalWrapper";
import { ApiLogDetail } from "@lagrowthmachine/lgmwebapp";

function Modal(props: any) {
  return (
    <ModalWrapper>
      {props.data && (
        <ApiLogDetail
          title={props.data.apiName || ""}
          onClose={props.onRequestClose}
          code={props.data.statusCode || -1}
          valid={props.data.statusCode === 200}
          execution={props.data.responseText}
          request={props.data.body || {}}
          response={props.data.response || {}}
          time={(props.data.createdAt || 0) / 1000}
        />
      )}
    </ModalWrapper>
  );
}

export default Modal;

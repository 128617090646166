import React, { useState } from "react";
import ModalWrapper from "../../../../components/ModalWrapper";
import useSelector from "../../../../redux/useSelector";
import { EmailConnection } from "@lagrowthmachine/lgmwebapp";
import { useDispatch } from "react-redux";
import * as globalActions from "../../../../redux/actions";
import * as actions from "../../actions";
import {
  verifyImapSmpt,
  autodiscover,
  addImapSmpt,
  revokeImapSmtp,
} from "../../functions";

function Modal(props: any) {
  const dispatch = useDispatch();
  const authReducer = useSelector((state) => state.authReducer);
  const identitiesReducer = useSelector((state) => state.identitiesReducer);
  const [loaders, setLoaders] = useState({
    loadingTunning: false,
    loadingSubmit: false,
    loadingRevoke: false,
  });

  const smtpData = identitiesReducer.list.find(
    (_identity: any) =>
      _identity.id === props.data.identityId &&
      _identity.emailData?.type === "SMTP"
  )?.emailData?.tokens;

  const idFirstname = identitiesReducer.list.find(
    (_identity: any) => _identity.id === props.data.identityId
  )?.firstname;
  const idLastname = identitiesReducer.list.find(
    (_identity: any) => _identity.id === props.data.identityId
  )?.lastname;

  const defaultSenderName =
    idFirstname && idLastname ? idFirstname + " " + idLastname : "";

  const _initConnection = {
    tunning: false,
    customPassword: false,
    email: smtpData?.email || "",
    password: "",
    senderFullName: smtpData?.name || defaultSenderName,
    imap: {
      host: smtpData?.imap?.host || "",
      port: smtpData?.imap?.port || 993,
      password: "",
      status: null,
    },
    smtp: {
      host: smtpData?.smtp?.host || "",
      port: smtpData?.smtp?.port || 587,
      password: "",
      status: null,
    },
    globalStatus: Boolean(smtpData),
  };

  const [connection, setConnection] = useState(_initConnection as any);

  const updateState = (obj: any) => {
    let _connection = { ...connection, ...obj };
    _connection.imap.status = null;
    _connection.smtp.status = null;
    setConnection(_connection);
  };

  const onCheck = (server: "imap" | "smtp") => {
    setConnection({
      ...connection,
      [server]: { ...connection[server], status: "loading" },
    });

    const body = {
      imap: {
        auth: {
          user: connection.email,
          pass: connection.customPassword
            ? connection.imap.password
            : connection.password,
        },
        host: connection.imap.host,
        port: connection.imap.port,
        secure: true,
      },
      smtp: {
        auth: {
          user: connection.email,
          pass: connection.customPassword
            ? connection.smtp.password
            : connection.password,
        },
        host: connection.smtp.host,
        port: connection.smtp.port,
        secure: false,
      },
    };

    verifyImapSmpt({ authReducer, ...props.data, body })
      .then((response) => {
        setConnection({
          ...connection,
          [server]: { ...connection[server], status: response[server].success },
        });
      })
      .catch((e) => {
        setConnection({
          ...connection,
          [server]: { ...connection[server], status: null },
        });
        dispatch(
          globalActions.setNotificationSmall({
            notification: {
              type: "error",
              text: String(e),
            },
            format: "basic",
          })
        );
      });
  };

  const onSubmit = (mode: "basic" | "tunned") => {
    if (mode === "basic") {
      const body = {
        user: connection.email,
        pass: connection.password,
      };
      setLoaders({ ...loaders, loadingTunning: true });
      autodiscover({ authReducer, ...props.data, body })
        .then((response) => {
          setLoaders({ ...loaders, loadingTunning: false });
          setConnection({
            ...connection,
            globalStatus: "check_tunning",
            tunning: true,
            imap: {
              host: response.imap.host,
              port: response.imap.port,
              password: "",
              status: null,
            },
            smtp: {
              host: response.smtp.host,
              port: response.smtp.port,
              password: "",
              status: null,
            },
          });
          //remove notifcation when autodiscover is ok
          dispatch(
            globalActions.setNotificationSmall({
              notification: {
                type: "warning",
                text: "Please check imap/smtp",
              },
              format: "basic",
            })
          );
        })
        .catch((e) => {
          setLoaders({ ...loaders, loadingTunning: false });
          setConnection({
            ...connection,
            globalStatus: "required_tunning",
            tunning: true,
          });
          dispatch(
            globalActions.setNotificationSmall({
              notification: {
                type: "warning",
                text:
                  "Autodiscover is not available yet. Please setup, imap/smtp manually",
              },
              format: "basic",
            })
          );
        });
    } else if (mode === "tunned") {
      setLoaders({ ...loaders, loadingSubmit: true });
      const body = {
        account: connection.email,
        name: connection.senderFullName,
        imap: {
          auth: {
            user: connection.email,
            pass: connection.customPassword
              ? connection.imap.password
              : connection.password,
          },
          host: connection.imap.host,
          port: connection.imap.port,
          secure: true,
        },
        smtp: {
          auth: {
            user: connection.email,
            pass: connection.customPassword
              ? connection.smtp.password
              : connection.password,
          },
          host: connection.smtp.host,
          port: connection.smtp.port,
          secure: false,
        },
      };

      addImapSmpt({ authReducer, ...props.data, body })
        .then((response) => {
          setLoaders({ ...loaders, loadingSubmit: false });
          setConnection({ ...connection, globalStatus: true, tunning: false });
          dispatch(
            globalActions.setNotificationSmall({
              notification: {
                type: "success",
                text: "IMAP/SMTP correctely configured",
              },
              format: "basic",
            })
          );
          dispatch(actions.loadIdentities({}));
          setTimeout(() => {
            props.onRequestClose();
          }, 1500);
        })
        .catch((e) => {
          setLoaders({ ...loaders, loadingSubmit: false });
          setConnection({
            ...connection,
            globalStatus: "wrong_server",
            tunning: true,
          });
          dispatch(
            globalActions.setNotificationSmall({
              notification: {
                type: "error",
                text: String(e),
              },
              format: "basic",
            })
          );
        });
    }
    return;
  };

  const onDisconnect = () => {
    setLoaders({ ...loaders, loadingRevoke: true });
    revokeImapSmtp({ authReducer, ...props.data })
      .then((response) => {
        setLoaders({ ...loaders, loadingRevoke: false });
        dispatch(actions.loadIdentities({}));
        /*
        dispatch(
          globalActions.setNotificationSmall({
            notification: {
              type: "success",
              text: "Your IMAP/SMTP config has been removed",
            },
            format: "basic",
          })
        );
        */
        props.onRequestClose();
      })
      .catch((e) => {
        setLoaders({ ...loaders, loadingRevoke: false });
        dispatch(
          globalActions.setNotificationSmall({
            notification: {
              type: "error",
              text: String(e),
            },
            format: "basic",
          })
        );
      });
  };

  return (
    <ModalWrapper>
      <EmailConnection
        loadingTunning={loaders.loadingTunning}
        loadingSubmit={loaders.loadingSubmit}
        loadingRevoke={loaders.loadingRevoke}
        connection={connection}
        updateState={updateState}
        onSubmit={onSubmit}
        onCheck={onCheck}
        onCancel={props.onRequestClose}
        onDisconnect={onDisconnect}
      />
    </ModalWrapper>
  );
}
export default Modal;

import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import * as actions from "../../actions";
import { NewMessagesTemplate, type } from "@lagrowthmachine/lgmwebapp";
import { defaultValues } from "../../data/configTemplate";
import endpointsAuth from "../../../Auth/endpoints";
import useSelector from "../../../../redux/useSelector";
import types from "../../types";
import uploadFileAsync from "../../../../helpers/uploadFileAsync";
import analyticsCall from "../../../../helpers/analyticsCall";

const confirmText = "You have unsaved changes. Are you sure?";

function Modal(props: any) {
  const dispatch = useDispatch();
  const [templateEdited, setTemplateEdited] = useState(false);
  const campaignReducer = useSelector((state) => state.campaignReducer);
  const authReducer = useSelector((state) => state.authReducer);
  const loadingReducer = useSelector((state) => state.loadingReducer);
  const templateData = {
    ...props.data,
    template: (campaignReducer as any).messageSelected,
  };
  const sameThread =
    templateData.template?.channel === "GMAIL"
      ? templateData.template.sameThread || false
      : null;

  useEffect(() => {
    dispatch(
      actions.loadMessage({
        query: {},
        params: { messageId: templateData.template_id },
      })
    );
  }, [dispatch, templateData.template_id]);

  useEffect(() => {
    if (templateData.template && templateData?.modeInit === "WRITE")
      analyticsCall(
        "message",
        `message edit ${
          templateData.template.channel === "GMAIL"
            ? "email"
            : templateData.template.channel.toLowerCase()
        }: ${templateData.template.name}`
      );

    if (templateData.template && templateData?.modeInit === "PREVIEW")
      analyticsCall(
        "message",
        `message edit ${
          templateData.template.channel === "GMAIL"
            ? "email"
            : templateData.template.channel.toLowerCase()
        }: ${templateData.template.name}`
      );
  }, [templateData]);

  useEffect(() => {
    window.onbeforeunload = function () {
      return window.confirm(confirmText);
    };
    return () => {
      window.onbeforeunload = function () {};
    };
  }, []);
  const handleUploadPic = async (file: any) => {
    return uploadFileAsync(
      file,
      authReducer.user.apikey,
      endpointsAuth,
      undefined,
      undefined,
      undefined,
      authReducer?.user?.apiVersion
    );
  };

  const onRequestClose = (data: {
    subjectState: any;
    contentState: any;
    subjectHTML: any;
    contentHTML: any;
    channel: type.ChannelType;
    name: string | null;
    cc: string | null;
    bcc: string | null;
  }) => {
    let close: boolean = true;

    if (templateEdited) {
      close = window.confirm(confirmText);
    }
    if (close) {
      props.onRequestClose();
    }
  };

  const btnSaveIsLoading =
    loadingReducer.btnTypesLoaders[
      types.REQUESTS_ACTIONS.EDIT_CAMPAIGN_TEMPLATE
    ];
  const btnSaveDisabled = btnSaveIsLoading;

  return (
    (templateData.template && !loadingReducer.dataTypesLoaders.LOAD_MESSAGE && (
      <NewMessagesTemplate
        templateWasModified={() => {
          setTemplateEdited(true);
        }}
        sameThread={sameThread}
        uploadPic={handleUploadPic}
        modeInit={templateData?.modeInit || "WRITE"}
        id={templateData.template_id || null}
        channel={templateData.template.channel || "GMAIL"}
        createdAt={templateData.template.createdAt || new Date().getTime()}
        name={templateData.template.name || null}
        cc={templateData.template.cc || null}
        bcc={templateData.template.bcc || null}
        saveTemplate={props.onEditTemplate}
        close={onRequestClose}
        campaigns={[]}
        contentHTML={templateData.template.contentHTML}
        subjectHTML={templateData.template.subjectHTML}
        defaultValues={defaultValues}
        subjectState={templateData.template.subjectState}
        btnSaveIsLoading={btnSaveIsLoading}
        btnSaveDisabled={btnSaveDisabled}
        contentState={templateData.template.contentState}
      />
    )) ||
    null
  );
}
export default Modal;

import { type } from "@lagrowthmachine/lgmwebapp";
import { createAction } from "redux-actions";
import types from "./types";

/**
 * Global actions
 */

export const clearNotifications = () => ({
  type: types.NOTIFICATION_CLEAR,
  payload: {},
});
export const clearAudiences = () => ({
  type: types.AUDIENCE_CLEAR,
  payload: {},
});
export const clearWebhooks = () => ({
  type: types.WEBHOOK_CLEAR,
  payload: {},
});

export const setNotificationSmall = (values: any) => ({
  type: types.SET_NOTIFICATION,
  payload: {
    id: values.id || Date.now(),
    ...values,
  },
});
export const setNotificationPersist = (values: any) => ({
  type: types.SET_NOTIFICATION_PERSIST,
  payload: {
    id: values.id || Date.now(),
    ...values,
  },
});

export const removeNotificationSmall = (values: any) => ({
  type: types.REMOVE_NOTIFICATION,
  payload: values,
});

export const modalUpdate = (payload: any) => ({
  type: types.UPDATE_MODAL,
  payload,
});
/**
 * Audiences
 */

export const loadAudiences = (values: any) => ({
  type: types.REQUESTS_DATA.LOAD_AUDIENCES,
  payload: values,
});
export const loadAudiencesFulfilled = (payload: any) => ({
  type: types.RESPONSES_DATA.LOAD_AUDIENCES_FULFILLED,
  payload,
});
export const rejectedLoadAudiences = (payload?: any) => ({
  type: types.RESPONSES_DATA.LOAD_AUDIENCES_REJECTED,
  payload,
});

export const createAudience = (values: any) => ({
  type: types.REQUESTS_ACTIONS.CREATE_AUDIENCE,
  payload: values,
});
export const createAudienceFulfilled = (payload: any) => ({
  type: types.RESPONSES_ACTIONS.CREATE_AUDIENCE_FULFILLED,
  payload,
});
export const rejectedCreateAudience = (payload?: any) => ({
  type: types.RESPONSES_ACTIONS.CREATE_AUDIENCE_REJECTED,
  payload,
});

export const editAudience = (values: any) => ({
  type: types.REQUESTS_ACTIONS.EDIT_AUDIENCE,
  payload: values,
});
export const editAudienceFulfilled = (payload: any) => ({
  type: types.RESPONSES_ACTIONS.EDIT_AUDIENCE_FULFILLED,
  payload,
});
export const rejectedEditAudience = (payload?: any) => ({
  type: types.RESPONSES_ACTIONS.EDIT_AUDIENCE_REJECTED,
  payload,
});

export const deleteAudience = (values: any) => ({
  type: types.REQUESTS_ACTIONS.DELETE_AUDIENCE,
  payload: values,
});
export const deleteAudienceFulfilled = (payload: any) => ({
  type: types.RESPONSES_ACTIONS.DELETE_AUDIENCE_FULFILLED,
  payload,
});
export const rejectedDeleteAudience = (payload?: any) => ({
  type: types.RESPONSES_ACTIONS.DELETE_AUDIENCE_REJECTED,
  payload,
});

/**
 * Webhooks
 */

export const loadWebhooks = (values: any) => ({
  type: types.REQUESTS_DATA.LOAD_WEBHOOKS,
  payload: values,
});
export const loadWebhooksFulfilled = (payload: any) => ({
  type: types.RESPONSES_DATA.LOAD_WEBHOOKS_FULFILLED,
  payload,
});
export const rejectedLoadWebhooks = (payload?: any) => ({
  type: types.RESPONSES_DATA.LOAD_WEBHOOKS_REJECTED,
  payload,
});

export const createWebhook = (values: any) => ({
  type: types.REQUESTS_ACTIONS.CREATE_WEBHOOK,
  payload: values,
});
export const createWebhookFulfilled = (payload: any) => ({
  type: types.RESPONSES_ACTIONS.CREATE_WEBHOOK_FULFILLED,
  payload,
});
export const rejectedCreateWebhook = (payload?: any) => ({
  type: types.RESPONSES_ACTIONS.CREATE_WEBHOOK_REJECTED,
  payload,
});

export const editWebhook = (values: any) => ({
  type: types.REQUESTS_ACTIONS.EDIT_WEBHOOK,
  payload: values,
});
export const editWebhookFulfilled = (payload: any) => ({
  type: types.RESPONSES_ACTIONS.EDIT_WEBHOOK_FULFILLED,
  payload,
});
export const rejectedEditWebhook = (payload?: any) => ({
  type: types.RESPONSES_ACTIONS.EDIT_WEBHOOK_REJECTED,
  payload,
});

export const removeWebhook = (values: any) => ({
  type: types.REQUESTS_ACTIONS.REMOVE_WEBHOOK,
  payload: values,
});
export const removeWebhookFulfilled = (payload: any) => ({
  type: types.RESPONSES_ACTIONS.REMOVE_WEBHOOK_FULFILLED,
  payload,
});
export const rejectedRemoveWebhook = (payload?: any) => ({
  type: types.RESPONSES_ACTIONS.REMOVE_WEBHOOK_REJECTED,
  payload,
});
export const simulateWebhook = createAction(
  types.REQUESTS_ACTIONS.SIMULATE_WEBHOOK,
  (payload: any) => payload
);
export const simulateWebhookFulfilled = createAction(
  types.RESPONSES_ACTIONS.SIMULATE_WEBHOOK_FULFILLED,
  (payload: any) => payload
);
export const rejectedSimulateWebhook = createAction(
  types.RESPONSES_ACTIONS.SIMULATE_WEBHOOK_REJECTED,
  (payload: any) => payload
);

export const assignationWebhook = (payload: any) => {
  return {
    type: types.ASSIGNATION_WEBHOOK,
    payload,
  };
};
export const assignationWebhookClear = () => {
  return {
    type: types.ASSIGNATION_WEBHOOK_CLEAR,
  };
};

export const assignationAudience = (payload: any) => {
  return {
    type: types.ASSIGNATION_AUDIENCE,
    payload,
  };
};
export const assignationAudienceClear = () => {
  return {
    type: types.ASSIGNATION_AUDIENCE_CLEAR,
  };
};

export const updateBtnLoaders = (payload: any) => {
  return {
    type: types.UPDATE_BTN_LOADERS,
    payload,
  };
};

export const updateDataLoaders = (payload: any) => {
  return {
    type: types.UPDATE_DATA_LOADERS,
    payload,
  };
};

export const addAction = (payload: any) => {
  return {
    type: types.ADD_ACTION,
    payload,
  };
};

export const removeAction = (payload: any) => {
  return {
    type: types.REMOVE_ACTION,
    payload,
  };
};

export const socketInit = createAction(types.SOCKET_INIT);
export const socketDestroy = createAction(types.SOCKET_DESTROY);
export const socketConnectChannel = createAction(
  types.SOCKET_CONNECT_CHANNEL,
  (payload: { identityId: string; channel: type.ChannelType }) => payload
);
export const socketDisconnectChannel = createAction(
  types.SOCKET_DISCONNECT_CHANNEL,
  (payload: { identityId: string; channel: type.ChannelType }) => payload
);
export const socketOpenBrowser = createAction(types.SOCKET_OPEN_BROWSER);
export const socketOnboardingFinished = createAction(
  types.SOCKET_ONBOARDING_FINISHED
);
export const socketIsWidgetAvailable = createAction(
  types.SOCKET_WIDGET_IS_AVAILABLE
);

export const widgetAvailable = createAction(types.WIDGET_AVAILABLE);
export const widgetNotAvailable = createAction(types.WIDGET_NOT_AVAILABLE);

export const scrollToTop = () => {
  return {
    type: types.SCROLL_TO_TOP,
  };
};

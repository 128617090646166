import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  CampaignHomeTopBar,
  CampaignHomeStoreBar,
  GraphSample,
  GraphTag,
  CampaignHomeActiveBar2,
  CampaignLimits,
  CampaignHomeDraftBar,
  Button,
} from "@lagrowthmachine/lgmwebapp";
import Modal, { types as modalTypes } from "../Modals";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ICampaignState } from "../../interfaces/IState";
import { IStateWebhooks, IStateAudiences } from "../../../../interfaces/IState";
import { attributes } from "../../data/configGraph";
import shouldWidgetShowDisconnect from "../../helpers/shouldWidgetShowDisconnect";
import * as adapterWebhook from "../../adapters/webhook";
import * as adapterAudience from "../../adapters/audience";
import * as authActions from "../../../Auth/actions";
import * as globalActions from "../../../../redux/actions";
import * as actions from "../../actions";
import * as identitiesActions from "../../../Identities/actions";
import types from "../../types";
import useSelector from "../../../../redux/useSelector";
import useDebounce from "../../../../helpers/useDebounce";
import constants from "../../../../config/constants";
import analyticsCall from "../../../../helpers/analyticsCall";

const CampaignComponent = (props: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loadingReducer = useSelector((state) => state.loadingReducer);
  const authReducer = useSelector((state) => state.authReducer);
  const campaignReducer: ICampaignState = useSelector(
    (state) => state.campaignReducer
  );
  const webhookReducer: IStateWebhooks = useSelector(
    (state) => state.webhookReducer
  );
  const audiencesReducer: IStateAudiences = useSelector(
    (state) => state.audienceReducer
  );
  const modalsReducer = useSelector((state) => state.modalsReducer);

  const [campaigns, setCampaigns] = useState([]);

  const [campaignsStatus, setCampaignsStatus] = useState("all");
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 500);

  const [campaignsTotal, setCampaignsTotal] = useState(0);

  const [campaignsDraft, setCampaignsDraft] = useState([]);
  const [campaignsDraftTotal, setCampaignsDraftTotal] = useState(0);

  useEffect(() => {
    analyticsCall("campaigns", "campaigns index");
  }, []);

  const handleModal = useCallback(
    ({ name, data }: { name: string; data?: any }) => {
      dispatch(globalActions.modalUpdate({ name, data }));
    },
    [dispatch]
  );

  const onAskForReview = useCallback(
    (campaignId?: string) => {
      dispatch(actions.campaignReview({ params: { id: campaignId } }));
    },
    [dispatch]
  );

  const triggerTour = useCallback(
    (feature: string) => {
      const tourId = constants.intercomTourIds[feature][authReducer.user.plan];
      if (tourId) {
        (window as any).Intercom("startTour", tourId);
      }
    },
    [authReducer.user.plan]
  );

  const onWatchReview = useCallback(() => {
    handleModal({ name: modalTypes.reset });
    triggerTour("review");
  }, [handleModal, triggerTour]);

  const onUpgrade = useCallback(
    (plan: any) => {
      handleModal({ name: modalTypes.reset });
      history.push("/settings/pricing");
    },
    [handleModal, history]
  );

  const pageLoadOrRefresh = useCallback(() => {
    const statusConverted = {
      all: ["RUNNING", "PAUSED", "CANCELED"],
      canceled: ["CANCELED"],
      paused: ["PAUSED"],
    };
    const status = statusConverted[campaignsStatus];
    setCampaignsTotal(0);
    dispatch(
      actions.loadCampaigns({
        status: status,
        search: debouncedSearch || "",
        campaigns: [],
        setCampaigns: setCampaigns,
        setTotal: setCampaignsTotal,
      })
    );
    dispatch(
      actions.loadCampaigns({
        status: ["READY"],
        skip: 0,
        campaigns: [],
        setCampaigns: setCampaignsDraft,
        setTotal: setCampaignsDraftTotal,
      })
    );
    dispatch(authActions.loadUser({}));
  }, [dispatch, campaignsStatus, debouncedSearch]);

  const onChangeStatus = useCallback(
    (nextStatus: "START" | "PAUSE" | "CANCEL", _campaign: any) => {
      dispatch(
        actions.editCampaignState({
          callback: pageLoadOrRefresh,
          params: {
            id: _campaign.id,
            status: nextStatus,
          },
        })
      );
    },
    [dispatch, pageLoadOrRefresh]
  );

  useEffect(() => {
    dispatch(actions.loadCampaignsRecommended({}));
    dispatch(globalActions.loadAudiences({ query: {} }));
    dispatch(globalActions.loadWebhooks({ query: {} }));
    dispatch(identitiesActions.loadIdentities({ query: {} }));
  }, [dispatch]);

  useEffect(() => {
    pageLoadOrRefresh();
  }, [dispatch, campaignsStatus, debouncedSearch]);

  const onLoadMoreCampaigns = () => {
    const statusConverted = {
      all: ["RUNNING", "PAUSED", "CANCELED"],
      canceled: ["CANCELED"],
      paused: ["PAUSED"],
    };
    const status = statusConverted[campaignsStatus];
    dispatch(
      actions.loadCampaigns({
        status: status,
        search: debouncedSearch || "",
        campaigns: campaigns,
        setCampaigns: setCampaigns,
        setTotal: setCampaignsTotal,
      })
    );
  };

  const onLoadMoreCampaignsDraft = () => {
    dispatch(
      actions.loadCampaigns({
        status: ["READY"],
        campaigns: campaignsDraft,
        setCampaigns: setCampaignsDraft,
        setTotal: setCampaignsDraftTotal,
      })
    );
  };

  const handleCampaignAction = useCallback(
    (action: string, _campaign: any) => {
      switch (action) {
        case "pause":
          onChangeStatus("PAUSE", _campaign);
          break;
        case "resume":
          if (_campaign.status === "PAUSED") {
            onChangeStatus("START", _campaign);
          } else if (_campaign.status === "READY") {
            handleModal({
              name: modalTypes.campaignStart,
              data: {
                onAskForReview: () => onAskForReview(_campaign.id),
                onWatchReview,
                onUpgrade,
                onAction: () => onChangeStatus("START", _campaign),
              },
            });
          }
          break;
        case "cancel":
          handleModal({
            name: modalTypes.campaignStop,
            data: {
              campaign: _campaign,
              refresh: pageLoadOrRefresh,
            },
          });
          break;
        case "delete":
          handleModal({
            name: modalTypes.campaignDelete,
            data: {
              campaign: _campaign,
              refresh: pageLoadOrRefresh,
            },
          });
          break;
        case "duplicate":
          dispatch(
            actions.duplicateCampaign({
              data: { closeModal: () => console.log("") },
              query: {},
              params: { id: _campaign.id },
            })
          );
          break;
        default:
      }
    },
    [
      dispatch,
      handleModal,
      onAskForReview,
      //onChangeStatus,
      onUpgrade,
      onWatchReview,
      //pageLoadOrRefresh,
    ]
  );

  const openPreview = useCallback(
    (campaignId: string) => {
      return dispatch(
        globalActions.modalUpdate({
          name: "previewGraph",
          data: { campaignId },
        })
      );
    },
    [dispatch]
  );

  const webhooks =
    adapterWebhook.arrayOfGraphWebhooks(webhookReducer?.list, "IN") || [];

  const audiences =
    adapterAudience.arrayOfGraphAudiences(audiencesReducer?.list, "IN") || [];

  const recommended = useMemo(() => {
    let _recommended = {};
    (campaignReducer.recommended || [])
      .sort((_campaignA: any, _campaignB: any) =>
        _campaignA.order > _campaignB.order ? 1 : -1
      )
      .some((_campaign: any) => {
        _recommended[_campaign.id] = (
          <GraphSample
            key={_campaign.id}
            attributes={attributes}
            graph={_campaign.seqV2.graph}
            campaignName={_campaign.name}
            onSelect={() => handleCampaignAction("duplicate", _campaign)}
            onShowPreview={() => openPreview(_campaign.id)}
          />
        );
        return null;
      });
    return _recommended;
  }, [handleCampaignAction, openPreview, campaignReducer.recommended]);

  const graphTag = (_campaign: any, ii: number) => {
    const campaignCrm = _campaign.hubspot
      ? "HUBSPOT"
      : _campaign.pipedrive
      ? "PIPEDRIVE"
      : undefined;
    const crmError =
      campaignCrm && _campaign.reconnect.crm
        ? "disconnected"
        : _campaign.reconnect.crmMapping
        ? "mapping"
        : _campaign.reconnectcrmLifecycle
        ? "lifecycle"
        : "";

    let disableStartOrRestart = "";
    if (["READY", "PAUSED"].includes(_campaign.status)) {
      if (authReducer.user.campaignPermissions?.campaignLimitReached) {
        disableStartOrRestart =
          "You have reached your limit of running campaigns (" +
          (authReducer.user.campaignPermissions?.running +
            "/" +
            authReducer.user.campaignPermissions?.maxRunningCampaigns) +
          "). Consider upgrading to run more.";
      }
      if (
        _campaign.isCustom &&
        !authReducer.user.campaignPermissions?.canEditStep
      ) {
        disableStartOrRestart =
          "This is a custom campaign. Consider upgrading to " +
          (_campaign.status === "READY" ? "start" : "resume") +
          " it.";
      }
    }

    let _idName =
      (_campaign.identity?.firstname || "") +
      " " +
      (_campaign.identity?.lastname || "");
    if (!_idName.trim()) {
      _idName = "Identity missing name";
    }

    return (
      <GraphTag
        disableStartOrRestart={disableStartOrRestart || undefined}
        key={_campaign.id}
        identity={
          _campaign.identity
            ? {
                label: _idName,
                picture: _campaign.identity.profilePicture,
                disconnected: shouldWidgetShowDisconnect(
                  _campaign.reconnect,
                  _campaign.sequence?.channels
                ),
              }
            : undefined
        }
        audience={
          _campaign.audience && {
            name: _campaign.audience?.name || "",
            size: _campaign.audience?.size,
          }
        }
        templates={[]}
        webhooks={webhooks}
        audiences={audiences}
        attributes={attributes}
        graph={_campaign.sequence?.seqV2?.graph}
        goals={
          (_campaign.audience &&
            _campaign.audience?.size !== 0 && {
              data: [
                {
                  type: "converted",
                  value: _campaign.converted, //les leads converted en vert
                  ico: "verified",
                },
                {
                  type: "started",
                  value: _campaign.activated, //les leads activated en hachurés
                  ico: "forward_to_inbox",
                },
                {
                  type: "none",
                  value:
                    _campaign.audience?.size -
                    _campaign.activated -
                    _campaign.converted, //la partie blanche de la barre (leads ni converted ni activated)
                },
              ],
              total: _campaign.audience.size, //la taille de l'audience donc la taille totale de la barre
            }) ||
          undefined
        }
        crmError={crmError}
        sequence={{
          name: _campaign.name,
          ending: _campaign.sequence.ending || "none",
          customEnding: _campaign.sequence.customEnding || [],
          language: _campaign.language === "french" ? "fr" : "en",
          description: _campaign?.description,
          //identityId: "coucou1",
          //audienceId: "2",
          enrich: _campaign.enrich,
          crm: campaignCrm,
        }}
        campaignStatus={_campaign.status}
        onEdit={() => history.push("/campaigns/" + _campaign.id + "/edit")}
        onOverview={() =>
          history.push("/campaigns/" + _campaign.id + "/overview")
        }
        onPreview={() => openPreview(_campaign.id)}
        onAction={(action: string) => handleCampaignAction(action, _campaign)}
      />
    );
  };

  return (
    <div
      className={
        "campaigns_index_container " +
        (loadingReducer.dataTypesLoaders[types.REQUESTS_DATA.LOAD_CAMPAIGNS] ||
        loadingReducer.btnTypesLoaders[
          types.REQUESTS_ACTIONS.EDIT_CAMPAIGN_STATE
        ]
          ? "loading"
          : "")
      }
    >
      <Modal
        name={modalsReducer.name}
        data={modalsReducer.data}
        onRequestClose={() => handleModal({ name: modalTypes.reset })}
        onAction={modalsReducer.data?.onAction}
        btnDisabled={
          loadingReducer.btnTypesLoaders[
            types.REQUESTS_ACTIONS.EDIT_CAMPAIGN
          ] ||
          loadingReducer.btnTypesLoaders[
            types.REQUESTS_ACTIONS.DUPLICATE_CAMPAIGN
          ]
        }
        btnIsLoading={
          loadingReducer.btnTypesLoaders[
            types.REQUESTS_ACTIONS.EDIT_CAMPAIGN
          ] ||
          loadingReducer.btnTypesLoaders[
            types.REQUESTS_ACTIONS.DUPLICATE_CAMPAIGN
          ]
        }
      />
      <CampaignHomeTopBar
        permission={
          authReducer.user.plan !== "BUSINESS"
            ? {
                onUpgrade: onUpgrade,
                text: "Upgrade",
                label: "BUSINESS",
                available: null,
              }
            : undefined
        }
      />
      <CampaignHomeStoreBar
        onCreateCampaign={() => history.push("/campaigns/recommended")}
      />
      <div className="samples">
        <div className="sample">{Object.values(recommended)}</div>
        <GraphSample
          isDefault={true}
          attributes={[]}
          graph={{ nodes: [], arrows: [] }}
          onSelect={() => history.push("/campaigns/recommended")}
          onShowPreview={() => history.push("/campaigns/recommended")}
        />
      </div>

      {(campaigns.length !== 0 ||
        campaignsStatus !== "all" ||
        debouncedSearch !== "") && (
        <React.Fragment key="campaigns">
          <div /*ref={searchRef}*/>
            <CampaignHomeActiveBar2
              selectSearch={setSearch}
              selectFilter={setCampaignsStatus}
            />
          </div>
          {authReducer.user.campaignPermissions?.campaignLimitReached &&
            authReducer.user.campaignPermissions?.maxRunningCampaigns && (
              <CampaignLimits
                permission={
                  authReducer.user.campaignPermissions
                    ?.maxRunningCampaignsPermission
                    ? {
                        label: "BUSINESS",
                        available: ["BUSINESS"],
                      }
                    : undefined
                }
                maxRunningCampaigns={
                  authReducer.user.campaignPermissions.maxRunningCampaigns
                }
                running={authReducer.user.campaignPermissions.running}
                plan={authReducer.user.plan}
                onUpgrade={onUpgrade}
              />
            )}
          {campaigns.length !== 0 && (
            <div className="active">
              {campaigns.map((_campaign: any, ii: number) =>
                graphTag(_campaign, ii)
              )}
            </div>
          )}
          {campaigns.length === 0 &&
            (campaignsStatus !== "all" || debouncedSearch) && (
              <div key="2" className="archived-empty">
                You&nbsp;have&nbsp;no&nbsp;campaign
                {debouncedSearch && (
                  <span>
                    &nbsp;matching&nbsp;<i>{debouncedSearch}</i>
                  </span>
                )}
                .
              </div>
            )}
          <div className="center">
            {campaignsTotal > campaigns.length && (
              <Button
                role="basic_bordered"
                size="wide"
                onClick={onLoadMoreCampaigns}
                disabled={
                  loadingReducer.dataTypesLoaders[
                    types.REQUESTS_DATA.LOAD_CAMPAIGNS
                  ]
                }
                isLoading={
                  loadingReducer.dataTypesLoaders[
                    types.REQUESTS_DATA.LOAD_CAMPAIGNS
                  ]
                }
              >
                <span className="material_black">expand_more</span>
                &nbsp;Show&nbsp;more
              </Button>
            )}
          </div>
        </React.Fragment>
      )}
      {campaignsDraft.length !== 0 && (
        <React.Fragment key="draft">
          <CampaignHomeDraftBar
            review={{
              permission: authReducer.user.campaignPermissions
                ?.campaignReviewPermission
                ? {
                    label:
                      authReducer.user.campaignPermissions
                        ?.campaignReviewPermission[0],
                    available:
                      authReducer.user.campaignPermissions
                        ?.campaignReviewPermission,
                  }
                : undefined,
              picsUrl: [
                "/pics/brice.png",
                "/pics/agathe.png",
                "/pics/adrien.png",
              ],
              onUpgrade: onUpgrade,
              onWatchReview: onWatchReview,
              onAskForReview: onAskForReview,
              tooltipPosition: "left",
            }}
          />
          <div className="active">
            {campaignsDraft.map((_campaign: any, ii: number) =>
              graphTag(_campaign, ii)
            )}
          </div>
          <div className="center">
            {campaignsDraftTotal > campaignsDraft.length && (
              <Button
                role="basic_bordered"
                size="wide"
                onClick={onLoadMoreCampaignsDraft}
                disabled={
                  loadingReducer.dataTypesLoaders[
                    types.REQUESTS_DATA.LOAD_CAMPAIGNS
                  ]
                }
                isLoading={
                  loadingReducer.dataTypesLoaders[
                    types.REQUESTS_DATA.LOAD_CAMPAIGNS
                  ]
                }
              >
                <span className="material_black">expand_more</span>
                &nbsp;Show&nbsp;more
              </Button>
            )}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default CampaignComponent;

export default {
  REQUESTS_ACTIONS: {
    CREATE_LEAD: "CREATE_LEAD",
    EDIT_LEAD: "EDIT_LEAD",
    EDIT_LEADS: "EDIT_LEADS",
    DELETE_LEADS: "DELETE_LEADS",
  },
  REQUESTS_DATA: {
    LOAD_LEADS: "LOAD_LEADS",
    LOAD_LEAD: "LOAD_LEAD",
    EXPORT_LEADS: "EXPORT_LEADS",
    LOAD_HUBSPOT_LEADS: "LOAD_HUBSPOT_LEADS",
    LOAD_PIPEDRIVE_LEADS: "LOAD_PIPEDRIVE_LEADS",
    LOAD_LEAD_CAMPAIGN_LOGS: "LOAD_LEAD_CAMPAIGN_LOGS",
  },
  RESPONSES_ACTIONS: {
    CREATE_LEAD_FULFILLED: "CREATE_LEAD_FULFILLED",
    CREATE_LEAD_REJECTED: "CREATE_LEAD_REJECTED",
    CREATE_LEAD_CANCELLED: "CREATE_LEAD_CANCELLED",

    EDIT_LEAD_FULFILLED: "EDIT_LEAD_FULFILLED",
    EDIT_LEAD_REJECTED: "EDIT_LEAD_REJECTED",
    EDIT_LEAD_CANCELLED: "EDIT_LEAD_CANCELLED",

    EDIT_LEADS_FULFILLED: "EDIT_LEADS_FULFILLED",
    EDIT_LEADS_REJECTED: "EDIT_LEADS_REJECTED",
    EDIT_LEADS_CANCELLED: "EDIT_LEADS_CANCELLED",

    DELETE_LEADS_FULFILLED: "DELETE_LEADS_FULFILLED",
    DELETE_LEADS_REJECTED: "DELETE_LEADS_REJECTED",
    DELETE_LEADS_CANCELLED: "DELETE_LEADS_CANCELLED",
  },
  RESPONSES_DATA: {
    LOAD_LEADS_FULFILLED: "LOAD_LEADS_FULFILLED",
    LOAD_LEADS_REJECTED: "LOAD_LEADS_REJECTED",
    LOAD_LEADS_CANCELLED: "LOAD_LEADS_CANCELLED",

    EXPORT_LEADS_FULFILLED: "EXPORT_LEADS_FULFILLED",
    EXPORT_LEADS_REJECTED: "EXPORT_LEADS_REJECTED",
    EXPORT_LEADS_CANCELLED: "EXPORT_LEADS_CANCELLED",

    LOAD_LEAD_FULFILLED: "LOAD_LEAD_FULFILLED",
    LOAD_LEAD_REJECTED: "LOAD_LEAD_REJECTED",
    LOAD_LEAD_CANCELLED: "LOAD_LEAD_CANCELLED",

    LOAD_HUBSPOT_LEADS_FULFILLED: "LOAD_HUBSPOT_LEADS_FULFILLED",
    LOAD_HUBSPOT_LEADS_REJECTED: "LOAD_HUBSPOT_LEADS_REJECTED",
    LOAD_HUBSPOT_LEADS_CANCELLED: "LOAD_HUBSPOT_LEADS_CANCELLED",

    LOAD_PIPEDRIVE_LEADS_FULFILLED: "LOAD_PIPEDRIVE_LEADS_FULFILLED",
    LOAD_PIPEDRIVE_LEADS_REJECTED: "LOAD_PIPEDRIVE_LEADS_REJECTED",
    LOAD_PIPEDRIVE_LEADS_CANCELLED: "LOAD_PIPEDRIVE_LEADS_CANCELLED",

    LOAD_LEAD_CAMPAIGN_LOGS_FULFILLED: "LOAD_LEAD_CAMPAIGN_LOGS_FULFILLED",
    LOAD_LEAD_CAMPAIGN_LOGS_REJECTED: "LOAD_LEAD_CAMPAIGN_LOGS_REJECTED",
    LOAD_LEAD_CAMPAIGN_LOGS_CANCELLED: "LOAD_LEAD_CAMPAIGN_LOGS_CANCELLED",
  },

  LEADS_CLEAR: "LEADS_CLEAR",

  LEADS_FILTERS: "LEADS_FILTERS",
  LEADS_PAGINATE: "LEADS_PAGINATE",
  LEAD_SIDE_PANEL: "LEAD_SIDE_PANEL",

  LEAD_MODAL: "LEAD_MODAL",
  CSV_MATCHING_ERROR: "NOTIFY_CSV_MATCHING_ERROR",
};

import React, { useState, useEffect, useCallback } from "react";

import {
  MessagesSumup,
  MessagesSumupLoading,
  Messages,
  MessagesLoading,
  MessagesList,
  CampaignTitleBar,
  CampaignActionsBar,
} from "@lagrowthmachine/lgmwebapp";
import { Link, useParams, useHistory } from "react-router-dom";
import Modal, { types as modalTypes } from "../Modals";
import { useDispatch } from "react-redux";
import useSelector from "../../../../redux/useSelector";
import * as actions from "../../actions";
import * as authActions from "../../../Auth/actions";
import * as globalActions from "../../../../redux/actions";
import { IHandleModal } from "../../interfaces/IModal";
import types from "../../types";
import { types as modalSequenceType } from "../Modals";
import analyticsCall from "../../../../helpers/analyticsCall";
import adapterChannel from "../../../../helpers/adapterChannel";
import { ICampaignState } from "../../interfaces/IState";
import constants from "../../../../config/constants";

interface IDataTemplate {
  templateId: string;
  channel: string;
  name: string;
  description: string;
  sent: number;
  opened: number;
  replies: number;
  clicked: number;
  position: number;
}
const getPercentage = (value: number = 0, total: number) => {
  const percent = Math.round(((value * 100) / total) * 10) / 10;
  if (!percent) return 0;
  if (percent > 100) return 100;
  return percent;
};

const CampaignEngagementComponent = (props: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const campaignReducer: ICampaignState = useSelector(
    (state) => state.campaignReducer
  );
  const modalsReducer = useSelector((state) => state.modalsReducer);
  const loadingReducer = useSelector((state) => state.loadingReducer);
  const authReducer = useSelector((state) => state.authReducer);
  const campaign = campaignReducer.selected;
  const stats = campaignReducer.stats.engagements;
  const templates = campaignReducer.stats?.templates;
  const params: any = useParams();
  const audienceId = campaign?.audience?.id;

  const [firstLoad, setFirstLoad] = useState(true);
  useEffect(() => {
    setFirstLoad(false);
  }, []);

  useEffect(() => {
    if (campaign.name)
      analyticsCall("campaign", `campaign engagement: ${campaign.name}`);
  }, [campaign.name]);

  const loadStats = useCallback(() => {
    const startDate =
      Number(campaign.createdAt) ||
      Number(new Date().getTime()) -
        constants.defaultStatProgressTimeScale * 24 * 3600 * 1000;
    dispatch(
      actions.loadCampaignStats({
        query: {
          startDate: startDate,
        },
        params: {
          id: params.id,
        },
      })
    );
  }, [campaign.createdAt, dispatch, params.id]);

  useEffect(() => {
    dispatch(
      actions.loadCampaign({
        query: {},
        params: { id: params.id },
      })
    );
    dispatch(authActions.loadUser({}));
    loadStats();
    dispatch(
      actions.loadCampaignLeads({
        query: {
          skip: 0,
          audiences: [audienceId],
        },
        params: {
          id: params.id,
        },
      })
    );
  }, [dispatch, params.id, audienceId]);

  if (campaign.status === "READY") {
    history.push("/campaigns/" + campaign.id + "/edit");
  }

  if (campaign.status === "DELETED") {
    history.push("/campaigns");
  }

  useEffect(() => {
    if (campaign.id === params?.id && campaign.pauseWarning) {
      const stopped = {
        crm: campaign.pauseWarning?.warnings?.includes("crm") && {
          availablePlans: authReducer.user.campaignPermissions?.crmPermissions,
        },
        enrich: campaign.pauseWarning?.warnings?.includes("enrich") && {
          availablePlans:
            authReducer.user.campaignPermissions?.enrichPermission,
        },
      };
      const warningId = campaign.pauseWarning.id;
      const warningsSeen =
        localStorage?.getItem("warnings_seen")?.split("___") || [];
      if (!warningsSeen.includes(warningId)) {
        dispatch(
          globalActions.modalUpdate({
            name: "campaignStopped",
            data: { stopped, warningId },
          })
        );
      }
    }
  }, [dispatch, params, campaign.id]);

  const getTemplates = (data: IDataTemplate, key: number) => {
    /*
    const tmpl = {
      step: key + 1,
      channel: data.channel,
      template: {
        title: data.name,
        preview: data.description,
        id: data.templateId,
      },
      sent: data.sent,
      opened: {
        value: data.opened,
        percent: getPercentage(data.opened, data.sent),
      },
      replies: {
        value: data.replies,
        percent: getPercentage(data.replies, data.sent),
      },
      clicked: {
        value: data.clicked,
        percent: getPercentage(data.clicked, data.sent),
      },
    };
    */
    return {
      step: key + 1,
      channel: adapterChannel(data.channel),
      template: {
        title: data.name,
        preview: data.description,
        id: data.templateId,
      },
      sent: data.sent,
      opened: {
        value: data.opened,
        percent: getPercentage(data.opened, data.sent),
      },
      replies: {
        value: data.replies,
        percent: getPercentage(data.replies, data.sent),
      },
      clicked: {
        value: data.clicked,
        percent: getPercentage(data.clicked, data.sent),
      },
    };
  };

  const handleModal = ({ name, data = {} }: IHandleModal) => {
    dispatch(globalActions.modalUpdate({ name, data }));
  };

  const handleEditTemplate = (template: any, duplicate: boolean) => {};

  const handleOpenTemplate = (id: string | undefined) => {
    if (!id) return;
    dispatch(
      globalActions.modalUpdate({
        name: modalSequenceType.editTemplate,
        data: {
          template_id: id,
          modeInit: "PREVIEW",
        },
      })
    );
  };

  const handleRedirectCampaigns = () => {
    history.push("/campaigns");
  };

  const handleTitleBarActions = (action: string) => {
    switch (action) {
      case "info":
        handleModal({
          name: modalTypes.campaignInfo,
          data: { name: campaign.name, description: campaign.description },
        });
        break;
      case "delete":
        handleModal({
          name: modalTypes.campaignDelete,
          data: { campaignId: campaign.id, campaign: campaign },
        });
        break;
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      <div
        key="modal-aside"
        style={{
          transform: ["editTemplate", "newTemplate"].includes(
            modalsReducer.name
          )
            ? "translateX(0px)"
            : "translateX(970px)",
          boxShadow: ["editTemplate", "newTemplate"].includes(
            modalsReducer.name
          )
            ? ""
            : "none",
        }}
        id="message-aside-container"
        className="message-aside-container"
      >
        <Modal
          name={modalsReducer.name}
          data={modalsReducer.data}
          onRequestClose={() => handleModal({ name: "" })}
          onEditTemplate={handleEditTemplate}
          btnIsLoading={
            loadingReducer.btnTypesLoaders[
              types.REQUESTS_ACTIONS.DUPLICATE_MESSAGE_TEMPLATE
            ] /*||
            loadingReducer.btnTypesLoaders[
              types.REQUESTS_ACTIONS.DELETE_CAMPAIGN_TEMPLATE
            ]*/
          }
        />
      </div>
      <div className="campaigns_engagement_container">
        <CampaignTitleBar
          name={campaign.name}
          // description={campaign.description}
          lang="fr"
          status={
            campaign?.status !== "CANCELED" ? campaign?.status : "STOPPED"
          }
          onClickAction={handleTitleBarActions}
          goToCampaigns={handleRedirectCampaigns}
        />
        <CampaignActionsBar
          campaignId={campaign.id}
          page="engagement"
          linkC={Link}
        />

        {!loadingReducer.dataTypesLoaders.LOAD_CAMPAIGN && !firstLoad ? (
          <MessagesSumup
            data={{
              audiences: {
                translations: "audiences",
                value: campaign?.audience?.size || 0,
                end: "",
                tooltip: "Number of unique leads in this campaign",
              },
              contacted: {
                translations: "contacted",
                value: stats?.leads_contacted || 0,
                end: "",
                tooltip: "Number of unique leads contacted",
              },
              replied: {
                translations: "replied",
                value: stats?.leads_that_reply || 0,
                end: "",
                tooltip:
                  "Number of unique leads who replied your messages (on Linkedin, Email or Twitter)",
              },
              newRelations: {
                translations: "new relations",
                value: stats?.linkedin_contact_accepted || 0,
                end: "",
                tooltip:
                  "Number of unique leads who accepted your Linkedin Contact Request",
              },
            }}
          />
        ) : (
          <MessagesSumupLoading />
        )}
        {!loadingReducer.dataTypesLoaders.LOAD_CAMPAIGN && !firstLoad ? (
          campaign?.paginate && (
            <div className="leads-gafa-stats">
              <Messages
                title="Linkedin"
                info=""
                ico="LINKEDIN"
                columns={[
                  {
                    title: "Enriched",
                    value: stats?.linkedin_enriched || 0,
                    percent: getPercentage(
                      stats?.linkedin_enriched,
                      campaign.paginate.total?.all
                    ),
                    lines: [
                      {
                        title: "Company Domain found",
                        value: stats?.linkedin_company_domain_found || 0,
                        percent: getPercentage(
                          stats?.linkedin_company_domain_found,
                          stats?.linkedin_enriched
                        ),
                      },
                      {
                        title: "Pro email found",
                        value: stats?.linkedin_pro_email_found || 0,
                        percent: getPercentage(
                          stats?.linkedin_pro_email_found,
                          stats?.linkedin_enriched
                        ),
                      },
                      {
                        title: "Perso email found",
                        value: stats?.linkedin_perso_email_found || 0,
                        percent: getPercentage(
                          stats?.linkedin_perso_email_found,
                          stats?.linkedin_enriched
                        ),
                      },
                      {
                        title: "Phone number found",
                        value: stats?.linkedin_phone_number_found || 0,
                        percent: getPercentage(
                          stats?.linkedin_phone_number_found,
                          stats?.linkedin_enriched
                        ),
                      },
                      {
                        title: "Twitter found",
                        value: stats?.linkedin_twitter_found || 0,
                        percent: getPercentage(
                          stats?.linkedin_twitter_found,
                          stats?.linkedin_enriched
                        ),
                      },
                    ],
                  },
                  {
                    title: "Contact Requested",
                    value: stats?.linkedin_contact_request || 0,
                    percent: getPercentage(
                      stats?.linkedin_contact_request,
                      campaign.paginate.total?.all
                    ),
                    lines: [
                      {
                        title: "Accepted",
                        value: stats?.linkedin_contact_accepted || 0,
                        percent: getPercentage(
                          stats?.linkedin_contact_accepted,
                          stats?.linkedin_contact_request
                        ),
                      },
                      {
                        title: "Not Accepted",
                        value: stats?.linkedin_contact_not_accepted || 0,
                        percent: getPercentage(
                          stats?.linkedin_contact_not_accepted,
                          stats?.linkedin_contact_request
                        ),
                      },
                    ],
                  },
                  {
                    title: "Message Sent",
                    value: stats?.linkedin_message_sent || 0,
                    percent: getPercentage(
                      stats?.linkedin_message_sent,
                      campaign.paginate.total?.all
                    ),
                    lines: [
                      {
                        title: "Answered DM’s",
                        value: stats?.linkedin_message_answered || 0,
                        percent: getPercentage(
                          stats?.linkedin_message_answered,
                          stats?.linkedin_message_sent
                        ),
                      },
                      {
                        title: "No Answer Dm",
                        value: stats?.linkedin_message_not_answered || 0,
                        percent: getPercentage(
                          stats?.linkedin_message_not_answered,
                          stats?.linkedin_message_sent
                        ),
                      },
                    ],
                  },
                ]}
              />
              <Messages
                title="Twitter"
                info=""
                ico="TWITTER"
                columns={[
                  {
                    title: "Engaged",
                    value: stats?.twitter_engaged || 0,
                    percent: getPercentage(
                      stats?.twitter_engaged,
                      campaign.paginate.total?.all
                    ),
                    lines: [
                      {
                        title: "DM Sent",
                        value: stats?.twitter_dm_sent || 0,
                        percent: getPercentage(
                          stats?.twitter_dm_sent,
                          stats?.twitter_engaged
                        ),
                      },
                      {
                        title: "Retweeted",
                        value: stats?.twitter_retweeted || 0,
                        percent: getPercentage(
                          stats?.twitter_retweeted,
                          stats?.twitter_engaged
                        ),
                      },
                      {
                        title: "Favorited",
                        value: stats?.twitter_favorited || 0,
                        percent: getPercentage(
                          stats?.twitter_favorited,
                          stats?.twitter_engaged
                        ),
                      },
                      {
                        title: "Followed",
                        value: stats?.twitter_followed || 0,
                        percent: getPercentage(
                          stats?.twitter_followed,
                          stats?.twitter_engaged
                        ),
                      },
                      {
                        title: "Followed Back",
                        value: stats?.twitter_followed_back || 0,
                        percent: getPercentage(
                          stats?.twitter_followed_back,
                          stats?.twitter_engaged
                        ),
                      },
                    ],
                  },
                ]}
              />
              <Messages
                title="Email"
                info=""
                ico="GMAIL"
                columns={[
                  {
                    title: "Contacted",
                    value: stats?.email_contacted || 0,
                    percent: getPercentage(
                      stats?.email_contacted,
                      campaign.paginate.total?.all
                    ),
                    lines: [
                      {
                        title: "Received",
                        value: stats?.email_received || 0,
                        percent: getPercentage(
                          stats?.email_received,
                          stats?.email_contacted
                        ),
                      },
                      {
                        title: "Opened",
                        value: stats?.email_opened || 0,
                        percent: getPercentage(
                          stats?.email_opened,
                          stats?.email_contacted
                        ),
                      },
                      {
                        title: "Replied",
                        value: stats?.email_replied || 0,
                        percent: getPercentage(
                          stats?.email_replied,
                          stats?.email_contacted
                        ),
                      },
                      {
                        title: "Clicked",
                        value: stats?.email_clicked || 0,
                        percent: getPercentage(
                          stats?.email_clicked,
                          stats?.email_contacted
                        ),
                      },
                      {
                        title: "Bounced",
                        value: stats?.email_bounced || 0,
                        percent: getPercentage(
                          stats?.email_bounced,
                          stats?.email_contacted
                        ),
                      },
                    ],
                  },
                ]}
              />
            </div>
          )
        ) : (
          <div className="leads-gafa-stats">
            <MessagesLoading key="0" columnsNumber={3} />
            <MessagesLoading key="1" columnsNumber={1} />
            <MessagesLoading key="2" columnsNumber={1} />
          </div>
        )}
        {templates?.length > 0 && (
          <MessagesList
            onClick={handleOpenTemplate}
            messages={templates?.map((t: IDataTemplate, key: number) =>
              getTemplates(t, key)
            )}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default CampaignEngagementComponent;

import { ChannelType } from "@lagrowthmachine/lgmwebapp/build/Components/type";

const limitDetails: {
  [key: string]: {
    bounding: number[];
    channel: ChannelType;
    tooltip: string;
    name: string;
  };
} = {
  twitterDMPerDay: {
    bounding: [0, 70, 100, 150],
    channel: "TWITTER",
    tooltip:
      "I you go too fast, twitter may flag you and send you a warning notice. 2nd notice and you'll be banned. Be warned!",
    name: "Twitter DM",
  },
  twitterFavouritePerDay: {
    bounding: [0, 70, 100, 150],
    channel: "TWITTER",
    tooltip:
      "I you go too fast, twitter may flag you and send you a warning notice. 2nd notice and you'll be banned. Be warned!",
    name: "Twitter Like",
  },
  twitterRetweetPerDay: {
    bounding: [0, 100, 300, 500],
    channel: "TWITTER",
    tooltip:
      "I you go too fast, twitter may flag you and send you a warning notice. 2nd notice and you'll be banned. Be warned!",
    name: "Twitter Retweet",
  },
  twitterTweetPerDay: {
    bounding: [0, 70, 99, 100],
    channel: "TWITTER",
    tooltip:
      "I you go too fast, twitter may flag you and send you a warning notice. 2nd notice and you'll be banned. Be warned!",
    name: "Twitter Tweet",
  },
  twitterFollowPerDay: {
    bounding: [0, 70, 100, 150],
    channel: "TWITTER",
    tooltip:
      "I you go too fast, twitter may flag you and send you a warning notice. 2nd notice and you'll be banned. Be warned!",
    name: "Twitter Follow",
  },
  twitterUnfollowPerDay: {
    bounding: [0, 100, 300, 500],
    channel: "TWITTER",
    tooltip:
      "I you go too fast, twitter may flag you and send you a warning notice. 2nd notice and you'll be banned. Be warned!",
    name: "Twitter Unfollow",
  },
  emailSendEmailPerDay: {
    bounding: [0, 80, 250, 400],
    channel: "GMAIL",
    tooltip:
      "I you go too fast, google may flag you and send you a warning notice. 2nd notice and you'll be banned. Be warned!",
    name: "Send Email",
  },
  linkedinContactPerDay: {
    bounding: [0, 20, 40, 100],
    channel: "LINKEDIN",
    tooltip:
      "I you go too fast, linkedin may disconnect your identity: you'll need to reconnect manually. If this occurs too frequently, you'll be temporarly banned from Linkedin. To remain safe, stay under the risky zone !",
    name: "Linkedin Contact",
  },
  linkedinMessagePerDay: {
    bounding: [0, 100, 175, 230],
    channel: "LINKEDIN",
    tooltip:
      "I you go too fast, linkedin may disconnect your identity: you'll need to reconnect manually. If this occurs too frequently, you'll be temporarly banned from Linkedin. To remain safe, stay under the risky zone !",
    name: "Linkedin Message",
  },
  linkedinVisitPerDay: {
    bounding: [0, 250, 275, 400],
    channel: "LINKEDIN",
    tooltip:
      "I you go too fast, linkedin may disconnect your identity: you'll need to reconnect manually. If this occurs too frequently, you'll be temporarly banned from Linkedin. To remain safe, stay under the risky zone !",
    name: "Linkedin Visit",
  },
};

function getCustomLimit(
  email: string | null,
  type: string | null
): Record<
  string,
  {
    bounding: number[];
    channel: ChannelType;
    tooltip: string;
    name: string;
  }
> {
  const limits: {
    [key: string]: {
      bounding: number[];
      channel: ChannelType;
      tooltip: string;
      name: string;
    };
  } = {};
  if (
    email &&
    email.split("@")[1] &&
    !email.split("@")[1].includes("gmail.com")
  ) {
    limits.emailSendEmailPerDay = {
      bounding: [0, 150, 325, 500],
      channel: "GMAIL",
      tooltip:
        "I you go too fast, google may flag you and send you a warning notice. 2nd notice and you'll be banned. Be warned!",
      name: "Send Email",
    };
  }
  if (type && type !== "Basic") {
    // limits.emailSendEmailPerDay = {
    //   bounding: [0, 80, 325, 400],
    //   channel: "GMAIL",
    //   tooltip:
    //       "I you go too fast, google may flag you and send you a warning notice. 2nd notice and you'll be banned. Be warned!",
    //   name: "Send Email",
    // };
    limits.linkedinContactPerDay = {
      bounding: [0, 100, 150, 200],
      channel: "LINKEDIN",
      tooltip:
        "I you go too fast, linkedin may disconnect your identity: you'll need to reconnect manually. If this occurs too frequently, you'll be temporarly banned from Linkedin. To remain safe, stay under the risky zone !",
      name: "Linkedin Contact",
    };
    limits.linkedinMessagePerDay = {
      bounding: [0, 150, 190, 250],
      channel: "LINKEDIN",
      tooltip:
        "I you go too fast, linkedin may disconnect your identity: you'll need to reconnect manually. If this occurs too frequently, you'll be temporarly banned from Linkedin. To remain safe, stay under the risky zone !",
      name: "Linkedin Message",
    };
    limits.linkedinVisitPerDay = {
      bounding: [0, 300, 500, 600],
      channel: "LINKEDIN",
      tooltip:
        "I you go too fast, linkedin may disconnect your identity: you'll need to reconnect manually. If this occurs too frequently, you'll be temporarly banned from Linkedin. To remain safe, stay under the risky zone !",
      name: "Linkedin Visit",
    };
  }
  return limits;
}
export { getCustomLimit };
export default limitDetails;

import React from "react";

import ConfirmDisconnect from "./ConfirmDisconnect";
import ImapSmtp from "./ImapSmtp";

export const types = {
  confirmDisconnect: "confirmDisconnect",
  imapSmtp: "imapSmtp",
  reset: "",
};

function Modals(props: any) {
  switch (props.name) {
    case types.confirmDisconnect:
      return <ConfirmDisconnect {...props} />;
    case types.imapSmtp:
      return <ImapSmtp {...props} />;
    default:
      return null;
  }
}

export default Modals;

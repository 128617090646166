import React from "react";
import ModalWrapper from "../../../../components/ModalWrapper";
import useSelector from "../../../../redux/useSelector";
import * as globalActions from "../../../../redux/actions";
import { ModalBasic } from "@lagrowthmachine/lgmwebapp";
import globalTypes from "../../../../redux/types";
import { useDispatch } from "react-redux";
import { modalUpdate } from "../../../../redux/actions";

function Modal(props: any) {
  const loadingReducer = useSelector((state) => state.loadingReducer);
  const dispatch = useDispatch();

  const onRequestClose = () => {
    dispatch(modalUpdate({ name: "reset", data: {} }));
  };

  const onDeleteAudience = () => {
    dispatch(
      globalActions.deleteAudience({
        params: { id: props.data.id },
      })
    );
  };

  return (
    <ModalWrapper>
      <ModalBasic
        type="warning"
        title="Delete audience"
        subtitle="This audience will be definitely deleted. Confirm?"
        onAction={onDeleteAudience}
        onCancel={onRequestClose}
        cancelTextBtn="Cancel"
        actionTextBtn="Delete audience"
        btnDisabled={
          loadingReducer.btnTypesLoaders[
            globalTypes.REQUESTS_ACTIONS.DELETE_AUDIENCE
          ]
        }
        btnIsLoading={
          loadingReducer.btnTypesLoaders[
            globalTypes.REQUESTS_ACTIONS.DELETE_AUDIENCE
          ]
        }
      />
    </ModalWrapper>
  );
}
export default Modal;

const paymentsResolve = async (
  stripe: any,
  paymentIds: { pm: string; pi_secret: string }[]
) => {
  //console.log("paymentIds");
  //console.log(paymentIds);
  if (paymentIds.length === 0) return [];
  let paymentsFailed: { pm: string; pi_secret: string; pi_id?: string }[] = [];

  for (const _pmId of paymentIds) {
    const result = await stripe.confirmCardPayment(_pmId.pi_secret, {
      payment_method: _pmId.pm,
    });
    //console.log(result);
    if (result.error) {
      paymentsFailed.push({ ..._pmId, pi_id: result.error.payment_intent?.id });
    }
  }
  //console.log("paymentsFailed");
  //console.log(paymentsFailed);
  return paymentsFailed;
};

export const afterPayments = async (
  stripe: any,
  paymentIds: any,
  ignorePmIds: boolean,
  history: any,
  handleModal: any
) => {
  console.log("paymentIds");
  console.log(paymentIds);
  if (ignorePmIds) {
    console.log("ignorePmIds");
    handleModal("close");
  }
  if (paymentIds && paymentIds.length !== 0) {
    handleModal("sca_ahead", {
      scaNumber: paymentIds.length,
      onStart: async () => {
        handleModal("close");
        const paymentsFailed = await paymentsResolve(stripe, paymentIds);
        if (paymentsFailed.length === 0) {
          handleModal("payment_success");
          history.push("/settings/billing");
        } else {
          handleModal("payment_failed", {
            onClose: () => {
              handleModal("close");
              history.push("/settings/billing");
            },
          });
        }
      },
    });
  } else {
    handleModal("payment_success");
    history.push("/settings/billing");
  }
};

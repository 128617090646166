import { createAction } from "redux-actions";
import types from "../types";

/**
 * Load identities
 */

export const loadIdentities = (values: any) => ({
  type: types.REQUESTS_DATA.LOAD_IDENTITIES,
  payload: values,
});
export const loadIdentitiesFulfilled = (payload: any) => ({
  type: types.RESPONSES_DATA.LOAD_IDENTITIES_FULFILLED,
  payload,
});
export const cancelLoadIdentities = () => ({
  type: types.RESPONSES_DATA.LOAD_IDENTITIES_CANCELLED,
});
export const rejectedLoadIdentities = (payload?: any) => ({
  type: types.RESPONSES_DATA.LOAD_IDENTITIES_REJECTED,
  payload,
});

export const editIdentity = (values: any) => ({
  type: types.REQUESTS_ACTIONS.EDIT_IDENTITY,
  payload: values,
});
export const editIdentityFulfilled = (payload: any) => ({
  type: types.RESPONSES_ACTIONS.EDIT_IDENTITY_FULFILLED,
  payload,
});
export const cancelEditIdentity = () => ({
  type: types.RESPONSES_ACTIONS.EDIT_IDENTITY_CANCELLED,
});
export const rejectedEditIdentity = (payload?: any) => ({
  type: types.RESPONSES_ACTIONS.EDIT_IDENTITY_REJECTED,
  payload,
});

export const editIdentity2 = (values: any) => ({
  type: types.REQUESTS_ACTIONS.EDIT_IDENTITY2,
  payload: values,
});
export const editIdentity2Fulfilled = (payload: any) => ({
  type: types.RESPONSES_ACTIONS.EDIT_IDENTITY2_FULFILLED,
  payload,
});
export const cancelEditIdentity2 = () => ({
  type: types.RESPONSES_ACTIONS.EDIT_IDENTITY2_CANCELLED,
});
export const rejectedEditIdentity2 = (payload?: any) => ({
  type: types.RESPONSES_ACTIONS.EDIT_IDENTITY2_REJECTED,
  payload,
});

export const startIdentityPayment = (payload?: any) => ({
  type: types.REQUESTS_ACTIONS.PAYMENT_IDENTITY,
  payload,
});
export const startIdentityPaymentFulfilled = (payload?: any) => ({
  type: types.RESPONSES_ACTIONS.PAYMENT_IDENTITY_FULFILLED,
  payload,
});
export const rejectedIdentityPayment = (payload?: any) => ({
  type: types.RESPONSES_ACTIONS.PAYMENT_IDENTITY_REJECTED,
  payload,
});

export const clear = createAction(types.IDENTITIES_CLEAR);

export const loadLogs = createAction(
  types.REQUESTS_DATA.LOAD_IDENTITY_LOGS,
  (payload: any) => payload
);
export const loadLogsFulfilled = createAction(
  types.RESPONSES_DATA.LOAD_IDENTITY_LOGS_FULFILLED,
  (payload: any) => payload
);
export const rejectedLoadLogs = createAction(
  types.RESPONSES_DATA.LOAD_IDENTITY_LOGS_REJECTED,
  (payload: any) => payload
);

export const paginateLogsUpdate = createAction(
  types.IDENTITY_LOGS_PAGINATE,
  (payload: any) => payload
);

export const loadIdentityLimits = (payload: any) => ({
  type: types.REQUESTS_DATA.LOAD_IDENTITY_LIMITS,
  payload,
});
export const loadIdentityLimitsFulfilled = (payload: any) => ({
  type: types.RESPONSES_DATA.LOAD_IDENTITY_LIMITS_FULFILLED,
  payload,
});
export const cancelLoadIdentityLimits = () => ({
  type: types.RESPONSES_DATA.LOAD_IDENTITY_LIMITS_CANCELLED,
});
export const rejectedLoadIdentityLimits = (payload?: any) => ({
  type: types.RESPONSES_DATA.LOAD_IDENTITY_LIMITS_REJECTED,
  payload,
});

import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { GoodBye } from "@lagrowthmachine/lgmwebapp";
import Modal, { types as modalTypes } from "../Modals";
import { useDispatch } from "react-redux";
import * as authActions from "../../../Auth/actions";
import * as actions from "../../../Settings/actions";
import * as globalActions from "../../../../redux/actions";
import useSelector from "../../../../redux/useSelector";
import analyticsCall from "../../../../helpers/analyticsCall";

const SettingsGoodbyeComponent = (props: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const modalsReducer = useSelector((state) => state.modalsReducer);
  const loadingReducer = useSelector((state) => state.loadingReducer);
  const authReducer = useSelector((state) => state.authReducer);
  const settingsReducer = useSelector((state) => state.settingsReducer);

  useEffect(() => {
    dispatch(actions.loadSummary({ query: {} }));
    dispatch(authActions.loadUser({ query: {} }));
  }, [dispatch]);

  useEffect(() => {
    analyticsCall("settings", "settings: end subscription confirmation");
  }, []);

  const deleteAll = () => {
    const email = authReducer.user.email || "your email";
    dispatch(actions.terminateUser({ query: {}, email: email }));
  };

  const handleModal = (action: string) => {
    switch (action) {
      case "terminateUser":
        dispatch(
          globalActions.modalUpdate({
            name: modalTypes.terminateUser,
            data: {
              title: "Deletion Confirmation Email",
              subtitle:
                "For security reasons, we need to send you a confirmation link to <strong>" +
                (authReducer.user?.email || "your email") +
                "</strong>." +
                "<br/><br/>This email might take up to a couple of minutes to appear in your mailbox." +
                "<br/><br/>Visit your mailbox and click the link to confirm deleting your account." +
                "<br/><br/>We need to log you out before sending the confirmation email.<br/>",
              deleteText: "Send confirmation email & Log out",
              cancelText: "Cancel",
              onDelete: () => {
                deleteAll();
                handleModal("close");
              },
            },
          })
        );
        break;
      case "close":
        dispatch(
          globalActions.modalUpdate({
            name: modalTypes.reset,
          })
        );
        break;
      default:
        break;
    }
  };

  return (
    <div className="settings_goodbye_container">
      <Modal
        name={modalsReducer.name}
        data={modalsReducer.data}
        onRequestClose={() => handleModal("close")}
        btnIsLoading={loadingReducer.btnTypesLoaders.TERMINATE}
      />
      <GoodBye
        items={[
          {
            type: "campaigns",
            number: settingsReducer.summary?.campaigns || 0,
          },
          {
            type: "messages",
            number: settingsReducer.summary?.messages || 0,
          },
          { type: "leads", number: settingsReducer.summary?.leads || 0 },
          {
            type: "sequences",
            number: settingsReducer.summary?.sequences || 0,
          },
          {
            type: "audiences",
            number: settingsReducer.summary?.audiences || 0,
          },
        ]}
        backToSafety={() => history.goBack()}
        deleteAll={() => handleModal("terminateUser")}
        loading={
          loadingReducer.dataTypesLoaders.LOAD_SUMMARY ||
          loadingReducer.dataTypesLoaders.LOAD_USER
        }
      />
    </div>
  );
};

export default SettingsGoodbyeComponent;

import React from "react";
import ModalWrapper from "../../../../components/ModalWrapper";
import useSelector from "../../../../redux/useSelector";
import { ModalBasic } from "@lagrowthmachine/lgmwebapp";
import { useDispatch } from "react-redux";
import * as actions from "../../actions";
import { ILeadState } from "../../interfaces/IState";
import types from "../../types";
import { modalUpdate } from "../../../../redux/actions";

function Modal(props: any) {
  const dispatch = useDispatch();
  const leadsReducer: ILeadState = useSelector((state) => state.leadsReducer);
  const loadingReducer = useSelector((state) => state.loadingReducer);

  const { data } = props;

  const onRequestClose = () => {
    dispatch(modalUpdate({ name: "reset", data: {} }));
  };

  const handleDeleteLeads = () => {
    dispatch(
      actions.deleteLeads({
        body: {
          actionName: "deleteLeads",
          leadIds: data.selectedLeads,
          filters: leadsReducer.filters,
        },
      })
    );
  };

  const leadsNumber =
    data.selectedLeads === "all"
      ? leadsReducer.list.length
      : data.selectedLeads?.length;
  return (
    <ModalWrapper>
      <ModalBasic
        type="warning"
        subtitle="Confirmation- This action cannot be undone. Are you sure you want to delete this..."
        onAction={handleDeleteLeads}
        onCancel={onRequestClose}
        title={`Deleting ${leadsNumber} lead(s) `}
        cancelTextBtn="Cancel"
        actionTextBtn="Delete"
        btnIsLoading={
          loadingReducer.btnTypesLoaders[types.REQUESTS_ACTIONS.DELETE_LEADS]
        }
        btnDisabled={
          loadingReducer.btnTypesLoaders[types.REQUESTS_ACTIONS.DELETE_LEADS]
        }
      />
    </ModalWrapper>
  );
}
export default Modal;

import { persistStore } from "redux-persist";
import configureStore, { rootEpic, history } from "./configure";

/**
 * Creating redux store and RxJS EpicMiddleware
 */
const { store, epicMiddleware } = configureStore();

/**
 * Redux Persist
 */
let persistor = persistStore(store);

/**
 * Activate all epics functions
 */
epicMiddleware.run(rootEpic);

export { history };
export default { store, persistor };

import { ILeadState } from "../interfaces/IState";
import types from "../types";

const initialState: ILeadState = {
  list: [],
  error: null,
  paginate: {
    current: 0,
    total: 0,
  },
  filters: {
    audiences: [],
    keywords: "",
    firstname: "",
    lastname: "",
    email: "",
    company: "",
    gender: "",
    inMultipleAudienceOnly: false,
    withNoAudienceOnly: false,
  },
};

export const leadsReducer = (state = initialState, action: any) => {
  /**
   * Campaigns
   */
  switch (action.type) {
    case types.REQUESTS_DATA.LOAD_LEADS:
      return {
        ...state,
        error: null,
      };
    case types.RESPONSES_DATA.LOAD_LEADS_FULFILLED:
      return {
        ...state,
        error: null,
        list: action.payload.leads,
        paginate: {
          current: state.paginate?.current || 0,
          total: action.payload.total?.all,
        },
      };
    case types.RESPONSES_DATA.LOAD_LEADS_REJECTED:
    case types.RESPONSES_DATA.LOAD_LEADS_CANCELLED:
      return {
        ...state,
        list: [],
        error: action.payload?.error,
        paginate: initialState.paginate,
      };
    case types.RESPONSES_DATA.LOAD_LEAD_CANCELLED:
      return {
        ...state,
        error: action.payload?.error,
      };
    case types.LEADS_FILTERS:
      return {
        ...state,
        filters: action.payload,
        paginate: {
          total: state.paginate.total,
          current: 0,
        },
      };
    case types.LEADS_PAGINATE:
      return {
        ...state,
        paginate: action.payload,
      };

    case types.LEADS_CLEAR:
      return initialState;
    default:
      return state;
  }
};

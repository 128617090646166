import { encodeQuery } from "../../../helpers/encodeQuery";
import config, { APP_ENV } from "../../../config";
import { IEndpointParams } from "../../../interfaces/IEndpoint";
import getApiUrl from "../../../helpers/getApiUrl";

export default function getEndpoint({
  name,
  body,
  query,
  params = {},
  apiVersion,
}: IEndpointParams) {
  let queryEncoded: any = "";

  if (query) {
    queryEncoded = encodeQuery(query);
  }
  switch (name) {
    case "loadMessage":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${config.endpoints.templates}${
          params?.messageId
        }?${queryEncoded}`,
        method: "GET",
        crossDomain: true,
      };
    case "saveCampaignsFilters":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.filters
        }?${queryEncoded}`,
        method: "PUT",
        body,
        headers: {
          "Content-Type": "application/json",
        },
        crossDomain: true,
      };
    case "loadCampaignsFilters":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.filters
        }?${queryEncoded}`,
        method: "GET",
        crossDomain: true,
      };
    case "loadCampaigns":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.campaigns
        }?${queryEncoded}`,
        method: "GET",
        crossDomain: true,
      };
    case "loadCampaignsName":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.campaigns
        }name?${queryEncoded}`,
        method: "GET",
        crossDomain: true,
      };
    case "createCampaign":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.campaigns
        }?${queryEncoded}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body,
        crossDomain: true,
      };
    case "duplicateCampaign":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.campaigns
        }duplicate/${params.id}?${queryEncoded}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body,
        crossDomain: true,
      };
    case "campaignReview":
      const campaignId = params.id || "";
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.campaigns
        }review/${campaignId}?${queryEncoded}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body,
        crossDomain: true,
      };
    case "editCampaign":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${config.endpoints.campaigns}${
          params.id
        }?${queryEncoded}`,
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body,
        crossDomain: true,
      };
    case "loadSequence":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${config.endpoints.sequences}${
          params.id
        }?${queryEncoded}`,
        method: "GET",
        crossDomain: true,
      };
    case "editSequence":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${config.endpoints.sequences}${
          params.sequenceId
        }?${queryEncoded}`,
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body,
        crossDomain: true,
      };
    case "editCampaignState":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${config.endpoints.campaigns}${
          params.id
        }/${params.status}?${queryEncoded}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body,
        crossDomain: true,
      };
    case "updateCampaignLeadStatus":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}queuesv1/campaign/${
          params.status
        }?${queryEncoded}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body,
        crossDomain: true,
      };
    case "loadCampaign":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${config.endpoints.campaigns}${
          params.id
        }?${queryEncoded}`,
        method: "GET",
        crossDomain: true,
      };
    case "loadCampaignsRecommended":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.campaigns
        }public?${queryEncoded}`,
        method: "GET",
        crossDomain: true,
      };
    case "loadCampaignsStore":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.campaigns
        }public?${queryEncoded}`,
        method: "GET",
        crossDomain: true,
      };
    case "loadCampaignLeads":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.leads
        }?${queryEncoded}`,
        method: "GET",
        crossDomain: true,
      };
    case "loadCampaignStats":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${config.endpoints.campaigns}${
          params.id
        }/stats?${queryEncoded}`,
        method: "GET",
        crossDomain: true,
      };

    case "loadCampaignActions":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${config.endpoints.campaigns}${
          params.id
        }/actions/${params.status}?${queryEncoded}`,
        method: "GET",
        crossDomain: true,
      };
    case "loadCampaignTemplates":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${config.endpoints.campaigns}${
          params.id
        }/templates/?${queryEncoded}`,
        method: "GET",
        crossDomain: true,
      };
    case "editCampaignTemplate":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${config.endpoints.templates}${
          params.id
        }?${queryEncoded}`,
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body,
        crossDomain: true,
      };
    case "createCampaignTemplate":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.templates
        }?${queryEncoded}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body,
        crossDomain: true,
      };
    case "duplicateMessageTemplate":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.templates
        }duplicate/${params.id}?${queryEncoded}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body,
        crossDomain: true,
      };
    default:
      return {
        url: ``,
        method: "GET",
        crossDomain: true,
      };
  }
}

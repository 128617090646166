export const actionsSequence = {
  START: { toolbox: "start", classes: ["isNode", "isStart"] },
  LINKEDIN_VISIT_ENRICH: {
    type: "action",
    channel: "LINKEDIN",
    ico: "LINKEDIN",
    text: "Visit & Enrich",
    learn: `
    <p>When using this action, your identity will visit the lead profile (same benefits as described above), extract information & find his professional email, twitter account and mobile phone.</p>
    <p>Information extracted from the lead’s profile:</p>
    <ul>
    <li>First Name</li>
    <li>Last Name</li>
    <li>Short bio</li>
    <li>Current Job title</li>
    <li>Current Company url</li>
    <li>Current Company website</li>
    <li>Company Industry</li>
    </ul>
    <br/>
    Informations we’re trying to enrich from this lead:<br/>
    <ul>
      <li>Professional Email</li>
      <li>Personal Twitter Account</li>
      <li>Personal Email</li>
      <li>Mobile Phone Number</li>
    </ul>
    <br/>
    &emsp; 1. You always start your sequence with a Visit & Enrich - to make sure we have all the information necessary to proceed<br/><br/>
    &emsp; 2. You Visit & Enrich again the lead whenever your lead has accepted your request (Condition - Accepted Request), to obtain their personal email (for retargeting) and mobile phone (for cold calling)<br/><br/>
    <br/>
    `,
    tooltip: `Action that will visit & enrich the lead's profile`,
    custom: {},
    toolboxInit: "basic",
    toolbox: "basic",
    classes: ["isNode", "isBrick", "isAction"],
  },
  LINKEDIN_VISIT_PROFILE: {
    type: "action",
    channel: "LINKEDIN",
    ico: "LINKEDIN",
    text: "Visit profile",
    learn: `<p class="intercom-align-left">When using this action, your identity will visit the lead profile. Linkedin will notify your lead that you visited his profile.&nbsp;</p>
    <p class="intercom-align-left">It is recommend you use this action :&nbsp;</p>
    <ol>
    <li>As a follow-up that is not yet another message - the notification will serve as a reminder that generate soft replies</li>
    <li>While also sending automated emails - to give the impression that a human is actually following up</li>
    </ol>`,
    tooltip: `Action that will visit the lead's profile`,
    custom: {},
    toolboxInit: "basic",
    toolbox: "basic",
    classes: ["isNode", "isBrick", "isAction"],
  },
  LINKEDIN_DIRECT_MESSAGE: {
    type: "action",
    channel: "LINKEDIN",
    ico: "LINKEDIN",
    text: "Send Message",
    learn: `<p class="intercom-align-left">When using this action, your identity will send a Linkedin Direct Message to your Lead. Linkedin will notify your lead that you sent a message in 2 ways: by email and in a notification within linkedin.</p>
    <p class="intercom-align-left"><b>⚠️ Note that you CANNOT send direct messages to people you are not connected with.</b></p>
    <p class="intercom-align-left"><i>If you are not connected with your lead, LaGrowthMachine will skip this action and move to the next one. You will be able to see if the message has been successfully sent in the “Activity” menu.</i></p>`,
    tooltip: `Action to send a Linkedin Direct Message to your Leads`,
    custom: {},
    toolboxInit: "select_template",
    toolbox: "add_template",
    classes: ["isNode", "isBrick", "isAction"],
  },
  LINKEDIN_ADD_CONTACT: {
    type: "action",
    channel: "LINKEDIN",
    ico: "LINKEDIN",
    text: "Add relation",
    learn: `<p class="intercom-align-left">When using this action, your identity will send a Linkedin Contact Request to your Lead. Linkedin will notify your lead that you sent this invitation in 2 ways: by email and in a notification within linkedin.</p>
    <p class="intercom-align-left">Your contact request can include a message. This is a best practice and will improve the number of leads accepting your contact request.</p>
    <p class="intercom-align-left"><b>⚠️ The message associated to a Linkedin Contact Request is limited to 300 characters. Be careful when writing those message and using variables (lead or identity), they might increase your message’s length to more than 300 characters.</b></p>`,
    tooltip: `Action to send a Linkedin Contact Request to your Leads`,
    custom: {},
    toolboxInit: "basic",
    toolbox: "basic",
    classes: ["isNode", "isBrick", "isAction"],
  },

  GOOGLE_SEND_EMAIL: {
    type: "action",
    channel: "GMAIL",
    ico: "GMAIL",
    text: "Send email",
    learn: `<p class="intercom-align-left">When using this action, your identity will send an email to your lead on his professional email.</p>
    <p class="intercom-align-left">If the professional email has not been found, the action will be skipped. You will be notified in your “Activity” menu.</p>`,
    tooltip: `Action to Send an Email to your Lead`,
    custom: {},
    toolboxInit: "select_template",
    toolbox: "add_template",
    classes: ["isNode", "isBrick", "isAction"],
  },
  TWITTER_TWEET: {
    type: "action",
    channel: "TWITTER",
    ico: "TWITTER",
    text: "Tweet",
    learn: `<p class="intercom-align-left">When using this action, your identity will tweet something on his public timeline.</p>
    <p class="intercom-align-left"><b>🔥Tip: If you can start your tweet with a @{{twitter}}, your tweet will not appear in your public timeline, but only in the “Tweets &amp; Answers” section of your profile. This can help polluting your timeline with tons similar tweets.</b></p>
    <p class="intercom-align-left"><b>🔥Tip: You can use spins to create multiple versions of your messages. Spinning a text can help you generate thousands of unique texts in just minutes. </b><a href="https://www.quora.com/Does-content-spinning-actually-work" rel="nofollow noopener noreferrer" target="_blank"><b>More about spinning content</b></a><b>.</b></p>`,
    tooltip: `Action to Tweet Something`,
    custom: {},
    toolboxInit: "select_template",
    toolbox: "add_template",
    classes: ["isNode", "isBrick", "isAction"],
  },
  TWITTER_FAVOURITE: {
    type: "action",
    channel: "TWITTER",
    ico: "TWITTER",
    text: "Favourite",
    learn: `<p class="intercom-align-left">When using this action, your identity will like one of your lead’s last tweet.</p>
    <p class="intercom-align-left">You can like the last tweet of your lead, or the last tweet containing something:</p>
    <ul>
    <li>
    <b>Last tweet: </b>we’ll search for your lead’s last tweet, and your identity will like it.</li>
    <li>
    <b>Last tweet containing:</b> we’ll search for your lead’s last tweet matching a twitter query, and your identity will like it. Note that this query can be anything supported by <a href="https://twitter.com/search-advanced?lang=fr" rel="nofollow noopener noreferrer" target="_blank">twitter advanced search</a>. You can use multiple queries by separating them with commas (,).</li>
    </ul>`,
    tooltip: `Action to Favourite one of your Lead's Tweet`,
    custom: { filter: "" },
    toolboxInit: "basic",
    toolbox: "favourite",
    classes: ["isNode", "isBrick", "isAction"],
  },
  TWITTER_RETWEET: {
    type: "action",
    channel: "TWITTER",
    ico: "TWITTER",
    text: "Retweet",
    learn: `<p class="intercom-align-left">When using this action, your identity will retweet one of your lead’s last tweet on his public timeline.&nbsp;</p>
    <p class="intercom-align-left">You can retweet the last tweet of your lead, or the last tweet containing something:</p>
    <ul>
    <li>
    <b>Last tweet: </b>we’ll search for your lead’s last tweet, and your identity will RT it.</li>
    <li>
    <b>Last tweet containing: </b>we’ll search for your lead’s last tweet matching a twitter query, and your identity will RT it. Note that this query can be anything supported by <a href="https://twitter.com/search-advanced?lang=fr" rel="nofollow noopener noreferrer" target="_blank">twitter advanced search</a>. You can use multiple queries by separating them with commas (,).</li>
    </ul>`,
    tooltip: `Action to ReTweet one of your Lead's Tweet`,
    custom: { filter: "" },
    toolboxInit: "basic",
    toolbox: "retweet",
    classes: ["isNode", "isBrick", "isAction"],
  },
  TWITTER_SEND_MESSAGE: {
    type: "action",
    channel: "TWITTER",
    ico: "TWITTER",
    text: "Send DM",
    learn: `<p class="intercom-align-left">When using this action, your identity will send a Twitter Direct Message to your Lead. Twitter will notify your lead that you sent a message in 2 ways: by email and in a notification within twitter.</p>
    <p class="intercom-align-left"><b>⚠️ Note that you CANNOT send direct messages to people you are not connected with unless they opted in (this is rarely the case...).</b></p>
    <p class="intercom-align-left"><i>If your lead doesn’t accept direct messages, LaGrowthMachine will skip this action and move to the next one. You will be able to see if the message has been successfully sent in the “Activity” menu.</i></p>`,
    tooltip: `Action to Send a Twitter Direct Message to your Lead`,
    custom: {},
    toolboxInit: "select_template",
    toolbox: "add_template",
    classes: ["isNode", "isBrick", "isAction"],
  },
  TWITTER_FOLLOW: {
    type: "action",
    channel: "TWITTER",
    ico: "TWITTER",
    text: "Follow",
    learn: `<p class="intercom-align-left">When using this action, your identity will start following your lead on Twitter.</p>
    <p class="intercom-align-left">If your identity is already following this lead, the action will be skipped. You will be notified in your “Activity” menu.</p>`,
    tooltip: `Action to Start Following a Lead`,
    custom: {},
    toolboxInit: "basic",
    toolbox: "basic",
    classes: ["isNode", "isBrick", "isAction"],
  },
  TWITTER_UNFOLLOW: {
    type: "action",
    channel: "TWITTER",
    ico: "TWITTER",
    text: "Unfollow",
    learn: `<p class="intercom-align-left">When using this action, your identity will unfollow your lead on Twitter.</p>
    <p class="intercom-align-left">If your identity is not following this lead, the action will be skipped. You will be notified in your “Activity” menu.</p>`,
    tooltip: `Action to Stop Following a Lead`,
    custom: {},
    toolboxInit: "basic",
    toolbox: "basic",
    classes: ["isNode", "isBrick", "isAction"],
  },

  LGM_ADD_TO_CAMPAIGN: {
    type: "action",
    channel: "LGM",
    ico: "SA1",
    text: "Add to audience",
    learn: `<p>This action will let you push your lead in another audience. The lead will be added to the other audience you selected, but will remain in the current one.</p>
    <p><b>🔥Tips: this feature is great when you have "long time running" campaigns that will perform regular tasks on your leads such as nurturing, enriching etc. You will be able to push someone who didn't answer to your messages in another audience connected to another "running" campaign. </b></p>
    <p><i>Example: Push all leads who answered in audience A, and those who didn't in audience B. Audience B being currently used in my "Nurturing" campaign, these leads will for receive contents every other month.</i></p>`,
    tooltip: "Action to push a lead in a selected audience",
    custom: {},
    toolboxInit: "select_audience",
    toolbox: "add_to_audience",
    classes: ["isNode", "isBrick", "isAction"],
  },
  LGM_ADD_DELAY: {
    type: "action",
    channel: "LGM",
    ico: "SA2",
    text: "Wait",
    learn: `<p class="intercom-align-left">When using this action, you will add delays (in days) between 2 actions / conditions. Adding delay let’s you better emulate real actions. Don’t hesitate to regularly add delays within your sequences. The longer your leads will see you around, the better for your conversions.</p>`,
    tooltip: `Action to add delay between 2 Actions or Conditions`,
    custom: { delay: 1 },
    toolboxInit: "input_delay",
    toolbox: "delay",
    classes: ["isNode", "isBrick", "isAction"],
  },
  LGM_WEBHOOK: {
    type: "action",
    channel: "LGM",
    ico: "SA3",
    text: "Webhook",
    learn: `To push informations outside LGM, you can use Zapier Webhooks. Just create a new Zap inside your regular Zapier account (or any other automation software), then create a webhook and connect it to LaGrowthMachine (settings > webhooks > create new).<br/><br/>You can then add "webhook" steps into your existing sequence: When a lead reaches that step, we'll automatically push its information and a custom status to Zapier.<br/><br/>It's up to you to push that information to any of the 1000+ other cloud apps available on Zapier. Most popular examples are:<br/><br/><ul><li>Push Lead Status to your Salesforce, Hubspot CRM or any CRM</li><li>Plan retargeting by pushing personal emails to Custom Audiences on Facebook & LinkedIn</li><li>Track Stats in a Google Sheet</li><li>Send a voice message to leads with a phone number ;-)</li><li>and much more !</li></ul><br/>`,
    tooltip: `Push information to over 1000 cloud apps using Zapier !`,
    custom: {},
    toolboxInit: "select_webhook",
    toolbox: "webhook",
    classes: ["isNode", "isBrick", "isAction"],
  },
  LGM_END_CAMPAIGN: {
    type: "action",
    channel: "LGM",
    ico: "SA6",
    text: "End sequence",
    learn: `<p class="intercom-align-left">When using this action, you decide to end the sequence for this lead.<br>If there are any actions planned left for that lead, LGM will cancel them.<br><br>It is recommended to use that action as a security - when you want to make sure nothing can happen next on that lead.</p>`,
    tooltip: `Condition to stop the Sequence for this Lead`,
    custom: {},
    toolboxInit: "basic",
    toolbox: "basic",
    classes: ["isNode", "isBrick", "isAction"],
  },
  LGM_CONVERTED: {
    type: "action",
    channel: "LGM",
    ico: "SA4",
    text: "Reach goal",
    learn: `<p class="intercom-align-left">When using this action, you will define that your lead has been converted. This is the key metric of your campaign, that will be reported on the campaign index view.</p>`,
    tooltip: `Condition to Track a Conversion Statistics`,
    custom: {},
    toolboxInit: "basicSmall",
    toolbox: "basicSmall",
    classes: ["isNode", "isBrick", "isAction"],
  },

  LINKEDIN_VISIT_BACK: {
    type: "condition",
    channel: "LINKEDIN",
    ico: "LINKEDIN",
    text: "Visited back",
    learn: `<p class="intercom-align-left">We will check every 30 minutes for people who visited your linkedin profiles. <b>This condition will check if the lead is in this list.&nbsp;</b></p>
    <p class="intercom-align-left">The next actions will continue if the <b>lead has visited your profile</b>.</p>`,
    tooltip: `Condition that checks for a Linkedin Visit by your Leads`,
    custom: {},
    toolboxInit: "basic",
    toolbox: "basic",
    classes: ["isNode", "isBrick", "isCondition"],
  },
  LINKEDIN_NO_VISIT_BACK: {
    type: "condition",
    channel: "LINKEDIN",
    ico: "LINKEDIN",
    text: "Didn't Visit Back",
    learn: `<p class="intercom-align-left">We will check every 30 minutes for people who visited your linkedin profiles. <b>This condition will check if the lead is NOT in this list.</b></p>
    <p class="intercom-align-left">The next actions will continue if the lead has <b>NOT visited your profile.</b></p>`,
    tooltip: `Condition to check if the Lead has NOT Visited your Linkedin profile`,
    custom: {},
    toolboxInit: "basic",
    toolbox: "basic",
    classes: ["isNode", "isBrick", "isCondition"],
  },
  LINKEDIN_ACCEPT_REQUEST: {
    type: "condition",
    channel: "LINKEDIN",
    ico: "LINKEDIN",
    text: "Is a contact",
    learn: `<p class="intercom-align-left">This condition will check in your <a href="https://www.linkedin.com/mynetwork/invite-connect/connections/" rel="nofollow noopener noreferrer" target="_blank">recent linkedin connections</a> if the current lead accepted your request:</p>
    <div class="intercom-container intercom-align-left"><img width='100%' src="https://downloads.intercomcdn.com/i/o/122486373/8ef2a53c346dfc2001313603/rWUwuWmm6IQTpqppXbx4Yn9rvPShUKbvqv_EPJC9wBHdeDHtugoVSSymHUB7SUUmMSvJSSaSRSP54zG8KdQGiO3jmpsLmBoLnVYSJtb9xhnwwIbqr6k9oMRQG6DVOb8ZxT55Vz1s"></div><p class="intercom-align-left">The next actions will continue <b>if the request has been accepted</b>.</p>`,
    tooltip: `Condition to check if the Lead has accepted your Linkedin Contact Request`,
    custom: {},
    toolboxInit: "basic",
    toolbox: "basic",
    classes: ["isNode", "isBrick", "isCondition"],
  },
  LINKEDIN_DENY_REQUEST: {
    type: "condition",
    channel: "LINKEDIN",
    ico: "LINKEDIN",
    text: "is Not a Contact",
    learn: `<p class="intercom-align-left">This condition will check in your <a href="https://www.linkedin.com/mynetwork/invite-connect/connections/" rel="nofollow noopener noreferrer" target="_blank">recent linkedin connections</a> if the current lead accepted your request:</p>
    <div class="intercom-container intercom-align-left"><img width='100%' src="https://downloads.intercomcdn.com/i/o/122486868/50eab61515b5b20062cf4529/rWUwuWmm6IQTpqppXbx4Yn9rvPShUKbvqv_EPJC9wBHdeDHtugoVSSymHUB7SUUmMSvJSSaSRSP54zG8KdQGiO3jmpsLmBoLnVYSJtb9xhnwwIbqr6k9oMRQG6DVOb8ZxT55Vz1s"></div><p class="intercom-align-left">The next actions will continue <b>if the request has NOT been accepted.</b></p>`,
    tooltip: `Condition to check if the Lead has REFUSED your Linkedin Contact Request`,
    custom: {},
    toolboxInit: "basic",
    toolbox: "basic",
    classes: ["isNode", "isBrick", "isCondition"],
  },
  LINKEDIN_HAS_REPLY: {
    type: "condition",
    channel: "LINKEDIN",
    ico: "LINKEDIN",
    text: "Replied",
    learn: `<p>This condition will check if your lead <b>has replied to any</b> of the LinkedIn messages (DM or Contact Request) you sent since the campaign started.</p>
    <div class="intercom-container"><img width='100% src="https://downloads.intercomcdn.com/i/o/208409636/ad851f281d74a92db11ed966/Screenshot+2020-05-12+at+17.53.18.png"></div><p>The next actions will continue <b>if the lead HAS replied.</b></p>`,
    tooltip: `Condition that checks if your Lead Replied to your Message`,
    custom: {},
    toolboxInit: "basic",
    toolbox: "basic",
    classes: ["isNode", "isBrick", "isCondition"],
  },
  LINKEDIN_HAS_NOT_REPLY: {
    type: "condition",
    channel: "LINKEDIN",
    ico: "LINKEDIN",
    text: "Not replied",
    learn: `<p>This condition will check if your lead <b>didn't reply to any</b> of the LinkedIn messages (DM or Contact Request) you sent since the campaign started.</p>
    <div class="intercom-container"><img width='100%' src="https://downloads.intercomcdn.com/i/o/208409636/ad851f281d74a92db11ed966/Screenshot+2020-05-12+at+17.53.18.png"></div><p>The next actions will continue <b>if the lead HAS NOT replied.</b></p>`,
    tooltip: `Condition that checks if your Lead Didn't Reply to your Message`,
    custom: {},
    toolboxInit: "basic",
    toolbox: "basic",
    classes: ["isNode", "isBrick", "isCondition"],
  },

  TWITTER_FOLLOW_BACK: {
    type: "condition",
    channel: "TWITTER",
    ico: "TWITTER",
    text: "Follow",
    learn: `<p class="intercom-align-left">This condition will check if the lead is following you on twitter.&nbsp;</p>
    <div class="intercom-container intercom-align-left"><img width='100%' src="https://downloads.intercomcdn.com/i/o/122488728/3d3308461b22dcbcdba43a7d/tX5YHGKQV_ckh0dd1gQZtFt7-gWuMFKr1REZa3lEBwZGPuaV38NeN2cB0cjj0FwqoqIIJUthWJjBaFkic8bF8yOs2xlfPONBEVMsm84YtVdfLrlMwcxDs8g2NqKm4v13yQc7e_j5"></div><p class="intercom-align-left"><b>The next actions will continue if the lead is following you.</b></p>`,
    tooltip: `Condition to check if your Lead is Following you.`,
    custom: {},
    toolboxInit: "basic",
    toolbox: "basic",
    classes: ["isNode", "isBrick", "isCondition"],
  },
  TWITTER_DONT_FOLLOW_BACK: {
    type: "condition",
    channel: "TWITTER",
    ico: "TWITTER",
    text: "Don't Follow",
    learn: `<p class="intercom-align-left">This condition will check if the lead is NOT following you on twitter.&nbsp;</p>
    <div class="intercom-container intercom-align-left"><img width='100%' src="https://downloads.intercomcdn.com/i/o/122489113/c292ca778df2f02fd199b1a6/X7z1PdB9Zl2VyPD9IbWsVMg09uS38OwYty-KLbN03jugo0sBzohIEefmzjVZPRhoJ089OyO6x4XAhEY6A9_tKSrPDIMPiu7ZANj0rMFrlYDasA20EuYq7Oj_ZejJHsVB1Z6VZksF"></div><p class="intercom-align-left"><b>The next actions will continue if the lead is NOT following you.</b></p>`,
    tooltip: `Condition to check if your Lead is NOT Following you.`,
    custom: {},
    toolboxInit: "basic",
    toolbox: "basic",
    classes: ["isNode", "isBrick", "isCondition"],
  },

  LGM_ADD_STATUS: {
    type: "condition",
    channel: "GMAIL",
    ico: "GMAIL",
    text: "LGM_ADD_STATUS",
    learn: `<p class="intercom-align-left">This action will create a status on your campaign that will be incremented until the campaign ends. <b>Status can be used to monitor statistics of your campaigns.</b></p>`,
    tooltip: `Action to Track a Basic Statistic`,
    custom: {},
    toolboxInit: "basic",
    toolbox: "basic",
    classes: ["isNode", "isBrick", "isCondition"],
  },
  GOOGLE_OPENED: {
    type: "condition",
    channel: "GMAIL",
    ico: "GMAIL",
    text: "Opened",
    learn: `<p class="intercom-align-left"><b>Contrary to “<i>on</i>” conditions</b>, these conditions will be executed ONLY ONCE at a specific time in the sequence and trigger the next actions ONLY IF the check is returned to be true.</p>
    <p class="intercom-align-left">This condition will trigger the next actions you defined in your sequence if the email has been opened when the condition is checked.</p>
    <p class="intercom-align-left"><b>🔥Tip: These conditions will be checked only once. You should probably use delays BEFORE them to give your lead time to perform the tested action.</b></p>`,
    tooltip: `Condition to check if the Email has been Opened`,
    custom: {},
    toolboxInit: "basic",
    toolbox: "basic",
    classes: ["isNode", "isBrick", "isCondition"],
  },
  GOOGLE_NOT_OPENED: {
    type: "condition",
    channel: "GMAIL",
    ico: "GMAIL",
    text: "Not Opened",
    learn: `<p class="intercom-align-left"><b>Contrary to “<i>on</i>” conditions</b>, these conditions will be executed ONLY ONCE at a specific time in the sequence and trigger the next actions if the check is returned to be true.</p>
    <p class="intercom-align-left">This condition will trigger the next actions you defined in your sequence if the email has NOT been opened when the condition is checked.</p>
    <p class="intercom-align-left"><b>🔥Tip: These conditions will be checked only once. You should probably use delays BEFORE them to give your lead time to perform the tested action.</b></p>`,
    tooltip: `Condition to check if the Email has NOT been Opened`,
    custom: {},
    toolboxInit: "basic",
    toolbox: "basic",
    classes: ["isNode", "isBrick", "isCondition"],
  },
  GOOGLE_HAS_CLICKED: {
    type: "condition",
    channel: "GMAIL",
    ico: "GMAIL",
    text: "Clicked",
    learn: `<p class="intercom-align-left"><b>Contrary to “<i>on</i>” conditions</b>, these conditions will be executed ONLY ONCE at a specific time in the sequence and trigger the next actions if the check is returned to be true.</p>
    <p class="intercom-align-left">This condition will trigger the next actions you defined in your sequence if the email has been clicked when the condition is checked.</p>
    <p class="intercom-align-left"><b>🔥Tip: These conditions will be checked only once. You should probably use delays BEFORE them to give your lead time to perform the tested action.</b></p>`,
    tooltip: `Condition to check if the Email has been Replied`,
    custom: {},
    toolboxInit: "basic",
    toolbox: "basic",
    classes: ["isNode", "isBrick", "isCondition"],
  },
  GOOGLE_HAS_NOT_CLICKED: {
    type: "condition",
    channel: "GMAIL",
    ico: "GMAIL",
    text: "Not Clicked",
    learn: `<p class="intercom-align-left"><b>Contrary to “<i>on</i>” conditions</b>, these conditions will be executed ONLY ONCE at a specific time in the sequence and trigger the next actions if the check is returned to be true.</p>
    <p class="intercom-align-left">This condition will trigger the next actions you defined in your sequence if the email has NOT been clicked when the condition is checked.</p>
    <p class="intercom-align-left"><b>🔥Tip: These conditions will be checked only once. You should probably use delays BEFORE them to give your lead time to perform the tested action.</b></p>`,
    tooltip: `Condition to check if the Email has NOT been Clicked`,
    custom: {},
    toolboxInit: "basic",
    toolbox: "basic",
    classes: ["isNode", "isBrick", "isCondition"],
  },
  GOOGLE_REPLY: {
    type: "condition",
    channel: "GMAIL",
    ico: "GMAIL",
    text: "Replied",
    learn: `<p class="intercom-align-left">As for every “<i>on</i>” conditions, this condition will be triggered <i>“as soon as”</i> the trigger appears.</p>
    <p class="intercom-align-left">This condition will trigger the next actions you defined in your sequence as soon as the email receives a reply. This condition will only be triggered once (not for the 2nd reply for example)</p>
    <p class="intercom-align-left"><b>🔥Tip: If you don’t use delay, the next action will be triggered immediately: “On” conditions work perfectly with delay with delay AFTER them.</b></p>`,
    tooltip: `Condition to Trigger Actions when the email is Replied`,
    custom: {},
    toolboxInit: "basic",
    toolbox: "basic",
    classes: ["isNode", "isBrick", "isCondition"],
  },
  GOOGLE_NO_REPLY: {
    type: "condition",
    channel: "GMAIL",
    ico: "GMAIL",
    text: "Not Replied",
    learn: `<p class="intercom-align-left"><b>Contrary to “<i>on</i>” conditions</b>, these conditions will be executed ONLY ONCE at a specific time in the sequence and trigger the next actions if the check is returned to be true.</p>
    <p class="intercom-align-left">This condition will trigger the next actions you defined in your sequence if the email has NOT been replied when the condition is checked.</p>
    <p class="intercom-align-left"><b>🔥Tip: These conditions will be checked only once. You should probably use delays BEFORE them to give your lead time to perform the tested action.</b></p>`,
    tooltip: `Condition to check if the Email has NOT been Replied`,
    custom: {},
    toolboxInit: "basic",
    toolbox: "basic",
    classes: ["isNode", "isBrick", "isCondition"],
  },

  LGM_HAS_ATTRIBUTE: {
    type: "condition",
    channel: "LGM",
    ico: "SA5",
    text: "Has Attribute",
    learn: `<p>When you want to check if we found the email, the twitter handle, the company...or any other lead's attribute, use this condition: we will check for the attribute's availability for your lead, and continue if the <b>attribute IS available</b>.</p>
    <p><b>🔥Tips: You can also use this on custom attributes ! This means that you can add custom attributes for some of your leads, and leave the attribute empty for others, and use this condition to differentiate the leads in your sequence.</b></p>`,
    tooltip: "Condition that checks if your lead has a certain attribute",
    custom: {},
    toolboxInit: "select_attribute",
    toolbox: "attribute",
    classes: ["isNode", "isBrick", "isCondition"],
  },
  LGM_HAS_NOT_ATTRIBUTE: {
    type: "condition",
    channel: "LGM",
    ico: "SA5",
    text: "Has Not Attribute",
    learn: `<p>When you want to check if we found the email, the twitter handle, the company...or any other lead's attribute, use this condition: we will check for the attribute's availability for your lead, and continue if the <b>attribute IS NOT available</b>.</p>
    <p><b>🔥Tips: You can also use this on custom attributes ! This means that you can add custom attributes for some of your leads, and leave the attribute empty for others, and use this condition to differentiate the leads in your sequence.</b></p>`,
    tooltip: "Condition that checks if your lead has a certain attribute",
    custom: {},
    toolboxInit: "select_attribute",
    toolbox: "attribute",
    classes: ["isNode", "isBrick", "isCondition"],
  },

  // LINKEDIN_ON_REPLY: {
  //   type: "instant_condition",
  //   channel: "LINKEDIN",
  //   ico: "LINKEDIN",
  //   text: "On replied",
  //   learn: `Empty description`,
  //   tooltip: `Empty tooltip`,
  //   custom: {},
  //   toolboxInit: "basic",
  //   toolbox: "basic",
  //   classes: ["isNode", "isBrick", "isCondition"],
  // },
  GOOGLE_ON_SENT: {
    type: "instant_condition",
    channel: "GMAIL",
    ico: "GMAIL",
    text: "On Sent",
    learn: `
    <p class="intercom-align-left">As for every “<i>on</i>” conditions, this condition will be triggered <i>“as soon as”</i> the trigger appears.</p>
<p class="intercom-align-left">This condition will trigger the next actions you defined in your sequence as soon as the email is sent. This condition will only be triggered once (not for the 2nd sent for example)</p>
<p class="intercom-align-left"><b>🔥Tip: If you don’t use delay, the next action will be triggered immediately: “On” conditions work perfectly with delay AFTER them.</b></p>`,
    tooltip: `Condition to Trigger actions when the email is sent`,
    custom: {},
    toolboxInit: "basic",
    toolbox: "basic",
    classes: ["isNode", "isBrick", "isCondition"],
  },
  GOOGLE_ON_OPEN: {
    type: "instant_condition",
    channel: "GMAIL",
    ico: "GMAIL",
    text: "On Opened",
    learn: `<p class="intercom-align-left">As for every “<i>on</i>” conditions, this condition will be triggered <i>“as soon as”</i> the trigger appears.</p>
    <p class="intercom-align-left">This condition will trigger the next actions you defined in your sequence as soon as the email is opened. This condition will only be triggered once (not for the 2nd open for example)</p>
    <p class="intercom-align-left"><b>🔥Tip: If you don’t use delay, the next action will be triggered immediately: “On” conditions work perfectly with delay with delay AFTER them.</b></p>`,
    tooltip: `Condition to Trigger Actions when the email is opened`,
    custom: {},
    toolboxInit: "basic",
    toolbox: "basic",
    classes: ["isNode", "isBrick", "isCondition"],
  },
  GOOGLE_ON_CLICKED: {
    type: "instant_condition",
    channel: "GMAIL",
    ico: "GMAIL",
    text: "On Clicked",
    learn: `<p class="intercom-align-left">As for every “<i>on</i>” conditions, this condition will be triggered <i>“as soon as”</i> the trigger appears.</p>
    <p class="intercom-align-left">This condition will trigger the next actions you defined in your sequence as soon as the email is clicked. This condition will only be triggered once (not for the 2nd click for example)</p>
    <p class="intercom-align-left"><b>🔥Tip: If you don’t use delay, the next action will be triggered immediately: “On” conditions work perfectly with delay with delay AFTER them.</b></p>`,
    tooltip: `Condition to Trigger Actions when the email is clicked`,
    custom: {},
    toolboxInit: "basic",
    toolbox: "basic",
    classes: ["isNode", "isBrick", "isCondition"],
  },
  GOOGLE_ON_REPLY: {
    type: "instant_condition",
    channel: "GMAIL",
    ico: "GMAIL",
    text: "On Replied",
    learn: `<p class="intercom-align-left">As for every “<i>on</i>” conditions, this condition will be triggered <i>“as soon as”</i> the trigger appears.</p>
    <p class="intercom-align-left">This condition will trigger the next actions you defined in your sequence as soon as the email receives a reply. This condition will only be triggered once (not for the 2nd reply for example)</p>
    <p class="intercom-align-left"><b>🔥Tip: If you don’t use delay, the next action will be triggered immediately: “On” conditions work perfectly with delay with delay AFTER them.</b></p>`,
    tooltip: `Condition to Trigger Actions when the email is Replied`,
    custom: {},
    toolboxInit: "basic",
    toolbox: "basic",
    classes: ["isNode", "isBrick", "isCondition"],
  },
  GOOGLE_ON_BOUNCED: {
    type: "instant_condition",
    channel: "GMAIL",
    ico: "GMAIL",
    text: "On Bounced",
    learn: `<p class="intercom-align-left">As for every “<i>on</i>” conditions, this condition will be triggered <i>“as soon as”</i> the trigger appears.</p>
    <p class="intercom-align-left">This condition will trigger the next actions you defined in your sequence as soon as the email bounces. This condition will only be triggered once (not for the 2nd bounce for example)</p>
    <p class="intercom-align-left"><b>🔥Tip: If you don’t use delay, the next action will be triggered immediately: “On” conditions work perfectly with delay with delay AFTER them.</b></p>`,
    tooltip: `Condition to Trigger actions when the email has bounced`,
    custom: {},
    toolboxInit: "basic",
    toolbox: "basic",
    classes: ["isNode", "isBrick", "isCondition"],
  },
};

export const attributes1 = [
  "firstname",
  "lastname",
  "companyName",
  "companyUrl",
  "jobTitle",
  "twitter",
  "proEmail",
  "persoEmail",
  "industry",
  "location",
  "phone",
  "linkedinUrl",
  "gender",
  "profilePicture",
  "shortBio",
  "customAttribute1",
  "customAttribute2",
  "customAttribute3",
  "customAttribute4",
  "customAttribute5",
  "customAttribute6",
  "customAttribute7",
  "customAttribute8",
  "customAttribute9",
  "customAttribute10",
];

export const attributes = [
  { id: "firstname", name: "First Name" },
  { id: "lastname", name: "Last Name" },
  { id: "companyName", name: "Company" },
  { id: "companyUrl", name: "Website" },
  { id: "jobTitle", name: "Job" },
  { id: "twitter", name: "Twitter" },
  { id: "proEmail", name: "Pro Email" },
  { id: "persoEmail", name: "Perso Email" },
  { id: "industry", name: "Industry" },
  { id: "location", name: "Location" },
  { id: "phone", name: "Phone" },
  { id: "linkedinUrl", name: "Linkedin" },
  { id: "gender", name: "Gender" },
  { id: "profilePicture", name: "Profile Picture" },
  { id: "shortBio", name: "Bio" },
  { id: "customAttribute1", name: "Custom Attribute #1" },
  { id: "customAttribute2", name: "Custom Attribute #2" },
  { id: "customAttribute3", name: "Custom Attribute #3" },
  { id: "customAttribute4", name: "Custom Attribute #4" },
  { id: "customAttribute5", name: "Custom Attribute #5" },
  { id: "customAttribute6", name: "Custom Attribute #6" },
  { id: "customAttribute7", name: "Custom Attribute #7" },
  { id: "customAttribute8", name: "Custom Attribute #8" },
  { id: "customAttribute9", name: "Custom Attribute #9" },
  { id: "customAttribute10", name: "Custom Attribute #10" },
];

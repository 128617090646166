export const defaultValues = {
  "identity.firstname": "Brice",
  "identity.lastname": "Maurin",
  "identity.fullname": "Brice Maurin",
  "identity.gender": "Mr.",
  "identity.jobTitle": "Founder",
  "identity.location": "Paris",
  "identity.companyName": "LaGrowthMachine",
  "identity.companyUrl": "https://lagrowthmachine.com",
  "identity.industry": "Internet",
  "identity.email": "brice@lagrowthmachine.com",
  "identity.linkedin": "https://www.linkedin.com/in/bricemaurin/",
  "identity.twitter": "@b_maurin",
  "identity.customAttribute1": "CustomAttribute #1",
  "identity.customAttribute2": "CustomAttribute #2",
  "identity.customAttribute3": "CustomAttribute #3",
  "identity.customAttribute4": "CustomAttribute #4",
  "identity.customAttribute5": "CustomAttribute #5",
  "identity.customAttribute6": "CustomAttribute #6",
  "identity.customAttribute7": "CustomAttribute #7",
  "identity.customAttribute8": "CustomAttribute #8",
  "identity.customAttribute9": "CustomAttribute #9",
  "identity.customAttribute10": "CustomAttribute #10",
  firstname: "Jeff",
  lastname: "Weiner",
  fullname: "Jeff Weiner",
  gender: "Mr.",
  jobTitle: "CEO",
  location: "San Francisco",
  companyName: "Linkedin",
  companyUrl: "https://www.linkedin.com/",
  proEmail: "jeff@lagrowthmachine.com",
  persoEmail: "jeff@gmail.com",
  phone: "+ 33 6 12 34 56 78",
  linkedinUrl: "https://www.linkedin.com/",
  industry: "Internet",
  email: "jeff@gmail.com",
  twitter: "@jeffweiner",
  customAttribute1: "CustomAttribute #1",
  customAttribute2: "CustomAttribute #2",
  customAttribute3: "CustomAttribute #3",
  customAttribute4: "CustomAttribute #4",
  customAttribute5: "CustomAttribute #5",
  customAttribute6: "CustomAttribute #6",
  customAttribute7: "CustomAttribute #7",
  customAttribute8: "CustomAttribute #8",
  customAttribute9: "CustomAttribute #9",
  customAttribute10: "CustomAttribute #10",
};

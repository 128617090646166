import { ActionsObservable, ofType, Epic } from "redux-observable";
import { map, filter, takeUntil, mergeMap, catchError } from "rxjs/operators";
import types from "../types";
import { tag } from "rxjs-spy/operators/tag";
import * as actions from "../actions";
import config from "../../../config";
import { History } from "history";
import apiService from "../../../services/apis";
import getEndpoint from "../endpoints";
import IAction from "../../../interfaces/IAction";
import * as authActions from "../../Auth/actions";
import * as adapterLead from "../../Leads/adapters/lead";
import * as adapterActions from "../adapters/actions";
import * as adaptersMessageTemplate from "../adapters/messageTemplate";
import * as adaptersSequence from "../adapters/sequence";
import { of } from "rxjs/internal/observable/of";
import { push } from "connected-react-router";

import { v4 as uuidv4 } from "uuid";

//---- CAMPAIGNS ----
//SHORT CAMPAIGNS LIST WITH FEW INFORMATIONS
export const loadCampaignsNameEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_DATA.LOAD_CAMPAIGNS_NAME),

    mergeMap((action) => {
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "loadCampaignsName",
          params: action.payload?.params,
          query: {
            ...action.payload?.query,
            apikey: store.value.authReducer?.user?.apikey,
          },
        })
      )
        .pipe(
          tag("loadCampaignsName"),
          map((xhr) => actions.loadCampaignsNameFulfilled(xhr.response)),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_DATA.LOAD_CAMPAIGNS_NAME_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedLoadCampaignsName(e?.response));
          })
        );
    })
  );
};

//3 CAMPAIGNS ON "/campaigns page"
export const loadCampaignsRecommendedEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_DATA.LOAD_CAMPAIGNS_RECOMMENDED),

    mergeMap((action) => {
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "loadCampaignsRecommended",
          query: {
            apikey: store.value.authReducer?.user?.apikey,
            type: "recommended",
          },
        })
      )
        .pipe(
          tag("loadCampaignsRecommended"),
          map((xhr) => actions.loadCampaignsRecommendedFulfilled(xhr.response)),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_DATA.LOAD_CAMPAIGNS_RECOMMENDED_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedLoadCampaignsRecommended(e?.response));
          })
        );
    })
  );
};

//ALL PUBLIC CAMPAIGNS - STORE - "/campaigns/recommended"
export const loadCampaignsStoreEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_DATA.LOAD_CAMPAIGNS_STORE),

    mergeMap((action) => {
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "loadCampaignsStore",
          query: {
            apikey: store.value.authReducer?.user?.apikey,
            type: "public",
          },
        })
      )
        .pipe(
          tag("loadCampaignsStore"),
          map((xhr) => actions.loadCampaignsStoreFulfilled(xhr.response)),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_DATA.LOAD_CAMPAIGNS_STORE_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedLoadCampaignsStore(e?.response));
          })
        );
    })
  );
};

//LOAD CAMPAIGNS - WITH NEW PAGINATE(LOAD MORE)
export const loadCampaignsEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_DATA.LOAD_CAMPAIGNS),

    mergeMap((action) => {
      const campaigns = action.payload.campaigns;
      const setCampaigns = action.payload.setCampaigns;
      const setTotal = action.payload.setTotal;
      let query = {
        ...action.payload?.query,
        apikey: store.value.authReducer?.user?.apikey,
        status: action.payload.status.join(","),
        skip: campaigns.length,
        limit: config.limitCampaigns,
      };
      if (action.payload.search) {
        query.search = action.payload.search;
      }
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "loadCampaigns",
          //params: action.payload?.params,
          query: query,
        })
      )
        .pipe(
          tag("loadCampaigns"),
          map((xhr) =>
            actions.loadCampaignsFulfilled({
              response: xhr.response,
              campaigns: campaigns,
              setCampaigns: setCampaigns,
              setTotal: setTotal,
            })
          ),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type === types.RESPONSES_DATA.LOAD_CAMPAIGNS_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedLoadCampaigns(e?.response));
          })
        );
    })
  );
};

//UPDATE campaigns (useState) after LOAD_CAMPAIGNS - with paginate data
export const afterLoadCampaignsFulfilledEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.RESPONSES_DATA.LOAD_CAMPAIGNS_FULFILLED),
    mergeMap((action) => {
      const setCampaigns = action.payload.setCampaigns;
      const setTotal = action.payload.setTotal;
      setTotal(action.payload.response.total);
      setCampaigns(
        action.payload.campaigns.concat(action.payload.response.campaigns)
      );
      return of({ type: "" });
    })
  );
};

//RELOAD "/campaigns" (useState) after CAMPAIGN STATE EDITION
export const roladCampaignsPageAfterEditCampaignsStateEpic: Epic<
  IAction,
  IAction
> = (action$: ActionsObservable<IAction>, store: any) => {
  return action$.pipe(
    ofType(types.RESPONSES_ACTIONS.EDIT_CAMPAIGN_STATE_FULFILLED),
    mergeMap((action) => {
      if (action.payload.callback) {
        action.payload.callback();
      }
      return of({ type: "" });
    })
  );
};

//reload user after after CAMPAIGN STATE EDITION especially for limits
export const roladUserAfterEditCampaignsStateEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.RESPONSES_ACTIONS.EDIT_CAMPAIGN_STATE_FULFILLED),
    mergeMap((action) => {
      return of(authActions.loadUser({}));
    })
  );
};

//CREATE 1 CAMPAIGN
export const createCampaignEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_ACTIONS.CREATE_CAMPAIGN),
    mergeMap((action) => {
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "createCampaign",
          query: {
            ...action.payload?.query,
            apikey: store.value.authReducer?.user?.apikey,
          },
          params: action.payload?.params,
          body: action.payload?.body,
        })
      )
        .pipe(
          tag("createCampaign"),
          mergeMap((xhr) => {
            const campaignId = xhr.response.campaign.id;
            return of(
              actions.createCampaignFulfilled(xhr.response),
              push(`/campaigns/${campaignId}/edit`)
            );
          }),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_ACTIONS.CREATE_CAMPAIGN_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedCreateCampaign(e?.response));
          })
        );
    })
  );
};

//LOAD 1 CAMPAIGN WITH ALL DETAILS
export const loadCampaignEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_DATA.LOAD_CAMPAIGN),
    mergeMap((action) => {
      console.log("loadCampaign");
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "loadCampaign",
          query: {
            apikey: store.value.authReducer?.user?.apikey,
            ...action.payload?.query,
          },
          params: action.payload?.params,
        })
      )
        .pipe(
          tag("loadCampaign"),
          map((xhr) => {
            return actions.loadCampaignFulfilled(xhr.response);
          }),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type === types.RESPONSES_DATA.LOAD_CAMPAIGN_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedLoadCampaign(e?.response));
          })
        );
    })
  );
};

export const pushToHomeAfterRejectLoadCampaignEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.RESPONSES_DATA.LOAD_CAMPAIGN_REJECTED),
    mergeMap((action) => {
      return of({ type: "" }, push("/campaigns"));
    })
  );
};

//EDIT A CAMPAIGN
export const editCampaignEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_ACTIONS.EDIT_CAMPAIGN),
    mergeMap((action) => {
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "editCampaign",
          query: {
            ...action.payload?.query,
            apikey: store.value.authReducer?.user?.apikey,
          },
          params: action.payload?.params,
          body: action.payload?.body,
        })
      )
        .pipe(
          tag("editCampaign"),
          map((xhr) => actions.editCampaignFulfilled(xhr.response)),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_ACTIONS.EDIT_CAMPAIGN_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedEditCampaign(e?.response));
          })
        );
    })
  );
};

//EDIT A CAMPAIGN STATE
export const editCampaignStateEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_ACTIONS.EDIT_CAMPAIGN_STATE),
    mergeMap((action) => {
      const callback = action.payload?.callback;
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "editCampaignState",
          query: {
            ...action.payload?.query,
            apikey: store.value.authReducer?.user?.apikey,
          },
          params: action.payload?.params,
          body: action.payload?.body,
        })
      )
        .pipe(
          tag("editCampaignState"),
          mergeMap((xhr) => {
            return of(
              actions.editCampaignStateFulfilled({
                ...xhr.response,
                campaign: { id: action.payload?.params?.id },
                callback: callback,
              })
            );
          }),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_ACTIONS.EDIT_CAMPAIGN_STATE_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedEditCampaignState(e?.response));
          })
        );
    })
  );
};

//DUPLICATE A CAMPAIGN
export const duplicateCampaignEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_ACTIONS.DUPLICATE_CAMPAIGN),
    mergeMap((action) => {
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "duplicateCampaign",
          query: {
            apikey: store.value.authReducer?.user?.apikey,
          },
          params: action.payload?.params,
          body: action.payload?.body,
        })
      )
        .pipe(
          tag("duplicateCampaign"),
          mergeMap((xhr) => {
            const campaignId = xhr.response.campaign.id;
            return of(
              actions.duplicateCampaignFulfilled(xhr.response),
              push(`/campaigns/${campaignId}/edit`, { new: true })
            );
          }),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_ACTIONS.DUPLICATE_CAMPAIGN_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedDuplicateCampaign(e?.response));
          })
        );
    })
  );
};

//REQUEST CAMPAIGN REVIEW
export const campaignReviewEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_ACTIONS.CAMPAIGN_REVIEW),
    mergeMap((action) => {
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "campaignReview",
          query: {
            apikey: store.value.authReducer?.user?.apikey,
          },
          params: action.payload?.params,
        })
      )
        .pipe(
          tag("campaignReview"),
          map((xhr) => actions.campaignReviewFulfilled(xhr.response)),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_ACTIONS.CAMPAIGN_REVIEW_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedCampaignReview(e?.response));
          })
        );
    })
  );
};

//RELOAD A CAMPAIGN AFTER EDITION (DETAILS OR STATE)
export const reloadCampaignAfterEditOrEditStateEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(
      types.RESPONSES_ACTIONS.EDIT_CAMPAIGN_FULFILLED,
      types.RESPONSES_ACTIONS.EDIT_CAMPAIGN_STATE_FULFILLED
    ),
    map((action) => {
      return actions.loadCampaign({
        query: {},
        params: {
          id: action.payload.campaign.id,
        },
      });
    })
  );
};

//---- TEMPLATES----
//LOAD 1 MESSAGE for editor
export const loadMessageEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_DATA.LOAD_MESSAGE),
    mergeMap((action) => {
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "loadMessage",
          query: {
            apikey: store.value.authReducer?.user?.apikey,
            ...action.payload?.query,
          },
          params: action.payload?.params,
        })
      )
        .pipe(
          tag("loadMessage"),
          map((xhr) =>
            actions.loadMessageFulfilled({
              message: adaptersMessageTemplate.adapterIN(xhr.response),
            })
          ),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type === types.RESPONSES_DATA.LOAD_MESSAGE_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedLoadMessage(e?.response));
          })
        );
    })
  );
};

//LOAD ALL MESSAGES IN 1 CAMPAIGN
export const loadCampaignTemplates: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_DATA.LOAD_CAMPAIGN_TEMPLATES),
    mergeMap((action) => {
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "loadCampaignTemplates",
          query: {
            apikey: store.value.authReducer?.user?.apikey,
            ...action.payload?.query,
          },
          params: action.payload?.params,
        })
      )
        .pipe(
          tag("loadCampaignTemplates"),
          map((xhr) => actions.loadCampaignTemplatesFulfilled(xhr.response)),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_DATA.LOAD_CAMPAIGN_TEMPLATES_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedLoadCampaignTemplates(e?.response));
          })
        );
    })
  );
};

//LOAD ALL MESSAGES IN 1 CAMPAIGN - same as above. BUT DIFFERENT OBJECT IN REDUCER (GRAPH DUPLICATE MESSAGE FROM 1 CAMPAIGN TO PRESENT ONE)
export const loadOtherCampaignTemplates: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_DATA.LOAD_OTHER_CAMPAIGN_TEMPLATES),
    mergeMap((action) => {
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "loadCampaignTemplates",
          query: {
            apikey: store.value.authReducer?.user?.apikey,
            ...action.payload?.query,
          },
          params: action.payload?.params,
        })
      )
        .pipe(
          tag("loadOtherCampaignTemplates"),
          map((xhr) =>
            actions.loadOtherCampaignTemplatesFulfilled(xhr.response)
          ),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_DATA.LOAD_OTHER_CAMPAIGN_TEMPLATES_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedLoadOtherCampaignTemplates(e?.response));
          })
        );
    })
  );
};

//DUPLICATE A MESSAGE FROM A CAMPAIGN INTO PRESENT CAMPAIGN
export const duplicateMessageTemplateEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_ACTIONS.DUPLICATE_MESSAGE_TEMPLATE),
    mergeMap((action) => {
      const campaignId = action.payload?.body?.campaignId;
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "duplicateMessageTemplate",
          params: action.payload?.params,
          query: {
            ...action.payload?.query,
            apikey: store.value.authReducer?.user?.apikey,
          },
          body: action.payload?.body,
        })
      )
        .pipe(
          tag("duplicateMessageTemplate"),
          map((xhr) =>
            actions.duplicateMessageTemplateFulfilled({
              response: xhr.response,
              campaignId,
            })
          ),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_ACTIONS.DUPLICATE_MESSAGE_TEMPLATE_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedDuplicateMessageTemplate(e?.response));
          })
        );
    })
  );
};

//CREATE A TEMPLATE WITHIN A GIVEN CAMPAIGN
export const createCampaignTemplateEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_ACTIONS.CREATE_CAMPAIGN_TEMPLATE),
    mergeMap((action) => {
      const body = adaptersMessageTemplate.createOUT(action.payload?.body);
      const campaignId = body.campaignId;
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "createCampaignTemplate",
          query: {
            ...action.payload?.query,
            apikey: store.value.authReducer?.user?.apikey,
          },
          body: body,
        })
      )
        .pipe(
          tag("createCampaignTemplate"),
          map((xhr) =>
            actions.createCampaignTemplateFulfilled({
              response: xhr.response,
              campaignId: campaignId,
            })
          ),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_ACTIONS.CREATE_CAMPAIGN_TEMPLATE_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedCreateCampaignTemplate(e?.response));
          })
        );
    })
  );
};

//EDIT A TEMPLATE
export const editCampaignTemplateEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_ACTIONS.EDIT_CAMPAIGN_TEMPLATE),
    mergeMap((action) => {
      const campaignId = action.payload?.params.campaignId;
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "editCampaignTemplate",
          params: action.payload?.params,
          query: {
            ...action.payload?.query,
            apikey: store.value.authReducer?.user?.apikey,
          },
          body: adaptersMessageTemplate.editOUT(action.payload?.body),
        })
      )
        .pipe(
          tag("editCampaignTemplate"),
          map((xhr) =>
            actions.editCampaignTemplateFulfilled({
              response: xhr.response,
              campaignId: campaignId,
            })
          ),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_ACTIONS.EDIT_CAMPAIGN_TEMPLATE_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedEditCampaignTemplate(e?.response));
          })
        );
    })
  );
};

//RELOAD CAMPAIGN TEMPLATES AFTER CREATE-EDIT-DUPLICATE THE CAMPAIGN
export const reloadCampaignTemplatesAfterCreateEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(
      types.RESPONSES_ACTIONS.CREATE_CAMPAIGN_TEMPLATE_FULFILLED,
      types.RESPONSES_ACTIONS.EDIT_CAMPAIGN_TEMPLATE_FULFILLED,
      types.RESPONSES_ACTIONS.DUPLICATE_MESSAGE_TEMPLATE_FULFILLED
    ),
    map((action) => {
      return actions.loadCampaignTemplates({
        query: {},
        params: {
          id: action.payload.campaignId,
        },
      });
    })
  );
};

// ---- SEQUENCE ---
//JUST LOAD A CAMPAIGN.SEQUENCE
export const loadSequenceEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_DATA.LOAD_SEQUENCE),
    mergeMap((action) => {
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "loadSequence",
          params: action.payload?.params,
          query: {
            ...action.payload?.query,
            apikey: store.value.authReducer?.user?.apikey,
          },
        })
      )
        .pipe(
          tag("loadSequence"),
          map((xhr) =>
            actions.loadSequenceFulfilled(
              adaptersSequence.adapterIN(xhr.response)
            )
          ),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type === types.RESPONSES_DATA.LOAD_SEQUENCE_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedLoadSequence(e?.response));
          })
        );
    })
  );
};

//EDIT A SEQUENCE
export const editSequenceEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_ACTIONS.EDIT_SEQUENCE),
    mergeMap((action) => {
      const launchAfterSave = action.payload?.params.launchAfterSave;
      const campaignId = action.payload?.params.campaignId;
      const setGraphEdited = action.payload?.setGraphEdited;
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "editSequence",
          params: action.payload?.params,
          body: adaptersSequence.adapterEditOUT(action.payload?.body),
          query: {
            ...action.payload?.query,
            apikey: store.value.authReducer?.user?.apikey,
          },
        })
      )
        .pipe(
          tag("editSequence"),
          map((xhr) => {
            if (setGraphEdited) {
              setGraphEdited(false);
            }
            return actions.editSequenceFulfilled({
              response: adaptersSequence.adapterIN(xhr.response),
              launchAfterSave,
              campaignId,
            });
          }),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_ACTIONS.EDIT_SEQUENCE_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedEditSequence(e?.response));
          })
        );
    })
  );
};

//CASE SAVE-AND-LAUNCH - EDIT SEQUENCE AND LAUNCH ITS CAMPAIGN
export const launchCampaignAfterSequenceEditEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any,
  deps: { history: History }
) => {
  return action$.pipe(
    ofType(types.RESPONSES_ACTIONS.EDIT_SEQUENCE_FULFILLED),
    mergeMap((action) => {
      if (action.payload.launchAfterSave) {
        return of(
          actions.editCampaignState({
            params: {
              id: action.payload.campaignId,
              status: "START",
            },
          })
        );
      } else {
        return of({ type: "" });
      }
    })
  );
};

export const saveCampaignsFiltersEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.CAMPAIGN_CUSTOM_FILTER),
    mergeMap((action) => {
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "saveCampaignsFilters",
          query: {
            apikey: store.value.authReducer?.user?.apikey,
          },
          body: {
            configurationStats: action.payload.configurationStats,
            campaignFilters: action.payload.campaignFilters,
          },
        })
      )
        .pipe(
          map((xhr) => actions.saveCampaignsFiltersFulfilled(xhr.response)),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_ACTIONS.SAVE_CAMPAIGNS_FILTERS_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedSaveCampaignsFilters(e?.response));
          })
        );
    })
  );
};

export const loadCampaignsFiltersEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.RESPONSES_DATA.LOAD_CAMPAIGN_STATS_FULFILLED),
    mergeMap((action) => {
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "loadCampaignsFilters",
          params: action.payload?.params,
          query: {
            ...action.payload?.query,
            apikey: store.value.authReducer?.user?.apikey,
          },
        })
      )
        .pipe(
          tag("loadCampaignsFilters"),
          map((xhr) => actions.loadCampaignsFiltersFulfilled(xhr.response)),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_DATA.LOAD_CAMPAIGNS_FILTERS_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedLoadCampaignsFilters(e?.response));
          })
        );
    })
  );
};

export const leadCampaignLeadsAfterFiltersEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any,
  deps: { history: History }
) => {
  return action$.pipe(
    ofType(
      types.CAMPAIGN_FILTERS,
      types.CAMPAIGN_CUSTOM_FILTER,
      types.RESPONSES_ACTIONS.EDIT_LEADS_QUEUE_STATUS_FULFILLED
    ),
    map((action) => {
      if (deps?.history?.location?.pathname.indexOf("/actions") !== -1) {
        return actions.loadCampaignActions({});
      }
      const query = {
        audiences: {
          id: store.value.campaignReducer?.selected?.audience?.id,
        },
        stepIds: [],
      };

      if (action?.payload?.leadsFilters?.filterId) {
        query.stepIds = getStepIds(
          store.value.campaignReducer?.customFilters?.campaignFilters,
          store.value.campaignReducer?.selected?.id,
          action?.payload?.leadsFilters?.filterId
        );
      }
      if (action?.payload?.campaignFilters) {
        query.stepIds = getStepIds(
          action?.payload?.campaignFilters,
          store.value.campaignReducer?.selected?.id,
          store.value.campaignReducer?.selected?.leadsFilters?.filterId
        );
      }
      return actions.loadCampaignLeads({
        query,
      });
    })
  );
};

const getStepIds = (
  campaignFilter: any,
  campaignId: string,
  filterId: string
) => {
  if (["started", "converted", "all", "paused"].indexOf(filterId) !== -1)
    return [];
  const filter = campaignFilter?.find(
    (f: { campaignId: string; id: string }) =>
      f.campaignId === campaignId && f.id === filterId
  );
  return filter?.stepIds || [];
};

export const loadCampaignLeadsEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(
      types.REQUESTS_DATA.LOAD_CAMPAIGN_LEADS,
      types.CAMPAIGN_LEADS_PAGINATION
      // types.CAMPAIGN_FILTERS
    ),
    mergeMap((action) => {
      const query = {
        apikey: store.value.authReducer?.user?.apikey,
        ...action.payload?.query,
        limit: 25,
        skip:
          (store.value.campaignReducer?.selected?.paginate?.current || 0) * 25,
        audiences:
          store.value.campaignReducer?.selected?.audience?.id ||
          (Array.isArray(action.payload?.query?.audiences) &&
            action.payload?.query?.audiences[0]),
        campaignId: store.value.campaignReducer?.selected?.id,
        stepIds:
          action.payload.query?.stepIds ||
          getStepIds(
            store.value.campaignReducer?.customFilters?.campaignFilters,
            store.value.campaignReducer?.selected?.id,
            store.value.campaignReducer?.selected?.leadsFilters?.filterId
          ),
        filterId: store.value.campaignReducer?.selected?.leadsFilters?.filterId,
        keywords: store.value.campaignReducer?.selected?.leadsFilters?.keywords,
      };
      console.log("loadCampaignLeads");
      if (!query.audiences) return of({ type: "" });
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "loadCampaignLeads",
          query,
          params: action.payload?.params,
        })
      )
        .pipe(
          tag("loadCampaignLeads"),
          map((xhr) =>
            actions.loadCampaignLeadsFulfilled({
              leads: adapterLead.arrayOf(xhr.response.leads, "IN"),
              total: xhr.response.total,
            })
          ),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_DATA.LOAD_CAMPAIGN_LEADS_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedLoadCampaignLeads(e?.response));
          })
        );
    })
  );
};

export const exportCampaignLeadsEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_DATA.EXPORT_CAMPAIGN_LEADS),
    mergeMap((action) => {
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "loadCampaignLeads",
          query: {
            apikey: store.value.authReducer?.user?.apikey,
            ...action.payload?.query,
            limit: 25,
            skip:
              (store.value.campaignReducer?.selected?.paginate?.current || 0) *
              25,
            stepIds:
              action.payload.query?.stepIds ||
              getStepIds(
                store.value.campaignReducer?.customFilters?.campaignFilters,
                store.value.campaignReducer?.selected?.id,
                store.value.campaignReducer?.selected?.leadsFilters?.filterId
              ),
            audiences: store.value.campaignReducer?.selected?.audience?.id,
            campaignId: store.value.campaignReducer?.selected?.id,
            ...store.value.campaignReducer?.selected?.leadsFilters,
          },
          params: action.payload?.params,
        })
      )
        .pipe(
          map((xhr) => actions.exportCampaignLeadsFulfilled()),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_DATA.EXPORT_CAMPAIGN_LEADS_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedExportCampaignLeads(e?.response));
          })
        );
    })
  );
};

export const loadCampaignStatsEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_DATA.LOAD_CAMPAIGN_STATS),
    mergeMap((action) => {
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "loadCampaignStats",
          query: {
            apikey: store.value.authReducer?.user?.apikey,
            ...action.payload?.query,
            endDate: new Date().getTime(),
          },
          params: action.payload?.params,
        })
      )
        .pipe(
          tag("loadCampaignStats"),
          map((xhr) => actions.loadCampaignStatsFulfilled(xhr.response)),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_DATA.LOAD_CAMPAIGN_STATS_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedLoadCampaignStats(e?.response));
          })
        );
    })
  );
};

export const loadCampaignActionsAfterPaginateEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(
      types.CAMPAIGN_ACTIONS_PAGINATE,
      types.CAMPAIGN_ACTIONS_CHANNEL_TYPE
    ),
    map((action) => {
      return actions.loadCampaignActions({
        query: {},
        params: {
          id: action.payload.id,
        },
      });
    })
  );
};

export const loadCampaignActionsEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_DATA.LOAD_CAMPAIGN_ACTIONS),
    mergeMap((action) => {
      const status = store.value.campaignReducer?.actions?.status;
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "loadCampaignActions",
          query: {
            apikey: store.value.authReducer?.user?.apikey,
            ...action.payload?.query,
            skip:
              (store.value.campaignReducer?.actions?.paginate?.current || 0) *
              25,
            hubspot: String(store.value.campaignReducer?.actions.showHubspot),
            pipedrive: String(
              store.value.campaignReducer?.actions.showPipedrive
            ),
          },
          params: {
            ...action.payload?.params,
            id:
              action.payload?.params?.id ||
              store.value.campaignReducer?.selected?.id,
            status: status,
          },
        })
      )
        .pipe(
          tag("loadCampaignActions"),
          map((xhr) =>
            actions.loadCampaignActionsFulfilled({
              actions: adapterActions.arrayOf(xhr.response.actions, "IN"),
              total: xhr.response.total,
              status: status,
            })
          ),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_DATA.LOAD_CAMPAIGN_ACTIONS_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedLoadCampaignActions(e?.response));
          })
        );
    })
  );
};

export const editLeadsQueueStatusEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_ACTIONS.EDIT_LEADS_QUEUE_STATUS),

    mergeMap((action) => {
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "updateCampaignLeadStatus",
          params: action.payload?.params,
          body: action.payload?.body,
          query: {
            ...action.payload?.query,
            apikey: store.value.authReducer?.user?.apikey,
          },
        })
      )
        .pipe(
          map((xhr) => actions.editLeadsQueueStatusFulfilled(xhr.response)),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_ACTIONS.EDIT_LEADS_QUEUE_STATUS_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedEditLeadsQueueStatus(e?.response));
          })
        );
    })
  );
};

export const loadCampaignsFiltersFulfilledEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.RESPONSES_DATA.LOAD_CAMPAIGNS_FILTERS_FULFILLED),
    map((action) => {
      let campaignConfigurationStats = store.value.campaignReducer.customFilters.configurationStats.filter(
        (stat: any) =>
          stat.campaignId === store.value.campaignReducer?.selected?.id
      );

      if (campaignConfigurationStats.length === 0) {
        const configurationStats = store.value.campaignReducer.customFilters.configurationStats.concat(
          [
            {
              id: uuidv4(),
              campaignId: store.value.campaignReducer?.selected?.id,
              stepIds: store.value.campaignReducer.stats.statuts
                .filter((step: any) => step.label)
                .map((step: any) => step.stepId)
                .slice(0, 1),
            },
          ]
        );

        return actions.createCustomFilter({
          campaignFilters:
            store.value.campaignReducer.customFilters.campaignFilters,
          configurationStats: configurationStats,
        });
      } else {
        return { type: "" };
      }
    })
  );
};

export const loadCampaignProgressEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_DATA.LOAD_CAMPAIGN_PROGRESS),
    mergeMap((action) => {
      const timescale = Number(action.payload?.timescale) || 1;
      console.log(timescale);
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "loadCampaignStats",
          query: {
            apikey: store.value.authReducer?.user?.apikey,
            ...action.payload?.query,
            startDate:
              new Date().getTime() - Number(timescale) * 24 * 3600 * 1000,
            endDate: new Date().getTime(),
          },
          params: action.payload?.params,
        })
      )
        .pipe(
          tag("loadCampaignStats"),
          map((xhr) =>
            actions.loadCampaignProgressFulfilled({
              ...xhr.response,
              timescale: timescale,
            })
          ),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_DATA.LOAD_CAMPAIGN_PROGRESS_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedLoadCampaignProgress(e?.response));
          })
        );
    })
  );
};

export const checkTemplateAssignationEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(
      types.RESPONSES_ACTIONS.CREATE_CAMPAIGN_TEMPLATE_FULFILLED,
      types.RESPONSES_ACTIONS.DUPLICATE_MESSAGE_TEMPLATE_FULFILLED
    ),
    mergeMap((action) => {
      return of(actions.checkAssignation({ id: action.payload.response.id }));
    })
  );
};

export const resetTemplateAssignationEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(
      types.CHECK_ASSIGNATION,
      types.RESPONSES_ACTIONS.CREATE_CAMPAIGN_TEMPLATE_CANCELLED,
      types.RESPONSES_ACTIONS.CREATE_CAMPAIGN_TEMPLATE_REJECTED,
      types.RESPONSES_ACTIONS.DUPLICATE_MESSAGE_TEMPLATE_CANCELLED,
      types.RESPONSES_ACTIONS.DUPLICATE_MESSAGE_TEMPLATE_REJECTED
    ),
    mergeMap((action) => {
      return of(actions.resetAssignation());
    })
  );
};

import React from "react";
import ModalWrapper from "../../../../components/ModalWrapper";
import asyncRequest from "../../../../helpers/asyncRequest";
import globalEndpoints from "../../../../endpoints";
import { CampaignAudienceNew } from "@lagrowthmachine/lgmwebapp";
import useSelector from "../../../../redux/useSelector";
import * as globalActions from "../../../../redux/actions";
import globalTypes from "../../../../redux/types";
import { useDispatch } from "react-redux";

const getTypeFromUrl = (url: string) => {
  if (url.includes("com/sales/")) {
    return "SALES_NAV";
  }
  return "LINKEDIN";
};

function Modal(props: any) {
  const loadingReducer = useSelector((state) => state.loadingReducer);
  const authReducer = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();

  const sourceUrl = props.data.sourceUrl;
  const type = getTypeFromUrl(sourceUrl);

  const onNewAudience = async ({
    name,
    description,
  }: {
    name: string;
    description: string;
  }) => {
    dispatch(
      globalActions.updateBtnLoaders({
        name: globalTypes.REQUESTS_ACTIONS.CREATE_AUDIENCE,
        status: true,
      })
    );

    let responseAudience;
    try {
      responseAudience = await asyncRequest(globalEndpoints, {
        apiVersion: authReducer?.user?.apiVersion,
        name: "createAudience",
        body: {
          identityId: props.data.identityId,
          audience: {
            name,
            description,
            sourceUrl,
            type,
          },
        },
        query: {
          apikey: authReducer?.user?.apikey,
        },
      });
    } catch (e) {
      dispatch(
        globalActions.setNotificationSmall({
          notification: {
            type: "error",
            text: e.message || "Something went wrong",
          },
          format: "basic",
        })
      );
    }

    dispatch(
      globalActions.updateBtnLoaders({
        name: globalTypes.REQUESTS_ACTIONS.CREATE_AUDIENCE,
        status: false,
      })
    );

    if (responseAudience?.data?.audienceCreated?.id) {
      dispatch(
        globalActions.setNotificationSmall({
          notification: {
            type: "success",
            text: "Your linkedin import will start soon",
          },
          format: "basic",
        })
      );
      props.setAssignAudienceToCampaign(
        responseAudience?.data?.audienceCreated?.id
      );
      dispatch(globalActions.loadAudiences({ query: {} }));
    }
    onRequestClose();
  };

  const onRequestClose = () => {
    dispatch(globalActions.modalUpdate({ name: "reset", data: {} }));
  };

  return (
    <ModalWrapper>
      <CampaignAudienceNew
        //leadsNumber={leadsNumber || 0}
        audience={{ name: "", description: "" }}
        onNewAudience={onNewAudience}
        btnSaveDisabled={
          loadingReducer.btnTypesLoaders[
            globalTypes.REQUESTS_ACTIONS.CREATE_AUDIENCE
          ]
        }
        btnSaveIsLoading={
          loadingReducer.btnTypesLoaders[
            globalTypes.REQUESTS_ACTIONS.CREATE_AUDIENCE
          ]
        }
        onCancel={onRequestClose}
      />
    </ModalWrapper>
  );
}
export default Modal;

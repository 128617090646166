import { Action, Dispatch } from "redux";
import { IStoreApi } from "../configure";

interface ActionType extends Action {
  type: string;
  payload?: any;
}

export default (
  storeAPI: IStoreApi,
  createBugsnag: (storeAPI: IStoreApi, user: any) => void
) => {
  return (next: Dispatch<ActionType>) => (action: ActionType) => {
    const user = action.payload?.authReducer?.user;
    if (action.type === "persist/REHYDRATE") {
      createBugsnag(storeAPI, user);
    }
    return next(action);
  };
};

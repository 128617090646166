import React from "react";

import NewAudienceCreate from "./NewAudienceCreate";
import DeleteLeads from "./DeleteLeads";
import ImportAudienceCSV from "./ImportAudienceCSV";
import ImportLinkedinSelectAudience from "./ImportLinkedinSelectAudience";
import AudienceEdit from "./AudienceEdit";
import AudienceDelete from "./AudienceDelete";
import EditLeadConfirm from "../../../../components/Modals/EditLeadConfirm";

export const types = {
  newAudienceCreate: "newAudienceCreate",
  audienceEdit: "audienceEdit",
  deleteLeads: "deleteLeads",
  importAudienceCSV: "importAudienceCSV",
  linkedinImportSelectAudience: "linkedinImportSelectAudience",
  audienceDelete: "audienceDelete",
  edit_lead_confirm: "edit_lead_confirm",
  reset: "",
};

function Modals(props: any) {
  switch (props.name) {
    case types.newAudienceCreate:
      return <NewAudienceCreate {...props} />;
    case types.deleteLeads:
      return <DeleteLeads {...props} />;
    case types.importAudienceCSV:
      return <ImportAudienceCSV {...props} />;
    case types.linkedinImportSelectAudience:
      return <ImportLinkedinSelectAudience {...props} />;
    case types.audienceEdit:
      return <AudienceEdit {...props} />;
    case types.audienceDelete:
      return <AudienceDelete {...props} />;
    case types.edit_lead_confirm:
      return <EditLeadConfirm {...props} />;
    default:
      return null;
  }
}

export default Modals;

import MethodType from "../../../interfaces/IMethod";
import { encodeQuery } from "../../../helpers/encodeQuery";
import config, { APP_ENV } from "../../../config";
import { IEndpointParams } from "../../../interfaces/IEndpoint";
import getApiUrl from "../../../helpers/getApiUrl";

export default function getEndpoint({
  name,
  body,
  query,
  params,
  apiVersion,
}: IEndpointParams): {
  url: string;
  method: MethodType;
  headers?: { [name: string]: string };
  body?: any;
  crossDomain?: boolean;
} {
  let queryEncoded: string = "";

  if (query) {
    queryEncoded = encodeQuery(query);
  }
  switch (name) {
    case "loadLeads":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.leads
        }?${queryEncoded}`,
        method: "GET",
        crossDomain: true,
      };
    case "loadLead":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${config.endpoints.leads}${
          params?.leadId
        }?${queryEncoded}`,
        method: "GET",
        crossDomain: true,
      };
    case "loadLeadCampaignLogs":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${config.endpoints.leads}${
          params?.leadId
        }/logs?${queryEncoded}`,
        method: "GET",
        crossDomain: true,
      };
    case "deleteLeads":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.leads
        }deleteLeads?${queryEncoded}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body,
        crossDomain: true,
      };
    case "createLead":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.leads
        }?${queryEncoded}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body,
        crossDomain: true,
      };
    case "editLeads":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.leads
        }?${queryEncoded}`,
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body,
        crossDomain: true,
      };
    case "editLead":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${config.endpoints.leads}${
          params?.leadId
        }?${queryEncoded}`,
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body,
        crossDomain: true,
      };
    case "loadHubspotLeads":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.crm
        }contacts/hubspot?${queryEncoded}`,
        method: "GET",
        body,
        crossDomain: true,
      };
    case "loadPipedriveLeads":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.crm
        }contacts/pipedrive?${queryEncoded}`,
        method: "GET",
        body,
        crossDomain: true,
      };
    default:
      return {
        url: ``,
        method: "GET",
        crossDomain: true,
      };
  }
}

import React, { useState } from "react";
import Cropper from "react-easy-crop";
import ModalWrapper from "../../../../components/ModalWrapper";
import Slider from "@material-ui/core/Slider";
import getCroppedImg from "../../helpers/getCroppedImg";
import "./cropAvatar.scss";

function Modal(props: any) {
  const imageSrc = props.data.imageSrc;
  const [zoom, setZoom] = useState(1.2);
  const [crop, setCrop] = useState({ x: 0, y: 0 });

  const [config, setConfig] = useState({
    croppedAreaPixels: null,
    croppedImage: null,
    isCropping: false,
  });

  const onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
    setConfig({
      ...config,
      croppedAreaPixels,
    });
  };

  const image: string = imageSrc!;

  const generate = async () => {
    const croppedImage = await getCroppedImg(image, config.croppedAreaPixels);
    props.onCropped(croppedImage);
    props.onRequestClose();
  };

  return (
    <ModalWrapper>
      <div className="crop_avatar_wrapper">
        <Cropper
          image={image}
          crop={crop}
          zoom={zoom}
          aspect={1}
          cropShape="round"
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
        <div className="crop_save" onClick={generate}>
          Save your image
        </div>
        <div className="crop_cancel" onClick={props.onRequestClose}>
          Cancel
        </div>
        <div className="crop_avatar_slider">
          <Slider
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e: any, zoom: any) => setZoom(zoom)}
          />
        </div>
      </div>
    </ModalWrapper>
  );
}
export default Modal;

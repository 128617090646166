import React from "react";
import ModalWrapper from "../../../../components/ModalWrapper";
import { CampaignInfo } from "@lagrowthmachine/lgmwebapp";
import useSelector from "../../../../redux/useSelector";
import types from "../../types";

function Modal(props: any) {
  const loadingReducer = useSelector((state) => state.loadingReducer);

  const handleSave = (name: string, description: string) => {
    props.onCampaignEdit({ name, description });
  };

  const btnSaveDisabled =
    loadingReducer?.btnTypesLoaders[types.REQUESTS_ACTIONS.EDIT_CAMPAIGN];
  const btnSaveIsLoading = btnSaveDisabled;

  return (
    <ModalWrapper>
      <CampaignInfo
        name={props.data.name || ""}
        description={props.data.description || ""}
        onSave={handleSave}
        onCancel={props.onRequestClose}
        btnSaveDisabled={btnSaveDisabled}
        btnSaveIsLoading={btnSaveIsLoading}
      />
    </ModalWrapper>
  );
}
export default Modal;

import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useLocation } from "react-router";
import {
  SettingsTopBar,
  SettingsActionsBar,
  ExtStart,
  CrmMappings,
  CrmTriggers,
  CrmConnect,
  CrmUpgrade,
} from "@lagrowthmachine/lgmwebapp";
import Modal, { types as modalTypes } from "../Modals";
import settingsTypes from "../../types";
import { useDispatch } from "react-redux";
import * as authActions from "../../../Auth/actions";
import * as actions from "../../../Settings/actions";
import * as globalActions from "../../../../redux/actions";
import useSelector from "../../../../redux/useSelector";
import analyticsCall from "../../../../helpers/analyticsCall";
import barNavItems from "../../helpers/barNavItems";

const SettingsCrmComponent = (props: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const modalsReducer = useSelector((state) => state.modalsReducer);
  const loadingReducer = useSelector((state) => state.loadingReducer);
  const authReducer = useSelector((state) => state.authReducer);
  const settingsReducer = useSelector((state) => state.settingsReducer);

  const bar = barNavItems(authReducer);

  let params = useLocation();
  const crmRedirect = new URLSearchParams(params.search).get("crm") || "";
  if (crmRedirect) {
    window.scrollTo(0, 0);
  }
  const [channel, setChannel] = useState(
    (["PIPEDRIVE", "HUBSPOT"].includes(crmRedirect) && crmRedirect) || ""
  );

  let hubspot = authReducer?.user?.crm?.find(
    (crm: any) => crm.type === "HUBSPOT"
  );
  let pipedrive = authReducer?.user?.crm?.find(
    (crm: any) => crm.type === "PIPEDRIVE"
  );

  if (hubspot?.mapping) {
    hubspot.mapping = hubspot.mapping.map((_mapping: any) => ({
      extField: _mapping.extField,
      lgmField: _mapping.lgmField,
    }));
  }

  if (pipedrive?.mapping) {
    pipedrive.mapping = pipedrive.mapping.map((_mapping: any) => ({
      extField: _mapping.extField,
      lgmField: _mapping.lgmField,
    }));
  }

  useEffect(() => {
    analyticsCall("settings", "settings: crm settings");
  }, []);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    const code = new URLSearchParams(params.search).get("code");
    const isHubspot =
      params.pathname === "/settings/hubspot" ||
      params.pathname === "/settings/crm/hubspot";
    const isPipedrive =
      params.pathname === "/settings/pipedrive" ||
      params.pathname === "/settings/crm/pipedrive";
    if (code) {
      if (sessionStorage.getItem("crmConnectFrom") === "onboarding") {
        //return to onboarding if crm connect launched during onboarding flow
        history.push({
          pathname: "/onboarding/crm_connect",
          search:
            "?crm=" + (isHubspot ? "HUBSPOT" : "PIPEDRIVE") + "&code=" + code,
        });
        return;
      }
      if (isHubspot) {
        setChannel("HUBSPOT");
        dispatch(actions.hubspotConnect({ code: code }));
      }
      if (isPipedrive) {
        setChannel("PIPEDRIVE");
        dispatch(actions.pipedriveConnect({ code: code }));
      }
    }
  }, [dispatch, history, params]);

  useEffect(() => {
    dispatch(authActions.loadUser({ query: {} }));
  }, [dispatch]);

  useEffect(() => {
    if (hubspot) {
      dispatch(actions.loadHubspotMappings({}));
      dispatch(actions.loadHubspotLifecycles({}));
    }
    if (pipedrive) {
      dispatch(actions.loadPipedriveMappings({}));
      dispatch(actions.loadPipedriveLifecycles({}));
    }
  }, [dispatch, hubspot, pipedrive]);

  const handleActions = (action: string, data?: any) => {
    switch (action) {
      case "input":
        dispatch(authActions.signOutUser());
        break;
      default:
        break;
    }
  };

  const handleConnect = (channel: string) => {
    switch (channel) {
      case "HUBSPOT":
        dispatch(actions.loadHubspotConnectUri({}));
        break;
      case "PIPEDRIVE":
        dispatch(actions.loadPipedriveConnectUri({}));
        break;
      default:
        break;
    }
  };

  const handleDisconnect = (channel: string) => {
    switch (channel) {
      case "HUBSPOT":
        setChannel("");
        dispatch(actions.hubspotDisconnect({}));
        break;
      case "PIPEDRIVE":
        setChannel("");
        dispatch(actions.pipedriveDisconnect({}));
        break;
      default:
        break;
    }
  };

  const handleModal = (data: any) => {
    switch (data.name) {
      case "hubspotConfirmDisconnect":
        dispatch(
          globalActions.modalUpdate({
            name: "hubspotConfirmDisconnect",
            data: {
              title: "Disconnect Hubspot",
              subtitle:
                "Disconnection will be effective immediately, relevant campaigns will stop sending update to Hubspot, and current settings will be lost.",
              deleteText: "Disconnect Hubspot",
              cancelText: "Cancel",
            },
          })
        );
        break;
      case "pipedriveConfirmDisconnect":
        dispatch(
          globalActions.modalUpdate({
            name: "pipedriveConfirmDisconnect",
            data: {
              title: "Disconnect Pipedrive",
              subtitle:
                "Disconnection will be effective immediately, relevant campaigns will stop sending update to Pipedrive, and current settings will be lost.",
              deleteText: "Disconnect Pipedrive",
              cancelText: "Cancel",
            },
          })
        );
        break;
      default:
        dispatch(
          globalActions.modalUpdate({
            name: "",
            data: {},
          })
        );
        break;
    }
  };

  const toBack = () => {
    if (crmRedirect !== "") {
      setChannel("");
      history.push("/settings/crm");
      return;
    }
    setChannel("");
  };

  const loadHubspotMappings = () => {
    dispatch(actions.loadHubspotMappings());
  };

  const loadHubspotLifecycles = () => {
    dispatch(actions.loadHubspotLifecycles());
  };

  const loadPipedriveMappings = () => {
    dispatch(actions.loadPipedriveMappings());
  };

  const loadPipedriveLifecycles = () => {
    dispatch(actions.loadPipedriveLifecycles());
  };

  const editHubspotMappings = (obj: { [index: string]: string }) => {
    const fields = ["linkedinUrl", "shortBio"];

    const mapping = hubspot.mapping
      .filter((field: any) => !fields.includes(field.lgmField))
      .concat(
        fields.map((_field: string) => ({
          lgmField: _field,
          extField: obj[_field],
        }))
      );

    let updatedCrm = {
      ...hubspot,
      crmName: "HUBSPOT",
      mapping: mapping,
    };

    delete updatedCrm["type"];
    delete updatedCrm["creds"];
    delete updatedCrm["lifecycle"];
    dispatch(actions.hubspotMappingEdit({ data: updatedCrm }));
  };

  const editPipedriveMappings = (obj: { [index: string]: string }) => {
    const fields = [
      "gender",
      "jobTitle",
      "location",
      "twitter",
      "linkedinUrl",
      "shortBio",
    ];

    const mapping = pipedrive.mapping
      .filter((field: any) => !fields.includes(field.lgmField))
      .concat(
        fields.map((_field: string) => ({
          lgmField: _field,
          extField: obj[_field],
        }))
      );

    let updatedCrm = {
      ...pipedrive,
      crmName: "PIPEDRIVE",
      mapping: mapping,
    };

    delete updatedCrm["type"];
    delete updatedCrm["creds"];
    delete updatedCrm["lifecycle"];
    dispatch(actions.pipedriveMappingEdit({ data: updatedCrm }));
  };

  const updateHubspotLifecycle = (lifecycle: any) => {
    let updatedCrm = {
      ...hubspot,
      mapping: hubspot.mapping,
      crmName: "HUBSPOT",
      lifecycles: lifecycle.map((_lifecycle: any) => ({
        triggers: _lifecycle.actions,
        value: _lifecycle.lifecycle,
        label: _lifecycle.label,
        id: _lifecycle.id,
      })),
    };
    delete updatedCrm["type"];
    delete updatedCrm["creds"];
    delete updatedCrm["lifecycle"];
    dispatch(actions.hubspotLifecycleEdit({ data: updatedCrm }));
  };

  const updatePipedriveLifecycle = (lifecycle: any) => {
    let updatedCrm = {
      ...pipedrive,
      mapping: pipedrive.mapping,
      crmName: "PIPEDRIVE",
      lifecycles: lifecycle.map((_lifecycle: any) => ({
        triggers: _lifecycle.actions,
        value: _lifecycle.lifecycle,
        label: _lifecycle.label,
        id: _lifecycle.id,
      })),
    };
    delete updatedCrm["type"];
    delete updatedCrm["creds"];
    delete updatedCrm["lifecycle"];
    dispatch(actions.pipedriveLifecycleEdit({ data: updatedCrm }));
  };

  if (hubspot) {
    var linkedinUrl = hubspot.mapping.find(
      (field: any) => field.lgmField === "linkedinUrl"
    )?.extField;
    var shortBio = hubspot.mapping.find(
      (field: any) => field.lgmField === "shortBio"
    )?.extField;
    var hubspot_lifecycle = hubspot.lifecycle.map((_lifecycle: any) => ({
      actions: _lifecycle.triggers,
      lifecycle: _lifecycle.value,
      id: _lifecycle.id,
    }));
  }

  if (pipedrive) {
    var gender = pipedrive.mapping.find(
      (field: any) => field.lgmField === "gender"
    )?.extField;
    /*
    var pdr_birth = pipedrive.mapping.find(
      (field: any) => field.lgmField === "birth"
    )?.extField;*/
    var jobTitle = pipedrive.mapping.find(
      (field: any) => field.lgmField === "jobTitle"
    )?.extField;
    var location = pipedrive.mapping.find(
      (field: any) => field.lgmField === "location"
    )?.extField;
    var twitter = pipedrive.mapping.find(
      (field: any) => field.lgmField === "twitter"
    )?.extField;
    /*
    var linkedinUrl = pipedrive.mapping.find(
      (field: any) => field.lgmField === "linkedinUrl"
    )?.extField;*/
    /*
    var shortBio = pipedrive.mapping.find(
      (field: any) => field.lgmField === "shortBio"
    )?.extField;*/
    var pipedrive_lifecycle = pipedrive.lifecycle.map((_lifecycle: any) => ({
      actions: _lifecycle.triggers,
      lifecycle: _lifecycle.value,
      id: _lifecycle.id,
    }));
  }
  return (
    <div className="settings_crm_container">
      {[
        { modalName: "hubspotConfirmDisconnect", channel: "HUBSPOT" },
        { modalName: "pipedriveConfirmDisconnect", channel: "PIPEDRIVE" },
      ].map(
        (crm: any) =>
          modalsReducer.name === crm.modalName && (
            <Modal
              key={crm.modalName}
              name={modalsReducer.name}
              data={modalsReducer.data}
              onDelete={() => handleDisconnect(crm.channel)}
              onRequestClose={() => handleModal({ name: modalTypes.reset })}
            />
          )
      )}
      <SettingsTopBar onAction={handleActions} />
      <div className="content_wrapper">
        <SettingsActionsBar
          tabs={bar}
          page={"crm"}
          linkC={Link}
          direction="vertical"
        />
        <div className="in">
          {authReducer.user.campaignPermissions?.crm ? (
            <div
              style={
                channel === "" ? { height: "500px" } : { height: "4000px" }
              }
              className="crm_container"
            >
              <div
                className="crm-items-container"
                style={channel === "" ? { left: "0%" } : { left: "-100%" }}
              >
                <div className="choices">
                  <CrmConnect
                    permission={
                      authReducer.user.campaignPermissions?.crmPermissions
                        ? {
                            label:
                              authReducer.user.campaignPermissions
                                ?.crmPermissions[0],
                            available:
                              authReducer.user.campaignPermissions
                                ?.crmPermissions,
                          }
                        : undefined
                    }
                    channel={"HUBSPOT"}
                    onSelect={() => setChannel("HUBSPOT")}
                    disabled={
                      typeof pipedrive !== "undefined" &&
                      typeof hubspot === "undefined"
                    }
                    connected={typeof hubspot !== "undefined"}
                  />
                  <CrmConnect
                    permission={
                      authReducer.user.campaignPermissions?.crmPermissions
                        ? {
                            label:
                              authReducer.user.campaignPermissions
                                ?.crmPermissions[0],
                            available:
                              authReducer.user.campaignPermissions
                                ?.crmPermissions,
                          }
                        : undefined
                    }
                    channel={"PIPEDRIVE"}
                    onSelect={() => setChannel("PIPEDRIVE")}
                    disabled={
                      typeof hubspot !== "undefined" &&
                      typeof pipedrive === "undefined"
                    }
                    connected={typeof pipedrive !== "undefined"}
                  />
                </div>
              </div>
              <div
                style={
                  channel === "HUBSPOT" ? { left: "0%" } : { left: "100%" }
                }
                className="crm-items-container"
              >
                <div className="back" onClick={toBack}>
                  Back
                </div>
                {hubspot ? (
                  [
                    <ExtStart
                      key="ExtStart"
                      channel="HUBSPOT"
                      connected={true}
                      onDisconnect={(type) =>
                        handleModal({ name: "hubspotConfirmDisconnect" })
                      }
                    />,
                    settingsReducer.hubspot &&
                      settingsReducer.hubspot.mappings && (
                        <CrmMappings
                          key="CrmMappings"
                          type="HUBSPOT"
                          onSave={editHubspotMappings}
                          updateValues={loadHubspotMappings}
                          defaultOptions={settingsReducer.hubspot.mappings.map(
                            (obj: any) => ({ ...obj, value: obj.name })
                          )}
                          fields={{
                            linkedinUrl: linkedinUrl,
                            shortBio: shortBio,
                          }}
                          isLoading={
                            loadingReducer.btnTypesLoaders[
                              settingsTypes.REQUESTS_ACTIONS
                                .HUBSPOT_MAPPING_EDIT
                            ]
                          }
                        />
                      ),
                    hubspot_lifecycle && settingsReducer.hubspot?.lifecycle && (
                      <CrmTriggers
                        key="CrmTriggers"
                        crm="HUBSPOT"
                        onSave={updateHubspotLifecycle}
                        triggers={hubspot_lifecycle}
                        lifecycles={settingsReducer.hubspot.lifecycle}
                        updateValues={loadHubspotLifecycles}
                        isLoading={
                          loadingReducer.btnTypesLoaders[
                            settingsTypes.REQUESTS_ACTIONS
                              .HUBSPOT_LIFECYCLE_EDIT
                          ]
                        }
                      />
                    ),
                  ]
                ) : (
                  <ExtStart
                    key="ExtStart"
                    channel="HUBSPOT"
                    connected={false}
                    onConnect={handleConnect}
                  />
                )}
              </div>
              <div
                style={
                  channel === "PIPEDRIVE" ? { left: "0%" } : { left: "100%" }
                }
                className="crm-items-container"
              >
                <div onClick={toBack} className="back">
                  Back
                </div>
                {pipedrive ? (
                  [
                    <ExtStart
                      key="ExtStart"
                      channel="PIPEDRIVE"
                      connected={true}
                      onDisconnect={(type) =>
                        handleModal({ name: "pipedriveConfirmDisconnect" })
                      }
                    />,
                    settingsReducer.pipedrive &&
                      settingsReducer.pipedrive.mappings && (
                        <CrmMappings
                          key="CrmMappings"
                          type="PIPEDRIVE"
                          onSave={editPipedriveMappings}
                          updateValues={loadPipedriveMappings}
                          defaultOptions={settingsReducer.pipedrive.mappings.map(
                            (obj: any) => ({ ...obj, value: obj.name })
                          )}
                          fields={{
                            gender: gender,
                            jobTitle: jobTitle,
                            location: location,
                            twitter: twitter,
                            linkedinUrl: linkedinUrl,
                            shortBio: shortBio,
                          }}
                          isLoading={
                            loadingReducer.btnTypesLoaders[
                              settingsTypes.REQUESTS_ACTIONS
                                .PIPEDRIVE_MAPPING_EDIT
                            ]
                          }
                        />
                      ),
                    pipedrive_lifecycle &&
                      settingsReducer.pipedrive?.lifecycle && (
                        <CrmTriggers
                          key="CrmTriggers"
                          crm="PIPEDRIVE"
                          onSave={updatePipedriveLifecycle}
                          triggers={pipedrive_lifecycle}
                          lifecycles={settingsReducer.pipedrive.lifecycle}
                          updateValues={loadPipedriveLifecycles}
                          isLoading={
                            loadingReducer.btnTypesLoaders[
                              settingsTypes.REQUESTS_ACTIONS
                                .PIPEDRIVE_LIFECYCLE_EDIT
                            ]
                          }
                        />
                      ),
                  ]
                ) : (
                  <ExtStart
                    key="ExtStart"
                    channel="PIPEDRIVE"
                    connected={false}
                    onConnect={handleConnect}
                  />
                )}
              </div>
            </div>
          ) : (
            <CrmUpgrade
              onUpgrade={() => history.push("/settings/pricing")}
              permission={{
                label: authReducer.user.campaignPermissions?.crmPermissions[0],
                available: authReducer.user.campaignPermissions?.crmPermissions,
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SettingsCrmComponent;

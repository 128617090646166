import React, { useEffect, useState, useCallback } from "react";
import asyncRequest from "../../../../helpers/asyncRequest";
import useSelector from "../../../../redux/useSelector";
import { ConfirmEmail } from "@lagrowthmachine/lgmwebapp";
import { useDispatch } from "react-redux";
import analyticsCall from "../../../../helpers/analyticsCall";
import authTypes from "../../../Auth/types";
import * as globalActions from "../../../../redux/actions";
import authEndpoints from "../../../Auth/endpoints";

const ValidationAccComponent = (props: any) => {
  const dispatch = useDispatch();
  const loadingReducer = useSelector((state) => state.loadingReducer);
  const { token, email } = props.match?.params;
  const [fulfilled, setFulfilled] = useState(false);

  useEffect(() => {
    analyticsCall("authentication", "authentication: email validation");
  }, []);

  const onAccountValidate = useCallback(
    async ({
      email,
      verifyEmailToken,
    }: {
      email: string;
      verifyEmailToken: string;
    }) => {
      dispatch(
        globalActions.updateBtnLoaders({
          name: authTypes.REQUESTS_ACTIONS.VALIDATION_ACCOUNT,
          status: true,
        })
      );

      const responseValidation = await asyncRequest(authEndpoints, {
        name: "validationAccount",
        query: { email, verifyEmailToken },
      }).catch((err) => {
        dispatch(
          globalActions.updateBtnLoaders({
            name: authTypes.REQUESTS_ACTIONS.VALIDATION_ACCOUNT,
            status: false,
          })
        );
      });

      dispatch(
        globalActions.updateBtnLoaders({
          name: authTypes.REQUESTS_ACTIONS.VALIDATION_ACCOUNT,
          status: false,
        })
      );

      if (responseValidation) {
        setFulfilled(true);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    onAccountValidate({
      email: decodeURIComponent(email),
      verifyEmailToken: token,
    });
  }, [dispatch, token, email]);

  return (
    <div className="auth_validate_account_container">
      <ConfirmEmail
        loading={
          loadingReducer.btnTypesLoaders[
            authTypes.REQUESTS_ACTIONS.VALIDATION_ACCOUNT
          ]
        }
        fulfilled={fulfilled}
      />
    </div>
  );
};

export default ValidationAccComponent;

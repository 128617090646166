import { type } from "@lagrowthmachine/lgmwebapp";

interface ILastStatus {
  campaignId: string;
  status: string;
}
interface ILastAction {
  campaignId: string;
  channel: string;
  type: string;
}
export const getLastStatus = (
  lastStatus: Array<ILastStatus>,
  campaignId: string,
  pausedCampaigns?: string[]
) => {
  let paused = false;

  if (pausedCampaigns && pausedCampaigns.indexOf(campaignId) !== -1) {
    paused = true;
  }

  if ((!lastStatus || lastStatus.length === 0 || !campaignId) && paused) {
    return "PAUSED" as type.LeadStatusType;
  }
  if (!lastStatus || lastStatus.length === 0 || !campaignId)
    return "READY" as type.LeadStatusType;

  const statusFound = lastStatus.find(
    (lStatus: ILastStatus) => lStatus.campaignId === campaignId
  );

  if (statusFound?.status !== "STOPPED" && paused) {
    return "PAUSED" as type.LeadStatusType;
  }

  if (statusFound) {
    return statusFound.status as type.LeadStatusType;
  }
  return "READY" as type.LeadStatusType;
};

export const getLastAction = (
  actions: ILastAction[],
  campaignId: string,
  returnType?: boolean
) => {
  if (!actions) return {};
  const found = actions.find(
    (action: ILastAction) => action.campaignId === campaignId
  );

  if (found && returnType) {
    return {
      channel: found.channel,
      type: found.type,
    };
  }
  if (found) {
    return {
      channel: found.channel,
      name: found.type,
    };
  }
  return {};
};

export const getLastFromActions = (
  actions: ILastAction[],
  campaignId: string
) => {
  const found = actions.find(
    (action: ILastAction) => action.campaignId === campaignId
  );

  if (found) {
    return {
      channel: found.channel as type.ChannelType,
      name: found.type,
    };
  }
  return { channel: "LGM" as type.ChannelType, name: "EMPTY" };
};


export const generateCsv = (columnsTitle: string[], stats: any,title: string) => {
  const rows = [columnsTitle].concat(stats).map((row: string[]) => row.join(",")+"\r\n").join("");
  var blob = new Blob([rows], {type: "text/csv;charset=UTF-8"});
  var blobUrl = window.URL.createObjectURL(blob);

  var a = window.document.createElement("a");
  a.href = blobUrl;
  a.download = title+".csv";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  return true;
  };

import React, { useEffect } from "react";
import { ConnectingCrm, CrmConnected } from "@lagrowthmachine/lgmwebapp";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import useSelector from "../../../../redux/useSelector";
import * as globalActions from "../../../../redux/actions";
import * as settingsActions from "../../../Settings/actions";
import * as authActions from "../../../Auth/actions";
import analyticsCall from "../../../../helpers/analyticsCall";

const CrmConnectComponent = (props: any) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useLocation();
  const authReducer = useSelector((state) => state.authReducer);

  useEffect(() => {
    analyticsCall("signup flow", "signup flow crm");
  }, []);

  if (authReducer?.user?.onboardingDone) {
    history.push("/campaigns");
  }

  useEffect(() => {
    dispatch(authActions.loadUser({}));
  }, [dispatch]);

  const crmConnected = authReducer?.user?.crm?.find(
    (crm: any) => crm.type === "HUBSPOT"
  )
    ? "HUBSPOT"
    : authReducer?.user?.crm?.find((crm: any) => crm.type === "PIPEDRIVE")
    ? "PIPEDRIVE"
    : null;

  const onConnect = (_crm: any) => {
    sessionStorage.setItem("crmConnectFrom", "onboarding");
    switch (_crm) {
      case "HUBSPOT":
        dispatch(settingsActions.loadHubspotConnectUri({}));
        break;
      case "PIPEDRIVE":
        dispatch(settingsActions.loadPipedriveConnectUri({}));
        break;
      default:
        break;
    }
  };

  const crm = new URLSearchParams(params.search).get("crm");
  const code = new URLSearchParams(params.search).get("code");

  const onOnboardingFinished = () => {
    dispatch(globalActions.socketOnboardingFinished());
    history.push("/campaigns");
  };

  useEffect(() => {
    if (code && crm) {
      if (crm === "HUBSPOT") {
        dispatch(settingsActions.hubspotConnect({ code }));
      }
      if (crm === "PIPEDRIVE") {
        dispatch(settingsActions.pipedriveConnect({ code }));
      }
    }
  }, [dispatch, params]);

  return (
    <div className="auth_onboarding_container">
      {crmConnected ? (
        <CrmConnected
          crm={crmConnected}
          onSkip={onOnboardingFinished}
          onStart={onOnboardingFinished}
        />
      ) : (
        <ConnectingCrm
          loading={crm as any}
          crm={["HUBSPOT", "PIPEDRIVE"]}
          onCrmConnect={onConnect}
          onSkip={onOnboardingFinished}
        />
      )}
    </div>
  );
};

export default CrmConnectComponent;

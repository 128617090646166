import { ActionsObservable, ofType, Epic } from "redux-observable";

import { map, filter, takeUntil, mergeMap, catchError } from "rxjs/operators";

import types from "../types";

// import fetch from "rxjs/fetch";
import { tag } from "rxjs-spy/operators/tag";
import * as actions from "../actions";
import * as authActions from "../../Auth/actions";
import * as identitiesActions from "../../Identities/actions";
import * as globalActions from "../../../redux/actions";
import apiService from "../../../services/apis";
import getEndpoint from "../endpoints";
import IAction from "../../../interfaces/IAction";
import { push } from "connected-react-router";
import { of } from "rxjs/internal/observable/of";
import { adapterPlans, adapterPrices } from "../adapters";

// // const spy = create();
export const modalConfirmationDeleteWebhookEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  // spy.log("deleteSequence");
  return action$.pipe(
    ofType(types.OPEN_CONFIRMATION_DELETE_WEBHOOK),
    mergeMap((data) =>
      of(
        globalActions.modalUpdate({
          name: "confirmationDelete",
          data: {
            webhook: data?.payload?.webhook,
            name: "webhookDelete",
            subtitle: `This action cannot be undone. Are you sure you want to delete this webhook ?`,
            deleteText: "Delete webhook",
            title: `Delete webhook`,
          },
        })
      )
    )
  );
};

export const logsUpdateEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(
      types.LOGS_EXTERNAL_UPDATE_KEYWORDS,
      types.LOGS_EXTERNAL_PAGINATE,
      types.LOGS_EXTERNAL_SHOW_ERRORS
    ),
    map((action) => {
      return actions.loadLogs({ query: {} });
    })
  );
};

export const loadLogsExternalEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_DATA.LOAD_LOGS_EXTERNAL),

    mergeMap((action) => {
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "loadLogs",
          params: action.payload?.params,
          query: {
            ...action.payload?.query,
            errorOnly: store.value.settingsReducer.logsShowErrors || undefined,
            q: store.value.settingsReducer.logsKeywords || undefined,
            limit: 25,
            skip: store.value.settingsReducer?.logsPaginate
              ? store.value.settingsReducer?.logsPaginate.current * 25 || 0
              : 0,
            apikey: store.value.authReducer?.user?.apikey,
          },
        })
      )
        .pipe(
          tag("loadLogs"),
          map((xhr) => actions.loadLogsFulfilled(xhr.response)),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_DATA.LOAD_LOGS_EXTERNAL_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedLoadLogs(e?.response));
          })
        );
    })
  );
};

export const loadLogDetailEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_DATA.LOAD_LOG_EXTERNAL_DETAIL),

    mergeMap((action) => {
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "loadLogDetail",
          params: {
            ...action.payload?.params,
            id: action.payload.logId,
          },
          query: {
            ...action.payload?.query,
            apikey: store.value.authReducer?.user?.apikey,
          },
        })
      )
        .pipe(
          tag("loadLogDetail"),
          map((xhr) => actions.loadLogDetailFulfilled(xhr.response)),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_DATA.LOAD_LOG_EXTERNAL_DETAIL_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedloadLogDetail(e?.response));
          })
        );
    })
  );
};

export const loadInvoicesEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_DATA.LOAD_INVOICES),

    mergeMap((action) => {
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "loadInvoices",
          params: {
            ...action.payload?.params,
          },
          query: {
            ...action.payload?.query,
            limit: 10,
            apikey: store.value.authReducer?.user?.apikey,
          },
        })
      )
        .pipe(
          tag("loadInvoices"),
          map((xhr) => actions.loadInvoicesFulfilled(xhr.response)),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type === types.RESPONSES_DATA.LOAD_INVOICES_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedLoadInvoices(e?.response));
          })
        );
    })
  );
};

export const loadLogDetailFulfilledEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  // spy.log("deleteSequence");
  return action$.pipe(
    ofType(types.RESPONSES_DATA.LOAD_LOG_EXTERNAL_DETAIL_FULFILLED),
    mergeMap((data) =>
      of(
        globalActions.modalUpdate({
          name: "logShowDetails",
          data: data?.payload,
        })
      )
    )
  );
};

export const loadHubspotConnectUriEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_DATA.LOAD_HUBSPOT_CONNECT_URI),

    mergeMap((action) => {
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "loadHubspotConnectUri",
          query: {
            apikey: store.value.authReducer?.user?.apikey,
          },
        })
      )
        .pipe(
          tag("loadHubspotConnectUri"),
          map((xhr) => actions.loadHubspotConnectUriFulfilled(xhr.response)),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_DATA.LOAD_HUBSPOT_CONNECT_URI_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedLoadHubspotConnectUri(e?.response));
          })
        );
    })
  );
};

export const loadPlansEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_DATA.LOAD_PLANS),

    mergeMap((action) => {
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "loadPlans",
          query: {
            apikey: store.value.authReducer?.user?.apikey,
          },
        })
      )
        .pipe(
          tag("LOAD_PLANS"),
          map((xhr) => actions.loadPlansFulfilled(adapterPlans(xhr.response))),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type === types.RESPONSES_DATA.LOAD_PLANS_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedLoadPlans(e?.response));
          })
        );
    })
  );
};

export const retryLastPaymentEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_ACTIONS.RETRY_PAYMENT),
    mergeMap((action) => {
      const afterPayment = action.payload?.afterPayment;
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "retryLastPayment",
          query: {
            ...action.payload?.query,
            apikey: store.value.authReducer?.user?.apikey,
          },
        })
      )
        .pipe(
          tag("retryLastPayment"),
          map((xhr) =>
            actions.retryLastPaymentFulfilled({
              response: xhr.response,
              afterPayment: afterPayment,
            })
          ),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_ACTIONS.RETRY_PAYMENT_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedRetryLastPayment(e?.response));
          })
        );
    })
  );
};

export const retryLastPaymentFulfilledEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.RESPONSES_ACTIONS.RETRY_PAYMENT_FULFILLED),
    mergeMap((data) => {
      const afterPayment = data.payload.afterPayment;
      afterPayment(data.payload.response);
      return of({ type: "" });
    })
  );
};

export const loadCardsEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_DATA.LOAD_CARDS),

    mergeMap((action) => {
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "getPaymentMethods",
          query: {
            apikey: store.value.authReducer?.user?.apikey,
          },
        })
      )
        .pipe(
          tag("LOAD_CARDS"),
          map((xhr) => actions.loadCardsFulfilled(xhr.response)),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type === types.RESPONSES_DATA.LOAD_CARDS_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedLoadCards(e?.response));
          })
        );
    })
  );
};

export const attachCardToUserEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_ACTIONS.ATTACH_CARD_TO_USER),

    mergeMap((action) => {
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "attachPayment",
          query: {
            apikey: store.value.authReducer?.user?.apikey,
          },
          body: {
            paymentMethodId: action.payload.paymentMethodId,
          },
        })
      )
        .pipe(
          tag("ATTACH_CARD_TO_USER"),
          map((xhr) => actions.attachCardToUserFulfilled(xhr.response)),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_ACTIONS.ATTACH_CARD_TO_USER_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedAttachCardToUser(e?.response));
          })
        );
    })
  );
};

export const loadSummaryEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_DATA.LOAD_SUMMARY),

    mergeMap((action) => {
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "loadSummary",
          query: {
            apikey: store.value.authReducer?.user?.apikey,
          },
        })
      )
        .pipe(
          tag("LOAD_SUMMARY"),
          map((xhr) => actions.loadSummaryFulfilled(xhr.response)),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type === types.RESPONSES_DATA.LOAD_SUMMARY_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedLoadSummary(e?.response));
          })
        );
    })
  );
};

export const loadPriceEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_DATA.LOAD_PRICE),

    mergeMap((action) => {
      const prices = action.payload?.prices;
      const setPrices = action.payload?.setPrices;
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "loadPrice",
          query: {
            apikey: store.value.authReducer?.user?.apikey,
          },
          body: { ...action.payload?.body, pay: false },
        })
      )
        .pipe(
          tag("LOAD_PRICE"),
          map((xhr) =>
            actions.loadPriceFulfilled({
              response: xhr.response,
              setPrices: setPrices,
              prices: prices,
            })
          ),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type === types.RESPONSES_DATA.LOAD_PRICE_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            setPrices({
              ...prices,
              coupon: undefined,
              couponResult: undefined,
            });
            return of(actions.rejectedLoadPrice(e?.response));
          })
        );
    })
  );
};

export const unCancelIdEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_ACTIONS.UN_CANCEL_ID),
    mergeMap((action) => {
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "loadPrice",
          query: {
            apikey: store.value.authReducer?.user?.apikey,
          },
          body: {
            type: "cancelDeleteId",
            identities: [action.payload?.params.identityId],
          },
        })
      )
        .pipe(
          tag("UN_CANCEL_ID"),
          map((xhr) => actions.unCancelIdFulfilled(xhr.response)),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type === types.RESPONSES_ACTIONS.UN_CANCEL_ID_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            return of(actions.rejectedUnCancelId(e?.response));
          })
        );
    })
  );
};

export const loadPriceFulfilledEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.RESPONSES_DATA.LOAD_PRICE_FULFILLED),
    mergeMap((data) => {
      const setPrices = data.payload.setPrices;
      const formerPrices = data.payload.prices;
      const prices = adapterPrices(data.payload.response.summary, formerPrices);
      setPrices(prices);
      return of({ type: "" });
    })
  );
};

export const processPaymentEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_ACTIONS.PROCESS_PAYMENT),

    mergeMap((action) => {
      const afterPayment = action.payload?.afterPayment;
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "loadPrice",
          query: {
            apikey: store.value.authReducer?.user?.apikey,
          },
          body: { ...action.payload?.body, pay: true },
        })
      )
        .pipe(
          tag("PROCESS_PAYMENT"),
          map((xhr) =>
            actions.processPaymentFulfilled({
              response: xhr.response,
              afterPayment: afterPayment,
            })
          ),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_ACTIONS.PROCESS_PAYMENT_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            return of(actions.rejectedProcessPayment(e?.response));
          })
        );
    })
  );
};

export const processPaymentFulfilledEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.RESPONSES_ACTIONS.PROCESS_PAYMENT_FULFILLED),
    mergeMap((data) => {
      const afterPayment = data.payload.afterPayment;
      afterPayment(data.payload.response);
      return of({ type: "" });
    })
  );
};

export const loadDowngradeEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_DATA.LOAD_DOWNGRADE),

    mergeMap((action) => {
      const setDowngrade = action.payload?.setDowngrade;
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "loadDowngrade",
          query: {
            ...action.payload?.query,
            apikey: store.value.authReducer?.user?.apikey,
          },
        })
      )
        .pipe(
          tag("LOAD_DOWNGRADE"),
          map((xhr) =>
            actions.loadDowngradeFulfilled({
              response: xhr.response,
              setDowngrade: setDowngrade,
            })
          ),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type === types.RESPONSES_DATA.LOAD_DOWNGRADE_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            return of(actions.rejectedLoadDowngrade(e?.response));
          })
        );
    })
  );
};

export const loadDowngradeFulfilledEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.RESPONSES_DATA.LOAD_DOWNGRADE_FULFILLED),
    mergeMap((data) => {
      const setDowngrade = data.payload.setDowngrade;
      setDowngrade(data.payload.response);
      return of({ type: "" });
    })
  );
};

export const terminateUserEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_ACTIONS.TERMINATE),

    mergeMap((action) => {
      const email = action.payload?.email;
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "terminateUser1",
          query: {
            apikey: store.value.authReducer?.user?.apikey,
          },
        })
      )
        .pipe(
          tag("terminateUser"),
          map((xhr) => {
            alert(
              "Please Check your email " +
                email +
                " and click on the link to confirm your account deletion."
            );
            return actions.terminateUserFulfilled(xhr.response);
          }),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type === types.RESPONSES_ACTIONS.TERMINATE_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedTerminateUser(e?.response));
          })
        );
    })
  );
};

export const deleteIdentityEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_ACTIONS.DELETE_IDENTITY),

    mergeMap((action) => {
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "deleteId",
          query: {
            apikey: store.value.authReducer?.user?.apikey,
          },
          params: {
            ...action.payload?.params,
          },
        })
      )
        .pipe(
          tag("deleteId"),
          map((xhr) => actions.deleteIdentityFulfilled(xhr.response)),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_ACTIONS.DELETE_IDENTITY_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedDeleteIdentity(e?.response));
          })
        );
    })
  );
};

export const reloadIdentitiesAfterDeletionOrUnCancelEpic: Epic<
  IAction,
  IAction
> = (action$: ActionsObservable<IAction>, store: any) => {
  return action$.pipe(
    ofType(
      types.RESPONSES_ACTIONS.DELETE_IDENTITY_FULFILLED,
      types.RESPONSES_ACTIONS.DELETE_IDENTITY_REJECTED,
      types.RESPONSES_ACTIONS.UN_CANCEL_ID_FULFILLED,
      types.RESPONSES_ACTIONS.UN_CANCEL_ID_REJECTED
    ),
    mergeMap((data) => {
      return of(identitiesActions.loadIdentities({ query: {} }));
    })
  );
};

export const signoutAfterTerminateEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.RESPONSES_ACTIONS.TERMINATE_FULFILLED),
    mergeMap((data) => {
      return of(authActions.signOutUser());
    })
  );
};

export const loadPipedriveConnectUriEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_DATA.LOAD_PIPEDRIVE_CONNECT_URI),

    mergeMap((action) => {
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "loadPipedriveConnectUri",
          query: {
            apikey: store.value.authReducer?.user?.apikey,
          },
        })
      )
        .pipe(
          tag("loadPipedriveConnectUri"),
          map((xhr) => actions.loadPipedriveConnectUriFulfilled(xhr.response)),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_DATA.LOAD_PIPEDRIVE_CONNECT_URI_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedLoadPipedriveConnectUri(e?.response));
          })
        );
    })
  );
};

export const redirectToHubspotConnectUriEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.RESPONSES_DATA.LOAD_HUBSPOT_CONNECT_URI_FULFILLED),
    mergeMap((data) => {
      window.location.href = data.payload.oauthRedirect;
      return of({ type: "" });
    })
  );
};

export const redirectToPipedriveConnectUriEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.RESPONSES_DATA.LOAD_PIPEDRIVE_CONNECT_URI_FULFILLED),
    mergeMap((data) => {
      window.location.href = data.payload.oauthRedirect;
      return of({ type: "" });
    })
  );
};

export const hubspotConnectEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_ACTIONS.HUBSPOT_CONNECT),

    mergeMap((action) => {
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "hubspotConnect",
          query: {
            apikey: store.value.authReducer?.user?.apikey,
          },
          body: {
            code: action.payload.code,
          },
        })
      )
        .pipe(
          tag("hubspotConnect"),
          map((xhr) => actions.hubspotConnectFulfilled(xhr.response)),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_ACTIONS.HUBSPOT_CONNECT_FULFILLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedHubspotConnect(e?.response));
          })
        );
    })
  );
};

export const pipedriveConnectEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_ACTIONS.PIPEDRIVE_CONNECT),
    mergeMap((action) => {
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "pipedriveConnect",
          query: {
            apikey: store.value.authReducer?.user?.apikey,
          },
          body: {
            code: action.payload.code,
          },
        })
      )
        .pipe(
          tag("pipedriveConnect"),
          map((xhr) => actions.pipedriveConnectFulfilled(xhr.response)),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_ACTIONS.PIPEDRIVE_CONNECT_FULFILLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedPipedriveConnect(e?.response));
          })
        );
    })
  );
};

export const setDefaultCardEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_ACTIONS.SET_DEFAULT_CARD),
    mergeMap((action) => {
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "setDefaultCard",
          query: {
            apikey: store.value.authReducer?.user?.apikey,
          },
          body: {
            cardId: action.payload.cardId,
          },
        })
      )
        .pipe(
          tag("setDefaultCard"),
          map((xhr) => actions.setDefaultCardFulfilled(xhr.response)),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_ACTIONS.SET_DEFAULT_CARD_FULFILLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedSetDefaultCard(e?.response));
          })
        );
    })
  );
};

export const deleteCardEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_ACTIONS.DELETE_CARD),
    mergeMap((action) => {
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "deleteCard",
          query: {
            apikey: store.value.authReducer?.user?.apikey,
          },
          body: {
            cardId: action.payload.cardId,
          },
        })
      )
        .pipe(
          tag("deleteCard"),
          map((xhr) => actions.deleteCardFulfilled(xhr.response)),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type === types.RESPONSES_ACTIONS.DELETE_CARD_FULFILLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedDeleteCard(e?.response));
          })
        );
    })
  );
};

export const reloadCardsEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(
      types.RESPONSES_ACTIONS.SET_DEFAULT_CARD_FULFILLED,
      types.RESPONSES_ACTIONS.DELETE_CARD_FULFILLED,
      types.RESPONSES_ACTIONS.ATTACH_CARD_TO_USER_FULFILLED,
      types.RESPONSES_ACTIONS.RETRY_PAYMENT_FULFILLED
    ),
    map((action) => {
      return actions.loadCards({ query: {} });
    })
  );
};

export const reloadInvoicesEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.RESPONSES_ACTIONS.RETRY_PAYMENT_FULFILLED),
    map((action) => {
      return actions.loadInvoices({ query: {} });
    })
  );
};

export const crmConnectRedirectEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(
      types.RESPONSES_ACTIONS.HUBSPOT_CONNECT_FULFILLED,
      types.RESPONSES_ACTIONS.HUBSPOT_CONNECT_REJECTED,
      types.RESPONSES_ACTIONS.HUBSPOT_CONNECT_CANCELLED,
      types.RESPONSES_ACTIONS.PIPEDRIVE_CONNECT_FULFILLED,
      types.RESPONSES_ACTIONS.PIPEDRIVE_CONNECT_REJECTED,
      types.RESPONSES_ACTIONS.PIPEDRIVE_CONNECT_CANCELLED
    ),
    mergeMap((data) => {
      const redirectTo =
        sessionStorage.getItem("crmConnectFrom") === "onboarding"
          ? "/onboarding/crm_connect"
          : "/settings/crm";
      return of({ type: "" }, push(redirectTo));
    })
  );
};

export const hubspotDisconnectEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_ACTIONS.HUBSPOT_DISCONNECT),

    mergeMap((action) => {
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "hubspotDisconnect",
          query: {
            apikey: store.value.authReducer?.user?.apikey,
          },
        })
      )
        .pipe(
          tag("hubspotDisconnect"),
          map((xhr) => actions.hubspotDisconnectFulfilled(xhr.response)),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_ACTIONS.HUBSPOT_DISCONNECT_FULFILLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedHubspotDisconnect(e?.response));
          })
        );
    })
  );
};

export const pipedriveDisconnectEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_ACTIONS.PIPEDRIVE_DISCONNECT),

    mergeMap((action) => {
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "pipedriveDisconnect",
          query: {
            apikey: store.value.authReducer?.user?.apikey,
          },
        })
      )
        .pipe(
          tag("pipedriveDisconnect"),
          map((xhr) => actions.pipedriveDisconnectFulfilled(xhr.response)),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_ACTIONS.PIPEDRIVE_DISCONNECT_FULFILLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedPipedriveDisconnect(e?.response));
          })
        );
    })
  );
};

export const loadHubspotMappingsEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_DATA.LOAD_HUBSPOT_MAPPINGS),

    mergeMap((action) => {
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "loadHubspotMappings",
          query: {
            apikey: store.value.authReducer?.user?.apikey,
          },
        })
      )
        .pipe(
          tag("loadHubspotMappings"),
          map((xhr) => actions.loadHubspotMappingsFulfilled(xhr.response)),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_DATA.LOAD_HUBSPOT_MAPPINGS_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedLoadHubspotMappings(e?.response));
          })
        );
    })
  );
};

export const loadPipedriveMappingsEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_DATA.LOAD_PIPEDRIVE_MAPPINGS),

    mergeMap((action) => {
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "loadPipedriveMappings",
          query: {
            apikey: store.value.authReducer?.user?.apikey,
          },
        })
      )
        .pipe(
          tag("loadPipedriveMappings"),
          map((xhr) => actions.loadPipedriveMappingsFulfilled(xhr.response)),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_DATA.LOAD_PIPEDRIVE_MAPPINGS_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedLoadPipedriveMappings(e?.response));
          })
        );
    })
  );
};

export const loadHubspotLifecyclesEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_DATA.LOAD_HUBSPOT_LIFECYCLES),

    mergeMap((action) => {
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "loadHubspotLifecycles",
          query: {
            apikey: store.value.authReducer?.user?.apikey,
          },
        })
      )
        .pipe(
          tag("loadHubspotLifecycles"),
          map((xhr) => actions.loadHubspotLifecyclesFulfilled(xhr.response)),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_DATA.LOAD_HUBSPOT_LIFECYCLES_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedLoadHubspotLifecycles(e?.response));
          })
        );
    })
  );
};

export const loadPipedriveLifecyclesEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_DATA.LOAD_PIPEDRIVE_LIFECYCLES),

    mergeMap((action) => {
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "loadPipedriveLifecycles",
          query: {
            apikey: store.value.authReducer?.user?.apikey,
          },
        })
      )
        .pipe(
          tag("loadPipedriveLifecycles"),
          map((xhr) => actions.loadPipedriveLifecyclesFulfilled(xhr.response)),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_DATA.LOAD_PIPEDRIVE_LIFECYCLES_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedLoadPipedriveLifecycles(e?.response));
          })
        );
    })
  );
};

export const hubspotMappingEditEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_ACTIONS.HUBSPOT_MAPPING_EDIT),

    mergeMap((action) => {
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "settingsEdit",
          query: {
            apikey: store.value.authReducer?.user?.apikey,
          },
          body: action.payload.data,
        })
      )
        .pipe(
          tag("settingsEdit"),
          map((xhr) => actions.hubspotMappingEditFulfilled(xhr.response)),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_ACTIONS.HUBSPOT_MAPPING_EDIT_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedHubspotMappingEdit(e?.response));
          })
        );
    })
  );
};

export const pipedriveMappingEditEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_ACTIONS.PIPEDRIVE_MAPPING_EDIT),

    mergeMap((action) => {
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "settingsEdit",
          query: {
            apikey: store.value.authReducer?.user?.apikey,
          },
          body: action.payload.data,
        })
      )
        .pipe(
          tag("settingsEdit"),
          map((xhr) => actions.pipedriveMappingEditFulfilled(xhr.response)),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_ACTIONS.PIPEDRIVE_MAPPING_EDIT_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedPipedriveMappingEdit(e?.response));
          })
        );
    })
  );
};

export const hubspotLifecycleEditEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_ACTIONS.HUBSPOT_LIFECYCLE_EDIT),

    mergeMap((action) => {
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "settingsEdit",
          query: {
            apikey: store.value.authReducer?.user?.apikey,
          },
          body: action.payload.data,
        })
      )
        .pipe(
          tag("settingsEdit"),
          map((xhr) => actions.hubspotLifecycleEditFulfilled(xhr.response)),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_ACTIONS.HUBSPOT_LIFECYCLE_EDIT_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedHubspotLifecycleEdit(e?.response));
          })
        );
    })
  );
};

export const pipedriveLifecycleEditEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_ACTIONS.PIPEDRIVE_LIFECYCLE_EDIT),

    mergeMap((action) => {
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "settingsEdit",
          query: {
            apikey: store.value.authReducer?.user?.apikey,
          },
          body: action.payload.data,
        })
      )
        .pipe(
          tag("settingsEdit"),
          map((xhr) => actions.pipedriveLifecycleEditFulfilled(xhr.response)),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_ACTIONS.PIPEDRIVE_LIFECYCLE_EDIT_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedPipedriveLifecycleEdit(e?.response));
          })
        );
    })
  );
};

import { createAction } from "redux-actions";
import types from "../types";

export const signOutUser = () => ({
  type: types.SIGNOUT_USER,
  payload: {},
});
export const clear = () => ({
  type: types.AUTH_CLEAR,
  payload: {},
});
export const authAdmin = () => ({
  type: types.AUTH_ADMIN,
  payload: {},
});
export const registerUser = (values: any) => ({
  type: types.REQUESTS_ACTIONS.REGISTER_USER,
  payload: values,
});
export const registerUserFulfilled = (payload: any) => ({
  type: types.RESPONSES_ACTIONS.REGISTER_USER_FULFILLED,
  payload,
});

export const setApiKey = (payload: any) => ({
  type: types.REQUESTS_ACTIONS.SET_APIKEY,
  payload,
});

export const rejectedRegisterUser = (payload?: any) => ({
  type: types.RESPONSES_ACTIONS.REGISTER_USER_REJECTED,
  payload,
});

export const loginUser = (payload?: any) => ({
  type: types.REQUESTS_ACTIONS.LOGIN_USER,
  payload: payload,
});
export const loginUserFulfilled = (payload: any) => ({
  type: types.RESPONSES_ACTIONS.LOGIN_USER_FULFILLED,
  payload,
});
export const cancelLoginUser = () => ({
  type: types.RESPONSES_ACTIONS.LOGIN_USER_CANCELLED,
});
export const rejectedLoginUser = (payload?: any) => ({
  type: types.RESPONSES_ACTIONS.LOGIN_USER_REJECTED,
  payload,
});

export const resetPwd = (values: any) => ({
  type: types.REQUESTS_ACTIONS.RESET_PWD,
  payload: values,
});
export const resetPwdFulfilled = (payload: any) => ({
  type: types.RESPONSES_ACTIONS.RESET_PWD_FULFILLED,
  payload,
});
export const resetPwdRequest = (values: any) => ({
  type: types.REQUESTS_ACTIONS.RESET_PWD_REQ,
  payload: values,
});
export const resetPwdReqFulfilled = (payload: any) => ({
  type: types.RESPONSES_ACTIONS.RESET_PWD_REQ_FULFILLED,
  payload,
});
export const rejectedResetPwdReq = (payload?: any) => ({
  type: types.RESPONSES_ACTIONS.RESET_PWD_REQ_REJECTED,
  payload,
});
export const rejectedResetPwd = (payload?: any) => ({
  type: types.RESPONSES_ACTIONS.RESET_PWD_REJECTED,
  payload,
});
// export const cancelResetPwd = () => ({ type: types.LOGIN_USER_CANCELLED });

export const validationAccount = (values: any) => ({
  type: types.REQUESTS_ACTIONS.VALIDATION_ACCOUNT,
  payload: values,
});
export const validationAccountFulfilled = (payload: any) => ({
  type: types.RESPONSES_ACTIONS.VALIDATION_ACCOUNT_FULFILLED,
  payload,
});
export const rejectedValidationAccount = (payload?: any) => ({
  type: types.RESPONSES_ACTIONS.VALIDATION_ACCOUNT_REJECTED,
  payload,
});

export const loadUser = createAction(
  types.REQUESTS_DATA.LOAD_USER,
  (payload: any) => payload
);
export const loadUserFulfilled = createAction(
  types.RESPONSES_DATA.LOAD_USER_FULFILLED,
  (payload: any) => payload
);
export const rejectedLoadUser = createAction(
  types.RESPONSES_DATA.LOAD_USER_REJECTED,
  (payload: any) => payload
);
export const cancelledLoadUser = createAction(
  types.RESPONSES_DATA.LOAD_USER_CANCELLED,
  (payload: any) => payload
);

export const editUser = createAction(
  types.REQUESTS_ACTIONS.EDIT_USER,
  (payload: any) => payload
);
export const editUserFulfilled = createAction(
  types.RESPONSES_ACTIONS.EDIT_USER_FULFILLED,
  (payload: any) => payload
);
export const rejectedEditUser = createAction(
  types.RESPONSES_ACTIONS.EDIT_USER_REJECTED,
  (payload: any) => payload
);
export const cancelledEditUser = createAction(
  types.RESPONSES_ACTIONS.EDIT_USER_CANCELLED,
  (payload: any) => payload
);

export const editMember = createAction(
  types.REQUESTS_ACTIONS.EDIT_MEMBER,
  (payload: any) => payload
);
export const editMemberFulfilled = createAction(
  types.RESPONSES_ACTIONS.EDIT_MEMBER_FULFILLED,
  (payload: any) => payload
);
export const rejectedEditMember = createAction(
  types.RESPONSES_ACTIONS.EDIT_MEMBER_REJECTED,
  (payload: any) => payload
);
export const cancelledEditMember = createAction(
  types.RESPONSES_ACTIONS.EDIT_MEMBER_CANCELLED,
  (payload: any) => payload
);

export const getUploadUrl = createAction(
  types.REQUESTS_ACTIONS.UPLOAD_URL_USER,
  (payload: any) => payload
);
export const getUploadUrlFulfilled = createAction(
  types.RESPONSES_ACTIONS.UPLOAD_URL_USER_FULFILLED,
  (payload: any) => payload
);
export const rejectedGetUploadUrl = createAction(
  types.RESPONSES_ACTIONS.UPLOAD_URL_USER_REJECTED,
  (payload: any) => payload
);
export const cancelledGetUploadUrl = createAction(
  types.RESPONSES_ACTIONS.UPLOAD_URL_USER_CANCELLED,
  (payload: any) => payload
);

export const uploadFile = createAction(
  types.REQUESTS_ACTIONS.UPLOAD_FILE,
  (payload: any) => payload
);
export const uploadFileFulfilled = createAction(
  types.RESPONSES_ACTIONS.UPLOAD_FILE_FULFILLED,
  (payload: any) => payload
);
export const rejectedUploadFile = createAction(
  types.RESPONSES_ACTIONS.UPLOAD_FILE_REJECTED,
  (payload: any) => payload
);
export const cancelledUploadFile = createAction(
  types.RESPONSES_ACTIONS.UPLOAD_FILE_CANCELLED,
  (payload: any) => payload
);

export const clearCoupon = () => ({
  type: types.CLEAR_GET_COUPON,
});
export const getCoupon = (values: any) => ({
  type: types.REQUESTS_ACTIONS.GET_COUPON,
  payload: values,
});
export const getCouponFulfilled = (payload: any) => ({
  type: types.RESPONSES_ACTIONS.GET_COUPON_FULFILLED,
  payload,
});
export const cancelGetCoupon = () => ({
  type: types.RESPONSES_ACTIONS.GET_COUPON_CANCELLED,
});
export const rejectedGetCoupon = (payload?: any) => ({
  type: types.RESPONSES_ACTIONS.GET_COUPON_REJECTED,
  payload,
});

/*
Extern API Key
*/
export const refreshApiKey = createAction(
  types.REQUESTS_ACTIONS.REFRESH_EXTERN_APIKEY,
  (payload: any) => payload
);

export const refreshApiKeyFulfilled = createAction(
  types.RESPONSES_ACTIONS.REFRESH_EXTERN_APIKEY_FULFILLED,
  (payload: any) => payload
);
export const rejectedRefreshApiKey = createAction(
  types.RESPONSES_ACTIONS.REFRESH_EXTERN_APIKEY_REJECTED,
  (payload: any) => payload
);
export const cancelledRefreshApiKey = createAction(
  types.RESPONSES_ACTIONS.REFRESH_EXTERN_APIKEY_CANCELLED,
  (payload: any) => payload
);

/*
Extern API Key
*/
export const deleteAccount = createAction(
  types.REQUESTS_ACTIONS.DELETE_ACCOUNT,
  (payload: any) => payload
);

export const deleteAccountFulfilled = createAction(
  types.RESPONSES_ACTIONS.DELETE_ACCOUNT_FULFILLED,
  (payload: any) => payload
);
export const rejectedDeleteAccount = createAction(
  types.RESPONSES_ACTIONS.DELETE_ACCOUNT_REJECTED,
  (payload: any) => payload
);
export const cancelledDeleteAccount = createAction(
  types.RESPONSES_ACTIONS.DELETE_ACCOUNT_CANCELLED,
  (payload: any) => payload
);

/*Notify TC not checked */
export const notifyTc = createAction(types.NOTIFY_TC);

export const clearAllReducers = () => ({ type: types.CLEAR_ALL_REDUCERS });

import React, { MouseEvent } from "react";
import ReactModal from "react-modal";
import * as globalActions from "../../redux/actions";
import { useDispatch } from "react-redux";

ReactModal.setAppElement("#root");

function Modal(props: any) {
  let mouseDownOut = false;

  const dispatch = useDispatch();

  const onMouseUpOut = () => {
    if (mouseDownOut) {
      dispatch(globalActions.modalUpdate({ name: "", data: {} }));
      mouseDownOut = false;
    }
  };

  const clickIn = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  const stopEvent = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  return (
    <ReactModal
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.75)",
          //overflow: "scroll",
          inset: 0,
        },
        content: {
          //overflow: "hidden",
          inset: 0,
        },
      }}
      isOpen={true}
      contentLabel="Modal"
    >
      <div
        onMouseDown={() => {
          mouseDownOut = true;
        }}
        onMouseMove={() => {
          mouseDownOut = false;
        }}
        onMouseUp={onMouseUpOut}
        className="modal_content"
      >
        <div onMouseDown={stopEvent} onMouseUp={stopEvent} className="in">
          {props.children}
        </div>
      </div>
    </ReactModal>
  );
}

export default Modal;

import React from "react";
import { Link } from "react-router-dom";
import { SettingsOrganisation } from "@lagrowthmachine/lgmwebapp";
import { SettingsTopBar, SettingsActionsBar } from "@lagrowthmachine/lgmwebapp";
import analyticsCall from "../../../../helpers/analyticsCall";
import barNavItems from "../../helpers/barNavItems";
import useSelector from "../../../../redux/useSelector";
import { useDispatch } from "react-redux";
import * as authActions from "../../../Auth/actions";

const SettingsUserComponent = (props: any) => {
  const authReducer = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();
  const bar = barNavItems(authReducer);
  /*
  useEffect(() => {
    analyticsCall("settings", "settings: api settings");
  }, []);
*/
  /*test*/
  const handleActions = (action: string, data?: any) => {
    switch (action) {
      case "input":
        dispatch(authActions.signOutUser());
        break;
      default:
        break;
    }
  };

  const onSubmit = (data: any) => {
    const _data = {
      company: data.companyName,
      website: data.companyWebsite,
      billingInfos: {
        city: data.city,
        state: data.state,
        complement: data.complement,
        country: data.country,
        address: data.address,
        postalCode: data.postalCode,
        vatNumber: data.vatNumber,
      },
    };

    dispatch(
      authActions.editUser({
        query: {},
        body: _data,
      })
    );
  };

  return (
    <div className="settings_company_container">
      <SettingsTopBar onAction={handleActions} />
      <div className="content_wrapper">
        <SettingsActionsBar
          tabs={bar}
          page="user"
          linkC={Link}
          direction="vertical"
        />
        <div className="in">
          <SettingsOrganisation
            companyName={authReducer.user?.company}
            companyWebsite={authReducer.user?.website}
            city={authReducer.user?.billingInfos?.city}
            address={authReducer.user?.billingInfos?.address}
            country={authReducer.user?.billingInfos?.country}
            state={authReducer.user?.billingInfos?.state}
            postalCode={authReducer.user?.billingInfos?.postalCode}
            vatNumber={authReducer.user?.billingInfos?.vatNumber}
            complement={authReducer.user?.billingInfos?.complement}
            onSubmit={onSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default SettingsUserComponent;

export const adapterSignupOUT = (payload: any) => {
  return {
    firstname: payload.firstName,
    lastname: payload.lastName,
    company: payload.companyName,
    email: payload.email,
    password: payload.password,
    timezone: payload.timezone,
    captcha: payload.captcha,
    lang: payload.lang,
  };
};

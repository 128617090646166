import { combineEpics, Epic } from "redux-observable";
import { combineReducers } from "redux";
import { createBrowserHistory } from "history";
import { Dispatch } from "redux";

import { createStore, applyMiddleware, compose } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { createEpicMiddleware } from "redux-observable";
import { connectRouter, routerMiddleware } from "connected-react-router";

import createMySocketMiddleware from "../middlewares/sockets";
import epics from "./epics/";
import reducers from "./reducers/";
import createBugsnagMiddleware from "../middlewares/bugsnag";

/**
 * combineEpics do not support object. The trick is to use Object Values
 */

export const history = createBrowserHistory();
export const rootEpic: Epic<any> = combineEpics(...Object.values(epics));
export const combinedReducersWithRouter = combineReducers({
  ...reducers,
  router: connectRouter(history),
});

/**
 * Configuration for redux persist
 */

const persistConfig = {
  key: "root",
  storage,
  blacklist: [
    "modalsReducer",
    "notificationReducer",
    "loadingReducer",
    "socketReducer",
  ],
};

const persistedReducer = persistReducer(
  persistConfig,
  combinedReducersWithRouter
);

type RootState = ReturnType<typeof persistedReducer>;
export interface IStoreApi {
  dispatch: Dispatch;
  getState: () => RootState;
}
export interface IStoreEpic {
  value: RootState;
}
export const rootReducer = () => persistedReducer;

export default function configureStore() {
  const composeEnhancers =
    (window["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"] as typeof compose) ||
    compose;

  /**
   * Create middleware with all epics functions
   */
  const epicMiddleware = createEpicMiddleware({
    dependencies: { history },
  });

  /*
   * Store is created while middleware are being apply
   * Persistor is created with store file
   */
  return {
    store: createStore(
      rootReducer(),
      composeEnhancers(
        applyMiddleware(
          epicMiddleware,
          createMySocketMiddleware(),
          createBugsnagMiddleware(),
          routerMiddleware(history)
        )
      )
    ),
    epicMiddleware,
  };
}

import React from "react";
import ImapSmtp from "../../../Identities/views/Modals/ImapSmtp";

export const types = {
  imapSmtp: "imapSmtp",
  reset: "",
};

function Modals(props: any) {
  switch (props.name) {
    case types.imapSmtp:
      return <ImapSmtp {...props} />;
    default:
      return null;
  }
}

export default Modals;

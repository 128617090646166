import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { IdentityNewBar, IdentityMain } from "@lagrowthmachine/lgmwebapp";
import { useDispatch } from "react-redux";
import * as actions from "../../actions";
import { loadLoginUrl, refreshSpfDkim } from "../../functions";
import * as globalActions from "../../../../redux/actions";
import * as authActions from "../../../Auth/actions";
import useSelector from "../../../../redux/useSelector";
import Modal, { types as modalTypes } from "../Modals";
import analyticsCall from "../../../../helpers/analyticsCall";
import constants from "../../../../config/constants";

const getIdentityMessage = (identity: any) => {
  if (!identity.firstname && !identity.lastname && !identity.linkedinData)
    return {
      type: "warning",
      text: "Sign in with a LINKEDIN profile to setup your identity",
      reconnect: [""],
    };
  const channelsDisconnected = ["LINKEDIN", "EMAIL", "TWITTER"].filter(
    (_channel: string) => identity.reconnect[_channel.toLowerCase()]
  );
  if (channelsDisconnected.length !== 0) {
    return {
      type: "error",
      text:
        channelsDisconnected.join(", ") +
        " " +
        (channelsDisconnected.length === 1 ? "was" : "were") +
        " disconnected for this identity.",
      reconnect: channelsDisconnected,
    };
  }
  if (identity.message) return identity.message;
  return null;
};

const IdentitiesComponent = (props: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useLocation();
  const authReducer = useSelector((state) => state.authReducer);
  const identitiesReducer = useSelector((state) => state.identitiesReducer);
  const modalsReducer = useSelector((state) => state.modalsReducer);
  const socketReducer = useSelector((state) => state.socketReducer);

  const [channelLoader, setChannelLoader] = useState({
    channel: "",
    identityId: "",
  });

  const [openStmpFor, setOpenStmpFor] = useState("");

  useEffect(() => {
    analyticsCall("identities", "identities index");
    //analytics2("identities", "identities index");
  }, []);

  useEffect(() => {
    const action = new URLSearchParams(params.search).get("action");
    const identityId = new URLSearchParams(params.search).get("identityId");
    if (action === "open_smtp" && identityId) {
      setOpenStmpFor(identityId);
    }
  }, []);

  useEffect(() => {
    const _identity = identitiesReducer.list.find(
      (__identity: any) => __identity.id === openStmpFor
    );
    if (openStmpFor && _identity) {
      handleAction(
        "toggleChannel",
        { channel: "SMTP", activate: true },
        openStmpFor
      );
      setOpenStmpFor("");
      history.push("/identities");
    }
  }, [openStmpFor, identitiesReducer.list]);

  useEffect(() => {
    if (channelLoader.channel)
      setTimeout(() => {
        setChannelLoader({ channel: "", identityId: "" });
      }, 5000);
  }, [channelLoader]);

  useEffect(() => {
    dispatch(authActions.loadUser({}));
    dispatch(actions.loadIdentities({ query: { limit: true } }));
  }, [dispatch]);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(globalActions.socketIsWidgetAvailable());
    }, 4000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [dispatch, socketReducer.widgetAvailable]);

  const [loadingSpfDkimForId, setLoadingSpfDkimForId] = useState("");

  const handleAction = (
    name: string,
    data: any,
    identityId: string,
    identityName?: string
  ) => {
    switch (name) {
      case "toggleChannel":
        if (data.activate) {
          if (["LINKEDIN", "TWITTER", "GMAIL"].includes(data.channel)) {
            //WIDGET CONNECTION
            if (!socketReducer.widgetAvailable) {
              dispatch(
                globalActions.setNotificationSmall({
                  notification: {
                    type: "error",
                    text: "Your LGM Widget needs to be opened",
                  },
                  format: "basic",
                })
              );
              return;
            }
            setChannelLoader({ channel: data.channel, identityId });
            dispatch(
              globalActions.socketConnectChannel({
                identityId,
                channel: data.channel === "GMAIL" ? "GOOGLE" : data.channel,
              })
            );
          } else if (["OUTLOOK"].includes(data.channel)) {
            //GET OUTLOOK URL and open it in a new tab
            setChannelLoader({ channel: data.channel, identityId });
            loadLoginUrl({
              authReducer,
              channel: data.channel.toLowerCase(),
              identityId,
            })
              .then((response) => {
                window.open(response.url, "_blank");
              })
              .catch((e) => setChannelLoader({ channel: "", identityId: "" }));
          } else if (["SMTP"].includes(data.channel)) {
            //OPEN SMTP/IMAP MODAL
            handleModal({
              name: modalTypes.imapSmtp,
              data: {
                identityId,
                channel: data.channel.toLowerCase(),
              },
            });
          }
        } else {
          handleModal({
            name: modalTypes.confirmDisconnect,
            data: {
              identityId,
              channel: data.channel === "GMAIL" ? "GOOGLE" : data.channel,
              identityName: identityName || "this identity",
              disconnect: true,
            },
          });
        }
        break;
      case "emailSettings":
        //OPEN SMTP/IMAP MODAL
        if (["SMTP"].includes(data.channel)) {
          handleModal({
            name: modalTypes.imapSmtp,
            data: {
              identityId,
              channel: data.channel.toLowerCase(),
            },
          });
        }
        break;
      case "setupSpfDkim":
        const setupSpfDkimUrl = constants.setup_spf_dkim;
        (window as any).open(setupSpfDkimUrl, "_blank").focus();
        break;
      case "checkDns":
        setLoadingSpfDkimForId(identityId);
        refreshSpfDkim({ authReducer, identityId, channel: data.channel })
          .then((response) => {
            setLoadingSpfDkimForId("");
            dispatch(actions.loadIdentities({}));
          })
          .catch((e) => {
            setLoadingSpfDkimForId("");
            dispatch(
              globalActions.setNotificationSmall({
                notification: {
                  type: "error",
                  text: String(e),
                },
                format: "basic",
              })
            );
          });
        break;
      default:
        break;
    }
  };

  const handleModal = ({ name, data }: { name: string; data: any }) => {
    dispatch(globalActions.modalUpdate({ name, data }));
  };

  const getLoader = (ch: string, identityId: string) => {
    if (channelLoader.channel === ch && identityId === channelLoader.identityId)
      return true;
    return false;
  };

  const requestData = (identityId: string, startDate: number) => {
    dispatch(
      actions.loadIdentityLimits({
        params: { identityId: identityId },
        query: { startDate },
      })
    );
  };

  const onUpgrade = (identityId: string) => {
    history.push("/settings/pricing");
  };

  const onReactivate = (identityId: string, type: "canceled" | "past_due") => {
    if (type === "canceled" && authReducer.user.plan !== "TRIAL") {
      history.push({
        pathname: "/settings/payment/reactivateid",
        state: { identities: [identityId] },
      });
    } else if (type === "canceled" && authReducer.user.plan === "TRIAL") {
      history.push("/settings/pricing");
    } else if (type === "past_due") {
      history.push("/settings/billing");
    }
    return;
  };

  return (
    <div className="identities_index_container">
      <IdentityNewBar
        identities={identitiesReducer.list.map((identity: any) => ({
          value: identity.id,
          firstName: identity.firstname,
          lastName: identity.lastname,
          avatar: identity.profilePicture,
          status:
            identity.subscription?.status === "active" ||
            identity.subscription?.status === "trialing"
              ? "connected"
              : "disconnected",
        }))}
        subPage=""
        hideRs={true}
        rightBtn={authReducer.user?.plan === "TRIAL" ? "upgrade" : "newId"}
        onUpgrade={() => history.push("/settings/pricing")}
        identityId={null}
        onSelectIdentity={(page, identityId) =>
          history.push("/identities/" + identityId + "/details")
        }
        identityLoading={false}
        goToIdentitiesHome={() => history.push("/identities")}
        onNewIdentity={() => history.push("/settings/payment/addid")}
      />
      <Modal
        name={modalsReducer.name}
        data={modalsReducer.data}
        onRequestClose={() => handleModal({ name: modalTypes.reset, data: {} })}
        // createCustom={() => setModal("newAudienceCreate")}
      />
      {identitiesReducer.list.map((identity: any, key: number) => {
        return (
          <IdentityMain
            key={key}
            onSelectIdentity={() =>
              history.push("/identities/" + identity.id + "/details")
            }
            id={identity.id}
            profilePicture={identity.profilePicture}
            firstName={identity.firstname || ""}
            lastName={identity.lastname || ""}
            label={identity.linkedinData?.type}
            channels={{
              TWITTER: !!identity.twitterData,
              LINKEDIN: !!identity.linkedinData,
              GMAIL: identity.emailData?.type === "GMAIL",
              OUTLOOK: identity.emailData?.type === "OUTLOOK",
              SMTP: identity.emailData?.type === "SMTP",
            }}
            loading={{
              TWITTER: getLoader("TWITTER", identity.id),
              LINKEDIN: getLoader("LINKEDIN", identity.id),
              GMAIL: getLoader("GMAIL", identity.id),
              OUTLOOK: getLoader("OUTLOOK", identity.id),
              SMTP: getLoader("SMTP", identity.id),
            }}
            spfDkimCheckLoading={loadingSpfDkimForId === identity.id}
            emailConfig={
              identity.emailConfig || { spf: "NOT_VALID", dkim: "NOT_VALID" }
            }
            onAction={(name, data) =>
              handleAction(name, data, identity.id, identity.firstname)
            }
            requestData={requestData}
            upgrade={onUpgrade}
            reactivate={onReactivate}
            upgradeable={identity.subscription?.status === "trialing"}
            limitsLoading={identity.limitsLoading}
            idDisabled={
              {
                incomplete_expired: "canceled",
                undefined: "canceled",
                past_due: "past_due",
                incomplete: "past_due",
                canceled: "canceled",
              }[String(identity.subscription?.status)] || undefined
            }
            message={getIdentityMessage(identity)}
            reconnect={getIdentityMessage(identity)?.reconnect || []}
            limits={identity.limits || []}
            onEditLimits={() =>
              history.push("/identities/" + identity.id + "/limits")
            }
          />
        );
      })}
    </div>
  );
};

export default IdentitiesComponent;

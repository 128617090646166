import { ActionsObservable, ofType, Epic } from "redux-observable";

import { filter, takeUntil, mergeMap, catchError, map } from "rxjs/operators";
import { tag } from "rxjs-spy/operators/tag";
import types from "../types";
import * as actions from "../actions";
import config from "../../../config";
import * as adapterIdentity from "../adapters/identity";
import apiService from "../../../services/apis";
import getEndpoint from "../endpoints";
import IAction from "../../../interfaces/IAction";
import { of } from "rxjs/internal/observable/of";

export const loadIdentitiesEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  // spy.log("login");
  return action$.pipe(
    ofType(types.REQUESTS_DATA.LOAD_IDENTITIES),
    mergeMap((action) => {
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "loadIdentities",
          query: {
            ...action.payload?.query,
            apikey: store.value.authReducer?.user?.apikey,
            limit: action.payload?.query?.limit || false,
          },
        })
      )
        .pipe(
          mergeMap((xhr) => of(actions.loadIdentitiesFulfilled(xhr.response))),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type === types.RESPONSES_DATA.LOAD_IDENTITIES_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedLoadIdentities(e?.response));
          })
        );
    })
  );
};

export const reloadIdentitiesEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(
      types.RESPONSES_ACTIONS.EDIT_IDENTITY_FULFILLED,
      types.RESPONSES_ACTIONS.EDIT_IDENTITY2_FULFILLED
    ),
    map((action) => {
      return actions.loadIdentities({});
    })
  );
};

export const editIdentityEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  // spy.log("login");
  return action$.pipe(
    ofType(types.REQUESTS_ACTIONS.EDIT_IDENTITY),
    mergeMap((action) => {
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "editIdentity",
          body: adapterIdentity.adapterEditOUT(action.payload?.body),
          params: action.payload?.params,
          query: {
            ...action.payload?.query,
            apikey: store.value.authReducer?.user?.apikey,
          },
        })
      )
        .pipe(
          mergeMap((xhr) => of(actions.editIdentityFulfilled(xhr.response))),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_ACTIONS.EDIT_IDENTITY_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedEditIdentity(e?.response));
          })
        );
    })
  );
};

export const editIdentity2Epic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  // spy.log("login");
  return action$.pipe(
    ofType(types.REQUESTS_ACTIONS.EDIT_IDENTITY2),
    mergeMap((action) => {
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "editIdentity",
          body: adapterIdentity.adapterEditOUT(action.payload?.body),
          params: action.payload?.params,
          query: {
            ...action.payload?.query,
            apikey: store.value.authReducer?.user?.apikey,
          },
        })
      )
        .pipe(
          mergeMap((xhr) => of(actions.editIdentity2Fulfilled(xhr.response))),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_ACTIONS.EDIT_IDENTITY2_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedEditIdentity2(e?.response));
          })
        );
    })
  );
};

export const loadLogsEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_DATA.LOAD_IDENTITY_LOGS),

    mergeMap((action) => {
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "loadLogs",
          params: action.payload?.params,
          query: {
            ...action.payload?.query,
            apikey: store.value.authReducer?.user?.apikey,
            limit: config.limitLogs,
            skip:
              (store.value.identitiesReducer?.paginateL?.current || 0) *
              config.limitLogs,
          },
        })
      )
        .pipe(
          tag("loadLogs"),
          map((xhr) => actions.loadLogsFulfilled(xhr.response)),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_DATA.LOAD_IDENTITY_LOGS_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedLoadLogs(e?.response));
          })
        );
    })
  );
};

export const loadIdentityLimitsEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_DATA.LOAD_IDENTITY_LIMITS),
    mergeMap((action) => {
      const identityId = action.payload?.params.identityId;
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "loadIdentityLimits",
          params: action.payload?.params,
          query: {
            ...action.payload?.query,
            apikey: store.value.authReducer?.user?.apikey,
          },
        })
      )
        .pipe(
          mergeMap((xhr) =>
            of(
              actions.loadIdentityLimitsFulfilled({
                limits: xhr.response,
                identityId: identityId,
              })
            )
          ),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_DATA.LOAD_IDENTITY_LIMITS_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedLoadIdentityLimits(e?.response));
          })
        );
    })
  );
};

interface IAnalytics {
  identify: (userId?: string | null, data?: any, options?: any) => void;
  group: (
    userId?: string | null,
    memberId?: string | null,
    data?: any,
    options?: any
  ) => void;
  page: (index?: string, subIndex?: string, data?: any) => void;
  track: (event: string, data?: any) => void;
}
declare var analytics: IAnalytics;

export const parseAnalyticsPayload = (data: any = {}) => {
  const env = process.env.REACT_APP_ENV || "DEVELOPMENT";
  return {
    ...data,
    //env: process.env.NODE_ENV,
    env: env,
  };
};

export default () => analytics;

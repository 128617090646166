import React, { useState } from "react";
import ModalWrapper from "../../../../components/ModalWrapper";
import { ModalBasic } from "@lagrowthmachine/lgmwebapp";
import { useDispatch } from "react-redux";
import * as globalActions from "../../../../redux/actions";
import * as actions from "../../actions";
import { revokeImapSmtp } from "../../functions";
import useSelector from "../../../../redux/useSelector";

function Modal(props: any) {
  const dispatch = useDispatch();
  const authReducer = useSelector((state) => state.authReducer);
  const network = props.data.channel.toLowerCase();

  const [revokeLoader, setRevokeLoader] = useState(false);

  const onAction = () => {
    if (["OUTLOOK", "SMTP"].includes(props.data.channel)) {
      setRevokeLoader(true);
      revokeImapSmtp({ authReducer, ...props.data })
        .then((response) => {
          setRevokeLoader(false);
          dispatch(actions.loadIdentities({}));
          /*
          dispatch(
            globalActions.setNotificationSmall({
              notification: {
                type: "success",
                text: `Your ${network} config has been removed`,
              },
              format: "basic",
            })
          );
          */
          props.onRequestClose();
        })
        .catch((e) => {
          setRevokeLoader(false);
          dispatch(
            globalActions.setNotificationSmall({
              notification: {
                type: "error",
                text: String(e),
              },
              format: "basic",
            })
          );
        });
    } else {
      dispatch(
        globalActions.socketDisconnectChannel({
          identityId: props.data.identityId,
          channel: props.data.channel,
        })
      );
      props.onRequestClose();
    }
  };

  return (
    <ModalWrapper>
      <ModalBasic
        type="warning"
        title={`Disconnecting ${network}`}
        subtitle={`Do you really want to disconnect ${props.data.identityName} from ${network}?\nPending actions on ${network} will be paused.`}
        onAction={onAction}
        onCancel={props.onRequestClose}
        cancelTextBtn="Cancel"
        actionTextBtn="Disconnect"
        btnIsLoading={revokeLoader}
        btnDisabled={revokeLoader}
      />
    </ModalWrapper>
  );
}
export default Modal;

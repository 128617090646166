import { ActionsObservable, ofType, Epic } from "redux-observable";

import { map, filter, takeUntil, mergeMap, catchError } from "rxjs/operators";
import types from "../types";
import settingsTypes from "../../Settings/types";
import { create } from "rxjs-spy";
import * as adapterSignup from "../adapters/signup";
import * as adapterEditUser from "../adapters/editUser";
import * as adapterLoadUser from "../adapters/loadUser";

import { tag } from "rxjs-spy/operators/tag";
import * as actions from "../actions";
import * as identitiesActions from "../../Identities/actions";
import * as leadsActions from "../../Leads/actions";
import * as campaignsActions from "../../Campaigns/actions";
import * as globalActions from "../../../redux/actions";
import apiService from "../../../services/apis";
import getEndpoint from "../endpoints";
import { ILoginAction } from "../interfaces/ILogin";
import { IRegisterAction } from "../interfaces/IRegister";
import { IResetPasswordReqAction } from "../interfaces/IResetPassword";
import { IValidationAccountAction } from "../interfaces/IValidationAccount";
import { push } from "connected-react-router";
import { fromFetch } from "rxjs/fetch";
import IAction from "../../../interfaces/IAction";

import { of } from "rxjs/internal/observable/of";

const spy = create();

export const signOutUserEpic: Epic<ILoginAction, ILoginAction> = (
  action$: ActionsObservable<ILoginAction>
) => {
  // spy.log("signOut");
  return action$.pipe(
    ofType(types.SIGNOUT_USER),
    mergeMap((action) =>
      of(
        actions.clear(),
        leadsActions.clear(),
        identitiesActions.clear(),
        campaignsActions.clear(),
        globalActions.clearAudiences(),
        globalActions.clearNotifications(),
        globalActions.clearWebhooks(),
        push("/login"),
        globalActions.socketDestroy()
      )
    )
  );
};

export const relogUserEpic: Epic<ILoginAction, ILoginAction> = (
  action$: ActionsObservable<ILoginAction>
) => {
  // spy.log("signOut");
  return action$.pipe(
    ofType(types.RELOG_USER),
    mergeMap((action) =>
      of(
        actions.clear(),
        leadsActions.clear(),
        identitiesActions.clear(),
        campaignsActions.clear(),
        globalActions.clearAudiences(),
        globalActions.clearNotifications(),
        globalActions.clearWebhooks(),
        push("/login")
      )
    )
  );
};

export const clearAllReducersEpic: Epic<ILoginAction, ILoginAction> = (
  action$: ActionsObservable<ILoginAction>
) => {
  // spy.log("signOut");
  return action$.pipe(
    ofType(types.REQUESTS_ACTIONS.LOGIN_USER),
    mergeMap((action) =>
      of(
        actions.clear(),
        leadsActions.clear(),
        identitiesActions.clear(),
        campaignsActions.clear(),
        globalActions.clearAudiences(),
        globalActions.clearNotifications(),
        globalActions.clearWebhooks()
      )
    )
  );
};

export const loginUserEpic: Epic<ILoginAction, ILoginAction> = (
  action$: ActionsObservable<ILoginAction>,
  store: any
) => {
  spy.log("login");
  return action$.pipe(
    ofType(types.REQUESTS_ACTIONS.LOGIN_USER),
    mergeMap((action) => {
      const pushTo =
        action.payload?.redirect?.replace("?redirect_url=", "") || "/campaigns";

      return apiService(
        getEndpoint({
          name: "login",
          body: action.payload?.body,
          apiVersion: store.value.authReducer?.user?.apiVersion,
        })
      )
        .pipe(
          tag("login"),
          mergeMap((xhr) =>
            of(
              actions.loginUserFulfilled(xhr.response),
              push(pushTo),
              globalActions.socketInit()
            )
          ),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type === types.RESPONSES_ACTIONS.LOGIN_USER_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedLoginUser(e?.response));
          })
        );
    })
  );
};

export const fakeLoginUserEpic: Epic<ILoginAction, ILoginAction> = (
  action$: ActionsObservable<ILoginAction>
) => {
  spy.log("login");
  return action$.pipe(
    ofType("FAKE_LOGIN_USER"),
    mergeMap((action) =>
      of(
        actions.loginUserFulfilled(action.payload),
        push(`/campaigns`),
        globalActions.socketInit()
      )
    )
  );
};

export const registerUserEpic: Epic<IRegisterAction, IRegisterAction> = (
  action$: ActionsObservable<IRegisterAction>,
  store: any
) => {
  spy.log("register");
  return action$.pipe(
    ofType(types.REQUESTS_ACTIONS.REGISTER_USER),
    mergeMap((action) => {
      return apiService(
        getEndpoint({
          name: "register",
          body: adapterSignup.adapterSignupOUT(action.payload?.body),
          apiVersion: store.value.authReducer?.user?.apiVersion,
        })
      )
        .pipe(
          tag("register"),
          mergeMap((xhr) => of(actions.registerUserFulfilled(xhr.response))),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_ACTIONS.REGISTER_USER_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedRegisterUser(e?.response));
          })
        );
    })
  );
};

export const setApiKeyAfterRegister: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.RESPONSES_ACTIONS.REGISTER_USER_FULFILLED),
    mergeMap((action) => {
      return of(actions.setApiKey(action.payload));
    })
  );
};

export const resetPwdReqEpic: Epic<
  IResetPasswordReqAction,
  IResetPasswordReqAction
> = (action$: ActionsObservable<IResetPasswordReqAction>, store: any) => {
  spy.log("resetPwdReq");
  return action$.pipe(
    ofType(types.REQUESTS_ACTIONS.RESET_PWD_REQ),
    mergeMap((action) => {
      return apiService(
        getEndpoint({
          name: "resetPasswordRequest",
          query: action.payload?.query,
          body: action.payload?.body,
          apiVersion: store.value.authReducer?.user?.apiVersion,
        })
      )
        .pipe(
          tag("resetPwdReq"),
          map((xhr) =>
            actions.resetPwdReqFulfilled({
              ...xhr.response,
              email: action.payload?.body?.email,
            })
          ),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_ACTIONS.RESET_PWD_REQ_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedResetPwdReq(e?.response));
          })
        );
    })
  );
};

export const resetPwdEpic: Epic<
  IResetPasswordReqAction,
  IResetPasswordReqAction
> = (action$: ActionsObservable<IResetPasswordReqAction>, store: any) => {
  spy.log("resetPwd");
  return action$.pipe(
    ofType(types.REQUESTS_ACTIONS.RESET_PWD),
    mergeMap((action) => {
      return apiService(
        getEndpoint({
          name: "resetPassword",
          body: action.payload?.body,
          apiVersion: store.value.authReducer?.user?.apiVersion,
        })
      )
        .pipe(
          tag("resetPwd"),
          mergeMap((xhr) =>
            of(actions.resetPwdFulfilled(xhr.response), push("/login"))
          ),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type === types.RESPONSES_ACTIONS.RESET_PWD_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedResetPwd(e?.response));
          })
        );
    })
  );
};

export const validationAccountEpic: Epic<
  IValidationAccountAction,
  IValidationAccountAction
> = (action$: ActionsObservable<IValidationAccountAction>, store: any) => {
  spy.log("validationAccount");
  return action$.pipe(
    ofType(types.REQUESTS_ACTIONS.VALIDATION_ACCOUNT),
    mergeMap((action) => {
      alert(JSON.stringify(action.payload));
      return apiService(
        getEndpoint({
          name: "validationAccount",
          body: action.payload?.body,
          params: action.payload?.params,
          query: action.payload?.query,
          apiVersion: store.value.authReducer?.user?.apiVersion,
        })
      )
        .pipe(
          tag("validationAccount"),
          map((xhr) => actions.validationAccountFulfilled(xhr.response)),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_ACTIONS.VALIDATION_ACCOUNT_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedValidationAccount(e?.response));
          })
        );
    })
  );
};

export const loadUserEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(
      types.REQUESTS_DATA.LOAD_USER,
      types.RESPONSES_ACTIONS.EDIT_USER_FULFILLED,
      types.RESPONSES_ACTIONS.EDIT_MEMBER_FULFILLED,
      types.RESPONSES_ACTIONS.REFRESH_EXTERN_APIKEY_FULFILLED,

      settingsTypes.RESPONSES_ACTIONS.HUBSPOT_CONNECT_FULFILLED,
      settingsTypes.RESPONSES_ACTIONS.HUBSPOT_DISCONNECT_FULFILLED,
      settingsTypes.RESPONSES_ACTIONS.HUBSPOT_MAPPING_EDIT_FULFILLED,
      settingsTypes.RESPONSES_ACTIONS.HUBSPOT_LIFECYCLE_EDIT_FULFILLED,

      settingsTypes.RESPONSES_ACTIONS.PIPEDRIVE_CONNECT_FULFILLED,
      settingsTypes.RESPONSES_ACTIONS.PIPEDRIVE_DISCONNECT_FULFILLED,
      settingsTypes.RESPONSES_ACTIONS.PIPEDRIVE_MAPPING_EDIT_FULFILLED,
      settingsTypes.RESPONSES_ACTIONS.PIPEDRIVE_LIFECYCLE_EDIT_FULFILLED
    ),
    mergeMap((action) => {
      return apiService(
        getEndpoint({
          name: "loadUser",
          params: action.payload?.params,
          query: {
            ...action.payload?.query,
            apikey: store.value.authReducer?.user?.apikey,
          },
          apiVersion: store.value.authReducer?.user?.apiVersion,
        })
      )
        .pipe(
          tag("loadUser"),
          mergeMap((xhr) =>
            of(
              actions.loadUserFulfilled(
                adapterLoadUser.adapterLoadUser(xhr.response)
              )
            )
          ),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type === types.RESPONSES_DATA.LOAD_USER_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedLoadUser(e?.response));
          })
        );
    })
  );
};

export const editMemberEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(
      types.REQUESTS_ACTIONS.EDIT_MEMBER,
      types.RESPONSES_ACTIONS.UPLOAD_FILE_FULFILLED
    ),
    mergeMap((action) => {
      return apiService(
        getEndpoint({
          name: "editMember",
          params: action.payload?.params,
          body: adapterEditUser.adapterEditOUT(action.payload?.body),
          query: {
            ...action.payload?.query,
            apikey: store.value.authReducer?.user?.apikey,
          },
          apiVersion: store.value.authReducer?.user?.apiVersion,
        })
      )
        .pipe(
          tag("editMember"),
          mergeMap((xhr) => of(actions.editMemberFulfilled(xhr.response))),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type === types.RESPONSES_ACTIONS.EDIT_MEMBER_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedEditMember(e?.response));
          })
        );
    })
  );
};

export const editUserEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_ACTIONS.EDIT_USER),
    mergeMap((action) => {
      return apiService(
        getEndpoint({
          name: "editUser",
          params: action.payload?.params,
          body: adapterEditUser.adapterEditOUT(action.payload?.body),
          query: {
            ...action.payload?.query,
            apikey: store.value.authReducer?.user?.apikey,
          },
          apiVersion: store.value.authReducer?.user?.apiVersion,
        })
      )
        .pipe(
          tag("editUser"),
          mergeMap((xhr) => of(actions.editUserFulfilled(xhr.response))),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type === types.RESPONSES_ACTIONS.EDIT_USER_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedEditUser(e?.response));
          })
        );
    })
  );
};

export const getUploadUrlEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_ACTIONS.UPLOAD_URL_USER),
    mergeMap((action) => {
      return apiService(
        getEndpoint({
          name: "getUploadUrl",
          params: action.payload?.params,
          body: action.payload?.body,
          query: {
            ...action.payload?.query,
            apikey: store.value.authReducer?.user?.apikey,
          },
          apiVersion: store.value.authReducer?.user?.apiVersion,
        })
      )
        .pipe(
          tag("uploadUrl"),
          mergeMap((xhr) => of(actions.getUploadUrlFulfilled(xhr.response))),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_ACTIONS.UPLOAD_URL_USER_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedGetUploadUrl(e?.response));
          })
        );
    })
  );
};

export const getCouponEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_ACTIONS.GET_COUPON),
    mergeMap((action) => {
      return apiService(
        getEndpoint({
          name: "getCoupon",
          params: action.payload?.params,
          body: action.payload?.body,
          query: {
            ...action.payload?.query,
            apikey: store.value.authReducer?.user?.apikey,
          },
          apiVersion: store.value.authReducer?.user?.apiVersion,
        })
      )
        .pipe(
          tag("getCoupon"),
          mergeMap((xhr) => of(actions.getCouponFulfilled(xhr.response))),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type === types.RESPONSES_ACTIONS.GET_COUPON_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedGetCoupon(e?.response));
          })
        );
    })
  );
};

export const uploadFileEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_ACTIONS.UPLOAD_FILE),
    mergeMap((action) => {
      return fromFetch(action.payload?.query.url, {
        method: "PUT",
        body: action.payload?.body,
      })
        .pipe(
          tag("uploadFile"),
          mergeMap((xhr) =>
            of(
              actions.uploadFileFulfilled({
                body: { avatar: action.payload?.query.futurUrl },
              })
            )
          ),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type === types.RESPONSES_ACTIONS.UPLOAD_FILE_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedUploadFile(e?.response));
          })
        );
    })
  );
};

export const refreshApiKeyEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_ACTIONS.REFRESH_EXTERN_APIKEY),
    mergeMap((action) => {
      return apiService(
        getEndpoint({
          name: "generateApiKey",
          query: {
            apikey: store.value.authReducer?.user?.apikey,
          },
          body: {},
          apiVersion: store.value.authReducer?.user?.apiVersion,
        })
      )
        .pipe(
          map((xhr) => actions.refreshApiKeyFulfilled(xhr.response)),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_ACTIONS.REFRESH_EXTERN_APIKEY_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedRefreshApiKey(e?.response));
          })
        );
    })
  );
};

export const deleteAccountEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_ACTIONS.DELETE_ACCOUNT),
    mergeMap((action) => {
      const setResult = action.payload?.setResult;
      return apiService(
        getEndpoint({
          name: "terminateUser2",
          query: {
            ...action.payload?.query,
            //apikey: store.value.authReducer?.user?.apikey,
          },
          body: action.payload?.body,
          //apiVersion: store.value.authReducer?.user?.apiVersion,
        })
      )
        .pipe(
          map((xhr) => {
            setResult(true);
            return actions.deleteAccountFulfilled(xhr.response);
          }),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_ACTIONS.DELETE_ACCOUNT_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedDeleteAccount(e?.response));
          })
        );
    })
  );
};

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  SettingsTopBar,
  SettingsActionsBar,
  SettingsCampaign,
} from "@lagrowthmachine/lgmwebapp";
import { useDispatch } from "react-redux";
import * as globalActions from "../../../../redux/actions";
import * as authActions from "../../../Auth/actions";
import useSelector from "../../../../redux/useSelector";
import authTypes from "../../../Auth/types";
import analyticsCall from "../../../../helpers/analyticsCall";
import barNavItems from "../../helpers/barNavItems";

const SettingsCampaignComponent = (props: any) => {
  const dispatch = useDispatch();
  const authReducer = useSelector((state) => state.authReducer);
  const loadingReducer = useSelector((state) => state.loadingReducer);
  const bar = barNavItems(authReducer);

  useEffect(() => {
    dispatch(authActions.loadUser({ query: {} }));
  }, [dispatch]);

  useEffect(() => {
    analyticsCall("settings", "settings: campaign settings");
  }, []);

  useEffect(() => {
    dispatch(globalActions.scrollToTop());
  }, [dispatch]);

  const handleActions = (action: string, data?: any) => {
    switch (action) {
      case "input":
        dispatch(authActions.signOutUser());
        break;
      case "saveCampaignSettings":
        dispatch(
          authActions.editUser({
            query: {},
            body: {
              campaignSettings: {
                sendEmailToBusinessEmails: data.sendEmailToBusinessEmails,
                sendEmailToPersonnalEmails: data.sendEmailToPersonnalEmails,
                sendEmailToCatchAll: data.sendEmailToCatchAll,
              },
              userSettings: { mergeDuplicateLead: data.mergeDuplicateLead },
            },
          })
        );
        break;
      default:
        break;
    }
  };

  return (
    <div className="settings_campaign_container">
      <SettingsTopBar onAction={handleActions} />
      <div className="content_wrapper">
        <SettingsActionsBar
          tabs={bar}
          page={"campaign"}
          linkC={Link}
          direction="vertical"
        />
        <div className="settings_container">
          <SettingsCampaign
            onAction={handleActions}
            btnIsLoading={
              loadingReducer.btnTypesLoaders[
                authTypes.REQUESTS_ACTIONS.EDIT_USER
              ]
            }
            btnDisabled={
              loadingReducer.btnTypesLoaders[
                authTypes.REQUESTS_ACTIONS.EDIT_USER
              ]
            }
            {...(authReducer?.user?.campaignSettings || {})}
            {...(authReducer?.user?.userSettings || {})}
          />
        </div>
      </div>
    </div>
  );
};

export default SettingsCampaignComponent;

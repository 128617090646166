import getEndpoint from "../endpoints";

export const createCampaignTemplate = async ({
  authReducer,
  body,
}: {
  authReducer: any;
  body: any;
}) => {
  const endpoint = getEndpoint({
    apiVersion: authReducer?.user?.apiVersion,
    name: "createCampaignTemplate",
    query: {
      apikey: authReducer?.user?.apikey,
    },
    params: {},
    body: JSON.stringify(body),
  });
  const response = await fetch(endpoint.url, {
    headers: endpoint.headers,
    method: endpoint.method,
    body: endpoint.body,
  });
  const json = await response.json();
  if (json.error) {
    throw json.error || "Unknow error";
  }
  return json;
};

export const editCampaignTemplate = async ({
  authReducer,
  templateId,
  body,
}: {
  authReducer: any;
  templateId: string;
  body: any;
}) => {
  const endpoint = getEndpoint({
    apiVersion: authReducer?.user?.apiVersion,
    name: "editCampaignTemplate",
    query: {
      apikey: authReducer?.user?.apikey,
    },
    params: {
      id: templateId,
    },
    body: JSON.stringify(body),
  });
  const response = await fetch(endpoint.url, {
    headers: endpoint.headers,
    method: endpoint.method,
    body: endpoint.body,
  });
  const json = await response.json();
  if (json.error) {
    throw json.error || "Unknow error";
  }
  return json;
};

export const duplicateMessageTemplate = async ({
  authReducer,
  templateId,
  body,
}: {
  authReducer: any;
  templateId: string;
  body: any;
}) => {
  const endpoint = getEndpoint({
    apiVersion: authReducer?.user?.apiVersion,
    name: "duplicateMessageTemplate",
    query: {
      apikey: authReducer?.user?.apikey,
    },
    params: {
      id: templateId,
    },
    body: JSON.stringify(body),
  });
  const response = await fetch(endpoint.url, {
    headers: endpoint.headers,
    method: endpoint.method,
    body: endpoint.body,
  });
  const json = await response.json();
  if (json.error) {
    throw json.error || "Unknow error";
  }
  return json;
};

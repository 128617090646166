import Login from "./Login/";
import Join from "./Join/";
import Register from "./Register/";
import ResetPassword from "./ResetPassword/";
import ValidationAccount from "./ValidationAccount/";
import DeleteAccount from "./DeleteAccount/";
import WidgetDownload from "./WidgetDownload/";
import ConnectingChannel from "./ConnectingChannel/";
import CrmConnect from "./CrmConnect/";
import "./style.scss";

const AuthViews = {
  Login,
  Register,
  Join,
  ResetPassword,
  ValidationAccount,
  DeleteAccount,
  WidgetDownload,
  ConnectingChannel,
  CrmConnect,
};

export default AuthViews;

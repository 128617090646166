import React, { useEffect, useState } from "react";
import { NewMessagesTemplate, type } from "@lagrowthmachine/lgmwebapp";
import { defaultValues } from "../../data/configTemplate";
import types from "../../types";
import useSelector from "../../../../redux/useSelector";
import uploadFileAsync from "../../../../helpers/uploadFileAsync";
import endpointsAuth from "../../../Auth/endpoints";
import analyticsCall from "../../../../helpers/analyticsCall";

const confirmText = "You have unsaved changes. Are you sure?";

function Modal(props: any) {
  const channel = props.data.channel || ("GMAIL" as type.ChannelType);
  const [templateEdited, setTemplateEdited] = useState(false);
  const authReducer = useSelector((state) => state.authReducer);
  const loadingReducer = useSelector((state) => state.loadingReducer);

  useEffect(() => {
    analyticsCall(
      "message new",
      `message new ${channel === "GMAIL" ? "email" : channel.toLowerCase()}`
    );
  }, [channel]);

  const handleUploadPic = async (file: any) => {
    return uploadFileAsync(
      file,
      authReducer.user.apikey,
      endpointsAuth,
      undefined,
      undefined,
      undefined,
      authReducer?.user?.apiVersion
    );
  };

  const btnSaveIsLoading =
    loadingReducer.btnTypesLoaders[
      types.REQUESTS_ACTIONS.CREATE_CAMPAIGN_TEMPLATE
    ];
  const btnSaveDisabled = btnSaveIsLoading;

  useEffect(() => {
    window.onbeforeunload = function () {
      return window.confirm(confirmText);
    };
    return () => {
      window.onbeforeunload = function () {};
    };
  }, []);

  const onRequestClose = (data: {
    subjectState: any;
    contentState: any;
    subjectHTML: any;
    contentHTML: any;
    channel: type.ChannelType;
    name: string | null;
    cc: string | null;
    bcc: string | null;
  }) => {
    let close: boolean = true;

    if (templateEdited) {
      close = window.confirm(confirmText);
    }
    if (close) {
      props.onRequestClose();
    }
  };
  const sameThread =
    channel === "GMAIL" ? props.data.sameThread || false : null;

  return (
    <NewMessagesTemplate
      templateWasModified={() => {
        setTemplateEdited(true);
      }}
      sameThread={sameThread}
      uploadPic={handleUploadPic}
      modeInit="WRITE"
      id={props.data.id || null}
      channel={channel}
      createdAt={props.data.createdAt || null}
      name={props.data.name || null}
      cc={props.data.cc || null}
      bcc={props.data.bcc || null}
      saveTemplate={props.onCreateTemplate}
      close={onRequestClose}
      campaigns={[]}
      defaultValues={defaultValues}
      subjectState={null}
      contentState={null}
      btnSaveDisabled={btnSaveDisabled}
      btnSaveIsLoading={btnSaveIsLoading}
    />
  );
}
export default Modal;

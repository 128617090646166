import SocketIOClient from "socket.io-client";
import socketsReducer from "../redux/reducers/sockets";
import * as globalActions from "../redux/actions";
import * as leadsActions from "../Containers/Leads/actions";
import convertTypeIntoText from "../helpers/convertTypeIntoText";
import { IStoreApi } from "../redux/configure";
import config, { APP_ENV } from "../config";
import analytics, { parseAnalyticsPayload } from "../helpers/analytics";

const createMyWebsocket = (
  store: IStoreApi,
  apikey?: string,
  userId?: string,
  memberId?: string
) => {
  const user = store.getState().authReducer.user;
  const globalCount = store.getState().authReducer.globalCount;

  let socketio: SocketIOClient.Socket | null = null;

  socketio = SocketIOClient(
    `${config.socketUrl2[APP_ENV]}?apikey=${apikey}&userId=${userId}`,
    {
      transports: ["websocket", "polling"],
    }
  );

  if (socketio) {
    socketio.on("connect", (event: any) => {
      console.log("Connecting", { event });
    });
    socketio.on("close", function (ev: Event) {
      // const user = store.getState().authReducer?.user;
      // if (user?.apikey) {
      //   setTimeout(() => {
      //     store.dispatch(globalActions.socketInit());
      //   }, 5000);
      // }
    });
    socketio.on("message", function (message: any) {
      console.log("onmessage event", { message });
      if (message.type === "COLD_REFRESH") {
        window.location.reload(true);
      }

      if (message.type === "DISPATCH_ACTION") {
        /*
        if(message.data?.type === "SET_NOTIFICATION_PERSIST"){
          message.data.payload = {
            format: "cta",
            ...message.data?.payload,
            notification: {
              type: "info",
              topHtmlContent: "", 
              bottomHtmlContent: "", 
              canCancel: false,
              btns: [],
              ...message.data?.payload?.notification,
              }
            }
          }else if("SET_NOTIFICATION"){
            message.data.payload = {
              format: "basic",
              ...message.data?.payload,
              notification: {
                type: "error",
                text: JSON.stringify(message.data?.payload?.notification),
                //...message.data?.payload?.notification,
                }
              }
          }

        console.log(message.data);*/

        store.dispatch(message.data);
        if (message.data?.payload?.notification?.status === "ok") {
          store.dispatch(globalActions.loadAudiences({}));
          store.dispatch(leadsActions.loadLeads({}));
        }
      }
      if (
        message.data?.payload?.notification?.nextDispatch === "RELOAD_LEADS"
      ) {
        store.dispatch(leadsActions.loadLeads({}));
      }

      if (message.type === "LAUNCH_BROWSER_RESULT") {
        if (message.data?.url) {
          const modalName =
            (/^\/campaigns\//.test(window.location.pathname) &&
              /\/edit$/.test(window.location.pathname) &&
              "importLinkedin") ||
            (window.location.pathname.startsWith("/leads") &&
              "linkedinImportSelectAudience") ||
            false;
          if (modalName) {
            store.dispatch(
              globalActions.modalUpdate({
                name: modalName,
                data: {
                  sourceUrl: message.data?.url,
                  identityId: message.data?.identityId,
                },
              })
            );
          }
        } else {
          store.dispatch(
            globalActions.setNotificationSmall({
              notification: {
                type: "error",
                text: "Wrong message.data.url",
              },
              format: "basic",
            })
          );
        }
      }
      if (message.type === "WIDGET_AVAILABLE") {
        store.dispatch(globalActions.widgetAvailable());
      }
      if (message.type === "WIDGET_NOT_AVAILABLE") {
        store.dispatch(globalActions.widgetNotAvailable());
      }
      if (
        message.type === "LINKEDIN_JOB" &&
        message.data?.done &&
        message.data.data?.campaignName &&
        message.data.lead
      ) {
        store.dispatch(
          globalActions.setNotificationSmall({
            notification: {
              // type: "check_circle",
              type: "lead_action",
              widget: {
                id: String(Math.floor(100 * Math.random())),
                firstName: message.data.identity?.firstname,
                lastName: message.data.identity?.lastname,
                avatar: message.data.identity?.profilePicture,
                has_circle: false,
                is_disconnected: false,
              },
              text1: `${convertTypeIntoText(message.data.type)} ${
                message.data.lead?.firstname
              } ${message.data.lead?.lastname}`,
              text2: `In: ${message.data.data?.campaignName}`,
              status: "",
            },
            format: "big",
          })
        );
      }

      if (message.type === "AUDIENCE_FROM_LINKEDIN") {
        const audience = store
          .getState()
          .audienceReducer.list.find(
            (aud: { id: string }) => aud.id === message.data.audienceId
          );

        if (!audience) {
          store.dispatch(globalActions.loadAudiences({}));
        }
        store.dispatch(
          globalActions.setNotificationPersist({
            id: message.data.id,
            format: "cta",
            notification: {
              type: message.data.completed ? "ok" : "loading",
              topHtmlContent: "Importing leads from linkedin",
              bottomHtmlContent:
                `Audience: ${audience?.name || message.data.audienceId}` +
                (message.data.completed
                  ? `<p>Status: ${message.data.totalPage} pages imported successfully.</p>`
                  : `<p>Status: Page ${message.data.current || 1}/${
                      message.data.totalPage || 1
                    }</p>`),
              warningMessage: "",
              tooltipEnd: "",
              canCancel: false,
            },
          })
        );
        if (message.data.completed) {
          store.dispatch(globalActions.loadAudiences({}));
          store.dispatch(leadsActions.loadLeads({}));
        }
      }
    });
  }

  analytics().identify(
    memberId,
    parseAnalyticsPayload({
      company_id: userId,
      createdAt: user?.createdAt,
      email: user?.email,
      fname: user?.firstname,
      lname: user?.lastname,
      name: `${user?.firstname} ${user?.lastname}`,
      appStatus: user?.appStatus,
      appVersion: user?.appVersion,
    }),
    { Intercom: { hideDefaultLauncher: false } }
  );
  analytics().group(
    userId,
    memberId,
    parseAnalyticsPayload({
      company_id: userId,
      createdAt: user?.createdAt,
      name: user?.company,
      fname: user?.website,
      identities: globalCount?.identities,
      running_campaigns: user?.runningCampaign,
      campaigns: globalCount?.campaigns,
      audiences: globalCount?.audiences,
      leads: globalCount?.leads,
    }),
    { Intercom: { hideDefaultLauncher: false } }
  );
  return socketio;
};

const createMySocketMiddleware = () => {
  return (store: IStoreApi) => {
    return socketsReducer(store, createMyWebsocket);
  };
};

export default createMySocketMiddleware;

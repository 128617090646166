import React from "react";
import ModalWrapper from "../ModalWrapper";
import { ModalBasic } from "@lagrowthmachine/lgmwebapp";
import { IModalProps } from "../../interfaces/IState";

export interface IModalConfirmationDelete extends IModalProps {
  title: string;
  subtitle: string;
  deleteText: string;
  onDelete: (data?: any) => void;
  onCancel: () => void | Promise<void>;
  btnDisabled?: boolean;
  btnIsLoading?: boolean;
  isWarning?: boolean;
}

function Modal(props: IModalConfirmationDelete) {
  const handleDelete = () => {
    props.onDelete(props.data);
  };

  return (
    <ModalWrapper>
      <ModalBasic
        type="warning"
        title={props.data.title}
        subtitle={props.data.subtitle}
        HtmlContent={props.data.HtmlContent}
        actionTextBtn="Delete"
        cancelTextBtn="Cancel"
        onAction={handleDelete}
        onCancel={props.onRequestClose}
        btnDisabled={props.btnIsLoading}
        btnIsLoading={props.btnIsLoading}
      />
    </ModalWrapper>
  );
}

export default Modal;

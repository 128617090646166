export const arrayOfGraphWebhooks = (array: any[], type: string) => {
  if (!array || array.length === 0) return null;
  if (type === "IN") {
    return array.map((item) => adapterGraphWebhookIN(item));
  }
};

export const adapterGraphWebhookIN = (webhook: any) => {
  return {
    id: webhook.id,
    name: webhook.name,
  };
};

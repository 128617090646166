export default {
  REQUESTS_ACTIONS: {
    CREATE_CAMPAIGN: "CREATE_CAMPAIGN",
    EDIT_CAMPAIGN: "EDIT_CAMPAIGN",
    EDIT_SEQUENCE: "EDIT_SEQUENCE",
    EDIT_CAMPAIGN_STATE: "EDIT_CAMPAIGN_STATE",
    DUPLICATE_CAMPAIGN: "DUPLICATE_CAMPAIGN",
    CAMPAIGN_REVIEW: "CAMPAIGN_REVIEW",

    CREATE_CAMPAIGN_TEMPLATE: "CREATE_CAMPAIGN_TEMPLATE",
    EDIT_CAMPAIGN_TEMPLATE: "EDIT_CAMPAIGN_TEMPLATE",
    DUPLICATE_MESSAGE_TEMPLATE: "DUPLICATE_MESSAGE_TEMPLATE",

    SAVE_CAMPAIGNS_FILTERS: "SAVE_CAMPAIGNS_FILTERS",
    EDIT_LEADS_QUEUE_STATUS: "EDIT_LEADS_QUEUE_STATUS",
  },
  REQUESTS_DATA: {
    LOAD_CAMPAIGNS: "LOAD_CAMPAIGNS",
    LOAD_CAMPAIGNS_NAME: "LOAD_CAMPAIGNS_NAME",
    LOAD_CAMPAIGN: "LOAD_CAMPAIGN",
    LOAD_SEQUENCE: "LOAD_SEQUENCE",
    LOAD_CAMPAIGNS_RECOMMENDED: "LOAD_CAMPAIGNS_RECOMMENDED",
    LOAD_CAMPAIGNS_STORE: "LOAD_CAMPAIGNS_STORE",
    LOAD_CAMPAIGNS_FILTERS: "LOAD_CAMPAIGNS_FILTERS",
    LOAD_CAMPAIGN_LEADS: "LOAD_CAMPAIGN_LEADS",
    LOAD_CAMPAIGN_STATS: "LOAD_CAMPAIGN_STATS",
    LOAD_MESSAGE: "LOAD_MESSAGE",
    LOAD_CAMPAIGN_ACTIONS: "LOAD_CAMPAIGN_ACTIONS",
    LOAD_CAMPAIGN_TEMPLATES: "LOAD_CAMPAIGN_TEMPLATES",
    LOAD_OTHER_CAMPAIGN_TEMPLATES: "LOAD_OTHER_CAMPAIGN_TEMPLATES",
    EXPORT_CAMPAIGN_LEADS: "EXPORT_CAMPAIGN_LEADS",
    LOAD_CAMPAIGN_PROGRESS: "LOAD_CAMPAIGN_PROGRESS",
  },

  RESPONSES_ACTIONS: {
    CREATE_CAMPAIGN_FULFILLED: "CREATE_CAMPAIGN_FULFILLED",
    CREATE_CAMPAIGN_REJECTED: "CREATE_CAMPAIGN_REJECTED",
    CREATE_CAMPAIGN_CANCELLED: "CREATE_CAMPAIGN_CANCELLED",

    EDIT_CAMPAIGN_FULFILLED: "EDIT_CAMPAIGN_FULFILLED",
    EDIT_CAMPAIGN_REJECTED: "EDIT_CAMPAIGN_REJECTED",
    EDIT_CAMPAIGN_CANCELLED: "EDIT_CAMPAIGN_CANCELLED",

    DUPLICATE_CAMPAIGN_FULFILLED: "DUPLICATE_CAMPAIGN_FULFILLED",
    DUPLICATE_CAMPAIGN_REJECTED: "DUPLICATE_CAMPAIGN_REJECTED",
    DUPLICATE_CAMPAIGN_CANCELLED: "DUPLICATE_CAMPAIGN_CANCELLED",

    EDIT_SEQUENCE_FULFILLED: "EDIT_SEQUENCE_FULFILLED",
    EDIT_SEQUENCE_REJECTED: "EDIT_SEQUENCE_REJECTED",
    EDIT_SEQUENCE_CANCELLED: "EDIT_SEQUENCE_CANCELLED",

    EDIT_CAMPAIGN_STATE_FULFILLED: "EDIT_CAMPAIGN_STATE_FULFILLED",
    EDIT_CAMPAIGN_STATE_REJECTED: "EDIT_CAMPAIGN_STATE_REJECTED",
    EDIT_CAMPAIGN_STATE_CANCELLED: "EDIT_CAMPAIGN_STATE_CANCELLED",

    CREATE_CAMPAIGN_TEMPLATE_FULFILLED: "CREATE_CAMPAIGN_TEMPLATE_FULFILLED",
    CREATE_CAMPAIGN_TEMPLATE_REJECTED: "CREATE_CAMPAIGN_TEMPLATE_REJECTED",
    CREATE_CAMPAIGN_TEMPLATE_CANCELLED: "CREATE_CAMPAIGN_TEMPLATE_CANCELLED",

    EDIT_CAMPAIGN_TEMPLATE_FULFILLED: "EDIT_CAMPAIGN_TEMPLATE_FULFILLED",
    EDIT_CAMPAIGN_TEMPLATE_REJECTED: "EDIT_CAMPAIGN_TEMPLATE_REJECTED",
    EDIT_CAMPAIGN_TEMPLATE_CANCELLED: "EDIT_CAMPAIGN_TEMPLATE_CANCELLED",

    DUPLICATE_MESSAGE_TEMPLATE_FULFILLED:
      "DUPLICATE_MESSAGE_TEMPLATE_FULFILLED",
    DUPLICATE_MESSAGE_TEMPLATE_REJECTED: "DUPLICATE_MESSAGE_TEMPLATE_REJECTED",
    DUPLICATE_MESSAGE_TEMPLATE_CANCELLED:
      "DUPLICATE_MESSAGE_TEMPLATE_CANCELLED",

    EDIT_LEADS_QUEUE_STATUS_FULFILLED: "EDIT_LEADS_QUEUE_STATUS_FULFILLED",
    EDIT_LEADS_QUEUE_STATUS_REJECTED: "EDIT_LEADS_QUEUE_STATUS_REJECTED",
    EDIT_LEADS_QUEUE_STATUS_CANCELLED: "EDIT_LEADS_QUEUE_STATUS_CANCELLED",

    SAVE_CAMPAIGNS_FILTERS_FULFILLED: "SAVE_CAMPAIGNS_FILTERS_FULFILLED",
    SAVE_CAMPAIGNS_FILTERS_REJECTED: "SAVE_CAMPAIGNS_FILTERS_REJECTED",
    SAVE_CAMPAIGNS_FILTERS_CANCELLED: "SAVE_CAMPAIGNS_FILTERS_CANCELLED",

    CAMPAIGN_REVIEW_FULFILLED: "CAMPAIGN_REVIEW_FULFILLED",
    CAMPAIGN_REVIEW_REJECTED: "CAMPAIGN_REVIEW_REJECTED",
    CAMPAIGN_REVIEW_CANCELLED: "CAMPAIGN_REVIEW_CANCELLED",
  },
  RESPONSES_DATA: {
    LOAD_CAMPAIGNS_FULFILLED: "LOAD_CAMPAIGNS_FULFILLED",
    LOAD_CAMPAIGNS_REJECTED: "LOAD_CAMPAIGNS_REJECTED",
    LOAD_CAMPAIGNS_CANCELLED: "LOAD_CAMPAIGNS_CANCELLED",

    LOAD_CAMPAIGNS_NAME_FULFILLED: "LOAD_CAMPAIGNS_NAME_FULFILLED",
    LOAD_CAMPAIGNS_NAME_REJECTED: "LOAD_CAMPAIGNS_NAME_REJECTED",
    LOAD_CAMPAIGNS_NAME_CANCELLED: "LOAD_CAMPAIGNS_NAME_CANCELLED",

    LOAD_SEQUENCE_FULFILLED: "LOAD_SEQUENCE_FULFILLED",
    LOAD_SEQUENCE_REJECTED: "LOAD_SEQUENCE_REJECTED",
    LOAD_SEQUENCE_CANCELLED: "LOAD_SEQUENCE_CANCELLED",

    LOAD_CAMPAIGN_FULFILLED: "LOAD_CAMPAIGN_FULFILLED",
    LOAD_CAMPAIGN_REJECTED: "LOAD_CAMPAIGN_REJECTED",
    LOAD_CAMPAIGN_CANCELLED: "LOAD_CAMPAIGN_CANCELLED",

    LOAD_MESSAGE_FULFILLED: "LOAD_MESSAGE_FULFILLED",
    LOAD_MESSAGE_REJECTED: "LOAD_MESSAGE_REJECTED",
    LOAD_MESSAGE_CANCELLED: "LOAD_MESSAGE_CANCELLED",

    LOAD_CAMPAIGNS_RECOMMENDED_FULFILLED:
      "LOAD_CAMPAIGNS_RECOMMENDED_FULFILLED",
    LOAD_CAMPAIGNS_RECOMMENDED_REJECTED: "LOAD_CAMPAIGNS_RECOMMENDED_REJECTED",
    LOAD_CAMPAIGNS_RECOMMENDED_CANCELLED:
      "LOAD_CAMPAIGNS_RECOMMENDED_CANCELLED",

    LOAD_CAMPAIGNS_STORE_FULFILLED: "LOAD_CAMPAIGNS_STORE_FULFILLED",
    LOAD_CAMPAIGNS_STORE_REJECTED: "LOAD_CAMPAIGNS_STORE_REJECTED",
    LOAD_CAMPAIGNS_STORE_CANCELLED: "LOAD_CAMPAIGNS_STORE_CANCELLED",

    LOAD_CAMPAIGN_TEMPLATES_FULFILLED: "LOAD_CAMPAIGN_TEMPLATES_FULFILLED",
    LOAD_CAMPAIGN_TEMPLATES_REJECTED: "LOAD_CAMPAIGN_TEMPLATES_REJECTED",
    LOAD_CAMPAIGN_TEMPLATES_CANCELLED: "LOAD_CAMPAIGN_TEMPLATES_CANCELLED",

    LOAD_OTHER_CAMPAIGN_TEMPLATES_FULFILLED:
      "LOAD_OTHER_CAMPAIGN_TEMPLATES_FULFILLED",
    LOAD_OTHER_CAMPAIGN_TEMPLATES_REJECTED:
      "LOAD_OTHER_CAMPAIGN_TEMPLATES_REJECTED",
    LOAD_OTHER_CAMPAIGN_TEMPLATES_CANCELLED:
      "LOAD_OTHER_CAMPAIGN_TEMPLATES_CANCELLED",

    LOAD_CAMPAIGNS_FILTERS_FULFILLED: "LOAD_CAMPAIGNS_FILTERS_FULFILLED",
    LOAD_CAMPAIGNS_FILTERS_REJECTED: "LOAD_CAMPAIGNS_FILTERS_REJECTED",
    LOAD_CAMPAIGNS_FILTERS_CANCELLED: "LOAD_CAMPAIGNS_FILTERS_CANCELLED",

    LOAD_CAMPAIGN_LEADS_FULFILLED: "LOAD_CAMPAIGN_LEADS_FULFILLED",
    LOAD_CAMPAIGN_LEADS_REJECTED: "LOAD_CAMPAIGN_LEADS_REJECTED",
    LOAD_CAMPAIGN_LEADS_CANCELLED: "LOAD_CAMPAIGN_LEADS_CANCELLED",

    EXPORT_CAMPAIGN_LEADS_FULFILLED: "EXPORT_CAMPAIGN_LEADS_FULFILLED",
    EXPORT_CAMPAIGN_LEADS_REJECTED: "EXPORT_CAMPAIGN_LEADS_REJECTED",
    EXPORT_CAMPAIGN_LEADS_CANCELLED: "EXPORT_CAMPAIGN_LEADS_CANCELLED",

    LOAD_CAMPAIGN_STATS_FULFILLED: "LOAD_CAMPAIGN_STATS_FULFILLED",
    LOAD_CAMPAIGN_STATS_REJECTED: "LOAD_CAMPAIGN_STATS_REJECTED",
    LOAD_CAMPAIGN_STATS_CANCELLED: "LOAD_CAMPAIGN_STATS_CANCELLED",

    LOAD_CAMPAIGN_PROGRESS_FULFILLED: "LOAD_CAMPAIGN_PROGRESS_FULFILLED",
    LOAD_CAMPAIGN_PROGRESS_REJECTED: "LOAD_CAMPAIGN_PROGRESS_REJECTED",
    LOAD_CAMPAIGN_PROGRESS_CANCELLED: "LOAD_CAMPAIGN_PROGRESS_CANCELLED",

    LOAD_CAMPAIGN_ACTIONS_FULFILLED: "LOAD_CAMPAIGN_ACTIONS_FULFILLED",
    LOAD_CAMPAIGN_ACTIONS_REJECTED: "LOAD_CAMPAIGN_ACTIONS_REJECTED",
    LOAD_CAMPAIGN_ACTIONS_CANCELLED: "LOAD_CAMPAIGN_ACTIONS_CANCELLED",
  },

  CAMPAIGNS_FILTERS: "CAMPAIGNS/FILTERS",
  CAMPAIGN_FILTERS: "CAMPAIGN/FILTERS",
  CAMPAIGN_CUSTOM_FILTER: "CAMPAIGN/CUSTOM_FILTER",
  CAMPAIGN_LEAD_FILTER_SEARCH: "CAMPAIGN/LEAD_FILTER_SEARCH",

  CLEAR_LEADS: "CLEAR_LEADS",
  CAMPAIGNS_CLEAR: "CAMPAIGNS_CLEAR",
  CAMPAIGN_LEAD_SIDE_PANEL: "CAMPAIGN_LEAD_SIDE_PANEL",
  // CAMPAIGN_LEADS_PAGINATE: "CAMPAIGN_LEADS_PAGINATE",
  CAMPAIGN_LEADS_PAGINATION: "CAMPAIGN_LEADS_PAGINATION",
  CAMPAIGN_ACTIONS_PAGINATE: "CAMPAIGN_ACTIONS_PAGINATE",
  CAMPAIGN_ACTIONS_CHANNEL_TYPE: "CAMPAIGN_ACTIONS_CHANNEL_TYPE",

  EXPORT_CAMPAIGN_STATS: "EXPORT_CAMPAIGN_STATS",
  EXPORT_CAMPAIGN_STATS_DONE: "EXPORT_CAMPAIGN_STATS_DONE",
  EXPORT_CAMPAIGN_STATS_ERROR: "EXPORT_CAMPAIGN_STATS_ERROR",

  CAMPAIGN_TOOGLE_HUBSPOT: "CAMPAIGN_TOOGLE_HUBSPOT",
  CAMPAIGN_TOOGLE_PIPEDRIVE: "CAMPAIGN_TOOGLE_PIPEDRIVE",

  CHECK_ASSIGNATION: "CHECK_ASSIGNATION",
  RESET_ASSIGNATION: "RESET_ASSIGNATION",
};

import React, { useEffect, useState, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Join, type } from "@lagrowthmachine/lgmwebapp";
import * as actions from "../../actions";
import * as globalActions from "../../../../redux/actions";
import types from "../../types";
import useSelector from "../../../../redux/useSelector";
import analyticsCall from "../../../../helpers/analyticsCall";
import config from "../../../../config";
import { ReCaptcha, loadReCaptcha } from "react-recaptcha-v3";
import * as settingsFunctions from "../../../Settings/functions";

const JoinComponent = (props: any) => {
  let recaptchaRef: any;
  const [captcha, setCaptcha] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const loadingReducer = useSelector((state) => state.loadingReducer);
  const authReducer = useSelector((state) => state.authReducer);
  const socketReducer = useSelector((state) => state.socketReducer);
  const params: any = useParams();

  const loadInvitation = settingsFunctions.loadInvitation(
    dispatch,
    authReducer
  );
  const addMember = settingsFunctions.addMember(dispatch, authReducer);
  const [invitation, setInvitation] = useState(null as any);

  const onRedirectSignIn = () => history.push("/login");
  const onRedirectForgotPassword = () => history.push("/reset-password");
  const onSignUp = async (body: type.SignUpStateT) => {
    const _body: any = {
      firstname: body.firstName,
      lastname: body.lastName,
      password: body.password,
      token: params.invitationToken,
      userId: params.userId,
      accept: body.termsAccepted,
      captcha,
    };
    const _recaptchaRef = recaptchaRef;
    return addMember(_body)
      .then((response) => {
        dispatch(actions.setApiKey({ apikey: response.member.apikey }));
        return true;
      })
      .catch((e) => {
        setCaptcha("");
        _recaptchaRef.execute();
        throw "Error";
      });
  };

  useEffect(() => {
    if (authReducer?.user?.apikey) {
      //loadUser will set authReducer?.user.id
      dispatch(actions.loadUser({}));
    }
  }, [dispatch, authReducer?.user?.apikey]);

  const onLoadInvitation = async ({
    invitationToken,
    userId,
  }: {
    invitationToken: string;
    userId: string;
  }) => {
    return loadInvitation({ invitationToken, userId })
      .then((response) => {
        setInvitation(response);
        return true;
      })
      .catch((e) => {
        setInvitation("");
        throw "Error";
      });
  };

  useEffect(() => {
    const invitationToken = params.invitationToken;
    const userId = params.userId;
    onLoadInvitation({ invitationToken, userId });
  }, [dispatch]);

  useEffect(() => {
    loadReCaptcha(config.recaptchaKey);
    if (!Boolean(authReducer?.user?.id)) {
      analyticsCall("authentication", "authentication: join");
    } else {
      analyticsCall("authentication", "authentication: join success");
      if (!socketReducer.connected) {
        dispatch(globalActions.socketInit());
      }
      history.push("/");
    }

    return () => {
      //remove captcha on page left
      Array.prototype.filter.call(
        document.getElementsByClassName("grecaptcha-badge"),
        function (el: any) {
          el.style.visibility = "hidden";
        }
      );
    };
  }, [authReducer?.user?.id]);

  useEffect(() => {
    Array.prototype.filter.call(
      document.getElementsByClassName("grecaptcha-badge"),
      function (el: any) {
        el.style.visibility = "visible";
      }
    );
  }, [recaptchaRef]);

  const onNotifyTc = () => {
    dispatch(actions.notifyTc({}));
  };

  const verifyCallback = useCallback(
    (response: any) => setCaptcha(response),
    []
  );

  return (
    <div
      className={
        "auth_register_container " + (invitation === null ? "loading" : "")
      }
    >
      {invitation === "" && (
        <style
          key="style"
          dangerouslySetInnerHTML={{
            __html: `
          .form-wrapper {
              pointer-events: none;
              filter: blur(4px);
              }
          `,
          }}
        />
      )}
      <Join
        key={invitation?.invitation?.id || "loading"}
        btnIsLoading={loadingReducer.currentActions.includes("addMember")}
        btnDisabled={
          captcha === "" || loadingReducer.currentActions.includes("addMember")
        }
        onSignUp={onSignUp}
        redirectSignIn={onRedirectSignIn}
        redirectForgotPassword={onRedirectForgotPassword}
        notifyTc={onNotifyTc}
        backUrl="https://lagrowthmachine.com/"
        firstName=""
        lastName=""
        companyName={invitation?.company || ""}
        email={invitation?.invitation?.email || ""}
      />
      <ReCaptcha
        ref={(ref) => (recaptchaRef = ref)}
        sitekey={config.recaptchaKey}
        action="join"
        verifyCallback={verifyCallback}
      />
    </div>
  );
};

export default JoinComponent;

import React from "react";
import ModalWrapper from "../../../../components/ModalWrapper";
import { SwitchBilling } from "@lagrowthmachine/lgmwebapp";
import useSelector from "../../../../redux/useSelector";

function Modal(props: any) {
  const loadingReducer = useSelector((state) => state.loadingReducer);
  return (
    <ModalWrapper>
      <SwitchBilling
        {...props.data}
        identities={props.data.identities.map((_identity: any) => ({
          id: _identity.id,
          name:
            _identity.firstname || _identity.lastname
              ? (_identity.firstname || "") + " " + (_identity.lastname || "")
              : "Identity missing name",
          profilePicture: _identity.profilePicture,
          billingEnd: 1000 * _identity.subscription.currentPeriodEnd,
        }))}
        loading={loadingReducer.btnTypesLoaders.PROCESS_PAYMENT}
      />
    </ModalWrapper>
  );
}

export default Modal;

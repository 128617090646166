// import { createReducer } from "@reduxjs/toolkit";
import { IStateWebhooks } from "../../interfaces/IState";
import types from "../types";

const initialState: IStateWebhooks = {
  list: [],
  assignationWebhook: null,
};

export const webhookReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.RESPONSES_ACTIONS.CREATE_WEBHOOK_FULFILLED:
    case types.RESPONSES_ACTIONS.EDIT_WEBHOOK_FULFILLED:
      return {
        ...state,
        assignationWebhook: {
          ...state.assignationWebhook,
          id: action.payload?.id,
          name: action.payload?.name,
        },
      };
    case types.ASSIGNATION_WEBHOOK:
      return {
        ...state,
        assignationWebhook: {
          brick_id: action.payload?.brick_id,
          webhook_id: action.payload?.webhook_id,
        },
      };
    case types.ASSIGNATION_WEBHOOK_CLEAR:
      return {
        ...state,
        assignationWebhook: null,
      };

    case types.REQUESTS_ACTIONS.CREATE_WEBHOOK:
    case types.REQUESTS_ACTIONS.EDIT_WEBHOOK:
    case types.REQUESTS_ACTIONS.REMOVE_WEBHOOK:
      return {
        ...state,
        error: null,
      };

    case types.REQUESTS_DATA.LOAD_WEBHOOKS:
      return {
        ...state,
        error: null,
        assignationWebhook: null,
      };
    case types.RESPONSES_DATA.LOAD_WEBHOOKS_FULFILLED:
      return {
        ...state,
        error: null,
        list: action.payload,
      };
    case types.RESPONSES_DATA.LOAD_WEBHOOKS_CANCELLED:
      return {
        ...state,
        list: [],
        error: action.payload?.error,
      };
    case types.WEBHOOK_CLEAR:
      return initialState;
    default:
      return state;
  }
};

import React from "react";
import ModalWrapper from "../../../../components/ModalWrapper";
import { AudienceNew } from "@lagrowthmachine/lgmwebapp";
import useSelector from "../../../../redux/useSelector";
import * as globalActions from "../../../../redux/actions";
import globalTypes from "../../../../redux/types";
import { useDispatch } from "react-redux";

function Modal(props: any) {
  const loadingReducer = useSelector((state) => state.loadingReducer);
  const dispatch = useDispatch();

  const onNewAudience = (obj: { name: string; description: string }) => {
    props.onAction("createAudience", { audience: { ...obj, type: "CLASSIC" } });
  };

  const onEditAudience = (obj: {
    id: string;
    name: string;
    description: string;
  }) => {
    props.onAction("editAudience", { audience: { ...obj, type: "CLASSIC" } });
  };

  const onRequestClose = () => {
    dispatch(globalActions.modalUpdate({ name: "reset", data: {} }));
  };

  return (
    <ModalWrapper>
      <AudienceNew
        audience={props.data}
        leadsNumber={0}
        onNewAudience={onNewAudience}
        onEditAudience={onEditAudience}
        preventBack
        onAddToAudience={() => {}}
        audiences={[]}
        action={props.data.id ? "edit" : "new"}
        originalAudiences={[]}
        onCancel={onRequestClose}
        btnSaveDisabled={
          loadingReducer.btnTypesLoaders[
            globalTypes.REQUESTS_ACTIONS.CREATE_AUDIENCE
          ]
        }
        btnSaveIsLoading={
          loadingReducer.btnTypesLoaders[
            globalTypes.REQUESTS_ACTIONS.CREATE_AUDIENCE
          ]
        }
      />
    </ModalWrapper>
  );
}
export default Modal;

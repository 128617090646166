import { ISettingsState } from "../interfaces/IState";
import types from "../types";

const initialState: ISettingsState = {
  error: null,
  loading: false,
  externalLogs: null,
  logsPaginate: {
    current: 0,
    total: 0,
  },
  logsKeywords: "",
  logsShowErrors: false,
  hubspot: {
    mappings: [],
    lifecycle: [],
  },
  pipedrive: {
    mappings: [],
    lifecycle: [],
  },
  invoices: {},
  plans: [],
  cards: [],
  retryPayment: false,
  summary: {},
};

export const settingsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.LOGS_EXTERNAL_UPDATE_KEYWORDS:
      return {
        ...state,
        logsKeywords: action.payload.keywords || "",
        logsPaginate: {
          total: 0,
          current: 0,
        },
      };
    case types.LOGS_EXTERNAL_PAGINATE:
      return {
        ...state,
        logsPaginate: {
          ...state.logsPaginate,
          current: action.payload?.current || 0,
        },
      };
    case types.LOGS_EXTERNAL_SHOW_ERRORS:
      return {
        ...state,
        logsShowErrors: action.payload.logsShowErrors,
        logsPaginate: {
          total: 0,
          current: 0,
        },
      };
    case types.RESPONSES_DATA.LOAD_INVOICES_FULFILLED:
      return {
        ...state,
        invoices: action.payload,
      };
    case types.RESPONSES_DATA.LOAD_SUMMARY_FULFILLED:
      return {
        ...state,
        summary: action.payload.summary,
      };
    case types.RESPONSES_DATA.LOAD_PLANS_FULFILLED:
      return {
        ...state,
        plans: action.payload?.plans,
      };
    case types.RESPONSES_DATA.LOAD_CARDS_FULFILLED:
      return {
        ...state,
        cards: action.payload.cards,
        retryPayment: action.payload.retryPayment,
      };
    case types.RESPONSES_DATA.LOAD_LOGS_EXTERNAL_FULFILLED:
      return {
        ...state,
        externalLogs: action.payload,
        logsPaginate: {
          ...state.logsPaginate,
          total: action.payload.total,
        },
      };
    case types.RESPONSES_DATA.LOAD_HUBSPOT_MAPPINGS_FULFILLED:
      return {
        ...state,
        hubspot: {
          ...state.hubspot,
          mappings: action.payload.properties,
        },
      };
    case types.RESPONSES_DATA.LOAD_HUBSPOT_LIFECYCLES_FULFILLED:
      return {
        ...state,
        hubspot: {
          ...state.hubspot,
          lifecycle: action.payload.lifecycles,
        },
      };
    case types.RESPONSES_DATA.LOAD_PIPEDRIVE_MAPPINGS_FULFILLED:
      return {
        ...state,
        pipedrive: {
          ...state.pipedrive,
          mappings: action.payload.properties,
        },
      };
    case types.RESPONSES_DATA.LOAD_PIPEDRIVE_LIFECYCLES_FULFILLED:
      return {
        ...state,
        pipedrive: {
          ...state.pipedrive,
          lifecycle: action.payload.lifecycles,
        },
      };
    case types.SETTINGS_CLEAR:
      return initialState;
    default:
      return state;
  }
};

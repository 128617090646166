// import { createReducer } from "@reduxjs/toolkit";
import { IModalState } from "../../interfaces/IState";
import types from "../types";

const initialState: IModalState = {
  name: "",
  data: {},
};

export const modalsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.UPDATE_MODAL:
      return {
        ...state,
        name: action.payload.name,
        data: action.payload.data,
      };
    default:
      return state;
  }
};

import React from "react";
import ModalWrapper from "../../../../components/ModalWrapper";
import { PaymentConfirm } from "@lagrowthmachine/lgmwebapp";

function Modal(props: any) {
  return (
    <ModalWrapper>
      <PaymentConfirm onClose={props.onRequestClose} />
    </ModalWrapper>
  );
}

export default Modal;

import React, { useState } from "react";
import ModalWrapper from "../../../../components/ModalWrapper";
import { NewCard, countries } from "@lagrowthmachine/lgmwebapp";
import useSelector from "../../../../redux/useSelector";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import asyncRequest from "../../../../helpers/asyncRequest";
import endpoints from "../../../Identities/endpoints";

import {
  ElementsConsumer,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import config, { APP_ENV } from "../../../../config";

const stripeKey = config.stripeKeys[APP_ENV];
const stripePromise = loadStripe(stripeKey);

function Modal(props: any) {
  //const loadingReducer = useSelector((state) => state.loadingReducer);
  const authReducer = useSelector((state) => state.authReducer);
  const [loader, setLoader] = useState(false);

  const createSetupIntent = async (paymentMethodId: string) => {
    const result = await asyncRequest(endpoints, {
      apiVersion: authReducer?.user?.apiVersion,
      name: "setupIntent",
      body: {
        paymentMethodId,
      },
      query: {
        apikey: authReducer?.user?.apikey,
      },
    });
    return result;
  };

  const onCardAdded = async (paymentMethodId: string, stripe: any) => {
    setLoader(true);
    const result = await createSetupIntent(paymentMethodId); //authorise the newly created card for futur payments
    const { /*setupIntent,*/ error } = await stripe.confirmCardSetup(
      result?.data?.secret,
      { payment_method: paymentMethodId }
    ); //eventually trigger the SCA check
    if (error) {
      alert(error.message || "Unknown error");
      setLoader(false);
      return;
    }
    props.onCardAdded(paymentMethodId);
    setLoader(false);
  };

  return (
    <ModalWrapper>
      <Elements stripe={stripePromise}>
        <ElementsConsumer>
          {({ elements, stripe }: any) => (
            <NewCard
              elements={elements}
              stripe={stripe}
              CardNumberElement={CardNumberElement}
              CardExpiryElement={CardExpiryElement}
              CardCvcElement={CardCvcElement}
              onClose={props.onRequestClose}
              onCardAdded={(paymentMethodId: string) =>
                onCardAdded(paymentMethodId, stripe)
              }
              onCardRefused={(message: string) => alert(message)}
              billingDetails={{
                address: {
                  city: authReducer.user?.billingInfos?.city,
                  country:
                    countries.find(
                      (_country: any) =>
                        _country.label ===
                        authReducer.user?.billingInfos?.country
                    )?.value || null,
                  line1: authReducer.user?.billingInfos?.address,
                  line2: authReducer.user?.billingInfos?.country,
                  postal_code: authReducer.user?.billingInfos?.postalCode,
                  state: authReducer.user?.billingInfos?.state,
                },
                email: authReducer.user?.email,
                name:
                  (authReducer.user?.firstname || "") +
                  " " +
                  (authReducer.user?.lastname || ""),
                phone: null,
              }}
              loading={loader}
            />
          )}
        </ElementsConsumer>
      </Elements>
    </ModalWrapper>
  );
}

export default Modal;

import React from "react";
import ModalWrapper from "../../../../components/ModalWrapper";
import useSelector from "../../../../redux/useSelector";
import { CampaignAudienceNew } from "@lagrowthmachine/lgmwebapp";
import globalTypes from "../../../../redux/types";

function Modal(props: any) {
  const loadingReducer = useSelector((state) => state.loadingReducer);

  const onNewAudience = (obj: { name: string; description: string }) => {
    props.data.createCampaignAudience({
      audience: { ...obj, type: "CLASSIC" },
    });
  };

  return (
    <ModalWrapper>
      <CampaignAudienceNew
        audience={{ name: props.data?.name || "", description: "" }}
        onNewAudience={onNewAudience}
        btnSaveDisabled={
          loadingReducer.btnTypesLoaders[
            globalTypes.REQUESTS_ACTIONS.CREATE_AUDIENCE
          ]
        }
        btnSaveIsLoading={
          loadingReducer.btnTypesLoaders[
            globalTypes.REQUESTS_ACTIONS.CREATE_AUDIENCE
          ]
        }
        onCancel={props.onRequestClose}
      />
    </ModalWrapper>
  );
}
export default Modal;

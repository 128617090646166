import Webhooks from "./Webhooks/";
import Campaign from "./Campaign/";
import Member from "./Member/";
import Api from "./Api/";
import Crm from "./Crm/";
import Widget from "./Widget/";
import Billing from "./Billing/";
import Pricing from "./Pricing/";
import Payment from "./Payment/";
import Downgrade from "./Downgrade/";
import Goodbye from "./Goodbye/";
import User from "./User/";
import Teammates from "./Teammates/";

import "./style.scss";

const Views = {
  Webhooks,
  Campaign,
  Member,
  Api,
  Crm,
  Widget,
  Billing,
  Payment,
  Downgrade,
  Goodbye,
  Pricing,
  User,
  Teammates,
};

export default Views;

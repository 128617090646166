import { encodeQuery } from "../../../helpers/encodeQuery";
import config, { APP_ENV } from "../../../config";
import { IEndpointParams } from "../../../interfaces/IEndpoint";
import getApiUrl from "../../../helpers/getApiUrl";

const defaultHeader = {
  "Content-Type": "application/json",
};
export default function getEndpoint({
  name,
  body,
  query,
  params,
  apiVersion,
}: IEndpointParams) {
  let queryEncoded: any = "";

  if (query) {
    queryEncoded = encodeQuery(query);
  }
  switch (name) {
    case "loadLogs":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.logs
        }external?${queryEncoded}`,
        method: "GET",
        crossDomain: true,
      };
    case "loadLogDetail":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.logs
        }external/${params.id}?${queryEncoded}`,
        method: "GET",
        crossDomain: true,
      };
    case "loadInvoices":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.users
        }invoices?${queryEncoded}`,
        method: "GET",
        crossDomain: true,
      };
    case "loadSummary":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.users
        }summary?${queryEncoded}`,
        method: "GET",
        crossDomain: true,
      };
    case "terminateUser1":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.users
        }terminate?${queryEncoded}`,
        method: "DELETE",
        body,
        crossDomain: true,
      };
    case "deleteId":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${config.endpoints.identities}${
          params.identityId
        }?${queryEncoded}`,
        method: "DELETE",
        //body,
        crossDomain: true,
      };
    case "loadHubspotConnectUri":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.crm
        }oauth/hubspot?${queryEncoded}`,
        method: "GET",
        crossDomain: true,
      };
    case "loadPlans":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.users
        }plans?${queryEncoded}`,
        method: "GET",
        crossDomain: true,
      };
    case "retryLastPayment":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.users
        }retrylastpayment?${queryEncoded}`,
        method: "GET",
        headers: defaultHeader,
      };
    case "getPaymentMethods":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.users
        }paymentmethods?${queryEncoded}`,
        method: "GET",
        headers: defaultHeader,
      };
    case "attachPayment":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.users
        }paymentmethod?${queryEncoded}`,
        method: "POST",
        headers: defaultHeader,
        body,
      };
    case "setDefaultCard":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.users
        }paymentmethods?${queryEncoded}`,
        method: "PUT",
        headers: defaultHeader,
        body,
        crossDomain: true,
      };
    case "deleteCard":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.users
        }paymentmethods?${queryEncoded}`,
        method: "DELETE",
        body,
        crossDomain: true,
      };
    case "loadPrice":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.identities
        }calculate?${queryEncoded}`,
        method: "POST",
        headers: defaultHeader,
        body,
      };
    case "loadDowngrade":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.users
        }downgrade?${queryEncoded}`,
        method: "GET",
        headers: defaultHeader,
      };
    case "loadPipedriveConnectUri":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.crm
        }oauth/pipedrive?${queryEncoded}`,
        method: "GET",
        crossDomain: true,
      };
    case "loadHubspotMappings":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.crm
        }settings/mappings?${queryEncoded}`,
        method: "GET",
        crossDomain: true,
      };
    case "loadHubspotLifecycles":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.crm
        }settings/lifecycle?${queryEncoded}`,
        method: "GET",
        crossDomain: true,
      };
    case "loadPipedriveMappings":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.crm
        }settings/pipedrive/mappings?${queryEncoded}`,
        method: "GET",
        crossDomain: true,
      };
    case "loadPipedriveLifecycles":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.crm
        }settings/pipedrive/lifecycle?${queryEncoded}`,
        method: "GET",
        crossDomain: true,
      };
    case "hubspotConnect":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.crm
        }oauth/hubspot?${queryEncoded}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body,
        crossDomain: true,
      };
    case "pipedriveConnect":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.crm
        }oauth/pipedrive?${queryEncoded}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body,
        crossDomain: true,
      };
    case "hubspotDisconnect":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.crm
        }oauth/hubspot?${queryEncoded}`,
        method: "DELETE",
        crossDomain: true,
      };
    case "pipedriveDisconnect":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.crm
        }oauth/pipedrive?${queryEncoded}`,
        method: "DELETE",
        crossDomain: true,
      };
    case "settingsEdit":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.crm
        }settings?${queryEncoded}`,
        method: "PUT",
        headers: defaultHeader,
        body,
        crossDomain: true,
      };
    case "createInvitation":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.invitations
        }?${queryEncoded}`,
        method: "POST",
        headers: defaultHeader,
        body,
        crossDomain: true,
      };
    case "loadInvitations":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.invitations
        }?${queryEncoded}`,
        method: "GET",
        crossDomain: true,
      };
    case "loadInvitation":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${config.endpoints.invitations}${
          params?.invitationToken
        }/${params?.userId}?${queryEncoded}`,
        method: "GET",
        crossDomain: true,
      };
    case "removeInvitation":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${config.endpoints.invitations}${
          params?.id
        }?${queryEncoded}`,
        method: "DELETE",
        crossDomain: true,
      };
    case "loadMembers":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.members
        }?${queryEncoded}`,
        method: "GET",
        crossDomain: true,
      };
    case "addMember":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.members
        }?${queryEncoded}`,
        method: "POST",
        headers: defaultHeader,
        body,
        crossDomain: true,
      };
    case "removeMember":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${config.endpoints.members}${
          params?.id
        }?${queryEncoded}`,
        method: "DELETE",
        crossDomain: true,
      };
    case "resendLink":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.members
        }resendverifyemail?${queryEncoded}`,
        method: "PUT",
        crossDomain: true,
      };
    case "reassignWidget":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${config.endpoints.members}${
          params?.memberId
        }/${params?.identityId}/reassignwidget?${queryEncoded}`,
        method: "PUT",
        crossDomain: true,
      };
    default:
      return {
        url: ``,
        method: "GET",
        crossDomain: true,
      };
  }
}

import { createAction } from "redux-actions";
import types from "../types";

/*
 * load Campaigns (all details / paginate)
 */

export const loadCampaigns = createAction(
  types.REQUESTS_DATA.LOAD_CAMPAIGNS,
  (payload: any) => payload
);
export const loadCampaignsFulfilled = createAction(
  types.RESPONSES_DATA.LOAD_CAMPAIGNS_FULFILLED,
  (payload: any) => payload
);
export const rejectedLoadCampaigns = createAction(
  types.RESPONSES_DATA.LOAD_CAMPAIGNS_REJECTED,
  (payload: any) => payload
);

/*
 * ask for campaign review
 */

export const campaignReview = createAction(
  types.REQUESTS_ACTIONS.CAMPAIGN_REVIEW,
  (payload: any) => payload
);
export const campaignReviewFulfilled = createAction(
  types.RESPONSES_ACTIONS.CAMPAIGN_REVIEW_FULFILLED,
  (payload: any) => payload
);
export const rejectedCampaignReview = createAction(
  types.RESPONSES_ACTIONS.CAMPAIGN_REVIEW_REJECTED,
  (payload: any) => payload
);

/*
 * load Campaigns name (id + name only)
 */

export const loadCampaignsName = createAction(
  types.REQUESTS_DATA.LOAD_CAMPAIGNS_NAME,
  (payload: any) => payload
);
export const loadCampaignsNameFulfilled = createAction(
  types.RESPONSES_DATA.LOAD_CAMPAIGNS_NAME_FULFILLED,
  (payload: any) => payload
);
export const rejectedLoadCampaignsName = createAction(
  types.RESPONSES_DATA.LOAD_CAMPAIGNS_NAME_REJECTED,
  (payload: any) => payload
);

/*
 * load Campaigns Store
 */

export const loadCampaignsStore = createAction(
  types.REQUESTS_DATA.LOAD_CAMPAIGNS_STORE,
  (payload: any) => payload
);
export const loadCampaignsStoreFulfilled = createAction(
  types.RESPONSES_DATA.LOAD_CAMPAIGNS_STORE_FULFILLED,
  (payload: any) => payload
);
export const rejectedLoadCampaignsStore = createAction(
  types.RESPONSES_DATA.LOAD_CAMPAIGNS_STORE_REJECTED,
  (payload: any) => payload
);

/*
 * load Campaigns Recommended
 */

export const loadCampaignsRecommended = createAction(
  types.REQUESTS_DATA.LOAD_CAMPAIGNS_RECOMMENDED,
  (payload: any) => payload
);
export const loadCampaignsRecommendedFulfilled = createAction(
  types.RESPONSES_DATA.LOAD_CAMPAIGNS_RECOMMENDED_FULFILLED,
  (payload: any) => payload
);
export const rejectedLoadCampaignsRecommended = createAction(
  types.RESPONSES_DATA.LOAD_CAMPAIGNS_RECOMMENDED_REJECTED,
  (payload: any) => payload
);

/*
 * load Campaigns filters
 */

export const loadCampaignsFilters = createAction(
  types.REQUESTS_DATA.LOAD_CAMPAIGNS_FILTERS,
  (payload: any) => payload
);
export const loadCampaignsFiltersFulfilled = createAction(
  types.RESPONSES_DATA.LOAD_CAMPAIGNS_FILTERS_FULFILLED,
  (payload: any) => payload
);
export const rejectedLoadCampaignsFilters = createAction(
  types.RESPONSES_DATA.LOAD_CAMPAIGNS_FILTERS_REJECTED,
  (payload: any) => payload
);

export const saveCampaignsFilters = createAction(
  types.REQUESTS_ACTIONS.SAVE_CAMPAIGNS_FILTERS,
  (payload: any) => payload
);
export const saveCampaignsFiltersFulfilled = createAction(
  types.RESPONSES_ACTIONS.SAVE_CAMPAIGNS_FILTERS_FULFILLED,
  (payload: any) => payload
);
export const rejectedSaveCampaignsFilters = createAction(
  types.RESPONSES_ACTIONS.SAVE_CAMPAIGNS_FILTERS_REJECTED,
  (payload: any) => payload
);

export const filterUpdate = createAction(
  types.CAMPAIGNS_FILTERS,
  (payload: any) => payload
);

/*
 * Create Campaign
 */

export const createCampaign = createAction(
  types.REQUESTS_ACTIONS.CREATE_CAMPAIGN,
  (payload: any) => payload
);
export const createCampaignFulfilled = createAction(
  types.RESPONSES_ACTIONS.CREATE_CAMPAIGN_FULFILLED,
  (payload: any) => payload
);
export const rejectedCreateCampaign = createAction(
  types.RESPONSES_ACTIONS.CREATE_CAMPAIGN_REJECTED,
  (payload: any) => payload
);

/*
 * Duplicate Campaign
 */

export const duplicateCampaign = createAction(
  types.REQUESTS_ACTIONS.DUPLICATE_CAMPAIGN,
  (payload: any) => payload
);
export const duplicateCampaignFulfilled = createAction(
  types.RESPONSES_ACTIONS.DUPLICATE_CAMPAIGN_FULFILLED,
  (payload: any) => payload
);
export const rejectedDuplicateCampaign = createAction(
  types.RESPONSES_ACTIONS.DUPLICATE_CAMPAIGN_REJECTED,
  (payload: any) => payload
);

/*
 * Edit Campaign
 */

export const editCampaign = createAction(
  types.REQUESTS_ACTIONS.EDIT_CAMPAIGN,
  (payload: any) => payload
);
export const editCampaignFulfilled = createAction(
  types.RESPONSES_ACTIONS.EDIT_CAMPAIGN_FULFILLED,
  (payload: any) => payload
);
export const rejectedEditCampaign = createAction(
  types.RESPONSES_ACTIONS.EDIT_CAMPAIGN_REJECTED,
  (payload: any) => payload
);

/**
 * Edit sequence
 */
export const editSequence = createAction(
  types.REQUESTS_ACTIONS.EDIT_SEQUENCE,
  (payload: any) => payload
);
export const editSequenceFulfilled = createAction(
  types.RESPONSES_ACTIONS.EDIT_SEQUENCE_FULFILLED,
  (payload: any) => payload
);
export const rejectedEditSequence = createAction(
  types.RESPONSES_ACTIONS.EDIT_SEQUENCE_REJECTED,
  (payload: any) => payload
);
export const cancelledEditequence = createAction(
  types.RESPONSES_ACTIONS.EDIT_SEQUENCE_CANCELLED,
  (payload: any) => payload
);

/**
 * Load sequence
 */
export const loadSequence = createAction(
  types.REQUESTS_DATA.LOAD_SEQUENCE,
  (payload: any) => payload
);
export const loadSequenceFulfilled = createAction(
  types.RESPONSES_DATA.LOAD_SEQUENCE_FULFILLED,
  (payload: any) => payload
);
export const rejectedLoadSequence = createAction(
  types.RESPONSES_DATA.LOAD_SEQUENCE_REJECTED,
  (payload: any) => payload
);
export const cancelledLoadSequence = createAction(
  types.RESPONSES_DATA.LOAD_SEQUENCE_CANCELLED,
  (payload: any) => payload
);

/*
 * Edit Campaign State
 */

export const editCampaignState = createAction(
  types.REQUESTS_ACTIONS.EDIT_CAMPAIGN_STATE,
  (payload: any) => payload
);
export const editCampaignStateFulfilled = createAction(
  types.RESPONSES_ACTIONS.EDIT_CAMPAIGN_STATE_FULFILLED,
  (payload: any) => payload
);
export const rejectedEditCampaignState = createAction(
  types.RESPONSES_ACTIONS.EDIT_CAMPAIGN_STATE_REJECTED,
  (payload: any) => payload
);

/**
 * Load 1 template
 */
export const loadMessage = createAction(
  types.REQUESTS_DATA.LOAD_MESSAGE,
  (payload: any) => payload
);
export const loadMessageFulfilled = createAction(
  types.RESPONSES_DATA.LOAD_MESSAGE_FULFILLED,
  (payload: any) => payload
);
export const rejectedLoadMessage = createAction(
  types.RESPONSES_DATA.LOAD_MESSAGE_REJECTED,
  (payload: any) => payload
);
export const cancelledLoadMessage = createAction(
  types.RESPONSES_DATA.LOAD_MESSAGE_CANCELLED,
  (payload: any) => payload
);

/**
 * Create campaign template
 */
export const createCampaignTemplate = createAction(
  types.REQUESTS_ACTIONS.CREATE_CAMPAIGN_TEMPLATE,
  (payload: any) => payload
);
export const createCampaignTemplateFulfilled = createAction(
  types.RESPONSES_ACTIONS.CREATE_CAMPAIGN_TEMPLATE_FULFILLED,
  (payload: any) => payload
);
export const rejectedCreateCampaignTemplate = createAction(
  types.RESPONSES_ACTIONS.CREATE_CAMPAIGN_TEMPLATE_REJECTED,
  (payload: any) => payload
);
export const cancelledCreateCampaignTemplate = createAction(
  types.RESPONSES_ACTIONS.CREATE_CAMPAIGN_TEMPLATE_CANCELLED,
  (payload: any) => payload
);

/*
 * editLeadsQueueStatus
 */

export const editLeadsQueueStatus = createAction(
  types.REQUESTS_ACTIONS.EDIT_LEADS_QUEUE_STATUS,
  (payload: any) => payload
);
export const editLeadsQueueStatusFulfilled = createAction(
  types.RESPONSES_ACTIONS.EDIT_LEADS_QUEUE_STATUS_FULFILLED,
  (payload: any) => payload
);
export const rejectedEditLeadsQueueStatus = createAction(
  types.RESPONSES_ACTIONS.EDIT_LEADS_QUEUE_STATUS_REJECTED,
  (payload: any) => payload
);

/**
 * Load campaign
 */
export const loadCampaign = createAction(
  types.REQUESTS_DATA.LOAD_CAMPAIGN,
  (payload: any) => payload
);
export const loadCampaignFulfilled = createAction(
  types.RESPONSES_DATA.LOAD_CAMPAIGN_FULFILLED,
  (payload: any) => payload
);
export const rejectedLoadCampaign = createAction(
  types.RESPONSES_DATA.LOAD_CAMPAIGN_REJECTED,
  (payload: any) => payload
);

/**
 * Load campaign leads
 */
export const loadCampaignLeads = createAction(
  types.REQUESTS_DATA.LOAD_CAMPAIGN_LEADS,
  (payload: any) => payload
);
export const loadCampaignLeadsFulfilled = createAction(
  types.RESPONSES_DATA.LOAD_CAMPAIGN_LEADS_FULFILLED,
  (payload: any) => payload
);
export const rejectedLoadCampaignLeads = createAction(
  types.RESPONSES_DATA.LOAD_CAMPAIGN_LEADS_REJECTED,
  (payload: any) => payload
);
/**
 * Export campaign leads
 */
export const exportCampaignLeads = createAction(
  types.REQUESTS_DATA.EXPORT_CAMPAIGN_LEADS,
  (payload: any) => payload
);
export const exportCampaignLeadsFulfilled = createAction(
  types.RESPONSES_DATA.EXPORT_CAMPAIGN_LEADS_FULFILLED
);
export const rejectedExportCampaignLeads = createAction(
  types.RESPONSES_DATA.EXPORT_CAMPAIGN_LEADS_REJECTED,
  (payload: any) => payload
);
/**
 * Load campaign stats
 */
export const loadCampaignStats = createAction(
  types.REQUESTS_DATA.LOAD_CAMPAIGN_STATS,
  (payload: any) => payload
);
export const loadCampaignStatsFulfilled = createAction(
  types.RESPONSES_DATA.LOAD_CAMPAIGN_STATS_FULFILLED,
  (payload: any) => payload
);
export const rejectedLoadCampaignStats = createAction(
  types.RESPONSES_DATA.LOAD_CAMPAIGN_STATS_REJECTED,
  (payload: any) => payload
);

/**
 * Export campaign stats
 */
export const exportCampaignStats = createAction(
  types.EXPORT_CAMPAIGN_STATS,
  (payload: any) => payload
);
export const exportCampaignStatsFulfilled = createAction(
  types.EXPORT_CAMPAIGN_STATS_DONE,
  (payload: any) => payload
);
export const rejectedExportCampaignStats = createAction(
  types.EXPORT_CAMPAIGN_STATS_ERROR,
  (payload: any) => payload
);

/**
 * Load campaign templates
 */
export const loadCampaignTemplates = createAction(
  types.REQUESTS_DATA.LOAD_CAMPAIGN_TEMPLATES,
  (payload: any) => payload
);
export const loadCampaignTemplatesFulfilled = createAction(
  types.RESPONSES_DATA.LOAD_CAMPAIGN_TEMPLATES_FULFILLED,
  (payload: any) => payload
);
export const rejectedLoadCampaignTemplates = createAction(
  types.RESPONSES_DATA.LOAD_CAMPAIGN_TEMPLATES_REJECTED,
  (payload: any) => payload
);

/**
 * Load other campaign templates
 */
export const loadOtherCampaignTemplates = createAction(
  types.REQUESTS_DATA.LOAD_OTHER_CAMPAIGN_TEMPLATES,
  (payload: any) => payload
);
export const loadOtherCampaignTemplatesFulfilled = createAction(
  types.RESPONSES_DATA.LOAD_OTHER_CAMPAIGN_TEMPLATES_FULFILLED,
  (payload: any) => payload
);
export const rejectedLoadOtherCampaignTemplates = createAction(
  types.RESPONSES_DATA.LOAD_OTHER_CAMPAIGN_TEMPLATES_REJECTED,
  (payload: any) => payload
);

/**
 * Edit template
 */
export const editCampaignTemplate = createAction(
  types.REQUESTS_ACTIONS.EDIT_CAMPAIGN_TEMPLATE,
  (payload: any) => payload
);
export const editCampaignTemplateFulfilled = createAction(
  types.RESPONSES_ACTIONS.EDIT_CAMPAIGN_TEMPLATE_FULFILLED,
  (payload: any) => payload
);
export const rejectedEditCampaignTemplate = createAction(
  types.RESPONSES_ACTIONS.EDIT_CAMPAIGN_TEMPLATE_REJECTED,
  (payload: any) => payload
);
export const cancelledEditCampaignTemplate = createAction(
  types.RESPONSES_ACTIONS.EDIT_CAMPAIGN_TEMPLATE_CANCELLED,
  (payload: any) => payload
);

/**
 * Duplicate message
 */
export const duplicateMessageTemplate = createAction(
  types.REQUESTS_ACTIONS.DUPLICATE_MESSAGE_TEMPLATE,
  (payload: any) => payload
);
export const duplicateMessageTemplateFulfilled = createAction(
  types.RESPONSES_ACTIONS.DUPLICATE_MESSAGE_TEMPLATE_FULFILLED,
  (payload: any) => payload
);
export const rejectedDuplicateMessageTemplate = createAction(
  types.RESPONSES_ACTIONS.DUPLICATE_MESSAGE_TEMPLATE_REJECTED,
  (payload: any) => payload
);
export const cancelledDuplicateMessageTemplate = createAction(
  types.RESPONSES_ACTIONS.DUPLICATE_MESSAGE_TEMPLATE_CANCELLED,
  (payload: any) => payload
);

/**
 * Load campaign actions
 */
export const loadCampaignActions = createAction(
  types.REQUESTS_DATA.LOAD_CAMPAIGN_ACTIONS,
  (payload: any) => payload
);
export const loadCampaignActionsFulfilled = createAction(
  types.RESPONSES_DATA.LOAD_CAMPAIGN_ACTIONS_FULFILLED,
  (payload: any) => payload
);

export const rejectedLoadCampaignActions = createAction(
  types.RESPONSES_DATA.LOAD_CAMPAIGN_ACTIONS_REJECTED,
  (payload: any) => payload
);

export const cancelledLoadCampaignActions = createAction(
  types.RESPONSES_DATA.LOAD_CAMPAIGN_ACTIONS_CANCELLED
);

/**
 * Clear leads
 */
export const clearLeads = () => ({
  type: types.CLEAR_LEADS,
  payload: {},
});

/**
 * Update Campaign filters
 */
export const updateCampaignFilters = createAction(
  types.CAMPAIGN_FILTERS,
  (payload: any) => payload
);
export const updateCampaignLeadsPagination = createAction(
  types.CAMPAIGN_LEADS_PAGINATION,
  (payload: any) => payload
);

export const createCustomFilter = createAction(
  types.CAMPAIGN_CUSTOM_FILTER,
  (payload: any) => payload
);
export const leadFilterSearch = createAction(
  types.CAMPAIGN_LEAD_FILTER_SEARCH,
  (payload: any) => payload
);

export const clear = createAction(types.CAMPAIGNS_CLEAR);

// export const paginateLeadsUpdate = createAction(
//   types.CAMPAIGN_LEADS_PAGINATE,
//   (payload: any) => payload
// );

/**
 * Paginate Actions
 */
export const paginateActionsUpdate = createAction(
  types.CAMPAIGN_ACTIONS_PAGINATE,
  (payload: any) => payload
);

export const channelTypeUpdate = createAction(
  types.CAMPAIGN_ACTIONS_CHANNEL_TYPE,
  (payload: any) => payload
);

export const setHubspotToogleActions = createAction(
  types.CAMPAIGN_TOOGLE_HUBSPOT,
  (payload: any) => payload
);

export const setPipedriveToogleActions = createAction(
  types.CAMPAIGN_TOOGLE_PIPEDRIVE,
  (payload: any) => payload
);

/*
Update Campaign Progress 
*/

export const loadCampaignProgress = createAction(
  types.REQUESTS_DATA.LOAD_CAMPAIGN_PROGRESS,
  (payload: any) => payload
);
export const loadCampaignProgressFulfilled = createAction(
  types.RESPONSES_DATA.LOAD_CAMPAIGN_PROGRESS_FULFILLED,
  (payload: any) => payload
);
export const rejectedLoadCampaignProgress = createAction(
  types.RESPONSES_DATA.LOAD_CAMPAIGN_PROGRESS_REJECTED,
  (payload: any) => payload
);

/*
Handle node assignation
*/

export const checkAssignation = createAction(
  types.CHECK_ASSIGNATION,
  (payload: any) => payload
);
export const resetAssignation = createAction(types.RESET_ASSIGNATION);

/*
Confirmation delete + duplicate
*/

// import { createReducer } from "@reduxjs/toolkit";
import { IStateNotifications } from "../../interfaces/IState";
import types from "../types";
import config from "../../config";
import constants from "../../config/constants";

const initialState: IStateNotifications = {
  basic: [],
  big: [],
  cta: [],
};

export const notificationReducer = (state = initialState, action: any) => {
  /**
   * Notifications
   */
  switch (action.type) {
    case types.SET_NOTIFICATION_PERSIST:
      if (
        constants.preventNotificationOnPages.includes(window.location.pathname)
      ) {
        return { ...state };
      }
      const notifToUpdate = state.cta.find(
        (cta: any) => cta.id === action.payload.id
      );
      let notificationsCTA = state.cta;
      // .map((cta: any, key: number) => {
      //   if (key === 0 && state.cta > 10) {
      //     return null;
      //   }
      //   return cta;
      // })
      // .filter((cta: any) => !!cta);

      if (!notifToUpdate && action.payload.notification.canHide) {
        return state;
      }
      if (notifToUpdate) {
        notificationsCTA = notificationsCTA.map((cta: any) => {
          if (cta.id === action.payload.id) {
            return { id: action.payload.id, ...action.payload.notification };
          }
          return cta;
        });
      } else {
        notificationsCTA.push({
          id: action.payload.id,
          ...action.payload.notification,
        });
      }

      if (notificationsCTA.length === config.maxNotifications) {
        notificationsCTA.shift();
      }

      return {
        ...state,
        cta: notificationsCTA,
      };

    case types.SET_NOTIFICATION:
      if (
        constants.preventNotificationOnPages.includes(window.location.pathname)
      ) {
        return { ...state };
      }
      const notifications = state[action.payload.format];

      if (notifications.length === config.maxNotifications) {
        notifications.shift();
      }
      return {
        ...state,
        [action.payload.format]: [
          ...notifications,
          { id: action.payload.id, ...action.payload.notification },
        ],
      };
    case types.REMOVE_NOTIFICATION:
      const newArray = state[action.payload.format]
        .map((e: any) => {
          if (e.id !== action.payload.id) return e;
          return false;
        })
        .filter(Boolean);
      return {
        ...state,
        [action.payload.format]: newArray,
      };
    case types.NOTIFICATION_CLEAR:
      return {
        basic: [],
        big: [],
        cta: [],
      };
    default:
      return state;
  }
};

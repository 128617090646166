import React, { useEffect } from "react";
import { SignIn2 } from "@lagrowthmachine/lgmwebapp";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import useSelector from "../../../../redux/useSelector";
import * as actions from "../../actions";
import types from "../../types";
import analyticsCall from "../../../../helpers/analyticsCall";

const LoginComponent = (props: any) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const loadingReducer = useSelector((state) => state.loadingReducer);
  const authReducer = useSelector((state) => state.authReducer);

  useEffect(() => {
    if (authReducer?.user !== null) {
      history.push("/campaigns");
    }
  }, [authReducer, history]);

  useEffect(() => {
    analyticsCall("authentication", "authentication: login");
  }, []);

  const onRedirectSignUp = () => history.push("/register");
  const onRedirectForgotPassword = () => history.push("/reset-password");
  const onSignIn = async (values: { password: string; email: string }) => {
    dispatch(
      actions.loginUser({
        redirect: `${location.search}`,
        query: {},
        body: {
          ...values,
          // captcha: captcha
        },
      })
    );
    // recaptchaRef.execute();
  };

  const onNotifyTc = () => {
    dispatch(actions.notifyTc({}));
  };

  return (
    <div className="auth_login_container">
      <SignIn2
        onSignIn={onSignIn}
        redirectSignUp={onRedirectSignUp}
        redirectForgotPassword={onRedirectForgotPassword}
        btnDisabled={
          loadingReducer.btnTypesLoaders[types.REQUESTS_ACTIONS.LOGIN_USER]
        }
        btnIsLoading={
          loadingReducer.btnTypesLoaders[types.REQUESTS_ACTIONS.LOGIN_USER]
        }
        toForgotPassword={"/reset-password"}
        linkC={Link}
        notifyTc={onNotifyTc}
        backUrl="https://lagrowthmachine.com/"
      />
    </div>
  );
};

export default LoginComponent;

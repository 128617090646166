import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Error404, Error500 } from "@lagrowthmachine/lgmwebapp";
import analyticsCall from "../../helpers/analyticsCall";

export function ErrorPage404(props: any) {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    analyticsCall("404", "error: 404 on " + location.pathname);
  }, [location.pathname]);

  return (
    <Error404
      onRedirectHome={() => history.push("/")}
      onRequestAccess={() => history.push("/register")}
      blogUrl="https://lagrowthmachine.com/blog"
    />
  );
}

export function ErrorPage500(props: any) {
  useEffect(() => {
    analyticsCall("500", "error: 500 on " + document.URL);
  }, []);

  const history = useHistory();

  return (
    <Error500
      onRedirectHome={() => history.push("/")}
      onRequestAccess={() => history.push("/register")}
      blogUrl="https://lagrowthmachine.com/blog"
    />
  );
}

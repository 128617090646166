import asyncRequest from "./asyncRequest";
import generateRandomString from "../Containers/Settings/helpers/generateRandomAvatar";
import IEndpoint from "../interfaces/IEndpoint";

export default async function (
  file: any,
  apikey: string,
  endpointsAuth: IEndpoint,
  bucket: string = "attached-images-users",
  fileName: string = `${generateRandomString(30)}.jpeg`,
  acl: string = "public-read",
  apiVersion: string | number
) {
  let realBucket = bucket;

  if (realBucket === "attached-images-users" && apiVersion === 2) {
    realBucket = "lgm-v2-attached-images-users";
  }

  const getUrl = await asyncRequest(endpointsAuth, {
    apiVersion,
    name: "getUploadUrl",
    query: {
      apikey,
    },
    body: {
      bucket,
      acl,
      fileName,
    },
  });

  if (getUrl?.data?.url) {
    await asyncRequest(endpointsAuth, {
      apiVersion,
      name: "uploadFile",
      query: {
        apikey,
        url: getUrl?.data?.url,
      },
      body: file,
    });
    return getUrl?.data?.futurUrl;
  }
  return "test";
}

import React, { useState } from "react";
import ModalWrapper from "../../../../components/ModalWrapper";
import useSelector from "../../../../redux/useSelector";
import { ModalBasic, Checkbox } from "@lagrowthmachine/lgmwebapp";
import { useDispatch } from "react-redux";
import * as actions from "../../actions";
import * as leadsActions from "../../../Leads/actions";
import types from "../../types";
import leadsTypes from "../../../Leads/types";

function Modal(props: any) {
  const dispatch = useDispatch();
  const loadingReducer = useSelector((state) => state.loadingReducer);
  const { data } = props;
  const [convert, setConvert] = useState(false);

  const onAction = () => {
    switch (data.status) {
      case "resume":
        dispatch(
          leadsActions.editLeads({
            body: {
              actionName: "resumeLeadsStatusInCampaign",
              status: data.status,
              leadIds: data.selectedLeads,
              campaignId: data.campaignId,
              filters: {
                audiences: [data.audienceId],
              },
            },
          })
        );
        break;
      case "pause":
        dispatch(
          leadsActions.editLeads({
            body: {
              actionName: "pauseLeadsStatusInCampaign",
              status: data.status,
              leadIds: data.selectedLeads,
              campaignId: data.campaignId,
              filters: {
                audiences: [data.audienceId],
              },
            },
          })
        );
        break;
      case "stop":
        dispatch(
          actions.editLeadsQueueStatus({
            body: {
              leadsId: data.selectedLeads,
              campaignId: data.campaignId,
              convert: convert,
            },
            params: {
              status: "stop",
            },
          })
        );
        break;
      default:
        break;
    }
  };

  return (
    <ModalWrapper>
      <ModalBasic
        type="warning"
        onAction={onAction}
        onCancel={props.onRequestClose}
        subtitle=""
        title={`Confirm updating ${data.selectedLeads?.length} ${
          data.selectedLeads?.length > 1 ? "leads" : "lead"
        }`}
        cancelTextBtn="Cancel"
        actionTextBtn="Confirm"
        btnIsLoading={
          loadingReducer.btnTypesLoaders[
            types.REQUESTS_ACTIONS.EDIT_LEADS_QUEUE_STATUS
          ] ||
          loadingReducer.btnTypesLoaders[leadsTypes.REQUESTS_ACTIONS.EDIT_LEADS]
        }
        btnDisabled={
          loadingReducer.btnTypesLoaders[
            types.REQUESTS_ACTIONS.EDIT_LEADS_QUEUE_STATUS
          ] ||
          loadingReducer.btnTypesLoaders[leadsTypes.REQUESTS_ACTIONS.EDIT_LEADS]
        }
      >
        {`Do you really want to ${data.status} ${
          data.selectedLeads?.length > 1 ? "these leads" : "this lead"
        } from this campaign ?`}
        {data.status === "stop" && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              margin: "8px 0 0 0",
            }}
          >
            <Checkbox
              checked={convert}
              disabled={false}
              check={() => setConvert(!convert)}
            />
            <span>
              &nbsp;&nbsp;
              {`Mark ${
                data.selectedLeads?.length > 1 ? "these leads" : "this lead"
              } as Converted  ?`}
            </span>
          </div>
        )}
      </ModalBasic>
    </ModalWrapper>
  );
}
export default Modal;

import React, { useState, useEffect } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import {
  IdentityNewBar,
  IdentityActionsBar,
  IdentityLimits,
  IdentityWH,
  IdentityLimitsLoading,
  IdentityWHLoading,
} from "@lagrowthmachine/lgmwebapp";
import { listTimeZones } from "timezone-support";
import { useDispatch } from "react-redux";
import * as actions from "../../actions";
import * as globalActions from "../../../../redux/actions";
import * as authActions from "../../../Auth/actions";
import types from "../../types";
import useSelector from "../../../../redux/useSelector";
import limitDetails, { getCustomLimit } from "../../data/limitDetails";
import Modal, { types as modalTypes } from "../Modals";
import analyticsCall from "../../../../helpers/analyticsCall";

const IdentitiesComponent = (props: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const authReducer = useSelector((state) => state.authReducer);
  const identitiesReducer = useSelector((state) => state.identitiesReducer);
  const modalsReducer = useSelector((state) => state.modalsReducer);
  const loadingReducer = useSelector((state) => state.loadingReducer);
  const socketReducer = useSelector((state) => state.socketReducer);

  const params: any = useParams();
  const urlIdentityId = params.id;

  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    setFirstLoad(false);
  }, []);

  useEffect(() => {
    dispatch(authActions.loadUser({}));
    dispatch(globalActions.scrollToTop());
  }, [dispatch]);

  useEffect(() => {
    analyticsCall("identities", "identities limits, id: " + urlIdentityId);
  }, [urlIdentityId]);

  useEffect(() => {
    dispatch(actions.loadIdentities({ query: {} }));
  }, [dispatch, urlIdentityId]);

  const identity = identitiesReducer.list.find(
    (identity: { id: string }) => identity.id === urlIdentityId
  );

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(globalActions.socketIsWidgetAvailable());
    }, 4000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [dispatch, socketReducer.widgetAvailable]);

  const handleSave = (obj: { id: string; state: any }) => {
    let myData = { ...identity, ...obj.state };
    myData.timezone = myData.timezone || myData.parameters?.timezone;
    dispatch(actions.editIdentity({ body: myData, params: { id: obj.id } }));
  };

  const handleModal = ({ name, data }: { name: string; data: any }) => {
    dispatch(globalActions.modalUpdate({ name, data }));
  };

  const loading =
    loadingReducer.btnTypesLoaders.EDIT_IDENTITY ||
    loadingReducer.dataTypesLoaders[types.REQUESTS_DATA.LOAD_IDENTITIES] ||
    firstLoad;
  return (
    <div className="identities_limits_container">
      <IdentityNewBar
        identities={identitiesReducer.list.map((identity: any) => {
          let label = identity.firstname || "";
          label = " " + (label + (identity.lastname || ""));
          if (label.trim() === "") {
            label = "Identity missing name";
          }
          return {
            value: identity.id,
            label: label,
            picture: identity.profilePicture,
            error: (Object.values(identity.reconnect) as boolean[]).every(
              (currentValue) => currentValue === false
            )
              ? null
              : "reconnect",
          };
        })}
        subPage="details"
        rightBtn={authReducer.user?.plan === "TRIAL" ? "upgrade" : "newId"}
        onUpgrade={() => history.push("/settings/pricing")}
        identityId={urlIdentityId}
        onSelectIdentity={(page, identityId) =>
          history.push("/identities/" + identityId + "/limits")
        }
        identityLoading={loading}
        goToIdentitiesHome={() => history.push("/identities")}
        onNewIdentity={() => history.push("/settings/payment/addid")}
      />
      <IdentityActionsBar
        linkC={Link}
        identityId={urlIdentityId}
        page="limits"
      />

      <Modal
        name={modalsReducer.name}
        data={modalsReducer.data}
        onRequestClose={() => handleModal({ name: modalTypes.reset, data: {} })}
        // createCustom={() => setModal("newAudienceCreate")}
      />
      <div className="identity_details">
        {!loading ? (
          identity && (
            <IdentityWH
              id={identity.id}
              timezone={identity.parameters?.timezone}
              timezones={listTimeZones()
                .filter((tmz) => {
                  return tmz.indexOf("/") === -1 ||
                    tmz.indexOf("GMT") !== -1 ||
                    tmz.indexOf("Etc") !== -1
                    ? false
                    : tmz;
                })
                .map((tmz) => ({ value: tmz, label: tmz }))}
              workingHours={identity.parameters.workingDays}
              workingHoursStart={0} //15*30min = 450 / 60 = 7h30 du matin
              workingHoursEnd={47} //35*30min = 1050 / 60 = 17h30
              save={handleSave}
              loading={loading}
            />
          )
        ) : (
          <IdentityWHLoading />
        )}
        {!loading ? (
          identity && (
            <IdentityLimits
              key={identity.parameters?.timezone}
              id={identity.id}
              limitsDetails={{
                ...limitDetails,
                ...getCustomLimit(
                  identity?.emailData?.tokens?.email || null,
                  identity?.linkedinData?.type || null
                ),
              }}
              userLimits={{
                twitterDMPerDay: identity.parameters.twitterDMPerDay,
                twitterFavouritePerDay:
                  identity.parameters.twitterFavouritePerDay,
                twitterRetweetPerDay: identity.parameters.twitterRetweetPerDay,
                twitterTweetPerDay: identity.parameters.twitterTweetPerDay,
                twitterFollowPerDay: identity.parameters.twitterFollowPerDay,
                twitterUnfollowPerDay:
                  identity.parameters.twitterUnfollowPerDay,
                emailSendEmailPerDay: identity.parameters.emailSendEmailPerDay,
                linkedinContactPerDay:
                  identity.parameters.linkedinContactPerDay,
                linkedinMessagePerDay:
                  identity.parameters.linkedinMessagePerDay,
                linkedinVisitPerDay: identity.parameters.linkedinVisitPerDay,
              }}
              save={handleSave}
              loading={loading}
            />
          )
        ) : (
          <IdentityLimitsLoading />
        )}
      </div>
    </div>
  );
};

export default IdentitiesComponent;

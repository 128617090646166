import { v4 as uuidv4 } from "uuid";
import { actionsList } from "@lagrowthmachine/lgmwebapp";

export const arrayOf = (array: any[], type: string) => {
  if (type === "IN") {
    return array.map((item) => adapterIN(item));
  }
  if (type === "OUT") {
    return array.map((item) => adapterOUT(item));
  }
};

export const adapterOUT = (sequence: any) => {
  const obj = {
    ...sequence,
    language: sequence.language === "en" ? "english" : "french",
  };

  if (obj.id) delete obj.id;
  if (obj.description === "") delete obj.description;
  return obj;
};

export const adapterEditOUT = (sequence: any) => {
  const obj = {
    ...sequence,
    language: sequence.language === "en" ? "english" : "french",
  };

  if (obj.description === "") obj.description = null;
  if (obj.id) delete obj.id;
  return obj;
};

export const adapterIN = (sequence: any) => {
  const obj = {
    ...sequence,
    title: sequence.name,
    interactions: sequence.interactions?.map(
      (i: { channel: string; content: string }) => {
        if (i.channel === "GOOGLE") {
          return { ...i, channel: "GMAIL" };
        }
        return i;
      }
    ),
    language: sequence.language === "english" ? "en" : "fr",
  };
  delete obj.name;
  //console.log(obj);
  return obj;
};

const opposite = {
  LGM_HAS_NOT_ATTRIBUTE: "LGM_HAS_ATTRIBUTE",
  GOOGLE_NO_REPLY: "GOOGLE_REPLY",
  GOOGLE_HAS_NOT_CLICKED: "GOOGLE_HAS_CLICKED",
  GOOGLE_NOT_OPENED: "GOOGLE_OPENED",
  TWITTER_DONT_FOLLOW_BACK: "TWITTER_FOLLOW_BACK",
  LINKEDIN_HAS_NOT_REPLY: "LINKEDIN_HAS_REPLY",
  LINKEDIN_DENY_REQUEST: "LINKEDIN_ACCEPT_REQUEST",
  LINKEDIN_NO_VISIT_BACK: "LINKEDIN_VISIT_BACK",
  LGM_HAS_ATTRIBUTE: "LGM_HAS_NOT_ATTRIBUTE",
  GOOGLE_REPLY: "GOOGLE_NO_REPLY",
  GOOGLE_HAS_CLICKED: "GOOGLE_HAS_NOT_CLICKED",
  GOOGLE_OPENED: "GOOGLE_NOT_OPENED",
  TWITTER_FOLLOW_BACK: "TWITTER_DONT_FOLLOW_BACK",
  LINKEDIN_HAS_REPLY: "LINKEDIN_HAS_NOT_REPLY",
  LINKEDIN_ACCEPT_REQUEST: "LINKEDIN_DENY_REQUEST",
  LINKEDIN_VISIT_BACK: "LINKEDIN_NO_VISIT_BACK",
};

const v2Bricks = [
  "START",
  "LINKEDIN_VISIT_ENRICH",
  "LINKEDIN_VISIT_PROFILE",
  "LINKEDIN_ADD_CONTACT",
  "LINKEDIN_DIRECT_MESSAGE",
  "GOOGLE_SEND_EMAIL",
  "TWITTER_TWEET",
  "TWITTER_FAVOURITE",
  "TWITTER_RETWEET",
  "TWITTER_FOLLOW",
  "TWITTER_UNFOLLOW",
  "TWITTER_SEND_MESSAGE",
  "LGM_ADD_TO_CAMPAIGN",
  "LGM_ADD_DELAY",
  "LGM_WEBHOOK",
  "LGM_END_CAMPAIGN",
  "LGM_CONVERTED",
];

const v2BricksConditions = [
  "LGM_HAS_ATTRIBUTE",
  "LINKEDIN_ACCEPT_REQUEST",
  "TWITTER_FOLLOW_BACK",
];

const v1BricksConditions = [
  "LGM_HAS_NOT_ATTRIBUTE",
  "LINKEDIN_DENY_REQUEST",
  "TWITTER_DONT_FOLLOW_BACK",
];

const v1Bricks = [
  "LGM_ADD_STATUS",
  "LINKEDIN_VISIT_BACK",
  "LINKEDIN_NO_VISIT_BACK",
  "LINKEDIN_HAS_REPLY",
  "LINKEDIN_HAS_NOT_REPLY",
  "GOOGLE_OPENED",
  "GOOGLE_NOT_OPENED",
  "GOOGLE_HAS_CLICKED",
  "GOOGLE_HAS_NOT_CLICKED",
  "GOOGLE_REPLY",
  "GOOGLE_NO_REPLY",
  "GOOGLE_ON_SENT",
  "GOOGLE_ON_OPEN",
  "GOOGLE_ON_CLICKED",
  "GOOGLE_ON_REPLY",
  "GOOGLE_ON_BOUNCED",
];

const orphanNodesToRemove = [
  //all conditions ?
  "LGM_HAS_NOT_ATTRIBUTE",
  "LINKEDIN_DENY_REQUEST",
  "TWITTER_DONT_FOLLOW_BACK",
  "LGM_HAS_ATTRIBUTE",
  "LINKEDIN_ACCEPT_REQUEST",
  "TWITTER_FOLLOW_BACK",
  "GOOGLE_OPENED",
  "GOOGLE_NOT_OPENED",
  "GOOGLE_HAS_CLICKED",
  "GOOGLE_HAS_NOT_CLICKED",
  "GOOGLE_REPLY",
  "GOOGLE_NO_REPLY",
  "GOOGLE_ON_SENT",
  "GOOGLE_ON_OPEN",
  "GOOGLE_ON_CLICKED",
  "GOOGLE_ON_REPLY",
  "GOOGLE_ON_BOUNCED",
];

export const seqV1ToV2 = (sequenceV1: any) => {
  if (!sequenceV1) {
    return null;
  }
  const createdAt = Date.now();
  let graph = { nodes: {}, arrows: {} };
  Object.keys(sequenceV1.elements).some((_key: string, ii: number) => {
    const basicObj = {
      id: _key,
      position: {
        x: 1.2 * sequenceV1.elements[_key].x,
        y: 1.2 * sequenceV1.elements[_key].y,
      },
      data: {
        kpi: sequenceV1.elements[_key].data?.status,
        trigger: sequenceV1.elements[_key].data?.filter,
        delay: sequenceV1.elements[_key].data?.delay
          ? sequenceV1.elements[_key].data.delay * 24 * 3600 * 1000
          : undefined,
        templateId: sequenceV1.elements[_key].data?.template?.id,
        audienceId: sequenceV1.elements[_key].data?.audience_id,
        attributeId: sequenceV1.elements[_key].data?.attribute,
        webhookId: sequenceV1.elements[_key].data?.webhook?.id,
      },
      createdAt: createdAt + ii,
    };

    if (v2Bricks.includes(sequenceV1.elements[_key].type)) {
      graph.nodes[_key] = {
        ...basicObj,
        type: sequenceV1.elements[_key].type,
      };
    } else if (v2BricksConditions.includes(sequenceV1.elements[_key].type)) {
      graph.nodes[_key] = {
        ...basicObj,
        type: sequenceV1.elements[_key].type,
        testSwitch: true, //va rajouter une condition sur la flèche sortante
        switch: false, //inverse ou pas la condition sur la flèche sortante
      };
    } else if (v1BricksConditions.includes(sequenceV1.elements[_key].type)) {
      graph.nodes[_key] = {
        ...basicObj,
        type: opposite[sequenceV1.elements[_key].type],
        testSwitch: true,
        switch: true,
      };
    } else if (v1Bricks.includes(sequenceV1.elements[_key].type)) {
      graph.nodes[_key] = {
        ...basicObj,
        type: sequenceV1.elements[_key].type,
        oldObj: sequenceV1.elements[_key],
      };
    }
    return null;
  });

  Object.keys(sequenceV1.relations).some((_fromNode: string) => {
    sequenceV1.relations[_fromNode].some((_toNode: string) => {
      const arrowId = uuidv4();
      let condition = "AND_THEN";
      if (graph.nodes[_fromNode].testSwitch) {
        condition = graph.nodes[_fromNode].switch
          ? opposite[graph.nodes[_fromNode].type]
          : graph.nodes[_fromNode].type;
      }
      graph.arrows[arrowId] = {
        id: arrowId,
        conditions: {
          delay: "AND_THEN",
          condition: condition,
          delayValue: 7 * 24 * 3600 * 1000,
        },
        from: _fromNode,
        to: _toNode,
      };
      return null;
    });
    return null;
  });

  Object.values(graph.nodes).some((_node: any) => {
    delete _node.testSwitch;
    delete _node.switch;
    return null;
  });
  return graph;
};

export const seqV2toV1 = (
  sequenceV2: any,
  templates: any[],
  audiences: any[],
  webhooks: any[],
  attributes: any[],
  ending: any,
  customEnding: any
) => {
  let elements = {};
  let relations = {};
  let graphNodes = Object.assign({}, sequenceV2.graph.nodes);
  let graphArrows = Object.assign({}, sequenceV2.graph.arrows);
  //recreate nodes
  Object.keys(graphNodes).some((_nodeId: string) => {
    const _node = graphNodes[_nodeId];
    const template = templates.find(
      (_tmplt: any) => _tmplt.id === _node.data.templateId
    );
    const audience = audiences.find(
      (_aud: any) => _aud.id === _node.data.audienceId
    );
    const webhook = webhooks.find(
      (_wbk: any) => _wbk.id === _node.data.webhookId
    );
    if (_node.type !== "OLD_NODE") {
      //basic nodes
      const hideTemplate =
        _node.type === "LINKEDIN_ADD_CONTACT" && _node.data?.invite !== true;
      elements[_nodeId] = {
        id: _nodeId,
        type: _node.type,
        x: _node.position.x,
        y: _node.position.y,
        valid: _node.valid,
        data: {
          status: _node.data.kpi || undefined,
          template:
            !hideTemplate && template
              ? {
                  id: template.id,
                  name: template?.name || "",
                  //body: template.contentHTML,
                }
              : undefined,
          audience: audience
            ? {
                id: audience.id,
                name: audience?.name || "",
              }
            : undefined,
          webhook: webhook
            ? {
                id: webhook.id,
                name: webhook?.name || "",
                //description: "coucou",//webhook.description,
                //url: "coucou"//webhook.url
              }
            : undefined,
          whs: _node.data?.status || undefined,
          filter: _node.data?.trigger || undefined,
          delay: _node.data?.delay
            ? Math.floor(_node.data?.delay / (24 * 3600 * 1000))
            : undefined,
          attribute: _node.data?.attributeId || undefined,
        },
      };
    } else {
      //old node
      elements[_nodeId] = {
        ..._node.oldObj,
        x: _node.position.x,
        y: _node.position.y,
        data: {
          ..._node.oldObj.data,
          status: _node.data.kpi || undefined,
        },
      };
    }
    //switch nodes/arrows for these 3 types of nodes
    if (
      [
        "TWITTER_FOLLOW_BACK",
        "LINKEDIN_ACCEPT_REQUEST",
        "LGM_HAS_ATTRIBUTE",
      ].includes(_node.type)
    ) {
      const oppositeId = _nodeId + "_opposite_node";
      elements[oppositeId] = Object.assign({}, elements[_nodeId]);
      elements[oppositeId].type = opposite[elements[_nodeId].type];
      elements[oppositeId].id = oppositeId;
      const arrowFrom = Object.values(graphArrows).filter(
        (_arrow: any) => _arrow.to === _nodeId
      );
      arrowFrom.some((_arr: any, _ii: number) => {
        const arrowId = _nodeId + "_opposite_arrow_" + _ii;
        graphArrows[arrowId] = Object.assign({}, _arr);
        graphArrows[arrowId].id = arrowId;
        graphArrows[arrowId].to = oppositeId;
        return null;
      });

      const toNodesYes = Object.values(graphArrows)
        .filter(
          (_arrow: any) =>
            _arrow.from === _nodeId && _arrow.conditions?.delay === _node.type
        )
        .map((_arrow: any) => _arrow.to);
      const toNodesNo = Object.values(graphArrows)
        .filter(
          (_arrow: any) =>
            _arrow.from === _nodeId &&
            _arrow.conditions?.delay === opposite[_node.type]
        )
        .map((_arrow: any) => _arrow.to);
      Object.keys(graphArrows)
        .filter((_arrKey: string) => graphArrows.from === _nodeId)
        .some((_arrKey: string) => {
          delete graphArrows[_arrKey];
          return null;
        });
      toNodesYes.some((toNodeId: string, _ii: number) => {
        const arrowId = _nodeId + "yes_arrow" + _ii;
        graphArrows[arrowId] = {
          id: arrowId,
          from: _nodeId,
          to: toNodeId,
          conditions: { delay: "AND_THEN" },
        };
        return null;
      });
      toNodesNo.some((toNodeId: string, _ii: number) => {
        const arrowId = oppositeId + "no_arrow" + _ii;
        graphArrows[arrowId] = {
          id: arrowId,
          from: oppositeId,
          to: toNodeId,
          conditions: { delay: "AND_THEN" },
        };
        return null;
      });
    }
    return null;
  });

  //recreate arrows
  Object.keys(graphArrows).some((_arrowId: string) => {
    const _arrow = graphArrows[_arrowId];
    if (_arrow.conditions?.delay === "AND_THEN") {
      relations[_arrow.from] = (relations[_arrow.from] || []).concat([
        _arrow.to,
      ]);
    } else if (
      //TWITTER_FOLLOW_BACK => keep loop system
      _arrow.conditions?.delay === "DURING_DELAY" &&
      _arrow.conditions?.condition === "TWITTER_FOLLOW_BACK"
    ) {
      const delayNodeId = _arrowId + "_delay";
      elements[delayNodeId] = {
        id: delayNodeId,
        type: "LGM_ADD_DELAY",
        x: Math.floor(10000 * Math.random()),
        y: Math.floor(10000 * Math.random()),
        valid: true,
        data: {
          delay: 1,
          maxLoop: Math.floor(
            _arrow.conditions?.delayValue / (24 * 3600 * 1000)
          ),
        },
      };
      const conditionNodeId =
        _arrowId + "_condition_" + _arrow.conditions?.condition;
      elements[conditionNodeId] = {
        id: conditionNodeId,
        type: _arrow.conditions?.condition,
        x: Math.floor(10000 * Math.random()),
        y: Math.floor(10000 * Math.random()),
        valid: true,
        data: {},
      };
      const notConditionNodeId =
        _arrowId + "_not_condition_" + opposite[_arrow.conditions?.condition];
      elements[notConditionNodeId] = {
        id: notConditionNodeId,
        type: opposite[_arrow.conditions?.condition],
        x: Math.floor(10000 * Math.random()),
        y: Math.floor(10000 * Math.random()),
        valid: true,
        data: {},
      };

      let arrowsToCreate: any[] = [];
      arrowsToCreate = [
        { from: _arrow.from, to: delayNodeId },
        { from: delayNodeId, to: notConditionNodeId },
        { from: _arrowId + "_not_condition", to: delayNodeId },
        { from: delayNodeId, to: conditionNodeId },
        { from: conditionNodeId, to: _arrow.to },
      ];

      arrowsToCreate.some((_arr: any) => {
        relations[_arr.from] = (relations[_arr.from] || []).concat([_arr.to]);
        return null;
      });
    } else if (
      // !== TWITTER_FOLLOW_BACK so condition ON_XXX. transform into FROM_NODE => ON_XXX (with maxTime and delayType = 'd') => NEXT_NODE
      _arrow.conditions?.delay === "DURING_DELAY" &&
      _arrow.conditions?.condition !== "TWITTER_FOLLOW_BACK"
    ) {
      //_arrow.conditions?.condition
      //.split("-AND-")
      //.some((_cond: string, ii: number) => {
      const conditionNodeId =
        _arrowId + "_condition_" + _arrow.conditions?.condition;
      elements[conditionNodeId] = {
        id: conditionNodeId,
        type: _arrow.conditions?.condition,
        x: Math.floor(10000 * Math.random()),
        y: Math.floor(10000 * Math.random()),
        valid: true,
        data: {
          maxTime: Math.floor(
            _arrow.conditions?.delayValue / (24 * 3600 * 1000)
          ),
          delayType: "d", //'ms' | 's' | 'm' | 'h' | 'd'
        },
      };

      //});
      let arrowsToCreate: any[] = [];
      arrowsToCreate = [
        { from: _arrow.from, to: conditionNodeId },
        { from: conditionNodeId, to: _arrow.to },
      ];
      //}

      arrowsToCreate.some((_arr: any) => {
        relations[_arr.from] = (relations[_arr.from] || []).concat([_arr.to]);
        return null;
      });
    } else if (_arrow.conditions?.delay === "IF_AFTER_DELAY") {
      const delayNodeId = _arrowId + "_delay";
      let arrowsToCreate = [_arrow.from, delayNodeId];
      elements[delayNodeId] = {
        id: delayNodeId,
        type: "LGM_ADD_DELAY",
        x: Math.floor(10000 * Math.random()),
        y: Math.floor(10000 * Math.random()),
        valid: true,
        data: {
          delay: Math.floor(_arrow.conditions?.delayValue / (24 * 3600 * 1000)),
        },
      };

      _arrow.conditions?.condition
        .split("-AND-")
        .some((_cond: string, _ii: number) => {
          const conditionNodeId = _arrowId + "_condition_" + _cond + "_" + _ii;
          arrowsToCreate.push(conditionNodeId);
          elements[conditionNodeId] = {
            id: conditionNodeId,
            type: _cond,
            x: Math.floor(10000 * Math.random()),
            y: Math.floor(10000 * Math.random()),
            valid: true,
            data: {},
          };
        });
      arrowsToCreate.push(_arrow.to);
      for (let i = 0; i < arrowsToCreate.length - 1; i++) {
        relations[arrowsToCreate[i]] = (
          relations[arrowsToCreate[i]] || []
        ).concat([arrowsToCreate[i + 1]]);
      }
    }
    return null;
  });

  //destroy conditions node with no arrow out but with entering arrows
  Object.keys(elements).some((_elt) => {
    //console.log("----");
    if (orphanNodesToRemove.includes(elements[_elt].type)) {
      if (!relations[_elt] || relations[_elt].length === 0) {
        //console.log(elements[_elt].type);
        //console.log(_elt);
        //destroying exit arrow object
        delete relations[_elt];
        //removing entering arrows
        Object.keys(relations).some((_arrFromKey: string) => {
          relations[_arrFromKey] = relations[_arrFromKey].filter(
            (_arrToKey: string) => _arrToKey !== _elt
          );
          return null;
        });
        //destroying node itself
        delete elements[_elt];
      }
    }
    return null;
  });

  //convert custom ending array
  if (ending !== "none") {
    let lgmConvertedId: string;
    let lgmEndId: string;
    if (ending === "custom") {
      lgmConvertedId = "ce_" + customEnding.length;
      lgmEndId = "ce_" + (customEnding.length + 1);
      customEnding.some((elt: any, ii: number) => {
        const eltId = "ce_" + ii;

        let webhook = undefined;
        let audience = undefined;
        let valid = true;
        if (elt.type === "LGM_WEBHOOK") {
          webhook = webhooks.find((_wbk: any) => _wbk.id === elt.id);
          webhook = webhook ? { id: elt.id, name: webhook.name } : undefined;
          valid = Boolean(webhook);
        }

        if (elt.type === "LGM_ADD_TO_CAMPAIGN") {
          audience = audiences.find((_aud: any) => _aud.id === elt.id);
          audience = audience ? { id: elt.id, name: audience.name } : undefined;
          valid = Boolean(audience);
        }

        let toPush = {
          id: eltId,
          type: elt.type,
          x: Math.floor(10000 * Math.random()),
          y: Math.floor(10000 * Math.random()),
          valid: valid,
          data: {
            whs: elt.type === "LGM_WEBHOOK" ? "CUSTOM_ENDING" : undefined,
            audience: audience,
            webhook: webhook,
          },
        };
        elements = { ...elements, [eltId]: toPush };
        return null;
      });
    } else {
      //ending === "auto"
      lgmConvertedId = "ce_0";
      lgmEndId = "ce_1";
    }

    //add goal reached node
    elements = {
      ...elements,
      [lgmConvertedId]: {
        id: lgmConvertedId,
        type: "LGM_CONVERTED",
        x: Math.floor(10000 * Math.random()),
        y: Math.floor(10000 * Math.random()),
        valid: true,
        data: {},
      },
      [lgmEndId]: {
        id: lgmEndId,
        type: "LGM_END_CAMPAIGN",
        x: Math.floor(10000 * Math.random()),
        y: Math.floor(10000 * Math.random()),
        valid: true,
        data: {},
      },
    };

    //add arrows in custom ending
    for (
      let i = 0;
      i < (ending === "custom" ? customEnding.length : 0) + 1;
      i++
    ) {
      relations["ce_" + i] = (relations["ce_" + i] || []).concat([
        "ce_" + (i + 1),
      ]);
    }
  }

  const actions = [
    "LINKEDIN_VISIT_ENRICH",
    "LINKEDIN_VISIT_PROFILE",
    "LINKEDIN_ADD_CONTACT",
    "LINKEDIN_DIRECT_MESSAGE",
    "GOOGLE_SEND_EMAIL",
    "TWITTER_TWEET",
    "TWITTER_FAVOURITE",
    "TWITTER_RETWEET",
    "TWITTER_FOLLOW",
    "TWITTER_UNFOLLOW",
    "TWITTER_SEND_MESSAGE",
    "LGM_ADD_TO_CAMPAIGN",
    "LGM_ADD_DELAY",
    "LGM_WEBHOOK",
    "LGM_CONVERTED",
  ];

  const conditions = [
    "LGM_HAS_ATTRIBUTE",
    "LGM_HAS_NOT_ATTRIBUTE",
    "LGM_ADD_STATUS",
    "LINKEDIN_ON_REPLY",
    "LINKEDIN_ON_VISIT_BACK",
    "LINKEDIN_ON_ACCEPT_REQUEST",
    "LINKEDIN_ACCEPT_REQUEST",
    "LINKEDIN_DENY_REQUEST",
    "LINKEDIN_VISIT_BACK",
    "LINKEDIN_NO_VISIT_BACK",
    "LINKEDIN_HAS_REPLY",
    "LINKEDIN_HAS_NOT_REPLY",
    "TWITTER_FOLLOW_BACK",
    "TWITTER_DONT_FOLLOW_BACK",
    "GOOGLE_OPENED",
    "GOOGLE_NOT_OPENED",
    "GOOGLE_HAS_CLICKED",
    "GOOGLE_HAS_NOT_CLICKED",
    "GOOGLE_REPLY",
    "GOOGLE_NO_REPLY",
    "GOOGLE_ON_SENT",
    "GOOGLE_ON_OPEN",
    "GOOGLE_ON_CLICKED",
    "GOOGLE_ON_REPLY",
    "GOOGLE_ON_BOUNCED",
  ];
  //add kpi status on empty condition brick {{condition.name}} after {{previous block}}
  Object.keys(relations).some((arrowFrom: string) => {
    const _fromAction =
      elements[arrowFrom].data.status ||
      actionsList[elements[arrowFrom].type]?.text ||
      elements[arrowFrom].type;
    relations[arrowFrom].some((arrowTo: string) => {
      if (
        actions.includes(elements[arrowFrom].type) &&
        conditions.includes(elements[arrowTo].type) &&
        !Boolean(elements[arrowTo].data?.status)
      ) {
        const _toCondition =
          elements[arrowTo].data.status ||
          actionsList[elements[arrowTo].type]?.text ||
          elements[arrowTo].type;
        elements[arrowTo].data = {
          ...(elements[arrowTo].data || {}),
          status: _toCondition + " after " + _fromAction,
        };
      }
      return null;
    });
    return null;
  });
  const sequenceV1 = { elements: elements, relations: relations };
  return sequenceV1;
};

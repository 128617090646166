import { createAction } from "redux-actions";
import types from "../types";

/**
 * Load sequences store
 */

export const clear = createAction(
  types.SETTINGS_CLEAR,
  (payload: any) => payload
);

export const openConfirmationDeleteWebhook = (payload: any) => {
  return {
    type: types.OPEN_CONFIRMATION_DELETE_WEBHOOK,
    payload,
  };
};

/*All logs */

export const loadLogs = createAction(
  types.REQUESTS_DATA.LOAD_LOGS_EXTERNAL
  //(payload: any) => payload
);

export const loadLogsFulfilled = createAction(
  types.RESPONSES_DATA.LOAD_LOGS_EXTERNAL_FULFILLED,
  (payload: any) => payload
);
export const rejectedLoadLogs = createAction(
  types.RESPONSES_DATA.LOAD_LOGS_EXTERNAL_REJECTED,
  (payload: any) => payload
);

export const cancelledLoadLogs = createAction(
  types.RESPONSES_DATA.LOAD_LOGS_EXTERNAL_CANCELLED
);

/*One log detail*/
export const loadLogDetail = createAction(
  types.REQUESTS_DATA.LOAD_LOG_EXTERNAL_DETAIL,
  (payload: any) => payload
);

export const loadLogDetailFulfilled = createAction(
  types.RESPONSES_DATA.LOAD_LOG_EXTERNAL_DETAIL_FULFILLED,
  (payload: any) => payload
);
export const rejectedloadLogDetail = createAction(
  types.RESPONSES_DATA.LOAD_LOG_EXTERNAL_DETAIL_REJECTED,
  (payload: any) => payload
);

export const cancelledloadLogDetail = createAction(
  types.RESPONSES_DATA.LOAD_LOG_EXTERNAL_DETAIL_CANCELLED
);

/*Paginate*/
export const logsActionsPaginate = createAction(
  types.LOGS_EXTERNAL_PAGINATE,
  (payload: any) => payload
);
/*Log keyword search*/
export const updateLogsKeywords = createAction(
  types.LOGS_EXTERNAL_UPDATE_KEYWORDS,
  (payload: any) => payload
);
/*only erroneous logs*/
export const logsShowErrors = createAction(
  types.LOGS_EXTERNAL_SHOW_ERRORS,
  (payload: any) => payload
);

/*Hubspot*/
/* get oauth code*/
export const loadHubspotConnectUri = createAction(
  types.REQUESTS_DATA.LOAD_HUBSPOT_CONNECT_URI,
  (payload: any) => payload
);

export const loadHubspotConnectUriFulfilled = createAction(
  types.RESPONSES_DATA.LOAD_HUBSPOT_CONNECT_URI_FULFILLED,
  (payload: any) => payload
);
export const rejectedLoadHubspotConnectUri = createAction(
  types.RESPONSES_DATA.LOAD_HUBSPOT_CONNECT_URI_REJECTED,
  (payload: any) => payload
);

export const cancelledLoadHubspotConnectUri = createAction(
  types.RESPONSES_DATA.LOAD_HUBSPOT_CONNECT_URI_CANCELLED
);

/*Pipedrive*/
/* get oauth code*/
export const loadPipedriveConnectUri = createAction(
  types.REQUESTS_DATA.LOAD_PIPEDRIVE_CONNECT_URI,
  (payload: any) => payload
);

export const loadPipedriveConnectUriFulfilled = createAction(
  types.RESPONSES_DATA.LOAD_PIPEDRIVE_CONNECT_URI_FULFILLED,
  (payload: any) => payload
);
export const rejectedLoadPipedriveConnectUri = createAction(
  types.RESPONSES_DATA.LOAD_PIPEDRIVE_CONNECT_URI_REJECTED,
  (payload: any) => payload
);

export const cancelledLoadPipedriveConnectUri = createAction(
  types.RESPONSES_DATA.LOAD_PIPEDRIVE_CONNECT_URI_CANCELLED
);

/* send oauth code for hubspot login*/
export const hubspotConnect = createAction(
  types.REQUESTS_ACTIONS.HUBSPOT_CONNECT,
  (payload: any) => payload
);

export const hubspotConnectFulfilled = createAction(
  types.RESPONSES_ACTIONS.HUBSPOT_CONNECT_FULFILLED
);
export const rejectedHubspotConnect = createAction(
  types.RESPONSES_ACTIONS.HUBSPOT_CONNECT_REJECTED,
  (payload: any) => payload
);

export const cancelledHubspotConnect = createAction(
  types.RESPONSES_ACTIONS.HUBSPOT_CONNECT_CANCELLED
);

/* send oauth code for pipedrive login*/
export const pipedriveConnect = createAction(
  types.REQUESTS_ACTIONS.PIPEDRIVE_CONNECT,
  (payload: any) => payload
);

export const pipedriveConnectFulfilled = createAction(
  types.RESPONSES_ACTIONS.PIPEDRIVE_CONNECT_FULFILLED
);
export const rejectedPipedriveConnect = createAction(
  types.RESPONSES_ACTIONS.PIPEDRIVE_CONNECT_REJECTED,
  (payload: any) => payload
);

export const cancelledPipedriveConnect = createAction(
  types.RESPONSES_ACTIONS.PIPEDRIVE_CONNECT_CANCELLED
);

/* disconnect hubspot*/
export const hubspotDisconnect = createAction(
  types.REQUESTS_ACTIONS.HUBSPOT_DISCONNECT
);

export const hubspotDisconnectFulfilled = createAction(
  types.RESPONSES_ACTIONS.HUBSPOT_DISCONNECT_FULFILLED
);
export const rejectedHubspotDisconnect = createAction(
  types.RESPONSES_ACTIONS.HUBSPOT_DISCONNECT_REJECTED,
  (payload: any) => payload
);

export const cancelledHubspotDisconnect = createAction(
  types.RESPONSES_ACTIONS.HUBSPOT_DISCONNECT_CANCELLED
);

/* disconnect pipedrive*/
export const pipedriveDisconnect = createAction(
  types.REQUESTS_ACTIONS.PIPEDRIVE_DISCONNECT
);

export const pipedriveDisconnectFulfilled = createAction(
  types.RESPONSES_ACTIONS.PIPEDRIVE_DISCONNECT_FULFILLED
);
export const rejectedPipedriveDisconnect = createAction(
  types.RESPONSES_ACTIONS.PIPEDRIVE_DISCONNECT_REJECTED,
  (payload: any) => payload
);

export const cancelledPipedriveDisconnect = createAction(
  types.RESPONSES_ACTIONS.PIPEDRIVE_DISCONNECT_CANCELLED
);

/* get hubspot mappings*/
export const loadHubspotMappings = createAction(
  types.REQUESTS_DATA.LOAD_HUBSPOT_MAPPINGS
);

export const loadHubspotMappingsFulfilled = createAction(
  types.RESPONSES_DATA.LOAD_HUBSPOT_MAPPINGS_FULFILLED,
  (payload: any) => payload
);
export const rejectedLoadHubspotMappings = createAction(
  types.RESPONSES_DATA.LOAD_HUBSPOT_MAPPINGS_REJECTED,
  (payload: any) => payload
);

export const cancelledLoadHubspotMappings = createAction(
  types.RESPONSES_DATA.LOAD_HUBSPOT_MAPPINGS_CANCELLED
);

/* get pipedrive mappings*/
export const loadPipedriveMappings = createAction(
  types.REQUESTS_DATA.LOAD_PIPEDRIVE_MAPPINGS
);

export const loadPipedriveMappingsFulfilled = createAction(
  types.RESPONSES_DATA.LOAD_PIPEDRIVE_MAPPINGS_FULFILLED,
  (payload: any) => payload
);
export const rejectedLoadPipedriveMappings = createAction(
  types.RESPONSES_DATA.LOAD_PIPEDRIVE_MAPPINGS_REJECTED,
  (payload: any) => payload
);

export const cancelledLoadPipedriveMappings = createAction(
  types.RESPONSES_DATA.LOAD_PIPEDRIVE_MAPPINGS_CANCELLED
);

/* get hubspot licycles*/
export const loadHubspotLifecycles = createAction(
  types.REQUESTS_DATA.LOAD_HUBSPOT_LIFECYCLES
);

export const loadHubspotLifecyclesFulfilled = createAction(
  types.RESPONSES_DATA.LOAD_HUBSPOT_LIFECYCLES_FULFILLED,
  (payload: any) => payload
);
export const rejectedLoadHubspotLifecycles = createAction(
  types.RESPONSES_DATA.LOAD_HUBSPOT_LIFECYCLES_REJECTED,
  (payload: any) => payload
);

export const cancelledLoadHubspotLifecycles = createAction(
  types.RESPONSES_DATA.LOAD_HUBSPOT_LIFECYCLES_CANCELLED
);

/* get pipedrive licycles*/
export const loadPipedriveLifecycles = createAction(
  types.REQUESTS_DATA.LOAD_PIPEDRIVE_LIFECYCLES
);

export const loadPipedriveLifecyclesFulfilled = createAction(
  types.RESPONSES_DATA.LOAD_PIPEDRIVE_LIFECYCLES_FULFILLED,
  (payload: any) => payload
);
export const rejectedLoadPipedriveLifecycles = createAction(
  types.RESPONSES_DATA.LOAD_PIPEDRIVE_LIFECYCLES_REJECTED,
  (payload: any) => payload
);

export const cancelledLoadPipedriveLifecycles = createAction(
  types.RESPONSES_DATA.LOAD_PIPEDRIVE_LIFECYCLES_CANCELLED
);

/* edit mapping hubspot*/
export const hubspotMappingEdit = createAction(
  types.REQUESTS_ACTIONS.HUBSPOT_MAPPING_EDIT,
  (payload: any) => payload
);

export const hubspotMappingEditFulfilled = createAction(
  types.RESPONSES_ACTIONS.HUBSPOT_MAPPING_EDIT_FULFILLED
);
export const rejectedHubspotMappingEdit = createAction(
  types.RESPONSES_ACTIONS.HUBSPOT_MAPPING_EDIT_REJECTED,
  (payload: any) => payload
);

export const cancelledHubspotMappingEdit = createAction(
  types.RESPONSES_ACTIONS.HUBSPOT_MAPPING_EDIT_CANCELLED
);

/* edit mapping pipedrive*/
export const pipedriveMappingEdit = createAction(
  types.REQUESTS_ACTIONS.PIPEDRIVE_MAPPING_EDIT,
  (payload: any) => payload
);

export const pipedriveMappingEditFulfilled = createAction(
  types.RESPONSES_ACTIONS.PIPEDRIVE_MAPPING_EDIT_FULFILLED
);
export const rejectedPipedriveMappingEdit = createAction(
  types.RESPONSES_ACTIONS.PIPEDRIVE_MAPPING_EDIT_REJECTED,
  (payload: any) => payload
);

export const cancelledPipedriveMappingEdit = createAction(
  types.RESPONSES_ACTIONS.PIPEDRIVE_MAPPING_EDIT_CANCELLED
);

/* edit hubspot lifecycle*/
export const hubspotLifecycleEdit = createAction(
  types.REQUESTS_ACTIONS.HUBSPOT_LIFECYCLE_EDIT,
  (payload: any) => payload
);

export const hubspotLifecycleEditFulfilled = createAction(
  types.RESPONSES_ACTIONS.HUBSPOT_LIFECYCLE_EDIT_FULFILLED
);

export const rejectedHubspotLifecycleEdit = createAction(
  types.RESPONSES_ACTIONS.HUBSPOT_LIFECYCLE_EDIT_REJECTED,
  (payload: any) => payload
);

export const cancelledHubspotLifecycleEdit = createAction(
  types.RESPONSES_ACTIONS.HUBSPOT_LIFECYCLE_EDIT_CANCELLED
);

/* edit pipedrive lifecycle*/
export const pipedriveLifecycleEdit = createAction(
  types.REQUESTS_ACTIONS.PIPEDRIVE_LIFECYCLE_EDIT,
  (payload: any) => payload
);

export const pipedriveLifecycleEditFulfilled = createAction(
  types.RESPONSES_ACTIONS.PIPEDRIVE_LIFECYCLE_EDIT_FULFILLED
);

export const rejectedPipedriveLifecycleEdit = createAction(
  types.RESPONSES_ACTIONS.PIPEDRIVE_LIFECYCLE_EDIT_REJECTED,
  (payload: any) => payload
);

export const cancelledPipedriveLifecycleEdit = createAction(
  types.RESPONSES_ACTIONS.PIPEDRIVE_LIFECYCLE_EDIT_CANCELLED
);

/* get invoices*/
export const loadInvoices = createAction(
  types.REQUESTS_DATA.LOAD_INVOICES,
  (payload: any) => payload
);

export const loadInvoicesFulfilled = createAction(
  types.RESPONSES_DATA.LOAD_INVOICES_FULFILLED,
  (payload: any) => payload
);
export const rejectedLoadInvoices = createAction(
  types.RESPONSES_DATA.LOAD_INVOICES_REJECTED,
  (payload: any) => payload
);

export const cancelledLoadInvoices = createAction(
  types.RESPONSES_DATA.LOAD_INVOICES_CANCELLED
);

/* get plans*/
export const loadPlans = createAction(types.REQUESTS_DATA.LOAD_PLANS);

export const loadPlansFulfilled = createAction(
  types.RESPONSES_DATA.LOAD_PLANS_FULFILLED,
  (payload: any) => payload
);
export const rejectedLoadPlans = createAction(
  types.RESPONSES_DATA.LOAD_PLANS_REJECTED,
  (payload: any) => payload
);

export const cancelledLoadPlans = createAction(
  types.RESPONSES_DATA.LOAD_PLANS_CANCELLED,
  (payload: any) => payload
);

/* get cards*/
export const loadCards = createAction(types.REQUESTS_DATA.LOAD_CARDS);

export const loadCardsFulfilled = createAction(
  types.RESPONSES_DATA.LOAD_CARDS_FULFILLED,
  (payload: any) => payload
);
export const rejectedLoadCards = createAction(
  types.RESPONSES_DATA.LOAD_CARDS_REJECTED,
  (payload: any) => payload
);

export const cancelledLoadCards = createAction(
  types.RESPONSES_DATA.LOAD_CARDS_CANCELLED
);

/* set default card*/
export const setDefaultCard = createAction(
  types.REQUESTS_ACTIONS.SET_DEFAULT_CARD,
  (payload: any) => payload
);
export const setDefaultCardFulfilled = createAction(
  types.RESPONSES_ACTIONS.SET_DEFAULT_CARD_FULFILLED,
  (payload: any) => payload
);
export const rejectedSetDefaultCard = createAction(
  types.RESPONSES_ACTIONS.SET_DEFAULT_CARD_REJECTED,
  (payload: any) => payload
);

export const cancelledSetDefaultCard = createAction(
  types.RESPONSES_ACTIONS.SET_DEFAULT_CARD_CANCELLED
);

/*delete card*/
export const deleteCard = createAction(
  types.REQUESTS_ACTIONS.DELETE_CARD,
  (payload: any) => payload
);
export const deleteCardFulfilled = createAction(
  types.RESPONSES_ACTIONS.DELETE_CARD_FULFILLED,
  (payload: any) => payload
);
export const rejectedDeleteCard = createAction(
  types.RESPONSES_ACTIONS.DELETE_CARD_REJECTED,
  (payload: any) => payload
);
export const cancelledDeleteCard = createAction(
  types.RESPONSES_ACTIONS.DELETE_CARD_CANCELLED
);

/* attach payment method (ie card) to user */
export const attachCardToUser = createAction(
  types.REQUESTS_ACTIONS.ATTACH_CARD_TO_USER,
  (payload: any) => payload
);
export const attachCardToUserFulfilled = createAction(
  types.RESPONSES_ACTIONS.ATTACH_CARD_TO_USER_FULFILLED,
  (payload: any) => payload
);
export const rejectedAttachCardToUser = createAction(
  types.RESPONSES_ACTIONS.ATTACH_CARD_TO_USER_REJECTED,
  (payload: any) => payload
);
export const cancelledAttachCardToUser = createAction(
  types.RESPONSES_ACTIONS.ATTACH_CARD_TO_USER_CANCELLED
);

/* retry last payment */
export const retryLastPayment = createAction(
  types.REQUESTS_ACTIONS.RETRY_PAYMENT
);
export const retryLastPaymentFulfilled = createAction(
  types.RESPONSES_ACTIONS.RETRY_PAYMENT_FULFILLED,
  (payload: any) => payload
);
export const rejectedRetryLastPayment = createAction(
  types.RESPONSES_ACTIONS.RETRY_PAYMENT_REJECTED,
  (payload: any) => payload
);
export const cancelledRetryLastPayment = createAction(
  types.RESPONSES_ACTIONS.RETRY_PAYMENT_CANCELLED
);

/* load summary */
export const loadSummary = createAction(types.REQUESTS_DATA.LOAD_SUMMARY);

export const loadSummaryFulfilled = createAction(
  types.RESPONSES_DATA.LOAD_SUMMARY_FULFILLED,
  (payload: any) => payload
);

export const rejectedLoadSummary = createAction(
  types.RESPONSES_DATA.LOAD_SUMMARY_REJECTED,
  (payload: any) => payload
);

export const cancelledLoadSummary = createAction(
  types.RESPONSES_DATA.LOAD_SUMMARY_CANCELLED
);

/* load summary */
export const loadPrice = createAction(
  types.REQUESTS_DATA.LOAD_PRICE,
  (payload: any) => payload
);

export const loadPriceFulfilled = createAction(
  types.RESPONSES_DATA.LOAD_PRICE_FULFILLED,
  (payload: any) => payload
);

export const rejectedLoadPrice = createAction(
  types.RESPONSES_DATA.LOAD_PRICE_REJECTED,
  (payload: any) => payload
);

export const cancelledLoadPrice = createAction(
  types.RESPONSES_DATA.LOAD_PRICE_CANCELLED
);

/* un cancel id */
export const unCancelId = createAction(
  types.REQUESTS_ACTIONS.UN_CANCEL_ID,
  (payload: any) => payload
);

export const unCancelIdFulfilled = createAction(
  types.RESPONSES_ACTIONS.UN_CANCEL_ID_FULFILLED,
  (payload: any) => payload
);

export const rejectedUnCancelId = createAction(
  types.RESPONSES_ACTIONS.UN_CANCEL_ID_REJECTED,
  (payload: any) => payload
);

export const cancelledUnCancelId = createAction(
  types.RESPONSES_ACTIONS.UN_CANCEL_ID_CANCELLED
);

/* load summary */
export const loadDowngrade = createAction(
  types.REQUESTS_DATA.LOAD_DOWNGRADE,
  (payload: any) => payload
);

export const loadDowngradeFulfilled = createAction(
  types.RESPONSES_DATA.LOAD_DOWNGRADE_FULFILLED,
  (payload: any) => payload
);

export const rejectedLoadDowngrade = createAction(
  types.RESPONSES_DATA.LOAD_DOWNGRADE_REJECTED,
  (payload: any) => payload
);

export const cancelledLoadDowngrade = createAction(
  types.RESPONSES_DATA.LOAD_DOWNGRADE_CANCELLED
);

/* process payment */
export const processPayment = createAction(
  types.REQUESTS_ACTIONS.PROCESS_PAYMENT,
  (payload: any) => payload
);

export const processPaymentFulfilled = createAction(
  types.RESPONSES_ACTIONS.PROCESS_PAYMENT_FULFILLED,
  (payload: any) => payload
);

export const rejectedProcessPayment = createAction(
  types.RESPONSES_ACTIONS.PROCESS_PAYMENT_REJECTED,
  (payload: any) => payload
);

export const cancelledProcessPayment = createAction(
  types.RESPONSES_ACTIONS.PROCESS_PAYMENT_CANCELLED
);

/* terminate user */
export const terminateUser = createAction(types.REQUESTS_ACTIONS.TERMINATE);

export const terminateUserFulfilled = createAction(
  types.RESPONSES_ACTIONS.TERMINATE_FULFILLED,
  (payload: any) => payload
);

export const rejectedTerminateUser = createAction(
  types.RESPONSES_ACTIONS.TERMINATE_REJECTED,
  (payload: any) => payload
);

export const cancelledTerminateUser = createAction(
  types.RESPONSES_ACTIONS.TERMINATE_CANCELLED
);

/* delete identity */
export const deleteIdentity = createAction(
  types.REQUESTS_ACTIONS.DELETE_IDENTITY,
  (payload: any) => payload
);

export const deleteIdentityFulfilled = createAction(
  types.RESPONSES_ACTIONS.DELETE_IDENTITY_FULFILLED,
  (payload: any) => payload
);

export const rejectedDeleteIdentity = createAction(
  types.RESPONSES_ACTIONS.DELETE_IDENTITY_REJECTED,
  (payload: any) => payload
);

export const cancelledDeleteIdentity = createAction(
  types.RESPONSES_ACTIONS.DELETE_IDENTITY_CANCELLED
);

/*notification for copied logs key*/
export const logsKeyCopied = createAction(types.LOGS_EXTERNAL_KEY_COPIED);

import React from "react";
import ModalWrapper from "../../../../components/ModalWrapper";
import { AudienceNew } from "@lagrowthmachine/lgmwebapp";
import useSelector from "../../../../redux/useSelector";
import * as leadActions from "../../../Leads/actions";
import * as globalActions from "../../../../redux/actions";
import globalTypes from "../../../../redux/types";
import { useDispatch } from "react-redux";

import asyncRequest from "../../../../helpers/asyncRequest";
import globalEndpoints from "../../../../endpoints";
import leadTypes from "../../../Leads/types";

function Modal(props: any) {
  const audienceReducer = useSelector((state) => state.audienceReducer);
  const authReducer = useSelector((state) => state.authReducer);
  const loadingReducer = useSelector((state) => state.loadingReducer);
  const dispatch = useDispatch();
  const { data } = props;

  const onNewAudience = async ({
    name,
    description,
  }: {
    name: string;
    description: string;
  }) => {
    dispatch(
      globalActions.updateBtnLoaders({
        name: globalTypes.REQUESTS_ACTIONS.CREATE_AUDIENCE,
        status: true,
      })
    );
    const responseAudience = await asyncRequest(globalEndpoints, {
      name: "createAudience",
      body: {
        audience: {
          name,
          description,
          type: "CLASSIC",
        },
      },
      query: {
        apikey: authReducer?.user?.apikey,
      },
      apiVersion: authReducer?.user?.apiVersion,
    });
    dispatch(
      globalActions.updateBtnLoaders({
        name: globalTypes.REQUESTS_ACTIONS.CREATE_AUDIENCE,
        status: false,
      })
    );

    if (responseAudience?.data?.audienceCreated?.id) {
      onAddToAudience(responseAudience?.data?.audienceCreated?.id);
    }
  };

  const onAddToAudience = (audienceId: string | null, action?: string) => {
    // props.selectedLeads
    // audienceId

    dispatch(
      leadActions.editLeads({
        body: {
          actionName: "addToAudience",
          audienceId,
          leadIds: props.data?.selectedLeads,
          // filters: leadsReducer.filters,
        },
      })
    );
  };

  const onRequestClose = () => {
    dispatch(globalActions.modalUpdate({ name: "reset", data: {} }));
  };

  const leadsNumber = data.selectedLeads?.length;

  return (
    <ModalWrapper>
      <AudienceNew
        leadsNumber={leadsNumber}
        onNewAudience={onNewAudience}
        onAddToAudience={onAddToAudience}
        btnSaveDisabled={
          loadingReducer.btnTypesLoaders[
            leadTypes.REQUESTS_ACTIONS.EDIT_LEADS
          ] ||
          loadingReducer.btnTypesLoaders[
            globalTypes.REQUESTS_ACTIONS.CREATE_AUDIENCE
          ]
        }
        btnSaveIsLoading={
          loadingReducer.btnTypesLoaders[
            leadTypes.REQUESTS_ACTIONS.EDIT_LEADS
          ] ||
          loadingReducer.btnTypesLoaders[
            globalTypes.REQUESTS_ACTIONS.CREATE_AUDIENCE
          ]
        }
        audienceInit={
          audienceReducer.list.find((aud: any) => {
            if (data.audienceId === aud.id) {
              return {
                ...aud,
                active: true,
              };
            }
            return false;
          }) || undefined
        }
        audiences={audienceReducer.list.map((aud: any) => {
          if (props.filters?.audiences?.indexOf(aud.id) === -1) {
            return {
              ...aud,
              active: true,
            };
          }
          return {
            ...aud,
            active: false,
          };
        })}
        action={data.audienceId ? "add" : "new"}
        originalAudiences={[]}
        onCancel={onRequestClose}
      />
    </ModalWrapper>
  );
}
export default Modal;

/**
 * Containers reducers
 */
import * as AuthReducer from "../../Containers/Auth/reducers";
import * as CampaignsReducer from "../../Containers/Campaigns/reducers";
import * as LeadsReducer from "../../Containers/Leads/reducers";
import * as SettingsReducer from "../../Containers/Settings/reducers";
import * as IdentitiesReducer from "../../Containers/Identities/reducers";

/**
 * Global reducers
 */
import * as NotificationsReducer from "./notifications";
import * as AudiencesReducer from "./audiences";
import * as WebhooksReducer from "./webhooks";
import * as ModalsReducer from "./modals";
import * as LoadingReducer from "./loading";
import { socketReducer } from "./sockets";
//import { ILeadState } from "../../Containers/Leads/interfaces/IState";

/**
 * Merge all reducers into one element
 */
const reducers = {
  /**
   * Campaigns reducer
   */
  ...CampaignsReducer,

  /**
   * Auth reducer
   */
  ...AuthReducer,

  /**
   * Leads reducer
   */

  ...LeadsReducer,

  /**
   * Notifications reducer
   */

  ...NotificationsReducer,

  /**
   * Audiences reducer
   */

  ...AudiencesReducer,

  /**
   * Webhooks reducer
   */

  ...WebhooksReducer,

  /**
   * Settings reducer
   */

  ...SettingsReducer,

  /**
   * Modals reducer
   */

  ...ModalsReducer,

  /**
   * Identities reducer
   */

  ...IdentitiesReducer,

  /**
   * Loading reducer
   */

  ...LoadingReducer,

  /**
   * Socket reducer
   */

  socketReducer,
  /**
   * Your next Container reducers ->
   */
};

export default reducers;

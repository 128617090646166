// @TODO impossible d'importer ChannelType de web-components
type ChannelType = "GMAIL" | "LINKEDIN" | "TWITTER" | "LGM";
export const GMAIL: ChannelType = "GMAIL";
export const TWITTER: ChannelType = "TWITTER";
export const LINKEDIN: ChannelType = "LINKEDIN";

const constants = {
  widgetProdUrl: {
    mac: "https://lagrowthmachine.com/download/mac",
    windows: "https://lagrowthmachine.com/download/windows",
  },
  typeformId: "TdXPgpbs",
  intercomTourIds: {
    audience_settings: {
      //how enrich works
      TRIAL: 218892,
      STARTER: 214165,
      PRO: 218892,
      BUSINESS: 218892,
    },
    edit_step: { TRIAL: 218898, STARTER: 218898, PRO: 218898, BUSINESS: null }, //how custom sequences works
    exit_settings: {
      //how custom ending works
      TRIAL: 218901,
      STARTER: 218905,
      PRO: 218905,
      BUSINESS: 218901,
    },
    review: { TRIAL: 218895, STARTER: 218895, PRO: 218895, BUSINESS: 218896 }, //how campaign review works
    api_documentation: {
      //how api works
      TRIAL: null,
      STARTER: null,
      PRO: null,
      BUSINESS: null,
    },
    crm_sync: { TRIAL: 254038, STARTER: 254037, PRO: 254037, BUSINESS: 254038 }, //how crm sync works
  },
  setup_spf_dkim:
    "https://help.lagrowthmachine.com/en/articles/5624287-how-to-set-up-my-email-domain-properly",
  postman_documentation:
    "https://documenter.getpostman.com/view/2071164/TVCmSkH2#8c9d579b-8ff2-472d-9815-9edf936d5b1d",
  providers_url: {
    zapier: "https://zapier.com/",
    zapier1: "https://zapier.com/",
    zapier2: "https://zapier.com/",
    zapier3: "https://zapier.com/",
    captain_data: "https://www.captaindata.co/",
    phantom_buster: "https://phantombuster.com/",
  },
  defaultStatProgressTimeScale: 40,
  channelTypes: {
    GMAIL,
    TWITTER,
    LINKEDIN,
  },
  channels: [
    { name: "Twitter", id: "TWITTER", checked: true },
    { name: "Linkedin", id: "LINKEDIN", checked: true },
    { name: "Email", id: "GMAIL", checked: true },
  ],
  messagesChannels: [
    {
      channel: GMAIL,
      title: "New email template",
      subtitle: "messages and contacts requests",
    },
    {
      channel: LINKEDIN,
      title: "New linkedin template",
      subtitle: "messages",
    },
    {
      channel: TWITTER,
      title: "New twitter template",
      subtitle: "messages and tweets",
    },
  ],
  campaignStatus: [
    { name: "Ready", id: "READY", checked: true },
    { name: "Running", id: "RUNNING", checked: true },
    { name: "Paused", id: "PAUSED", checked: true },
    { name: "Stopped", id: "CANCELED", checked: true },
  ],
  campaignActions: [
    { name: "Past", id: "past", checked: false },
    { name: "Upcoming", id: "next", checked: true },
  ],

  lang: [
    {
      name: "French",
      id: "FRENCH",
      checked: false,
    },
    {
      name: "English",
      id: "ENGLISH",
      checked: false,
    },
  ],
  preventNotificationOnPages: [
    "/onboarding/widget_download",
    //"/onboarding/linkedin_connect",
    //"/onboarding/email_connect",
    //"/onboarding/twitter_connect",
    //"/onboarding/crm_connect",
  ],
};

export default constants;

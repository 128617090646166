import { ICampaignState } from "../interfaces/IState";
import types from "../types";
import constants from "../../../config/constants";

const initialState: ICampaignState = {
  error: null,
  campaignsName: [],
  recommended: [],
  store: [],
  templates: [],
  otherCampaignTemplates: [],
  messageSelected: null,
  sequenceSelected: null,
  selected: {
    leadsFilters: {
      filterId: "all",
    },
    actionsFilters: {},
    leads_items: [],
  },
  customFilters: {
    campaignFilters: [],
    configurationStats: [],
  },
  stats: {
    histo: [],
    dailyGraph: null,
  },
  actions: {
    status:
      (constants.campaignActions.find((_status: any) => _status.checked)?.id as
        | "past"
        | "next") || "next",
    showHubspot: false,
    showPipedrive: false,
    next: [],
    past: [],
    paginate: {
      current: 0,
      total: 0,
    },
  },
  paginate: {
    //paginate for leads
    current: 0,
    total: { all: 0 },
  },
  filters: {
    channels: [],
    status: [],
  },
  statProgressTimeScale: constants.defaultStatProgressTimeScale, // default, all time
  checkAssignation: null,
};

export const campaignReducer = (state = initialState, action: any) => {
  /**
   * Campaigns
   */
  switch (action.type) {
    case types.REQUESTS_DATA.LOAD_SEQUENCE:
      return {
        ...state,
        error: null,
        sequenceSelected: null,
      };
    case types.RESPONSES_DATA.LOAD_SEQUENCE_FULFILLED:
      return {
        ...state,
        error: null,
        sequenceSelected: action.payload,
      };
    case types.RESPONSES_DATA.LOAD_SEQUENCE_CANCELLED:
      return {
        ...state,
        sequenceSelected: null,
        error: action.payload?.error,
      };
    case types.REQUESTS_DATA.LOAD_MESSAGE:
      return {
        ...state,
        error: null,
        messageSelected: null,
      };
    case types.RESPONSES_DATA.LOAD_MESSAGE_FULFILLED:
      return {
        ...state,
        error: null,
        messageSelected: action.payload.message,
      };
    case types.RESPONSES_DATA.LOAD_MESSAGE_REJECTED:
    case types.RESPONSES_DATA.LOAD_MESSAGE_CANCELLED:
      return {
        ...state,
        messageSelected: null,
        error: action.payload?.error,
      };
    case types.REQUESTS_DATA.LOAD_CAMPAIGNS:
      return {
        ...state,
        stats: initialState.stats,
        selected: initialState.selected,
        error: null,
      };
    case types.RESPONSES_DATA.LOAD_CAMPAIGNS_NAME_FULFILLED:
      return {
        ...state,
        error: null,
        campaignsName: action.payload.campaigns,
      };
    case types.RESPONSES_DATA.LOAD_CAMPAIGNS_NAME_REJECTED:
    case types.RESPONSES_DATA.LOAD_CAMPAIGNS_NAME_CANCELLED:
      return {
        ...state,
        campaignsName: [],
        error: action.payload?.error,
      };
    case types.RESPONSES_DATA.LOAD_CAMPAIGNS_REJECTED:
    case types.RESPONSES_DATA.LOAD_CAMPAIGNS_CANCELLED:
      return {
        ...state,
        list: [],
        error: action.payload?.error,
      };
    case types.RESPONSES_DATA.LOAD_CAMPAIGNS_RECOMMENDED_FULFILLED:
      return {
        ...state,
        error: null,
        recommended: action.payload.campaigns,
      };
    case types.RESPONSES_DATA.LOAD_CAMPAIGNS_RECOMMENDED_REJECTED:
      return {
        ...state,
        recommended: [],
        error: action.payload?.error,
      };
    case types.RESPONSES_DATA.LOAD_CAMPAIGNS_STORE_FULFILLED:
      return {
        ...state,
        error: null,
        store: action.payload.campaigns,
      };
    case types.RESPONSES_DATA.LOAD_CAMPAIGNS_STORE_REJECTED:
      return {
        ...state,
        store: [],
        error: action.payload?.error,
      };
    case types.RESPONSES_DATA.LOAD_CAMPAIGN_TEMPLATES_FULFILLED:
      return {
        ...state,
        error: null,
        templates: action.payload.templates,
      };
    case types.RESPONSES_DATA.LOAD_CAMPAIGN_TEMPLATES_REJECTED:
    case types.RESPONSES_DATA.LOAD_CAMPAIGN_TEMPLATES_CANCELLED:
      return {
        ...state,
        templates: [],
        error: action.payload?.error,
      };
    case types.RESPONSES_DATA.LOAD_OTHER_CAMPAIGN_TEMPLATES_FULFILLED:
      return {
        ...state,
        error: null,
        otherCampaignTemplates: action.payload.templates,
      };
    case types.RESPONSES_DATA.LOAD_OTHER_CAMPAIGN_TEMPLATES_REJECTED:
    case types.RESPONSES_DATA.LOAD_OTHER_CAMPAIGN_TEMPLATES_CANCELLED:
      return {
        ...state,
        otherCampaignTemplates: [],
        error: action.payload?.error,
      };
    case types.RESPONSES_DATA.LOAD_CAMPAIGNS_FILTERS_FULFILLED:
      return {
        ...state,
        customFilters: {
          campaignFilters: action.payload.campaignFilters,
          configurationStats: action.payload.configurationStats || [],
        },
      };

    /**
     * Campaign
     */
    case types.REQUESTS_DATA.LOAD_CAMPAIGN:
      return {
        ...state,
        error: null,
      };
    case types.RESPONSES_DATA.LOAD_CAMPAIGN_FULFILLED:
      return {
        ...state,
        selected: {
          //...state.selected,
          ...action.payload.campaign,
        },
        actions: initialState.actions,
        error: null,
      };
    case types.RESPONSES_DATA.LOAD_CAMPAIGN_LEADS_FULFILLED:
      return {
        ...state,
        selected: {
          ...state.selected,
          // ...action.payload,
          leads_items: action.payload.leads,
          leadsFilters: {
            ...state.selected.leadsFilters,
          },
          paginate: {
            current: state?.selected?.paginate?.current || 0,
            total: {
              ...state?.selected?.paginate?.total,
              ...action.payload.total,
            },
          },
        },
        // loading: false,
        error: null,
      };
    case types.CAMPAIGNS_FILTERS:
      return {
        ...state,
        loading: true,
        filters: action.payload,
      };
    case types.CAMPAIGN_LEADS_PAGINATION:
      return {
        ...state,
        selected: {
          ...state.selected,
          ...action.payload,
        },
      };
    case types.CAMPAIGN_FILTERS:
      return {
        ...state,
        selected: {
          ...state.selected,
          ...action.payload,
          paginate: {
            current: 0,
            total: state.selected?.paginate?.total || 0,
          },
        },
      };
    case types.CAMPAIGN_CUSTOM_FILTER:
      return {
        ...state,
        customFilters: action.payload,
      };
    case types.CAMPAIGN_LEAD_FILTER_SEARCH:
      return {
        ...state,
        selected: {
          ...state.selected,
          leadsFilters: {
            ...state.selected.leadsFilters,
            q: action.payload.q,
          },
        },
      };
    case types.CLEAR_LEADS:
      return {
        ...state,
        selected: {
          ...state.selected,
          leads_items: [],
        },
      };

    case types.RESPONSES_DATA.LOAD_CAMPAIGN_STATS_FULFILLED:
      return {
        ...state,
        stats: action.payload.stats,
      };

    case types.RESPONSES_DATA.LOAD_CAMPAIGN_PROGRESS_FULFILLED:
      return {
        ...state,
        stats: action.payload.stats,
        statProgressTimeScale: action.payload.timescale,
      };

    case types.RESPONSES_DATA.LOAD_CAMPAIGN_ACTIONS_FULFILLED:
      return {
        ...state,
        actions: {
          ...state.actions,
          [action.payload.status]: action.payload.actions,
          paginate: {
            total: action.payload.total,
            current: state.actions?.paginate?.current || 0,
          },
        },
      };

    case types.CAMPAIGN_ACTIONS_PAGINATE:
      return {
        ...state,
        actions: {
          ...state.actions,
          paginate: {
            total: state?.actions?.paginate?.total,
            current: action.payload.current || 0,
          },
        },
      };

    case types.CAMPAIGN_ACTIONS_CHANNEL_TYPE:
      return {
        ...state,
        actions: {
          ...state.actions,
          status: action.payload.status,
          paginate: {
            total: state.actions[action.payload.status].length,
            current: 0,
          },
        },
      };
    case types.CAMPAIGN_TOOGLE_HUBSPOT:
      return {
        ...state,
        actions: {
          ...state.actions,
          showHubspot: action.payload.showHubspot,
        },
      };
    case types.CAMPAIGN_TOOGLE_PIPEDRIVE:
      return {
        ...state,
        actions: {
          ...state.actions,
          showPipedrive: action.payload.showPipedrive,
        },
      };
    case types.CAMPAIGNS_CLEAR:
      return initialState;
    case types.CHECK_ASSIGNATION:
      return {
        ...state,
        checkAssignation: action.payload?.id,
      };
    case types.RESET_ASSIGNATION:
      return {
        ...state,
        checkAssignation: null,
      };
    default:
      return state;
  }
};

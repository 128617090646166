import { encodeQuery } from "../../../helpers/encodeQuery";
import config, { APP_ENV } from "../../../config";
import MethodType from "../../../interfaces/IMethod";
import { IEndpointParams } from "../../../interfaces/IEndpoint";
import getApiUrl from "../../../helpers/getApiUrl";

const defaultHeader = {
  "Content-Type": "application/json",
};

export default function getEndpoint({
  name,
  body,
  query,
  params,
  apiVersion,
}: IEndpointParams): {
  url: string;
  method: MethodType;
  headers?: { [name: string]: string };
  body?: any;
  crossDomain?: boolean;
} {
  let queryEncoded: string = "";

  if (query) {
    queryEncoded = encodeQuery(query);
  }
  switch (name) {
    case "getCoupon":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.users
        }coupons/${params.coupon}?${queryEncoded}`,
        method: "GET",
        headers: defaultHeader,
      };
    case "getPaymentMethods":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.users
        }paymentmethods?${queryEncoded}`,
        method: "GET",
        headers: defaultHeader,
      };
    case "getLinkedinVersion":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.users
        }linkedinversion?${queryEncoded}`,
        method: "GET",
        headers: defaultHeader,
      };
    case "getUploadUrl":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.users
        }uploadurl?${queryEncoded}`,
        method: "POST",
        headers: defaultHeader,
        body,
      };
    case "uploadFile":
      return {
        url: query?.url,
        method: "PUT",
        body,
      };
    case "register":
      return {
        url: ` ${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.users
        }signup`,
        method: "POST",
        headers: defaultHeader,
        body,
        crossDomain: true,
      };
    case "login":
      return {
        url: ` ${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.users
        }login?${queryEncoded}`,
        method: "POST",
        headers: defaultHeader,
        body,
      };
    case "resetPasswordRequest":
      return {
        url: ` ${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.users
        }forgotpassword?${queryEncoded}`,
        method: "POST",
        body,
        headers: defaultHeader,
      };
    case "resetPassword":
      return {
        url: ` ${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.users
        }resetpassword`,
        method: "POST",
        headers: defaultHeader,
        body,
      };
    case "validationAccount":
      return {
        url: ` ${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.users
        }verifyemail?${queryEncoded}`,
        method: "GET",
        headers: defaultHeader,
      };
    case "loadUser":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.users
        }?${queryEncoded}`,
        method: "GET",
        headers: defaultHeader,

        crossDomain: true,
      };
    case "terminateUser2":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.users
        }terminate?${queryEncoded}`,
        method: "DELETE",
        body,
        crossDomain: true,
      };
    case "editMember":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.members
        }?${queryEncoded}`,
        method: "PUT",
        headers: defaultHeader,
        body,
        crossDomain: true,
      };
    case "editUser":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.users
        }?${queryEncoded}`,
        method: "PUT",
        headers: defaultHeader,
        body,
        crossDomain: true,
      };
    case "generateApiKey":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.users
        }apikeyexternal?${queryEncoded}`,
        method: "GET",
        crossDomain: true,
      };
    default:
      return {
        url: ``,
        method: "GET",
        crossDomain: true,
      };
  }
}

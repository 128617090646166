import { ActionsObservable, ofType, Epic } from "redux-observable";

import { mergeMap } from "rxjs/operators";
import { of } from "rxjs/internal/observable/of";

import types from "../types";
import leadTypes from "../../Containers/Leads/types";
import identitiesTypes from "../../Containers/Identities/types";
import campaignsTypes from "../../Containers/Campaigns/types";
import settingsTypes from "../../Containers/Settings/types";

import IAction from "../../interfaces/IAction";

export const ScrollAfterPaginateEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(
      campaignsTypes.CAMPAIGN_ACTIONS_PAGINATE,
      identitiesTypes.IDENTITY_LOGS_PAGINATE,
      leadTypes.LEADS_PAGINATE,
      settingsTypes.LOGS_EXTERNAL_PAGINATE,
      types.SCROLL_TO_TOP
    ), //scrollToTop
    mergeMap((action) => {
      window.scroll({ top: 0, left: 0, behavior: "smooth" });
      return of({ type: "" });
    })
  );
};

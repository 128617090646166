import { ActionsObservable, ofType, Epic } from "redux-observable";
import { map, filter, takeUntil, mergeMap, catchError } from "rxjs/operators";
import { tag } from "rxjs-spy/operators/tag";
import IAction from "../../interfaces/IAction";
import { of } from "rxjs/internal/observable/of";
import apiService from "../../services/apis";
import getEndpoint from "../../endpoints";
import types from "../types";
import * as actions from "../actions";
import * as campaignsActions from "../../Containers/Campaigns/actions";

export const checkWebhookAssignationEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.RESPONSES_ACTIONS.CREATE_WEBHOOK_FULFILLED),
    mergeMap((action) => {
      return of(campaignsActions.checkAssignation({ id: action.payload?.id }));
    })
  );
};

export const resetWebhookAssignationEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(
      types.RESPONSES_ACTIONS.CREATE_WEBHOOK_CANCELLED,
      types.RESPONSES_ACTIONS.CREATE_WEBHOOK_REJECTED
    ),
    mergeMap((action) => {
      return of(campaignsActions.resetAssignation());
    })
  );
};

export const loadWebhooksEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  // spy.log("loadSequence");
  return action$.pipe(
    ofType(
      types.REQUESTS_DATA.LOAD_WEBHOOKS,
      types.RESPONSES_ACTIONS.REMOVE_WEBHOOK_FULFILLED,
      types.RESPONSES_ACTIONS.CREATE_WEBHOOK_FULFILLED,
      types.RESPONSES_ACTIONS.EDIT_WEBHOOK_FULFILLED
    ),
    mergeMap((action) => {
      // console.log({ action });
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "loadWebhooks",
          params: action.payload?.params,
          query: {
            apikey: store.value.authReducer?.user?.apikey,
            ...action.payload?.query,
          },
        })
      )
        .pipe(
          tag("loadWebhooks"),
          map((xhr) => actions.loadWebhooksFulfilled(xhr.response)),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type === types.RESPONSES_DATA.LOAD_WEBHOOKS_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedLoadWebhooks(e?.response));
          })
        );
    })
  );
};

export const createWebhooksEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  // spy.log("loadSequence");
  return action$.pipe(
    ofType(types.REQUESTS_ACTIONS.CREATE_WEBHOOK),
    mergeMap((action) => {
      // console.log({ action });
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "createWebhook",
          params: action.payload?.params,
          body: action.payload?.body,
          query: {
            apikey: store.value.authReducer?.user?.apikey,
            ...action.payload?.query,
          },
        })
      )
        .pipe(
          tag("createAudience"),
          map((xhr) => actions.createWebhookFulfilled(xhr.response)),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_ACTIONS.CREATE_WEBHOOK_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedCreateWebhook(e?.response));
          })
        );
    })
  );
};

export const editWebhooksEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  // spy.log("loadSequence");
  return action$.pipe(
    ofType(types.REQUESTS_ACTIONS.EDIT_WEBHOOK),
    mergeMap((action) => {
      // console.log({ action });
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "editWebhook",
          params: action.payload?.params,
          body: action.payload?.body,
          query: {
            apikey: store.value.authReducer?.user?.apikey,
            ...action.payload?.query,
          },
        })
      )
        .pipe(
          tag("createAudience"),
          map((xhr) => actions.editWebhookFulfilled(xhr.response)),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type === types.RESPONSES_ACTIONS.EDIT_WEBHOOK_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedEditWebhook(e?.response));
          })
        );
    })
  );
};

export const removeWebhooksEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  // spy.log("loadSequence");
  return action$.pipe(
    ofType(types.REQUESTS_ACTIONS.REMOVE_WEBHOOK),
    mergeMap((action) => {
      // console.log({ action });
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "removeWebhook",
          params: action.payload?.params,
          body: action.payload?.body,
          query: {
            apikey: store.value.authReducer?.user?.apikey,
            ...action.payload?.query,
          },
        })
      )
        .pipe(
          tag("removeWebhook"),
          map((xhr) => actions.removeWebhookFulfilled(xhr.response)),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_ACTIONS.REMOVE_WEBHOOK_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedRemoveWebhook(e?.response));
          })
        );
    })
  );
};

export const simulateWebhookEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_ACTIONS.SIMULATE_WEBHOOK),
    mergeMap((action) => {
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "simulateWebhook",
          params: action.payload?.params,
          body: action.payload?.body,
          query: {
            ...action.payload?.query,
            apikey: store.value.authReducer?.user?.apikey,
          },
        })
      )
        .pipe(
          tag("simulateWebhook"),
          map((xhr) => {
            if (xhr?.response?.statusCode !== 200) {
              return actions.rejectedSimulateWebhook({
                error: "This URL doesn't accept Webhook payloads",
              });
            }
            return actions.simulateWebhookFulfilled(xhr.response);
          }),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_ACTIONS.SIMULATE_WEBHOOK_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedSimulateWebhook(e?.response));
          })
        );
    })
  );
};

// import { createReducer } from "@reduxjs/toolkit";
import { IStateAudiences } from "../../interfaces/IState";
import types from "../types";

const initialState: IStateAudiences = {
  list: [],
  assignationAudience: null,
};

export const audienceReducer = (state = initialState, action: any) => {
  /**
   * Audiences
   */
  switch (action.type) {
    case types.ASSIGNATION_AUDIENCE:
      return {
        ...state,
        assignationAudience: {
          brick_id: action.payload?.brick_id,
          audience_id: action.payload?.audience_id,
        },
      };
    case types.RESPONSES_ACTIONS.CREATE_AUDIENCE_FULFILLED:
      return {
        ...state,
        assignationAudience: {
          ...state.assignationAudience,
          id: action.payload?.audienceCreated?.id,
          name: action.payload?.audienceCreated?.name,
        },
      };
    case types.RESPONSES_ACTIONS.EDIT_AUDIENCE_FULFILLED:
      return {
        ...state,
        assignationAudience: {
          ...state.assignationAudience,
          id: action.payload?.audienceUpdated?.id,
          name: action.payload?.audienceUpdated?.name,
        },
      };
    case types.ASSIGNATION_AUDIENCE_CLEAR:
      return {
        ...state,
        assignationAudience: null,
      };
    case types.REQUESTS_DATA.LOAD_AUDIENCES:
      return {
        ...state,
        error: null,
      };
    case types.RESPONSES_DATA.LOAD_AUDIENCES_FULFILLED:
      return {
        ...state,
        error: null,
        list: action.payload.audiences,
      };
    case types.RESPONSES_DATA.LOAD_AUDIENCES_CANCELLED:
      return {
        ...state,
        list: [],
        error: action.payload?.error,
      };
    case types.AUDIENCE_CLEAR:
      return initialState;
    default:
      return state;
  }
};

import { ActionsObservable, ofType, Epic } from "redux-observable";
import { map } from "rxjs/operators";
import IAction from "../../interfaces/IAction";
import types from "../types";
import leadTypes from "../../Containers/Leads/types";
import campaignsTypes from "../../Containers/Campaigns/types";
import authTypes from "../../Containers/Auth/types";

import settingsTypes from "../../Containers/Settings/types";

import * as actions from "../actions";

export const closeModalEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>
) => {
  return action$.pipe(
    ofType(
      leadTypes.RESPONSES_ACTIONS.EDIT_LEADS_FULFILLED,
      leadTypes.RESPONSES_ACTIONS.EDIT_LEAD_FULFILLED,
      leadTypes.RESPONSES_ACTIONS.DELETE_LEADS_FULFILLED,
      leadTypes.RESPONSES_ACTIONS.CREATE_LEAD_FULFILLED,

      types.RESPONSES_ACTIONS.CREATE_WEBHOOK_FULFILLED,
      types.RESPONSES_ACTIONS.EDIT_WEBHOOK_FULFILLED,
      types.RESPONSES_ACTIONS.REMOVE_WEBHOOK_FULFILLED,
      types.RESPONSES_ACTIONS.CREATE_AUDIENCE_FULFILLED,
      types.RESPONSES_ACTIONS.EDIT_AUDIENCE_FULFILLED,
      types.RESPONSES_ACTIONS.DELETE_AUDIENCE_FULFILLED,

      campaignsTypes.RESPONSES_ACTIONS.CREATE_CAMPAIGN_FULFILLED,
      campaignsTypes.RESPONSES_ACTIONS.EDIT_CAMPAIGN_FULFILLED,
      campaignsTypes.RESPONSES_ACTIONS.EDIT_SEQUENCE_FULFILLED,
      campaignsTypes.RESPONSES_ACTIONS.EDIT_CAMPAIGN_STATE_FULFILLED,
      campaignsTypes.RESPONSES_ACTIONS.EDIT_CAMPAIGN_STATE_REJECTED,
      campaignsTypes.RESPONSES_ACTIONS.DUPLICATE_CAMPAIGN_FULFILLED,
      campaignsTypes.RESPONSES_ACTIONS.EDIT_LEADS_QUEUE_STATUS_FULFILLED,
      campaignsTypes.RESPONSES_ACTIONS.CREATE_CAMPAIGN_TEMPLATE_FULFILLED,
      campaignsTypes.RESPONSES_ACTIONS.EDIT_CAMPAIGN_TEMPLATE_FULFILLED,
      campaignsTypes.RESPONSES_ACTIONS.CAMPAIGN_REVIEW_FULFILLED,
      campaignsTypes.RESPONSES_ACTIONS.CAMPAIGN_REVIEW_REJECTED,

      authTypes.RESPONSES_ACTIONS.REFRESH_EXTERN_APIKEY_FULFILLED,
      settingsTypes.RESPONSES_ACTIONS.HUBSPOT_DISCONNECT_FULFILLED,
      settingsTypes.RESPONSES_ACTIONS.PIPEDRIVE_DISCONNECT_FULFILLED,
      settingsTypes.RESPONSES_ACTIONS.ATTACH_CARD_TO_USER_FULFILLED,
      settingsTypes.RESPONSES_ACTIONS.DELETE_IDENTITY_FULFILLED,
      types.RESPONSES_ACTIONS.DELETE_AUDIENCE_REJECTED
    ),
    map(() => {
      return actions.modalUpdate({
        actionName: "",
        data: {},
      });
    })
  );
};

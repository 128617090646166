import React, { useEffect, useState } from "react";
import * as actions from "../../actions";
import types from "../../types";
import { ForgotPassword2, type } from "@lagrowthmachine/lgmwebapp";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import useSelector from "../../../../redux/useSelector";
import analyticsCall from "../../../../helpers/analyticsCall";
import config from "../../../../config";
import { ReCaptcha, loadReCaptcha } from "react-recaptcha-v3";

const ResetPasswordComponent = (props: any) => {
  let recaptchaRef: any = React.createRef();
  const [captcha, setCaptcha] = useState("");
  const { token, email } = props.match?.params;
  const history = useHistory();
  const dispatch = useDispatch();
  const loadingReducer = useSelector((state) => state.loadingReducer);
  const authReducer = useSelector((state) => state.authReducer);

  useEffect(() => {
    if (authReducer?.user !== null) {
      history.push("/campaigns");
    }
  }, [history]);

  useEffect(() => {
    loadReCaptcha(config.recaptchaKey);

    analyticsCall("authentication", "authentication: forgot password");
    return () => {
      Array.prototype.filter.call(
        document.getElementsByClassName("grecaptcha-badge"),
        function (el: any) {
          el.style.visibility = "hidden";
        }
      );
    };
  }, []);

  useEffect(() => {
    Array.prototype.filter.call(
      document.getElementsByClassName("grecaptcha-badge"),
      function (el: any) {
        el.style.visibility = "visible";
      }
    );
  }, [recaptchaRef]);

  const onRedirectSignIn = () => history.push("/login");
  const onForgotPassword = (values: type.ForgotPasswordStateT) => {
    if (email && token) {
      dispatch(
        actions.resetPwd({
          body: {
            token,
            newPassword: values.password,
            email,
            captcha: captcha,
          },
        })
      );
    } else {
      dispatch(
        actions.resetPwdRequest({
          body: { email: values.email, captcha: captcha },
        })
      );
    }

    setCaptcha("");
    recaptchaRef.execute();
  };

  var verifyCallback = function (response: any) {
    setCaptcha(response);
  };

  return (
    <div className="auth_rstpasswd_container">
      <ForgotPassword2
        redirectSignIn={onRedirectSignIn}
        token={token}
        onForgotPassword={onForgotPassword}
        btnIsLoading={
          loadingReducer.btnTypesLoaders[
            types.REQUESTS_ACTIONS.RESET_PWD_REQ
          ] || loadingReducer.btnTypesLoaders[types.REQUESTS_ACTIONS.RESET_PWD]
        }
        btnDisabled={
          captcha === "" ||
          loadingReducer.btnTypesLoaders[
            types.REQUESTS_ACTIONS.RESET_PWD_REQ
          ] ||
          loadingReducer.btnTypesLoaders[types.REQUESTS_ACTIONS.RESET_PWD]
        }
      />
      <ReCaptcha
        ref={(ref) => (recaptchaRef = ref)}
        sitekey={config.recaptchaKey}
        action="resetPassword"
        verifyCallback={verifyCallback}
      />
    </div>
  );
};
export default ResetPasswordComponent;

import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import Campaigns from "../Containers/Campaigns/views";
import Leads from "../Containers/Leads/views";
import Identities from "../Containers/Identities/views";
import Auth from "../Containers/Auth/views";
import Settings from "../Containers/Settings/views";
import PrivateRoute from "../middlewares/PrivateRoute";
import { ErrorPage404, ErrorPage500 } from "../components/Error";
import Layout from "../components/Layout/";

//comment
function Routes(props: any) {
  useEffect(() => {
    document!
      .getElementById("root")!
      .setAttribute("pathname", window.location.pathname);
    return props.history.listen((location: any) => {
      document!
        .getElementById("root")!
        .setAttribute("pathname", location.pathname);
    });
  }, [props.history]);

  return (
    <ConnectedRouter history={props.history}>
      <Switch>
        <Redirect from="/" exact to="/campaigns" />
        <PrivateRoute
          path="/onboarding/:page?"
          Component={(props: any) => {
            switch (props.match.params?.page) {
              case "widget_download":
                return <Auth.WidgetDownload {...props} />;
              case "linkedin_connect":
              case "email_connect":
              case "twitter_connect":
                return <Auth.ConnectingChannel {...props} />;
              case "crm_connect":
                return <Auth.CrmConnect {...props} />;
              default:
                return <Auth.WidgetDownload {...props} />;
            }
          }}
        />
        <PrivateRoute
          path="/campaigns/:id?/:name?"
          Component={(props: any) => {
            if (props.match.params?.id === "recommended") {
              return <Campaigns.Recommended {...props} />;
            } else if (props.match.params?.id) {
              switch (props.match.params?.name) {
                case "overview":
                  return <Campaigns.Overview {...props} />;
                case "engagement":
                  return <Campaigns.Engagement {...props} />;
                case "actions":
                  return <Campaigns.Actions {...props} />;
                case "edit":
                  return <Campaigns.Edit {...props} />;
                default:
                  return <Campaigns.Home {...props} />;
              }
            } else {
              return <Campaigns.Home {...props} />;
            }
          }}
        />

        <PrivateRoute
          path="/settings/:id?/:extra?"
          Component={(props: any) => {
            switch (props.match.params?.id) {
              case "webhooks":
                return <Settings.Webhooks {...props} />;
              case "campaign":
                return <Settings.Campaign {...props} />;
              case "api":
                return <Settings.Api {...props} />;
              case "billing":
                return <Settings.Billing {...props} />;
              case "pricing":
                return <Settings.Pricing {...props} />;
              case "payment":
                switch (props.match.params?.extra) {
                  case "starter":
                  case "pro":
                  case "business":
                    const _props_cp = {
                      ...props,
                      planTo: props.match.params?.extra.toUpperCase(),
                      type: "changePlan",
                    };
                    return <Settings.Payment {..._props_cp} />;
                  case "addid":
                    const _props_ai = { ...props, type: "addId" };
                    return <Settings.Payment {..._props_ai} />;
                  case "reactivateid":
                    const _props_ri = { ...props, type: "reactivateId" };
                    return <Settings.Payment {..._props_ri} />;
                  case "yearly":
                  case "monthly":
                    const _props_cb = {
                      ...props,
                      type: "changeBilling",
                      billing: props.match.params?.extra,
                    };
                    return <Settings.Payment {..._props_cb} />;
                  default:
                    return <Settings.User {...props} />;
                }
              case "downgrade":
                return <Settings.Downgrade {...props} />;
              case "goodbye":
                return <Settings.Goodbye {...props} />;
              case "crm":
              case "hubspot":
              case "pipedrive":
                return <Settings.Crm {...props} />;
              case "widget":
                return <Settings.Widget {...props} />;
              case "teammates":
                return <Settings.Teammates {...props} />;
              case "member":
                return <Settings.Member {...props} />;
              case "user":
                return <Settings.User {...props} />;
              default:
                return <Settings.Member {...props} />;
            }
          }}
        />
        <PrivateRoute
          path="/leads/:id?"
          Component={(props: any) => {
            switch (props.match.params?.id) {
              default:
                return <Leads.Home {...props} />;
            }
          }}
        />
        <PrivateRoute
          path="/identities/:id?/:name?"
          Component={(props: any) => {
            if (props.match.params?.id) {
              switch (props.match.params?.name) {
                case "details":
                  return <Identities.Details {...props} />;
                case "past":
                  return <Identities.Past {...props} />;
                case "limits":
                  return <Identities.Limits {...props} />;
                default:
                  return <Identities.Home {...props} />;
              }
            }
            return <Identities.Home {...props} />;
          }}
        />
        <Route
          path="/join/:invitationToken/:userId"
          component={(props: any) => (
            <Layout removeSidebar={true}>
              <Auth.Join {...props} />
            </Layout>
          )}
        />
        <Route
          path="/login"
          component={(props: any) => (
            <Layout removeSidebar={true}>
              <Auth.Login {...props} />
            </Layout>
          )}
        />
        <Route
          path="/register"
          component={(props: any) => (
            <Layout removeSidebar={true}>
              <Auth.Register {...props} />
            </Layout>
          )}
        />
        <Route
          path="/reset-password/:token?/:email?"
          component={(props: any) => (
            <Layout removeSidebar={true}>
              <Auth.ResetPassword {...props} />
            </Layout>
          )}
        />
        <Route
          path="/validation-account/:token?/:email?"
          component={(props: any) => (
            <Layout removeSidebar={true}>
              <Auth.ValidationAccount {...props} />
            </Layout>
          )}
        />
        <Route
          path="/delete-account/:key?/:email?"
          component={(props: any) => (
            <Layout removeSidebar={true}>
              <Auth.DeleteAccount {...props} />
            </Layout>
          )}
        />
        <Route
          path="/error500"
          component={(props: any) => (
            <Layout removeSidebar={true}>
              <ErrorPage500 {...props} />
            </Layout>
          )}
        />
        <Route
          path="*"
          component={(props: any) => (
            <Layout removeSidebar={true}>
              <ErrorPage404 {...props} />
            </Layout>
          )}
        />
      </Switch>
    </ConnectedRouter>
  );
}

export default Routes;

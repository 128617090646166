import { ActionsObservable, ofType, Epic } from "redux-observable";

import { map } from "rxjs/operators";
import types from "../types";
import leadTypes from "../../Containers/Leads/types";
import authTypes from "../../Containers/Auth/types";
import identityTypes from "../../Containers/Identities/types";
import campaignsTypes from "../../Containers/Campaigns/types";
import settingsTypes from "../../Containers/Settings/types";

import * as actions from "../actions";

import IAction from "../../interfaces/IAction";

export const loadersBtnEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>
) => {
  return action$.pipe(
    ofType(
      ...Object.keys(campaignsTypes.REQUESTS_ACTIONS),
      ...Object.keys(identityTypes.REQUESTS_ACTIONS),
      ...Object.keys(leadTypes.REQUESTS_ACTIONS),
      ...Object.keys(authTypes.REQUESTS_ACTIONS),
      ...Object.keys(types.REQUESTS_ACTIONS),
      ...Object.keys(settingsTypes.REQUESTS_ACTIONS)
    ),
    map((action) => {
      return actions.updateBtnLoaders({
        name: action.type,
        status: action?.payload?.loaderStatus || true,
      });
    })
  );
};

export const loadersDataEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>
) => {
  return action$.pipe(
    ofType(
      ...Object.keys(campaignsTypes.REQUESTS_DATA),
      ...Object.keys(leadTypes.REQUESTS_DATA),
      ...Object.keys(identityTypes.REQUESTS_DATA),
      ...Object.keys(authTypes.REQUESTS_DATA),
      ...Object.keys(settingsTypes.REQUESTS_DATA),
      ...Object.keys(types.REQUESTS_DATA)
    ),
    map((action) => {
      return actions.updateDataLoaders({
        name: action.type,
        status: true,
      });
    })
  );
};

const parseType = (type: string) => {
  if (type.indexOf("_FULFILLED") !== -1) return type.replace("_FULFILLED", "");
  if (type.indexOf("_CANCELLED") !== -1) return type.replace("_CANCELLED", "");
  if (type.indexOf("_REJECTED") !== -1) return type.replace("_REJECTED", "");
};

export const loadersResponsesDataEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>
) => {
  return action$.pipe(
    ofType(
      ...Object.keys(campaignsTypes.RESPONSES_DATA),
      ...Object.keys(identityTypes.RESPONSES_DATA),
      ...Object.keys(leadTypes.RESPONSES_DATA),
      ...Object.keys(authTypes.RESPONSES_DATA),
      ...Object.keys(settingsTypes.RESPONSES_DATA),
      ...Object.keys(types.RESPONSES_DATA)
    ),
    map((action) => {
      return actions.updateDataLoaders({
        name: parseType(action.type),
        status: false,
      });
    })
  );
};
export const loadersResponsesBtnEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>
) => {
  return action$.pipe(
    ofType(
      ...Object.keys(campaignsTypes.RESPONSES_ACTIONS),
      ...Object.keys(identityTypes.RESPONSES_ACTIONS),
      ...Object.keys(leadTypes.RESPONSES_ACTIONS),
      ...Object.keys(authTypes.RESPONSES_ACTIONS),
      ...Object.keys(settingsTypes.RESPONSES_ACTIONS),
      ...Object.keys(types.RESPONSES_ACTIONS)
    ),
    map((action) => {
      return actions.updateBtnLoaders({
        name: parseType(action.type),
        status: false,
      });
    })
  );
};

import { ajax } from "rxjs/ajax";

const apiService = (endpoint: any) => {
  return ajax({
    ...endpoint,
    crossDomain: true,
    withCredentials: false,
    timeout: 30000,
    // createXHR: function () {
    //   return new XMLHttpRequest();
    // },
  });
};

export default apiService;

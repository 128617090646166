import React, { useState, useEffect, useCallback } from "react";

import { v4 as uuidv4 } from "uuid";
import {
  CampaignActionsBar,
  CampaignBarRunning,
  CampaignTitleBar,
  CampaignBarPause,
  CampaignBarCanceled,
  LeadsBar,
  StatBar,
  Paginate,
  CampaignBarLoading,
  CampaignLimits,
} from "@lagrowthmachine/lgmwebapp";
import { useDispatch } from "react-redux";
import config from "../../../../config";
import constants from "../../../../config/constants";
import LeadASide from "../../../../components/LeadASide";
import * as actions from "../../actions";
import * as authActions from "../../../Auth/actions";
import * as identitiesActions from "../../../Identities/actions";
import * as leadsActions from "../../../Leads/actions";
import * as globalActions from "../../../../redux/actions";
import Modal, { types as modalTypes } from "../Modals";

import { Link, useParams, useHistory } from "react-router-dom";
import { getLastStatus, getLastAction, generateCsv } from "../../helpers";
import useDebounce from "../../../../helpers/useDebounce";
import useSelector from "../../../../redux/useSelector";
import { ICampaignState } from "../../interfaces/IState";
import validSubscription from "../../../../helpers/validSubscription";
import shouldWidgetShowDisconnect from "../../helpers/shouldWidgetShowDisconnect";
import analyticsCall from "../../../../helpers/analyticsCall";

const timescales = [
  { id: "1", name: "last 24 hours" },
  { id: "2", name: "last 48 hours" },
  { id: "7", name: "last week" },
  { id: "14", name: "last 2 weeks" },
  { id: "30", name: "last month" },
  { id: "40", name: "all time" },
];

function CampaignBar({
  campaign,
  identities,
  identity,
  audiences,
  audience,
  onEditCampaignState,
  history,
}: {
  campaign: any;
  identities: any[];
  identity: any;
  audiences: any[];
  audience: any;
  onEditCampaignState: (status: string) => void;
  history: History | any;
}) {
  const dispatch = useDispatch();

  if (!campaign || !campaign.status) return null;

  const onResume = () => {
    onEditCampaignState("START");
  };
  const onPause = () => {
    onEditCampaignState("PAUSE");
  };
  const onStop = () => {
    onEditCampaignState("CANCEL");
  };

  const goToSequence = (preview: boolean) => {
    dispatch(
      globalActions.modalUpdate({
        name: "previewGraph",
        data: { campaignId: campaign.id },
      })
    );
  };

  const crmConfigure = (crm: any) => {
    history.push("/settings/crm" + (crm ? "?crm=" + crm : ""));
  };

  const onReconnect = () => {
    history.push("/identities");
  };
  switch (campaign.status) {
    case "RUNNING":
      return (
        <CampaignBarRunning
          id={campaign.identity.id}
          widget={{
            id: String(Math.floor(100 * Math.random())),
            firstName: identity.firstName,
            lastName: identity.lastName,
            avatar: identity.avatar,
            has_circle: shouldWidgetShowDisconnect(
              campaign.reconnect,
              campaign.sequence?.channels
            ),
            is_disconnected: shouldWidgetShowDisconnect(
              campaign.reconnect,
              campaign.sequence?.channels
            ),
          }}
          audienceName={campaign.audience?.name}
          audienceSize={campaign.audience?.size}
          goToSequence={goToSequence}
          invalidSequence={!!campaign.sequence?.errors}
          started={campaign.createdAt / 1000}
          onPause={onPause}
          onReconnect={onReconnect}
          crmReconnect={{
            crm: campaign.reconnect.crm,
            crmMapping: campaign.reconnect.crmMapping,
            crmLifecycle: campaign.reconnectcrmLifecycle,
          }}
          campaignHasCrm={{
            HUBSPOT: campaign.hubspot,
            PIPEDRIVE: campaign.pipedrive,
          }}
          crmConfigure={crmConfigure}
        />
      );
    case "PAUSED":
      return (
        <CampaignBarPause
          id={campaign.identity.id}
          widget={{
            id: String(Math.floor(100 * Math.random())),
            firstName: identity.firstName,
            lastName: identity.lastName,
            avatar: identity.avatar,
            has_circle: shouldWidgetShowDisconnect(
              campaign.reconnect,
              campaign.sequence?.channels
            ),
            is_disconnected: shouldWidgetShowDisconnect(
              campaign.reconnect,
              campaign.sequence?.channels
            ),
          }}
          limitExcedeed={campaign.campaignLimitReached}
          blockCustomRestart={!campaign.canEditStep && campaign.isCustom}
          audienceName={campaign.audience?.name || "No audience"}
          audienceSize={campaign.audience?.size}
          goToSequence={goToSequence}
          invalidSequence={!!campaign.sequence?.errors}
          started={campaign.createdAt / 1000}
          onResume={onResume}
          onStop={onStop}
          onReconnect={onReconnect}
          crmReconnect={{
            crm: campaign.reconnect.crm,
            crmMapping: campaign.reconnect.crmMapping,
            crmLifecycle: campaign.reconnectcrmLifecycle,
          }}
          campaignHasCrm={{
            HUBSPOT: campaign.hubspot,
            PIPEDRIVE: campaign.pipedrive,
          }}
          crmConfigure={crmConfigure}
        />
      );
    case "CANCELED":
    case "STOPPED":
      return (
        <CampaignBarCanceled
          id={campaign.identity.id}
          widget={{
            id: String(Math.floor(100 * Math.random())),
            firstName: identity.firstName,
            lastName: identity.lastName,
            avatar: identity.avatar,
            has_circle: shouldWidgetShowDisconnect(
              campaign.reconnect,
              campaign.sequence?.channels
            ),
            is_disconnected: shouldWidgetShowDisconnect(
              campaign.reconnect,
              campaign.sequence?.channels
            ),
          }}
          audienceName={campaign.audience?.name || "No audience"}
          audienceSize={campaign.audience?.size}
          goToSequence={goToSequence}
          invalidSequence={!!campaign.sequence?.errors}
          started={campaign.createdAt / 1000}
          onReconnect={onReconnect}
          crmReconnect={{
            crm: campaign.reconnect.crm,
            crmMapping: campaign.reconnect.crmMapping,
            crmLifecycle: campaign.reconnectcrmLifecycle,
          }}
          campaignHasCrm={{
            HUBSPOT: campaign.hubspot,
            PIPEDRIVE: campaign.pipedrive,
          }}
          crmConfigure={crmConfigure}
        />
      );
    default:
      return null;
  }
}

const CampaignOverviewComponent = (props: any) => {
  const [firstLoad, setFirstLoad] = useState(true);
  useEffect(() => {
    setFirstLoad(false);
  }, []);

  const dispatch = useDispatch();
  const history = useHistory();
  const params: any = useParams();
  const loadingReducer = useSelector((state) => state.loadingReducer);
  const campaignReducer: ICampaignState = useSelector(
    (state) => state.campaignReducer
  );
  const audienceReducer = useSelector((state) => state.audienceReducer);
  const identitiesReducer = useSelector((state) => state.identitiesReducer);

  const modalsReducer = useSelector((state) => state.modalsReducer);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const authReducer = useSelector((state) => state.authReducer);

  const [leadAside, setLeadASide] = useState({ id: "", mode: "" });
  const closeLeadAside = () => {
    if (leadAside.id !== "" || leadAside.mode !== "") {
      setLeadASide({ id: "", mode: "" });
    }
  };

  const handleOpenLead = (id: string) => {
    setLeadASide({ id: id, mode: "show" });
  };

  const campaign = campaignReducer.selected;

  if (campaign.status === "READY") {
    //return null
    history.push("/campaigns/" + campaign.id + "/edit");
  }

  if (campaign.status === "DELETED") {
    history.push("/campaigns");
  }

  const audienceId = campaign?.audience?.id;
  const identities = identitiesReducer.list.map((identity: any) => ({
    id: identity.id,
    firstName: identity.firstname || "Noname",
    lastName: identity.lastname || "",
    avatar: identity.profilePicture,
    active: validSubscription(identity.subscription),
  }));
  const identity =
    identities &&
    identities.find((iden: any) => iden.id === campaign?.identity?.id);

  const audiences = audienceReducer.list.map((audience: any) => ({
    id: audience.id,
    name: audience.name,
    size: audience.size,
    active: true,
  }));

  const audience =
    audiences &&
    audiences.find((aud: any) => aud.id === campaign?.audience?.id);

  useEffect(() => {
    if (campaign.name)
      analyticsCall("campaign", `campaign overview: ${campaign.name}`);
  }, [campaign.name]);

  useEffect(() => {
    if (params.id) {
      dispatch(
        actions.loadCampaign({
          query: {},
          params: { id: params.id },
        })
      );
    }
  }, [dispatch, params.id]);

  useEffect(() => {
    dispatch(globalActions.loadAudiences({}));
    dispatch(identitiesActions.loadIdentities({}));
    dispatch(authActions.loadUser({}));
  }, [dispatch]);

  const loadLeads = useCallback(async () => {
    if (audienceId) {
      dispatch(
        actions.loadCampaignLeads({
          query: {
            skip: 0,
            audiences: [audienceId],
            campaignId: campaign.id,
          },
          params: {
            id: params.id,
          },
        })
      );
    }
  }, [audienceId, dispatch, params.id, campaign.id]);

  useEffect(() => {
    loadLeads();
  }, [loadLeads]);

  useEffect(() => {
    const startDate =
      Number(new Date().getTime()) -
      24 *
        3600 *
        1000 *
        Number(
          campaignReducer.statProgressTimeScale ||
            constants.defaultStatProgressTimeScale
        );
    dispatch(
      actions.loadCampaignStats({
        query: {
          startDate: startDate,
        },
        params: {
          id: params.id,
        },
      })
    );
  }, [dispatch, params.id, campaignReducer.statProgressTimeScale]);

  const statProgressAdapter = (data: any) => {
    const timescale = data
      .sort((a: any, b: any) => Number(a.date) - Number(b.date))
      .map((ind: any) => {
        const labels = ind.details.filter((detail: any) => detail.label);
        return {
          id: ind.stepId,
          time0: new Intl.DateTimeFormat(undefined, { weekday: "long" }).format(
            new Date(ind.date * 1000)
          ),
          time1: new Intl.DateTimeFormat(undefined, {
            day: "numeric",
            month: "short",
            year: "numeric",
          }).format(new Date(ind.date * 1000)),
          legend: new Intl.DateTimeFormat(undefined, {
            day: "numeric",
            month: "numeric",
          }).format(new Date(ind.date * 1000)),
          value: labels
            .map((d: any) => d.total)
            .reduce((a: number, b: number) => a + b, 0),
          converted: ind.converted,
          detailsValues: labels.map((d: any) => ({
            id: d.stepId,
            name: d.label,
            color: d.color,
            value: d.total,
          })),
        };
      });
    return timescale;
  };

  const handleChangePage = (page: number) => {
    dispatch(
      actions.updateCampaignLeadsPagination({
        paginate: { current: page, total: campaign.paginate.total },
      })
    );
  };

  const handleDownload = (filterId: string) => {
    dispatch(
      actions.exportCampaignLeads({
        query: {
          skip: 0,
          audiences: [audienceId],
          filterId: filterId,
          shouldExport: true,
        },
        params: {
          id: params.id,
        },
      })
    );
  };

  const handleDownloadStatProgress = () => {
    const csvContent = campaignReducer.stats?.dailyProgress
      .map((histo: any) =>
        histo.details
          .filter((detail: any) => detail.label)
          .map((detail: any) => [
            new Intl.DateTimeFormat(undefined, {
              day: "numeric",
              month: "short",
              year: "numeric",
            }).format(new Date(histo.date * 1000)),
            detail.label,
            detail.color,
            detail.total,
          ])
          .concat([
            [
              new Intl.DateTimeFormat(undefined, {
                day: "numeric",
                month: "short",
                year: "numeric",
              }).format(new Date(histo.date * 1000)),
              "CONVERTED",
              "",
              histo.converted,
            ],
          ])
      )
      .flat(1);
    if (
      generateCsv(
        ["DAY", "LABEL", "COLOR", "VALUE"],
        csvContent,
        campaignReducer.selected?.name + "_progress"
      )
    ) {
      dispatch(actions.exportCampaignStatsFulfilled({}));
    }
  };

  const handleStatIndAction = (
    option: string,
    id: string,
    stepIds: string[]
  ) => {
    switch (option) {
      case "download":
        if (
          generateCsv(
            ["LABEL", "VALUE", "COLOR"],
            campaignReducer.stats.statuts
              .filter(
                (stat: any) => stat.label && stepIds.includes(stat.stepId)
              )
              .map((stat: any) => [stat.label, String(stat.value), stat.color])
              .concat([["GOALS REACHED", campaign.converted || 0, ""]]),
            campaignReducer.selected?.name + "_" + id
          )
        ) {
          dispatch(actions.exportCampaignStatsFulfilled({}));
        }
        break;
      case "duplicate":
        //alert(id);
        //const index = configurationStats.findIndex((_stat: any) => _stat.id === id);

        const configurationStatsWithNew = campaignReducer.customFilters.configurationStats.concat(
          [
            {
              id: uuidv4(),
              campaignId: campaignReducer?.selected?.id,
              stepIds: stepIds,
            },
          ]
        );
        dispatch(
          actions.createCustomFilter({
            configurationStats: configurationStatsWithNew,
            campaignFilters: campaignReducer.customFilters.campaignFilters,
          })
        );
        break;
      case "delete":
        const configurationStatsDeleted = campaignReducer.customFilters.configurationStats.filter(
          (stat: any) => stat.id !== id
        );
        dispatch(
          actions.createCustomFilter({
            configurationStats: configurationStatsDeleted,
            campaignFilters: campaignReducer.customFilters.campaignFilters,
          })
        );
        break;
      default:
        break;
    }
  };

  const handleDeleteCustomFilter = (id: string) => {
    const customFilters = campaignReducer.customFilters;

    const filters = customFilters.campaignFilters
      .map((c: { id: string }) => (c.id !== id ? c : null))
      .filter((f: any) => !!f);
    // console.log({ customFilters, id, filters });
    dispatch(
      actions.createCustomFilter({
        configurationStats: campaignReducer.customFilters.configurationStats,
        campaignFilters: filters,
      })
    );
  };

  const handleEditCustomFilter = (id: string, labelsIds: string[]) => {
    const customFilters = campaignReducer.customFilters;

    customFilters.campaignFilters = customFilters.campaignFilters.map(
      (c: any) => {
        if (c.id === id) {
          // if (c.campaignId === campaign.id && c.id === id) {
          return {
            ...c,
            stepIds: labelsIds,
          };
        }
        return c;
      }
    );
    dispatch(
      actions.createCustomFilter({
        configurationStats: campaignReducer.customFilters.configurationStats,
        campaignFilters: customFilters.campaignFilters,
      })
    );
  };

  const handleNewCustomFilter = (name: string) => {
    const customFilters = campaignReducer.customFilters;

    if (!customFilters?.campaignFilters) {
      customFilters.campaignFilters = [
        {
          id: uuidv4(),
          name,
          campaignId: campaign.id,
          stepIds: [],
        },
      ];
    } else {
      customFilters.campaignFilters.push({
        id: uuidv4(),
        name,
        campaignId: campaign.id,
        stepIds: [],
      });
    }

    dispatch(
      actions.createCustomFilter({
        configurationStats: campaignReducer.customFilters.configurationStats,
        campaignFilters: customFilters.campaignFilters,
      })
    );
  };

  const handleEditCustomStats = (id: string, labelsIds: string[]) => {
    const customFilters = campaignReducer.customFilters;
    customFilters.configurationStats = customFilters.configurationStats.map(
      (c: any) => {
        if (c.id === id) {
          // if (c.campaignId === campaign.id && c.id === id) {
          return {
            ...c,
            stepIds: labelsIds,
          };
        }
        return c;
      }
    );
    dispatch(
      actions.createCustomFilter({
        configurationStats: customFilters.configurationStats,
        campaignFilters: campaignReducer.customFilters.campaignFilters,
      })
    );
  };

  const handleEditCampaignState = (status: string) => {
    dispatch(
      actions.editCampaignState({
        callback: () => dispatch(authActions.loadUser({})),
        params: {
          id: campaign?.id,
          status,
        },
      })
    );
  };

  const handleTitleBarActions = (action: string) => {
    switch (action) {
      case "info":
        handleModal({
          name: modalTypes.campaignInfo,
          data: {
            name: campaign.name,
            description: campaign.description,
          },
        });
        break;
      case "delete":
        handleModal({
          name: modalTypes.campaignDelete,
          data: {
            campaignId: campaign.id,
            campaign: campaign,
            //refresh: () => history.push("/campaigns")
          },
        });
        break;
    }
  };

  const handleModal = ({ name, data }: { name: string; data: any }) => {
    dispatch(globalActions.modalUpdate({ name, data }));
  };

  const handleAddToAudience = (audienceId: string, ids: string[] | string) => {
    let leadIds: string[] = [];

    if (ids === "all") {
      leadIds = campaign.leads_items.map((lead: { id: string }) => lead.id);
    } else if (Array.isArray(ids)) {
      leadIds = ids;
    }
    handleModal({
      name: modalTypes.campaignNewAudience,
      data: { selectedLeads: leadIds, audienceId },
    });
  };

  const handleNewAudience = (ids: string[] | string) => {
    let leadIds: string[] = [];

    if (ids === "all") {
      leadIds = campaign.leads_items.map((lead: { id: string }) => lead.id);
    } else if (Array.isArray(ids)) {
      leadIds = ids;
    }
    handleModal({
      name: modalTypes.campaignNewAudience,
      data: { selectedLeads: leadIds },
    });
  };

  const handleAppendNote = (ids: string[] | string, note: string) => {
    let leadIds: string[] = [];

    if (ids === "all") {
      leadIds = campaign.leads_items.map((lead: { id: string }) => lead.id);
    } else if (Array.isArray(ids)) {
      leadIds = ids;
    }
    dispatch(
      leadsActions.editLeads({
        body: {
          actionName: "appendNote",
          leadIds,
          note,
        },
      })
    );
  };

  useEffect(
    () => {
      if (debouncedSearchTerm !== campaign.leadsFilters?.keywords) {
        dispatch(
          actions.updateCampaignFilters({
            leadsFilters: {
              ...campaign.leadsFilters,
              keywords: debouncedSearchTerm,
            },
          })
        );
      } else if (
        !debouncedSearchTerm &&
        campaign.leadsFilters?.keywords !== "" &&
        campaign.id
      ) {
        dispatch(
          actions.updateCampaignFilters({
            leadsFilters: {
              ...campaign.leadsFilters,
              keywords: "",
            },
          })
        );
      }
    },
    [debouncedSearchTerm, dispatch, campaign.leadsFilters] // Only call effect if debounced search term changes
  );

  const handleSelectObj = (filterId: string) => {
    dispatch(
      actions.updateCampaignFilters({
        leadsFilters: {
          ...campaign.leadsFilters,
          filterId,
        },
      })
    );
  };

  const handleOnPauseLeads = (ids: string[] | string) => {
    let leadIds: string[] = [];

    if (ids === "all") {
      leadIds = campaign.leads_items.map((lead: { id: string }) => lead.id);
    } else if (Array.isArray(ids)) {
      leadIds = ids;
    }
    handleModal({
      name: modalTypes.confirmLeadStatus,
      data: {
        selectedLeads: leadIds,
        status: "pause",
        campaignId: campaign.id,
        audienceId: campaign.audience?.id,
      },
    });
  };

  const handleOnResumeLeads = (ids: string[] | string) => {
    let leadIds: string[] = [];

    if (ids === "all") {
      leadIds = campaign.leads_items.map((lead: { id: string }) => lead.id);
    } else if (Array.isArray(ids)) {
      leadIds = ids;
    }
    handleModal({
      name: modalTypes.confirmLeadStatus,
      data: {
        selectedLeads: leadIds,
        status: "resume",
        campaignId: campaign.id,
        audienceId: campaign.audience?.id,
      },
    });
  };

  const handleOnStopLeads = (ids: string[] | string) => {
    let leadIds: string[] = [];

    if (ids === "all") {
      leadIds = campaign.leads_items.map((lead: { id: string }) => lead.id);
    } else if (Array.isArray(ids)) {
      leadIds = ids;
    }
    handleModal({
      name: modalTypes.confirmLeadStatus,
      data: { selectedLeads: leadIds, status: "stop", campaignId: campaign.id },
    });
  };

  const getTotalPaginate = () => {
    const filterId = campaign?.leadsFilters?.filterId;
    return (
      Math.ceil(
        campaign?.paginate?.total[filterId || "all"] / config.limitLeads
      ) || 1
    );
  };

  const onChangeStatProgressTime = (timescale: string) => {
    dispatch(
      actions.loadCampaignProgress({
        query: {},
        params: {
          id: campaign.id,
        },
        timescale: timescale,
      })
    );
  };

  const getCustomList = () => {
    if (!Array.isArray(campaignReducer.customFilters?.campaignFilters))
      return [];
    const filtered = campaignReducer.customFilters?.campaignFilters?.filter(
      (f: any) => {
        if (f.campaignId === campaign.id) return f;
        return false;
      }
    );
    if (!filtered) return [];
    const filterId = campaign?.leadsFilters?.filterId;

    // console.log({ filtered, filterId });
    // if (["all"].indexOf(filterId) !== -1) return [];
    return (
      filtered.map((f: { id: string; name: string; stepIds: string[] }) => ({
        id: f.id,
        name: f.name,
        total: campaign.paginate?.total[f.id || filterId] || 0,
        labelsIds: f.stepIds,
      })) || []
    );
  };
  const handleRedirectCampaigns = () => {
    history.push("/campaigns");
  };

  const totalPaginate = getTotalPaginate();
  const customList = getCustomList();

  const configurationStats = campaignReducer.customFilters.configurationStats.filter(
    (stat: any) => stat.campaignId === campaignReducer?.selected?.id
  );

  const handleEditCampaign = (body: {
    name?: string;
    description?: string;
  }) => {
    dispatch(
      actions.editCampaign({
        body,
        params: {
          id: campaign?.id,
        },
      })
    );
  };

  const onUpgrade = () => {
    history.push("/settings/pricing");
  };

  useEffect(() => {
    if (campaign.id === params?.id && campaign.pauseWarning) {
      const stopped = {
        crm: campaign.pauseWarning?.warnings?.includes("crm") && {
          availablePlans: authReducer.user.campaignPermissions?.crmPermissions,
        },
        enrich: campaign.pauseWarning?.warnings?.includes("enrich") && {
          availablePlans:
            authReducer.user.campaignPermissions?.enrichPermission,
        },
      };
      const warningId = campaign.pauseWarning.id;
      const warningsSeen =
        localStorage?.getItem("warnings_seen")?.split("___") || [];
      if (!warningsSeen.includes(warningId)) {
        dispatch(
          globalActions.modalUpdate({
            name: "campaignStopped",
            data: { stopped, warningId },
          })
        );
      }
    }
  }, [dispatch, params, campaign.id]);

  const progressTimeScale =
    campaignReducer.statProgressTimeScale ||
    constants.defaultStatProgressTimeScale;
  return (
    <React.Fragment>
      <LeadASide
        id={leadAside.id}
        mode={leadAside.mode}
        audiences={[]} //audiences in which a new lead should be added to
        onClose={closeLeadAside}
        callback={loadLeads}
      />
      <div className="campaigns_overview_container">
        <Modal
          name={modalsReducer.name}
          data={modalsReducer.data}
          onRequestClose={() =>
            handleModal({ name: modalTypes.reset, data: {} })
          }
          onCampaignEdit={handleEditCampaign}
        />
        <CampaignTitleBar
          name={campaign?.name}
          lang="fr"
          status={
            campaign?.status !== "CANCELED" ? campaign?.status : "STOPPED"
          }
          onClickAction={handleTitleBarActions}
          goToCampaigns={handleRedirectCampaigns}
        />

        {campaign && (
          <CampaignActionsBar
            campaignId={campaign?.id}
            page="overview"
            linkC={Link}
          />
        )}
        {!loadingReducer.dataTypesLoaders.LOAD_CAMPAIGN &&
        !loadingReducer.dataTypesLoaders.LOAD_AUDIENCES &&
        !loadingReducer.dataTypesLoaders.LOAD_IDENTITIES &&
        !loadingReducer.dataTypesLoaders.LOAD_SEQUENCES_ALL &&
        !firstLoad ? (
          <CampaignBar
            campaign={campaign}
            identities={identities}
            identity={identity}
            audiences={audiences}
            audience={audience}
            onEditCampaignState={handleEditCampaignState}
            history={history}
          />
        ) : (
          <CampaignBarLoading />
        )}

        {campaignReducer.selected?.campaignLimitReached &&
          authReducer.user.campaignPermissions?.maxRunningCampaigns && (
            <React.Fragment>
              <CampaignLimits
                permission={
                  authReducer.user.campaignPermissions
                    ?.maxRunningCampaignsPermission
                    ? {
                        label: "BUSINESS",
                        available: ["BUSINESS"],
                      }
                    : undefined
                }
                maxRunningCampaigns={
                  authReducer.user.campaignPermissions?.maxRunningCampaigns
                }
                running={authReducer.user.campaignPermissions?.running}
                plan={authReducer.user.plan}
                onUpgrade={onUpgrade}
              />
            </React.Fragment>
          )}
        {campaignReducer.stats.statuts &&
          campaignReducer.stats.statuts?.length > 0 &&
          campaignReducer.stats.statuts.filter(
            (s: { label: string }) => s.label
          ).length !== 0 &&
          configurationStats && (
            <StatBar
              key={configurationStats.length}
              ind_values={configurationStats.map((stat: any) => ({
                id: stat.id,
                name: "Key performance indicators",
                time0: "All time",
                action: (option: string) =>
                  handleStatIndAction(option, stat.id, stat.stepIds),
                newKpiConfig: handleEditCustomStats,
                converted: campaign.converted,
                displayedValues: stat.stepIds,
                deletable: configurationStats.length !== 1,
                values: campaignReducer.stats.statuts
                  .filter((_status: any) => _status.stepId !== "START")
                  .map((_status: any) => ({
                    id: _status.stepId,
                    name: _status.label || _status.stepId,
                    value: _status.value,
                    color: _status.color,
                  })),
              }))}
              progress_values={{
                name: "Daily progress",
                stat_time: {
                  timescales: timescales,
                  timescale: String(progressTimeScale),
                  chooseTime: (_timescale: string) =>
                    onChangeStatProgressTime(_timescale),
                },
                download: handleDownloadStatProgress,
                timescale: statProgressAdapter(
                  campaignReducer.stats?.dailyProgress
                ),
              }}
            />
          )}

        {campaign?.leads_items && (
          <React.Fragment>
            <LeadsBar
              openLead={handleOpenLead}
              leads_control={{
                timescales,
                timescale: { id: "World", name: "all time" },
                labels:
                  campaignReducer.stats.statuts
                    ?.filter((s: { label: string }) => s.label)
                    .map((d: any) => ({
                      id: d.stepId,
                      name: d.label,
                      color: d.color,
                      value: d.value,
                    })) || [],
                basicList: [
                  {
                    id: "all",
                    name: "all",
                    total: campaign.paginate?.total?.all,
                  },
                  {
                    id: "paused",
                    name: "paused",
                    total: campaign.paginate?.total?.paused,
                  },
                  {
                    id: "started",
                    name: "started",
                    total: campaign.paginate?.total?.started,
                  },
                  {
                    id: "converted",
                    name: "converted",
                    total: campaign.paginate?.total?.converted,
                  },
                ],
                customList,

                selectedId: campaign.leadsFilters.filterId || "all",
                kpiOpened: null,

                chooseTime: (e) => console.log("LeadsControl_choose_time", e),
                search: setSearchTerm,
                download: handleDownload,
                delete: handleDeleteCustomFilter,
                onModifyKpi: handleEditCustomFilter,
                new_obj: handleNewCustomFilter,
                select_obj: handleSelectObj,
              }}
              leads_items={campaign.leads_items.map((lead: any) => ({
                id: lead.id,
                firstName: lead.firstName,
                lastName: lead.lastName,
                avatar: lead.avatar || lead.profilePicture,
                companyName: lead.companyName || "none",
                subtitle: lead.subtitle || lead.shortBio || "none",
                email: lead.proEmail || "none",
                phone: lead.phone || "none",
                lastAction: getLastAction(lead.lastActions?.last, campaign.id),
                website: lead.website,
                status: getLastStatus(
                  lead.lastActions?.lastStatus,
                  campaign.id,
                  lead.pausedCampaigns
                ),
              }))}
              stop={handleOnStopLeads}
              pause={handleOnPauseLeads}
              resume={handleOnResumeLeads}
              note={handleAppendNote}
              add_to_audience={handleAddToAudience}
              new_audience={handleNewAudience}
              audiences={audiences}
            />
            <Paginate
              goToPage={handleChangePage}
              current={campaign?.paginate?.current || 0}
              total={totalPaginate > 0 ? totalPaginate : 1}
            />
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

export default CampaignOverviewComponent;

export const adapterPlans = (payload: any) => {
  let _plans = { BUSINESS: {}, PRO: {}, STARTER: {} }; //keep in price decreasing order for pricing display
  payload.plans.some((_plan: any) => {
    let price =
      _plan.amount / (_plan.metadata.recurrence === "monthly" ? 100 : 12 * 100);
    const priceRounded = price === Math.floor(price) ? price : price.toFixed(2);

    const _type = _plan.metadata.type.toUpperCase();
    if (!Object.keys(_plans).includes(_type)) {
      return null;
    }
    _plans[_type][_plan.metadata.recurrence] = {
      price: priceRounded,
      id: _plan.id,
    };
    if (_plan.metadata.discount) {
      _plans[_type][_plan.metadata.recurrence].discount =
        _plan.metadata.discount;
    }
    return null;
  });
  return { plans: _plans };
};
const _toFixed = (_price: number) => {
  return Number(
    _price / 100 === Math.floor(_price / 100)
      ? Math.floor(_price / 100)
      : (_price / 100).toFixed(2)
  );
};

export const adapterPrices = (summary: any, formerPrices: any) => {
  const details = summary.details;
  ["monthly", "yearly"].some((billing: string) => {
    if (details[billing]) {
      details[billing].amount = details[billing].amount / 100;
    }
    return null;
  });

  const prices = {
    type: summary.type === "addIdentity" ? "addId" : summary.type,
    billing: summary.plan?.recurrence || "monthly",
    planTo: summary.plan?.id.toUpperCase(),
    count: summary.identity.count,
    credit: _toFixed(summary.credits),
    couponResult: summary.coupon?.valid && {
      code: summary.coupon.code,
      type: summary.coupon.type,
      percentage: summary.coupon.percentage,
      amount: _toFixed(summary.coupon.amount),
    },
    prorated: summary.prorateAmount
      ? {
          amount: _toFixed(summary.prorateAmount),
          nextBilling: 1000 * summary.nextBillingDate,
        }
      : undefined,
    coupon: summary.coupon?.code || undefined,
    total: _toFixed(summary.total),
    nextTotal: _toFixed(summary.nextTotal),
    daysUntilNextBilling: summary.daysUntilNextBilling,
    vat: {
      rate: summary.vat.percentage,
      amount: _toFixed(summary.vat.price),
    },
    identities: formerPrices.identities || [],
    nextBillingDate: summary.nextBillingDate,
    nextPlanId: summary.nextPlanId,
    details: summary.details,
  };
  return prices;
};

import queryString from "query-string";

/**
 *  Every are Array converted to string
 *  example: `audiences: [a,b]` ==> `audiences=a,b`
 */

export const encodeQuery = (query: any) => {
  let newQuery: any = {};

  Object.keys(query).map(key => {
    if (Array.isArray(query[key])) {
      newQuery[key] = query[key].toString();
    } else {
      newQuery[key] = query[key];
    }
    return false;
  });
  return queryString.stringify(newQuery);
};

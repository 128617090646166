import Home from "./Home/";
import Details from "./Details/";
import Past from "./Past/";
import Limits from "./Limits/";
import "./style.scss";

const Views = {
  Home,
  Past,
  Details,
  Limits,
};

export default Views;

export default {
  REQUESTS_ACTIONS: {
    HUBSPOT_CONNECT: "HUBSPOT_CONNECT",
    HUBSPOT_DISCONNECT: "HUBSPOT_DISCONNECT",
    HUBSPOT_MAPPING_EDIT: "HUBSPOT_MAPPING_EDIT",
    HUBSPOT_LIFECYCLE_EDIT: "HUBSPOT_LIFECYCLE_EDIT",

    PIPEDRIVE_CONNECT: "PIPEDRIVE_CONNECT",
    PIPEDRIVE_DISCONNECT: "PIPEDRIVE_DISCONNECT",
    PIPEDRIVE_MAPPING_EDIT: "PIPEDRIVE_MAPPING_EDIT",
    PIPEDRIVE_LIFECYCLE_EDIT: "PIPEDRIVE_LIFECYCLE_EDIT",

    SET_DEFAULT_CARD: "SET_DEFAULT_CARD",
    DELETE_CARD: "DELETE_CARD",
    ATTACH_CARD_TO_USER: "ATTACH_CARD_TO_USER",
    RETRY_PAYMENT: "RETRY_PAYMENT",
    TERMINATE: "TERMINATE",
    DELETE_IDENTITY: "DELETE_IDENTITY",

    PROCESS_PAYMENT: "PROCESS_PAYMENT",

    UN_CANCEL_ID: "UN_CANCEL_ID",
  },
  REQUESTS_DATA: {
    LOAD_LOGS_EXTERNAL: "LOAD_LOGS_EXTERNAL",
    LOAD_LOG_EXTERNAL_DETAIL: "LOAD_LOG_EXTERNAL_DETAIL",

    LOAD_HUBSPOT_CONNECT_URI: "LOAD_HUBSPOT_CONNECT_URI",
    LOAD_HUBSPOT_MAPPINGS: "LOAD_HUBSPOT_MAPPINGS",
    LOAD_HUBSPOT_LIFECYCLES: "LOAD_HUBSPOT_LIFECYCLES",

    LOAD_PIPEDRIVE_CONNECT_URI: "LOAD_PIPEDRIVE_CONNECT_URI",
    LOAD_PIPEDRIVE_MAPPINGS: "LOAD_PIPEDRIVE_MAPPINGS",
    LOAD_PIPEDRIVE_LIFECYCLES: "LOAD_PIPEDRIVE_LIFECYCLES",

    LOAD_PLANS: "LOAD_PLANS",
    LOAD_INVOICES: "LOAD_INVOICES",
    LOAD_CARDS: "LOAD_CARDS",
    LOAD_SUMMARY: "LOAD_SUMMARY",

    LOAD_PRICE: "LOAD_PRICE",
    LOAD_DOWNGRADE: "LOAD_DOWNGRADE",
  },

  RESPONSES_ACTIONS: {
    HUBSPOT_CONNECT_FULFILLED: "HUBSPOT_CONNECT_FULFILLED",
    HUBSPOT_CONNECT_REJECTED: "HUBSPOT_CONNECT_REJECTED",
    HUBSPOT_CONNECT_CANCELLED: "HUBSPOT_CONNECT_CANCELLED",

    HUBSPOT_DISCONNECT_FULFILLED: "HUBSPOT_DISCONNECT_FULFILLED",
    HUBSPOT_DISCONNECT_REJECTED: "HUBSPOT_DISCONNECT_REJECTED",
    HUBSPOT_DISCONNECT_CANCELLED: "HUBSPOT_DISCONNECT_CANCELLED",

    HUBSPOT_MAPPING_EDIT_FULFILLED: "HUBSPOT_MAPPING_EDIT_FULFILLED",
    HUBSPOT_MAPPING_EDIT_REJECTED: "HUBSPOT_MAPPING_EDIT_REJECTED",
    HUBSPOT_MAPPING_EDIT_CANCELLED: "HUBSPOT_MAPPING_EDIT_CANCELLED",

    HUBSPOT_LIFECYCLE_EDIT_FULFILLED: "HUBSPOT_LIFECYCLE_EDIT_FULFILLED",
    HUBSPOT_LIFECYCLE_EDIT_REJECTED: "HUBSPOT_LIFECYCLE_EDIT_REJECTED",
    HUBSPOT_LIFECYCLE_EDIT_CANCELLED: "HUBSPOT_LIFECYCLE_EDIT_CANCELLED",

    PIPEDRIVE_CONNECT_FULFILLED: "PIPEDRIVE_CONNECT_FULFILLED",
    PIPEDRIVE_CONNECT_REJECTED: "PIPEDRIVE_CONNECT_REJECTED",
    PIPEDRIVE_CONNECT_CANCELLED: "PIPEDRIVE_CONNECT_CANCELLED",

    PIPEDRIVE_DISCONNECT_FULFILLED: "PIPEDRIVE_DISCONNECT_FULFILLED",
    PIPEDRIVE_DISCONNECT_REJECTED: "PIPEDRIVE_DISCONNECT_REJECTED",
    PIPEDRIVE_DISCONNECT_CANCELLED: "PIPEDRIVE_DISCONNECT_CANCELLED",

    PIPEDRIVE_MAPPING_EDIT_FULFILLED: "PIPEDRIVE_MAPPING_EDIT_FULFILLED",
    PIPEDRIVE_MAPPING_EDIT_REJECTED: "PIPEDRIVE_MAPPING_EDIT_REJECTED",
    PIPEDRIVE_MAPPING_EDIT_CANCELLED: "PIPEDRIVE_MAPPING_EDIT_CANCELLED",

    PIPEDRIVE_LIFECYCLE_EDIT_FULFILLED: "PIPEDRIVE_LIFECYCLE_EDIT_FULFILLED",
    PIPEDRIVE_LIFECYCLE_EDIT_REJECTED: "PIPEDRIVE_LIFECYCLE_EDIT_REJECTED",
    PIPEDRIVE_LIFECYCLE_EDIT_CANCELLED: "PIPEDRIVE_LIFECYCLE_EDIT_CANCELLED",

    SET_DEFAULT_CARD_FULFILLED: "SET_DEFAULT_CARD_FULFILLED",
    SET_DEFAULT_CARD_REJECTED: "SET_DEFAULT_CARD_REJECTED",
    SET_DEFAULT_CARD_CANCELLED: "SET_DEFAULT_CARD_CANCELLED",

    DELETE_CARD_FULFILLED: "DELETE_CARD_FULFILLED",
    DELETE_CARD_REJECTED: "DELETE_CARD_REJECTED",
    DELETE_CARD_CANCELLED: "DELETE_CARD_CANCELLED",

    ATTACH_CARD_TO_USER_FULFILLED: "ATTACH_CARD_TO_USER_FULFILLED",
    ATTACH_CARD_TO_USER_REJECTED: "ATTACH_CARD_TO_USER_REJECTED",
    ATTACH_CARD_TO_USER_CANCELLED: "ATTACH_CARD_TO_USER_CANCELLED",

    RETRY_PAYMENT_FULFILLED: "RETRY_PAYMENT_FULFILLED",
    RETRY_PAYMENT_REJECTED: "RETRY_PAYMENT_REJECTED",
    RETRY_PAYMENT_CANCELLED: "RETRY_PAYMENT_CANCELLED",

    TERMINATE_FULFILLED: "TERMINATE_FULFILLED",
    TERMINATE_REJECTED: "TERMINATE_REJECTED",
    TERMINATE_CANCELLED: "TERMINATE_CANCELLED",

    DELETE_IDENTITY_FULFILLED: "DELETE_IDENTITY_FULFILLED",
    DELETE_IDENTITY_REJECTED: "DELETE_IDENTITY_REJECTED",
    DELETE_IDENTITY_CANCELLED: "DELETE_IDENTITY_CANCELLED",

    PROCESS_PAYMENT_FULFILLED: "PROCESS_PAYMENT_FULFILLED",
    PROCESS_PAYMENT_REJECTED: "PROCESS_PAYMENT_REJECTED",
    PROCESS_PAYMENT_CANCELLED: "PROCESS_PAYMENT_CANCELLED",

    UN_CANCEL_ID_FULFILLED: "UN_CANCEL_ID_FULFILLED",
    UN_CANCEL_ID_REJECTED: "UN_CANCEL_ID_REJECTED",
    UN_CANCEL_ID_CANCELLED: "UN_CANCEL_ID_CANCELLED",
  },
  RESPONSES_DATA: {
    LOAD_LOGS_EXTERNAL_FULFILLED: "LOAD_LOGS_EXTERNAL_FULFILLED",
    LOAD_LOGS_EXTERNAL_REJECTED: "LOAD_LOGS_EXTERNAL_REJECTED",
    LOAD_LOGS_EXTERNAL_CANCELLED: "LOAD_LOGS_EXTERNAL_CANCELLED",

    LOAD_LOG_EXTERNAL_DETAIL_FULFILLED: "LOAD_LOG_EXTERNAL_DETAIL_FULFILLED",
    LOAD_LOG_EXTERNAL_DETAIL_REJECTED: "LOAD_LOG_EXTERNAL_DETAIL_REJECTED",
    LOAD_LOG_EXTERNAL_DETAIL_CANCELLED: "LOAD_LOG_EXTERNAL_DETAIL_CANCELLED",

    LOAD_HUBSPOT_CONNECT_URI_FULFILLED: "LOAD_HUBSPOT_CONNECT_URI_FULFILLED",
    LOAD_HUBSPOT_CONNECT_URI_REJECTED: "LOAD_HUBSPOT_CONNECT_URI_REJECTED",
    LOAD_HUBSPOT_CONNECT_URI_CANCELLED: "LOAD_HUBSPOT_CONNECT_URI_CANCELLED",

    LOAD_HUBSPOT_MAPPINGS_FULFILLED: "LOAD_HUBSPOT_MAPPINGS_FULFILLED",
    LOAD_HUBSPOT_MAPPINGS_REJECTED: "LOAD_HUBSPOT_MAPPINGS_REJECTED",
    LOAD_HUBSPOT_MAPPINGS_CANCELLED: "LOAD_HUBSPOT_MAPPINGS_CANCELLED",

    LOAD_HUBSPOT_LIFECYCLES_FULFILLED: "LOAD_HUBSPOT_LIFECYCLES_FULFILLED",
    LOAD_HUBSPOT_LIFECYCLES_REJECTED: "LOAD_HUBSPOT_LIFECYCLES_REJECTED",
    LOAD_HUBSPOT_LIFECYCLES_CANCELLED: "LOAD_HUBSPOT_LIFECYCLES_CANCELLED",

    LOAD_PIPEDRIVE_CONNECT_URI_FULFILLED:
      "LOAD_PIPEDRIVE_CONNECT_URI_FULFILLED",
    LOAD_PIPEDRIVE_CONNECT_URI_REJECTED: "LOAD_PIPEDRIVE_CONNECT_URI_REJECTED",
    LOAD_PIPEDRIVE_CONNECT_URI_CANCELLED:
      "LOAD_PIPEDRIVE_CONNECT_URI_CANCELLED",

    LOAD_PIPEDRIVE_MAPPINGS_FULFILLED: "LOAD_PIPEDRIVE_MAPPINGS_FULFILLED",
    LOAD_PIPEDRIVE_MAPPINGS_REJECTED: "LOAD_PIPEDRIVE_MAPPINGS_REJECTED",
    LOAD_PIPEDRIVE_MAPPINGS_CANCELLED: "LOAD_PIPEDRIVE_MAPPINGS_CANCELLED",

    LOAD_PIPEDRIVE_LIFECYCLES_FULFILLED: "LOAD_PIPEDRIVE_LIFECYCLES_FULFILLED",
    LOAD_PIPEDRIVE_LIFECYCLES_REJECTED: "LOAD_PIPEDRIVE_LIFECYCLES_REJECTED",
    LOAD_PIPEDRIVE_LIFECYCLES_CANCELLED: "LOAD_PIPEDRIVE_LIFECYCLES_CANCELLED",

    LOAD_PLANS_FULFILLED: "LOAD_PLANS_FULFILLED",
    LOAD_PLANS_REJECTED: "LOAD_PLANS_REJECTED",
    LOAD_PLANS_CANCELLED: "LOAD_PLANS_CANCELLED",

    LOAD_INVOICES_FULFILLED: "LOAD_INVOICES_FULFILLED",
    LOAD_INVOICES_REJECTED: "LOAD_INVOICES_REJECTED",
    LOAD_INVOICES_CANCELLED: "LOAD_INVOICES_CANCELLED",

    LOAD_CARDS_FULFILLED: "LOAD_CARDS_FULFILLED",
    LOAD_CARDS_REJECTED: "LOAD_CARDS_REJECTED",
    LOAD_CARDS_CANCELLED: "LOAD_CARDS_CANCELLED",

    LOAD_SUMMARY_FULFILLED: "LOAD_SUMMARY_FULFILLED",
    LOAD_SUMMARY_REJECTED: "LOAD_SUMMARY_REJECTED",
    LOAD_SUMMARY_CANCELLED: "LOAD_SUMMARY_CANCELLED",

    LOAD_PRICE_FULFILLED: "LOAD_PRICE_FULFILLED",
    LOAD_PRICE_REJECTED: "LOAD_PRICE_REJECTED",
    LOAD_PRICE_CANCELLED: "LOAD_PRICE_CANCELLED",

    LOAD_DOWNGRADE_FULFILLED: "LOAD_DOWNGRADE_FULFILLED",
    LOAD_DOWNGRADE_REJECTED: "LOAD_DOWNGRADE_REJECTED",
    LOAD_DOWNGRADE_CANCELLED: "LOAD_DOWNGRADE_CANCELLED",
  },

  SETTINGS_CLEAR: "SETTINGS_CLEAR",
  LOGS_EXTERNAL_KEY_COPIED: " LOGS_EXTERNAL_KEY_COPIED",
  OPEN_CONFIRMATION_DELETE_WEBHOOK: "OPEN_CONFIRMATION_DELETE_WEBHOOK",
  LOGS_EXTERNAL_PAGINATE: "LOGS_PAGINATE",
  LOGS_EXTERNAL_UPDATE_KEYWORDS: "LOGS_UPDATE_KEYWORDS",
  LOGS_EXTERNAL_SHOW_ERRORS: "LOGS_SHOW_ERRORS",
};

import React, { useEffect, useState, useCallback } from "react";
import {
  AudienceNewBar,
  AudienceBar,
  Leads,
  CreateNewAudience,
  AudienceMenu,
  Paginate,
} from "@lagrowthmachine/lgmwebapp";
import useDebounce from "../../../../helpers/useDebounce";
import config from "../../../../config";
import { useDispatch } from "react-redux";
import * as actions from "../../actions";
import * as globalActions from "../../../../redux/actions";
import Modal, { types as modalTypes } from "../Modals";
import LeadASide from "../../../../components/LeadASide";
import useSelector from "../../../../redux/useSelector";
import { ILeadState } from "../../interfaces/IState";
import analyticsCall from "../../../../helpers/analyticsCall";
import * as adapterLead from "../../adapters/lead";

const LeadsComponent = (props: any) => {
  const dispatch = useDispatch();
  const leadsReducer: ILeadState = useSelector((state) => state.leadsReducer);
  const audienceReducer = useSelector((state) => state.audienceReducer);
  const modalsReducer = useSelector((state) => state.modalsReducer);
  const socketReducer = useSelector((state) => state.socketReducer);
  const loadingReducer = useSelector((state) => state.loadingReducer);
  const totalPaginate =
    Math.ceil(leadsReducer?.paginate?.total / config.limitLeads) || 1;

  const [leadAside, setLeadASide] = useState({ id: "", mode: "" });
  const closeLeadAside = () => {
    if (leadAside.id !== "" || leadAside.mode !== "") {
      setLeadASide({ id: "", mode: "" });
    }
  };

  useEffect(() => {
    dispatch(
      actions.filterUpdate({
        audiences: [],
        keywords: "",
        firstname: "",
        lastname: "",
        email: "",
        company: "",
        gender: "",
        inMultipleAudienceOnly: false,
        withNoAudienceOnly: false,
      })
    );
  }, [dispatch]);

  const loadLeads = () => {
    dispatch(actions.loadLeads({}));
  };

  const hasFilter = useCallback(() => {
    return (
      leadsReducer.filters?.audiences?.length !== 0 ||
      leadsReducer.filters?.firstname !== "" ||
      leadsReducer.filters?.lastname !== "" ||
      leadsReducer.filters?.email !== "" ||
      leadsReducer.filters?.company !== "" ||
      leadsReducer.filters?.gender !== "" ||
      leadsReducer.filters?.inMultipleAudienceOnly ||
      leadsReducer.filters?.withNoAudienceOnly
    );
  }, [leadsReducer.filters]);

  useEffect(() => {
    if (leadsReducer.filters) {
      if (hasFilter()) {
        analyticsCall("leads", `leads filtered`);
      } else {
        analyticsCall("leads", `leads index`);
      }
    }
  }, [leadsReducer.filters]);

  useEffect(() => {
    dispatch(globalActions.scrollToTop());
  }, [dispatch]);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(globalActions.socketIsWidgetAvailable());
    }, 4000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [dispatch, socketReducer.widgetAvailable]);

  useEffect(() => {
    if (leadsReducer.paginate.total > 0 && leadsReducer.list.length === 0) {
      dispatch(
        actions.paginateLeadsUpdate({
          total: leadsReducer.paginate.total,
          current: totalPaginate - 1,
        })
      );
    }
  }, [dispatch, leadsReducer.paginate.total, leadsReducer.list, totalPaginate]);

  const handleChangePage = (page: number) => {
    dispatch(
      actions.paginateLeadsUpdate({
        current: page,
        total: leadsReducer.paginate.total,
      })
    );
  };

  const handleOnDownload = () => {
    if (leadsReducer.list.length > 0) {
      dispatch(actions.exportLeads({}));
    }
  };

  const handleOnAddLead = () => {
    setLeadASide({ id: "", mode: "new" });
  };

  const handleOpenLead = (id: string) => {
    setLeadASide({ id: id, mode: "show" });
  };

  const handleModal = ({ name, data }: { name: string; data: any }) => {
    dispatch(globalActions.modalUpdate({ name, data }));
  };

  const handleLeadsBarActions = (obj: any, selectedLeads: string[] | "all") => {
    //closeLeadAside();

    if (selectedLeads === "all") {
      selectedLeads = leadsReducer.list.map((lead: { id: string }) => lead.id);
    }
    switch (obj.action) {
      case "add_note":
        dispatch(
          actions.editLeads({
            body: {
              actionName: "appendNote",
              leadIds: selectedLeads,
              note: obj.note,
              filters: leadsReducer.filters,
            },
          })
        );
        break;
      case "add_to_audience":
        const leadsToRemove = leadsReducer.list
          .filter((lead: any) => lead.audiences.includes(obj.obj.to))
          .map((lead: { id: string }) => lead.id);
        selectedLeads = selectedLeads.filter(
          (lead: any) => !leadsToRemove.includes(lead)
        );
        if (selectedLeads.length !== 0) {
          dispatch(
            actions.editLeads({
              body: {
                actionName: "addToAudience",
                audienceId: obj.obj.to,
                leadIds: selectedLeads,
              },
            })
          );
        }
        break;
      case "move_to_audience":
        dispatch(
          actions.editLeads({
            body: {
              actionName: "moveToAudience",
              audienceId: obj.obj.to,
              leadIds: selectedLeads,
              audienceIdsToReplace: [obj.obj.from],
              filters: leadsReducer.filters,
            },
          })
        );
        break;
      case "remove_from_audience":
        dispatch(
          actions.editLeads({
            body: {
              actionName: "removeFromAudience",
              leadIds: selectedLeads,
              audienceIdsToReplace: [obj.obj.from],
              filters: leadsReducer.filters,
            },
          })
        );
        break;

      case "delete_leads":
        dispatch(
          globalActions.modalUpdate({
            name: "deleteLeads",
            data: { selectedLeads: selectedLeads },
          })
        );
        break;
      default:
        break;
    }
  };

  let audienceMenuRef: any;
  const [forceFilters, setForceFilters] = useState({});

  useEffect(() => {
    if (Object.keys(forceFilters).length !== 0) {
      setForceFilters({});
    }
  }, [forceFilters]);

  const onUpdateFilter = (audId: string) => {
    setForceFilters({ audiences_selected: [audId, "all"] });
  };

  const onResetFilter = () => {
    audienceMenuRef.resetFilters();
  };

  const onNewAudience = (type: string) => {
    //closeLeadAside();
    if (type === "new") {
      handleModal({
        name: modalTypes.newAudienceCreate,
        data: { callback: (id: string) => onUpdateFilter(id) },
      });
    }
    if (type === "linkedin") {
      analyticsCall("leads new", `leads linkedin import`);
      dispatch(globalActions.socketOpenBrowser());
    }
    if (type === "csv") {
      handleModal({ name: modalTypes.importAudienceCSV, data: {} });
    }
  };

  const [showCreateNew, setShowCreateNew] = useState(
    audienceReducer.list.length === 0 &&
      leadsReducer.list.length === 0 &&
      !hasFilter()
  );

  useEffect(() => {
    if (!loadingReducer.dataTypesLoaders.LOAD_LEADS) {
      setShowCreateNew(
        audienceReducer.list.length === 0 &&
          leadsReducer.list.length === 0 &&
          !hasFilter()
      );
    }
  }, [
    audienceReducer.list.length,
    leadsReducer.list.length,
    leadsReducer.filters,
  ]);

  const [showMenu, setShowMenu] = useState(false);

  const onChangeAudience = (audIds: string[]) => {
    //closeLeadAside();
    let filters = leadsReducer.filters;
    filters!.audiences = audIds.filter(
      (_audId: string) => !["all", "multiple", "no_audience"].includes(_audId)
    );
    filters!.inMultipleAudienceOnly = audIds.includes("multiple");
    filters!.withNoAudienceOnly = audIds.includes("no_audience");

    delete filters!["leadId"];
    dispatch(actions.filterUpdate(filters));
  };

  const onFilter = (_filter: any) => {
    closeLeadAside();
    let filters = leadsReducer.filters || {};
    const filterHash = _filter.reduce(
      (acc: any, val: any) => ({ ...acc, [val.type]: val.arg }),
      {}
    );

    const basicFilter = {
      firstname: filterHash.firstname || "",
      lastname: filterHash.lastname || "",
      email: filterHash.email || "",
      company: filterHash.company || "",
      gender: filterHash.gender || "",
    };

    filters = { ...filters, ...basicFilter };

    dispatch(actions.filterUpdate(filters));
  };

  const [search, setSearch] = useState("");

  const onSearch = (_search: string) => {
    closeLeadAside();
    setSearch(_search);
  };

  const debouncedSearch = useDebounce(search, 500);

  useEffect(() => {
    if (search !== debouncedSearch) {
      let filters: any = leadsReducer.filters || {};
      filters.keywords = search;
      dispatch(actions.filterUpdate(filters));
    }
  }, [dispatch, debouncedSearch, search]);

  const editAudience = (aud: any) => {
    closeLeadAside();
    dispatch(
      globalActions.modalUpdate({
        name: "audienceEdit",
        data: { audience: aud },
      })
    );
  };

  const removeAudience = (audId: string) => {
    closeLeadAside();
    dispatch(
      globalActions.modalUpdate({ name: "audienceDelete", data: { id: audId } })
    );
  };

  const allColumns = [
    "audiencesWithName",
    "companyName",
    "phone",
    "proEmail",
    "persoEmail",
    "job",
    "location",
    "website",
    "industry",
    "linkedin",
    "twitter",
    "note",
    "bio",
    "company",
    "gender",
    "customAttribute1",
    "customAttribute2",
    "customAttribute3",
    "customAttribute4",
    "customAttribute5",
    "customAttribute6",
    "customAttribute7",
    "customAttribute8",
    "customAttribute9",
    "customAttribute10",
  ];

  const [columns, setColumns] = useState(
    localStorage?.getItem("lgm_columns_1")?.split("___") ||
      (allColumns as string[])
  );

  const onCheckColumns = (_columns: string[]) => {
    setColumns(_columns);
    localStorage?.setItem("lgm_columns_1", _columns.join("___"));
  };

  return (
    <React.Fragment>
      <LeadASide
        id={leadAside.id}
        mode={leadAside.mode}
        audiences={leadsReducer!.filters?.audiences} //audiences in which a new lead should be added to
        onClose={closeLeadAside}
        callback={loadLeads}
      />

      <div className="leads_index">
        <Modal
          name={modalsReducer.name}
          data={modalsReducer.data}
          filters={leadsReducer.filters}
        />
        <AudienceNewBar
          widgetAvailable={socketReducer.widgetAvailable}
          onCreateLead={handleOnAddLead}
          onNewAudience={onNewAudience}
        />
        {!showCreateNew && (
          <AudienceBar
            onToogleMenu={() => setShowMenu(!showMenu)}
            onResetFilter={onResetFilter}
            onSearch={onSearch}
            total={leadsReducer.paginate.total}
            onDownload={handleOnDownload}
            forceSearch={onSearch}
            displayReset={hasFilter()}
            columns={allColumns}
            columnsChecked={columns}
            onCheckColumns={onCheckColumns}
          />
        )}

        {showCreateNew && (
          <CreateNewAudience
            linkedin_disabled={false}
            onAction={onNewAudience}
          />
        )}

        {!showCreateNew && (
          <div className="leads">
            <div className="items">
              <div className="items_wrapper">
                <AudienceMenu
                  ref={(_ref) => (audienceMenuRef = _ref)}
                  forceFilters={forceFilters}
                  editAudience={editAudience}
                  removeAudience={removeAudience}
                  addAudience={() => onNewAudience("new")}
                  audiences={audienceReducer.list}
                  onFilter={onFilter}
                  onChangeAudience={onChangeAudience}
                  enableDeleteAudience={true}
                />
                <div
                  style={!showMenu ? { transform: "translateX(-332px)" } : {}}
                  className="wrapper_content"
                >
                  {leadsReducer.list.length !== 0 && (
                    <Leads
                      columns={allColumns.filter((_col: string) =>
                        columns.includes(_col)
                      )}
                      tooltip={
                        {
                          /*"audiences": "tooltip for audiences"*/
                        }
                      }
                      leadsItems={leadsReducer.list.map((_lead: any) =>
                        adapterLead.adapterTableIN(_lead)
                      )}
                      totalLeadsNumber={leadsReducer.list.length}
                      audiences={audienceReducer.list}
                      onAction={handleLeadsBarActions}
                      openLead={handleOpenLead}
                      closeSidePannel={closeLeadAside}
                      sidePannelReduced={showMenu ? 332 : 0}
                    />
                  )}
                </div>
              </div>
              <Paginate
                goToPage={handleChangePage}
                current={leadsReducer.paginate?.current}
                total={totalPaginate > 0 ? totalPaginate : 1}
              />
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default LeadsComponent;

export const adapterLoadUser = (response: any) => {
  let _response = response;
  _response.user.plan = response.user.plan.toUpperCase();
  let campaignPermissions = _response.user.campaignPermissions;

  [
    "editStepPermission",
    "enrichPermission",
    "campaignReviewPermission",
    "maxRunningCampaignsPermission",
    "crmPermissions",
    "apisPermissions",
  ].some((_permission: string) => {
    if (campaignPermissions[_permission].length === 0) {
      campaignPermissions[_permission] = undefined;
    }
    return null;
  });
  _response.user.campaignPermissions = campaignPermissions;
  return _response;
};

import React, { useEffect, useState } from "react";

import {
  CampaignTitleBar,
  CampaignActionsBar,
  ButtonGroup,
  LeadsBarUp,
  LeadsBarPast,
  type,
  Paginate,
  Toogle,
  LeadsBarLoading,
} from "@lagrowthmachine/lgmwebapp";
import LeadASide from "../../../../components/LeadASide";
import config from "../../../../config";
import { Link, useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { format } from "date-fns";
import constants from "../../../../config/constants";
import * as actions from "../../actions";
import * as authActions from "../../../Auth/actions";
import * as globalActions from "../../../../redux/actions";
import useSelector from "../../../../redux/useSelector";
import Modal, { types as modalTypes } from "../Modals";
import { ICampaignState } from "../../interfaces/IState";
import { getLastStatus } from "../../helpers";
import analyticsCall from "../../../../helpers/analyticsCall";

const reloadCounterTime: number = 30000;

const CampaignActionsComponent = (props: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const campaignReducer: ICampaignState = useSelector(
    (state) => state.campaignReducer
  );
  const modalsReducer = useSelector((state) => state.modalsReducer);
  const loadingReducer = useSelector((state) => state.loadingReducer);
  const authReducer = useSelector((state) => state.authReducer);
  const campaign = campaignReducer.selected;
  const campaignActions = campaignReducer.actions;
  const params: any = useParams();

  const [leadAside, setLeadASide] = useState({ id: "", mode: "" });
  const closeLeadAside = () => {
    if (leadAside.id !== "" || leadAside.mode !== "") {
      setLeadASide({ id: "", mode: "" });
    }
  };

  const handleOpenLead = (id: string) => {
    setLeadASide({ id: id, mode: "show" });
  };

  useEffect(() => {
    if (campaign.name)
      analyticsCall("campaign", `campaign actions: ${campaign.name}`);
  }, [campaign.name]);

  useEffect(() => {
    dispatch(
      actions.loadCampaign({
        query: {},
        params: { id: params.id },
      })
    );
    dispatch(globalActions.loadAudiences({}));
    dispatch(authActions.loadUser({}));
  }, [dispatch, params.id]);

  if (campaign.status === "READY") {
    history.push("/campaigns/" + campaign.id + "/edit");
  }

  if (campaign.status === "DELETED") {
    history.push("/campaigns");
  }

  const channelStatusUpdate = (status: string) => {
    dispatch(actions.channelTypeUpdate({ status: status, id: params.id }));
  };

  const [reloadCounter, setReloadCounter] = useState(0);

  var reloadCounterTimeout: any;

  useEffect(() => {
    if (campaign.id === params?.id && campaign.pauseWarning) {
      const stopped = {
        crm: campaign.pauseWarning?.warnings?.includes("crm") && {
          availablePlans: authReducer.user.campaignPermissions?.crmPermissions,
        },
        enrich: campaign.pauseWarning?.warnings?.includes("enrich") && {
          availablePlans:
            authReducer.user.campaignPermissions?.enrichPermission,
        },
      };
      const warningId = campaign.pauseWarning.id;
      const warningsSeen =
        localStorage?.getItem("warnings_seen")?.split("___") || [];
      if (!warningsSeen.includes(warningId)) {
        dispatch(
          globalActions.modalUpdate({
            name: "campaignStopped",
            data: { stopped, warningId },
          })
        );
      }
    }
  }, [campaign.id, dispatch, params]);

  useEffect(() => {
    reloadCounterTimeout = setTimeout(() => {
      setReloadCounter(reloadCounter + 1);
    }, reloadCounterTime);
  }, []);

  useEffect(() => {
    clearTimeout(reloadCounterTimeout);
    reloadCounterTimeout = setTimeout(() => {
      setReloadCounter(reloadCounter + 1);
    }, reloadCounterTime);

    dispatch(
      actions.loadCampaignActions({
        query: {},
        params: {
          id: params.id,
        },
      })
    );

    const cleanup = () => {
      clearTimeout(reloadCounterTimeout);
    };
    return cleanup;
  }, [
    dispatch,
    params.id,
    campaignReducer.actions?.status,
    reloadCounter,
    campaignReducer.actions?.showHubspot,
    campaignReducer.actions?.showPipedrive,
  ]);

  const handleModal = ({ name, data }: { name: string; data: any }) => {
    dispatch(globalActions.modalUpdate({ name, data }));
  };

  const handleChangePage = (page: number) => {
    dispatch(
      actions.paginateActionsUpdate({
        current: page,
        total: campaignActions?.paginate?.total,
        id: params.id,
      })
    );
  };

  const handleEditCampaign = (body: {
    audienceId?: string;
    sequenceId?: string;
    identityId?: string;
    name?: string;
    description?: string;
  }) => {
    dispatch(
      actions.editCampaign({
        body,
        params: {
          id: campaign?.id,
        },
      })
    );
  };

  const handleTitleBarActions = (action: string) => {
    switch (action) {
      case "info":
        handleModal({
          name: modalTypes.campaignInfo,
          data: { name: campaign.name, description: campaign.description },
        });
        break;
      case "delete":
        handleModal({
          name: modalTypes.campaignDelete,
          data: { campaignId: campaign.id, campaign: campaign },
        });
        break;
      default:
        break;
    }
  };

  const handleRedirectCampaigns = () => {
    history.push("/campaigns");
  };

  const hubspotToogle = (value: boolean) => {
    dispatch(actions.setHubspotToogleActions({ showHubspot: value }));
  };

  const pipedriveToogle = (value: boolean) => {
    dispatch(actions.setPipedriveToogleActions({ showPipedrive: value }));
  };

  if (!campaign) {
    return <div className="lgm-container">Loading</div>;
  }
  const totalPaginate =
    Math.ceil((campaignActions?.paginate?.total || 0) / config.limitLeads) || 1;

  return (
    <React.Fragment>
      <LeadASide
        id={leadAside.id}
        mode={leadAside.mode}
        audiences={[]} //audiences in which a new lead should be added to
        onClose={closeLeadAside}
        callback={() => setReloadCounter(reloadCounter + 1)}
      />
      <div className="campaigns_actions_container ">
        <CampaignTitleBar
          name={campaign.name}
          lang="fr"
          status={
            campaign?.status !== "CANCELED" ? campaign?.status : "STOPPED"
          }
          onClickAction={handleTitleBarActions}
          goToCampaigns={handleRedirectCampaigns}
        />
        <CampaignActionsBar
          campaignId={campaign.id}
          page="actions"
          linkC={Link}
        />

        <Modal
          name={modalsReducer.name}
          data={modalsReducer.data}
          onRequestClose={() =>
            handleModal({ name: modalTypes.reset, data: {} })
          }
          onCampaignDelete={console.log}
          onCampaignEdit={handleEditCampaign}
        />
        <div className="actions_select">
          <ButtonGroup
            values={constants.campaignActions.map((_channel: any) => ({
              ..._channel,
              checked: _channel.id === campaignReducer.actions?.status,
            }))}
            onChange={channelStatusUpdate}
          />
          {campaignReducer.selected.hubspot && (
            <div className="hubspot_toogle_container">
              <span>Hubspot Actions</span>&nbsp;&nbsp;
              <Toogle
                checked={campaignReducer.actions.showHubspot || false}
                onClick={hubspotToogle}
              />
            </div>
          )}
          {campaignReducer.selected.pipedrive && (
            <div className="hubspot_toogle_container">
              <span>Pipedrive Actions</span>&nbsp;&nbsp;
              <Toogle
                checked={campaignReducer.actions.showPipedrive || false}
                onClick={pipedriveToogle}
              />
            </div>
          )}
        </div>
        {(loadingReducer.dataTypesLoaders.LOAD_CAMPAIGN_ACTIONS && (
          <LeadsBarLoading />
        )) ||
          (campaignReducer.actions?.status === "past" && (
            <LeadsBarPast
              openLead={handleOpenLead}
              action_items={
                campaignActions?.past?.map((action: any) => ({
                  id: action.id,
                  lead_id: action.lead._id,
                  firstName: action.lead.firstname,
                  lastName: action.lead.lastname,
                  avatar: action.lead.profilePicture,
                  companyName: action.lead.companyName || "none",
                  subtitle: action.lead.note || "No notes added yet",
                  deleted: action.lead?.deleted,
                  nextAction: {
                    channel: action.channel as type.ChannelType,
                    name: action.type
                      .replace("GOOGLE_", "EMAIL_")
                      .replace("EMAIL_SEND_EMAIL", "SEND_EMAIL"),
                  },
                  website: action.lead.companyUrl,
                  errorMessage: action.message || undefined,
                  status: getLastStatus(
                    action.lead.lastActions?.lastStatus,
                    campaign.id
                  ),
                  isSuccess: action.status !== "FAILED",
                  when: action.createdAt,
                })) || []
              }
            />
          )) ||
          (campaignReducer.actions?.status === "next" && (
            <LeadsBarUp
              openLead={handleOpenLead}
              action_items={
                campaignActions?.next?.map((action: any) => ({
                  id: action.id,
                  lead_id: action.lead._id,
                  firstName: action.lead.firstname,
                  lastName: action.lead.lastname,
                  avatar: action.lead.profilePicture,
                  companyName: action.lead.companyName || "none",
                  subtitle: action.lead.note || "No notes added yet",
                  lastAction: {
                    name: action.type
                      .replace("GOOGLE_", "EMAIL_")
                      .replace("EMAIL_SEND_EMAIL", "SEND_EMAIL"),
                    channel: action.channel,
                  },
                  website: action.lead.companyUrl,
                  status: getLastStatus(
                    action.lead.lastActions?.lastStatus,
                    campaign.id
                  ),
                  delay: format(
                    new Date(action.scheduledAt || null),
                    "MM/dd/yy, HH:mm"
                  ),
                })) || []
              }
            />
          ))}
        <Paginate
          goToPage={handleChangePage}
          current={campaignActions?.paginate?.current || 0}
          total={totalPaginate > 0 ? totalPaginate : 1}
        />
      </div>
    </React.Fragment>
  );
};

export default CampaignActionsComponent;

const shouldWidgetShowDisconnect = (
  reconnect: { linkedin?: boolean; email?: boolean; twitter?: boolean } = {},
  channels: string[] = []
) => {
  if (reconnect.linkedin && channels.indexOf("LINKEDIN") !== -1) return true;
  if (reconnect.twitter && channels.indexOf("TWITTER") !== -1) return true;
  if (reconnect.email && channels.indexOf("GOOGLE") !== -1) return true;
  return false;
};

export default shouldWidgetShowDisconnect;

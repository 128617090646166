export default {
  REQUESTS_ACTIONS: {
    UPLOAD_URL_USER: "UPLOAD_URL_USER",
    UPLOAD_FILE: "UPLOAD_FILE",
    LOGIN_USER: "LOGIN_USER",
    REGISTER_USER: "REGISTER_USER",
    SET_APIKEY: "SET_APIKEY",
    RESET_PWD: "RESET_PWD",
    RESET_PWD_REQ: "RESET_PWD_REQ",
    VALIDATION_ACCOUNT: "VALIDATION_ACCOUNT",
    EDIT_USER: "EDIT_USER",
    EDIT_MEMBER: "EDIT_MEMBER",
    GET_COUPON: "GET_COUPON",
    REFRESH_EXTERN_APIKEY: "REFRESH_EXTERN_APIKEY",
    DELETE_ACCOUNT: "DELETE_ACCOUNT",
  },
  REQUESTS_DATA: {
    LOAD_USER: "LOAD_USER",
  },
  RESPONSES_ACTIONS: {
    UPLOAD_URL_USER_FULFILLED: "UPLOAD_URL_USER_FULFILLED",
    UPLOAD_URL_USER_CANCELLED: "UPLOAD_URL_USER_CANCELLED",
    UPLOAD_URL_USER_REJECTED: "UPLOAD_URL_USER_REJECTED",

    UPLOAD_FILE_FULFILLED: "UPLOAD_FILE_FULFILLED",
    UPLOAD_FILE_CANCELLED: "UPLOAD_FILE_CANCELLED",
    UPLOAD_FILE_REJECTED: "UPLOAD_FILE_REJECTED",

    LOGIN_USER_FULFILLED: "LOGIN_USER_FULFILLED",
    LOGIN_USER_REJECTED: "LOGIN_USER_REJECTED",
    LOGIN_USER_CANCELLED: "LOGIN_USER_CANCELLED",

    REGISTER_USER_FULFILLED: "REGISTER_USER_FULFILLED",
    REGISTER_USER_REJECTED: "REGISTER_USER_REJECTED",
    REGISTER_USER_CANCELLED: "REGISTER_USER_CANCELLED",

    VALIDATION_ACCOUNT_FULFILLED: "VALIDATION_ACCOUNT_FULFILLED",
    VALIDATION_ACCOUNT_REJECTED: "VALIDATION_ACCOUNT_REJECTED",
    VALIDATION_ACCOUNT_CANCELLED: "VALIDATION_ACCOUNT_CANCELLED",

    RESET_PWD_FULFILLED: "RESET_PWD_FULFILLED",
    RESET_PWD_REQ_FULFILLED: "RESET_PWD_REQ_FULFILLED",
    RESET_PWD_REJECTED: "RESET_PWD_REJECTED",
    RESET_PWD_REQ_REJECTED: "RESET_PWD_REQ_REJECTED",
    RESET_PWD_CANCELLED: "RESET_PWD_CANCELLED",
    RESET_PWD_REQ_CANCELLED: "RESET_PWD_REQ_CANCELLED",

    EDIT_USER_FULFILLED: "EDIT_USER_FULFILLED",
    EDIT_USER_REJECTED: "EDIT_USER_REJECTED",
    EDIT_USER_CANCELLED: "EDIT_USER_CANCELLED",

    EDIT_MEMBER_FULFILLED: "EDIT_MEMBER_FULFILLED",
    EDIT_MEMBER_REJECTED: "EDIT_MEMBER_REJECTED",
    EDIT_MEMBER_CANCELLED: "EDIT_MEMBER_CANCELLED",

    GET_COUPON_FULFILLED: "GET_COUPON_FULFILLED",
    GET_COUPON_CANCELLED: "GET_COUPON_CANCELLED",
    GET_COUPON_REJECTED: "GET_COUPON_REJECTED",

    REFRESH_EXTERN_APIKEY_FULFILLED: "REFRESH_EXTERN_APIKEY_FULFILLED",
    REFRESH_EXTERN_APIKEY_REJECTED: "REFRESH_EXTERN_APIKEY_REJECTED",
    REFRESH_EXTERN_APIKEY_CANCELLED: "REFRESH_EXTERN_APIKEY_CANCELLED",

    DELETE_ACCOUNT_FULFILLED: "DELETE_ACCOUNT_FULFILLED",
    DELETE_ACCOUNT_REJECTED: "DELETE_ACCOUNT_REJECTED",
    DELETE_ACCOUNT_CANCELLED: "DELETE_ACCOUNT_CANCELLED",
  },
  RESPONSES_DATA: {
    LOAD_USER_FULFILLED: "LOAD_USER_FULFILLED",
    LOAD_USER_REJECTED: "LOAD_USER_REJECTED",
    LOAD_USER_CANCELLED: "LOAD_USER_CANCELLED",
  },

  AUTH_PENDING_ACTION: "AUTH_PENDING_ACTION",
  SIGNOUT_USER: "SIGNOUT_USER",
  RELOG_USER: "RELOG_USER",
  AUTH_CLEAR: "AUTH_CLEAR",
  AUTH_ADMIN: "AUTH_ADMIN",
  CLEAR_ALL_REDUCERS: "CLEAR_ALL_REDUCERS",
  CLEAR_GET_COUPON: "CLEAR_GET_COUPON",
  NOTIFY_TC: "NOTIFY_TC",
};

import React from "react";
import ModalWrapper from "../../../../components/ModalWrapper";
import { ScaAhead } from "@lagrowthmachine/lgmwebapp";

function Modal(props: any) {
  return (
    <ModalWrapper>
      <ScaAhead scaNumber={props.data.scaNumber} onStart={props.data.onStart} />
    </ModalWrapper>
  );
}

export default Modal;

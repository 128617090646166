import getEndpoint from "../endpoints";
import getIndentiesEndpoint from "../../Identities/endpoints";
import * as globalActions from "../../../redux/actions";

const dispatchNotification = (dispatch: any, type: string, text?: string) => {
  dispatch(
    globalActions.setNotificationSmall({
      notification: {
        type: type,
        text: text || "Unknow error",
      },
      format: "basic",
    })
  );
};

export const createInvitation = (dispatch: any, authReducer: any) => {
  return async ({ emailsList }: { emailsList: string[] }) => {
    const body = { invitations: emailsList };
    dispatch(globalActions.addAction("createInvitation"));
    const endpoint = getEndpoint({
      apiVersion: authReducer?.user?.apiVersion,
      name: "createInvitation",
      query: {
        apikey: authReducer?.user?.apikey,
      },
      params: {},
      body: JSON.stringify(body),
    });
    const response = await fetch(endpoint.url, {
      headers: endpoint.headers,
      method: endpoint.method,
      body: endpoint.body,
    });
    dispatch(globalActions.removeAction("createInvitation"));
    const json = await response.json();
    if (json.error) {
      dispatchNotification(
        dispatch,
        "error",
        String(json.error || "Unknow error")
      );
      throw json.error || "Unknow error";
    }

    dispatchNotification(
      dispatch,
      "success",
      "invitation(s) sent to " + emailsList.join(", ")
    );

    return json;
  };
};

export const loadInvitation = (dispatch: any, authReducer: any) => {
  return async ({
    invitationToken,
    userId,
  }: {
    invitationToken: string;
    userId: string;
  }) => {
    dispatch(globalActions.addAction("loadInvitation"));
    const endpoint = getEndpoint({
      apiVersion: authReducer?.user?.apiVersion,
      name: "loadInvitation",
      query: {
        //apikey: authReducer?.user?.apikey,
      },
      params: { invitationToken, userId },
    });
    const response = await fetch(endpoint.url, {
      headers: endpoint.headers,
      method: endpoint.method,
    });
    dispatch(globalActions.removeAction("loadInvitation"));
    const json = await response.json();
    if (!!!json.invitation) {
      dispatchNotification(
        dispatch,
        "error",
        String("Sorry but this invitation link is not valid.")
      );
      throw json.error || "Unknow error";
    }

    return json;
  };
};

export const loadInvitations = (dispatch: any, authReducer: any) => {
  return async () => {
    dispatch(globalActions.addAction("loadInvitations"));
    const endpoint = getEndpoint({
      apiVersion: authReducer?.user?.apiVersion,
      name: "loadInvitations",
      query: {
        apikey: authReducer?.user?.apikey,
      },
      params: {},
    });
    const response = await fetch(endpoint.url, {
      headers: endpoint.headers,
      method: endpoint.method,
    });
    dispatch(globalActions.removeAction("loadInvitations"));
    const json = await response.json();
    if (!!!json.invitations) {
      dispatchNotification(
        dispatch,
        "error",
        String(json.error || "Unknow error")
      );
      throw json.error || "Unknow error";
    }

    return json.invitations;
  };
};

export const removeInvitation = (dispatch: any, authReducer: any) => {
  return async ({ invitationId }: { invitationId: string }) => {
    dispatch(globalActions.addAction("removeInvitation"));
    const endpoint = getEndpoint({
      apiVersion: authReducer?.user?.apiVersion,
      name: "removeInvitation",
      query: {
        apikey: authReducer?.user?.apikey,
      },
      params: { id: invitationId },
    });
    const response = await fetch(endpoint.url, {
      headers: endpoint.headers,
      method: endpoint.method,
    });
    dispatch(globalActions.removeAction("removeInvitation"));
    const json = await response.json();
    if (json.error) {
      dispatchNotification(
        dispatch,
        "error",
        String(json.error || "Unknow error")
      );
      throw json.error || "Unknow error";
    }
    dispatchNotification(dispatch, "success", "Invitation removed");
    return json;
  };
};

export const loadMembers = (dispatch: any, authReducer: any) => {
  return async () => {
    dispatch(globalActions.addAction("loadMembers"));
    const endpoint = getEndpoint({
      apiVersion: authReducer?.user?.apiVersion,
      name: "loadMembers",
      query: {
        apikey: authReducer?.user?.apikey,
      },
    });
    const response = await fetch(endpoint.url, {
      headers: endpoint.headers,
      method: endpoint.method,
    });
    dispatch(globalActions.removeAction("loadMembers"));
    const json = await response.json();
    if (!!!json.members) {
      dispatchNotification(
        dispatch,
        "error",
        String(json.error || "Unknow error")
      );
      throw json.error || "Unknow error";
    }

    return json.members;
  };
};

export const addMember = (dispatch: any, authReducer: any) => {
  return async ({
    firstname,
    lastname,
    password,
    token,
    userId,
    accept,
    captcha,
  }: {
    firstname: string;
    lastname: string;
    password: string;
    token: string;
    userId: string;
    accept: string;
    captcha: string;
  }) => {
    const body = {
      firstname,
      lastname,
      password,
      token,
      userId,
      accept,
      captcha,
    };
    dispatch(globalActions.addAction("addMember"));
    const endpoint = getEndpoint({
      apiVersion: authReducer?.user?.apiVersion,
      name: "addMember",
      query: {
        apikey: authReducer?.user?.apikey,
      },
      params: {},
      body: JSON.stringify(body),
    });
    const response = await fetch(endpoint.url, {
      headers: endpoint.headers,
      method: endpoint.method,
      body: endpoint.body,
    });
    dispatch(globalActions.removeAction("addMember"));
    const json = await response.json();
    if (json.error) {
      dispatchNotification(
        dispatch,
        "error",
        String(json.error || "Unknow error")
      );
      throw json.error || "Unknow error";
    }

    dispatchNotification(
      dispatch,
      "success",
      "You have join this organisation on LGM"
    );

    return json;
  };
};

export const removeMember = (dispatch: any, authReducer: any) => {
  return async ({ memberId }: { memberId: string }) => {
    dispatch(globalActions.addAction("removeMember"));
    const endpoint = getEndpoint({
      apiVersion: authReducer?.user?.apiVersion,
      name: "removeMember",
      query: {
        apikey: authReducer?.user?.apikey,
      },
      params: { id: memberId },
    });
    const response = await fetch(endpoint.url, {
      headers: endpoint.headers,
      method: endpoint.method,
    });
    dispatch(globalActions.removeAction("removeMember"));
    const json = await response.json();
    if (json.error) {
      dispatchNotification(
        dispatch,
        "error",
        String(json.error || "Unknow error")
      );
      throw json.error || "Unknow error";
    }
    dispatchNotification(dispatch, "success", "This member was removed");
    return json;
  };
};

export const reassignWidget = (dispatch: any, authReducer: any) => {
  return async ({
    identityId,
    memberId,
  }: {
    identityId: string;
    memberId?: string;
  }) => {
    const body = memberId ? { memberId } : {};
    dispatch(globalActions.addAction("reassignWidget"));
    const endpoint = getIndentiesEndpoint({
      apiVersion: authReducer?.user?.apiVersion,
      name: "reassignWidget",
      query: {
        apikey: authReducer?.user?.apikey,
      },
      params: { identityId },
      body: JSON.stringify(body),
    });
    const response = await fetch(endpoint.url, {
      headers: endpoint.headers,
      method: endpoint.method,
      body: endpoint.body,
    });
    dispatch(globalActions.removeAction("reassignWidget"));
    const json = await response.json();
    if (json.error) {
      dispatchNotification(
        dispatch,
        "error",
        String(json.error || "Unknow error")
      );
      throw json.error || "Unknow error";
    }
    dispatchNotification(
      dispatch,
      "success",
      String(
        memberId
          ? "This identity will now run on the member widget"
          : "This identity is now removed for member widget"
      )
    );
    return json;
  };
};

export const resendLink = (dispatch: any, authReducer: any) => {
  return async () => {
    dispatch(globalActions.addAction("resendLink"));
    const endpoint = getEndpoint({
      apiVersion: authReducer?.user?.apiVersion,
      name: "resendLink",
      query: {
        apikey: authReducer?.user?.apikey,
      },
      params: {},
      body: JSON.stringify({}),
    });
    const response = await fetch(endpoint.url, {
      headers: endpoint.headers,
      method: endpoint.method,
      body: endpoint.body,
    });
    dispatch(globalActions.removeAction("resendLink"));
    const json = await response.json();
    if (json.error) {
      dispatchNotification(
        dispatch,
        "error",
        String(json.error || "Unknow error")
      );
      throw json.error || "Unknow error";
    }
    dispatchNotification(
      dispatch,
      "success",
      "We sent you an email with a link to verify your email."
    );
    return json;
  };
};

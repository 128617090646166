import { createAction } from "redux-actions";
import types from "../types";

export const clear = () => ({
  type: types.LEADS_CLEAR,
  payload: {},
});

/**
 * Load leads
 */
export const loadLeads = createAction(
  types.REQUESTS_DATA.LOAD_LEADS,
  (payload: any) => payload
);
export const loadLeadsFulfilled = createAction(
  types.RESPONSES_DATA.LOAD_LEADS_FULFILLED,
  (payload: any) => payload
);
export const loadLeadsCancelled = createAction(
  types.RESPONSES_DATA.LOAD_LEADS_CANCELLED
);
export const rejectedLoadLeads = createAction(
  types.RESPONSES_DATA.LOAD_LEADS_REJECTED,
  (payload: any) => payload
);
export const exportLeads = createAction(
  types.REQUESTS_DATA.EXPORT_LEADS,
  (payload: any) => payload
);
export const exportLeadsFulfilled = createAction(
  types.RESPONSES_DATA.EXPORT_LEADS_FULFILLED,
  (payload: any) => payload
);
export const rejectedExportLeads = createAction(
  types.RESPONSES_DATA.EXPORT_LEADS_REJECTED,
  (payload: any) => payload
);

/**
 * Create lead
 */
export const createLead = createAction(
  types.REQUESTS_ACTIONS.CREATE_LEAD,
  (payload: any) => payload
);
export const createLeadFulfilled = createAction(
  types.RESPONSES_ACTIONS.CREATE_LEAD_FULFILLED,
  (payload: any) => payload
);
export const rejectedCreateLead = createAction(
  types.RESPONSES_ACTIONS.CREATE_LEAD_REJECTED,
  (payload: any) => payload
);

/**
 * Edit lead
 */
export const editLead = createAction(
  types.REQUESTS_ACTIONS.EDIT_LEAD,
  (payload: any) => payload
);
export const editLeadFulfilled = createAction(
  types.RESPONSES_ACTIONS.EDIT_LEAD_FULFILLED,
  (payload: any) => payload
);
export const rejectedEditLead = createAction(
  types.RESPONSES_ACTIONS.EDIT_LEAD_REJECTED,
  (payload: any) => payload
);
export const editLeads = createAction(
  types.REQUESTS_ACTIONS.EDIT_LEADS,
  (payload: any) => payload
);
export const editLeadsFulfilled = createAction(
  types.RESPONSES_ACTIONS.EDIT_LEADS_FULFILLED,
  (payload: any) => payload
);
export const rejectedEditLeads = createAction(
  types.RESPONSES_ACTIONS.EDIT_LEADS_REJECTED,
  (payload: any) => payload
);

/**
 * Load lead
 */
export const loadLead = createAction(
  types.REQUESTS_DATA.LOAD_LEAD,
  (payload: any) => payload
);
export const loadLeadFulfilled = createAction(
  types.RESPONSES_DATA.LOAD_LEAD_FULFILLED,
  (payload: any) => payload
);
export const rejectedLoadLead = createAction(
  types.RESPONSES_DATA.LOAD_LEAD_REJECTED,
  (payload: any) => payload
);

/**
 * Filter update
 */

export const filterUpdate = createAction(
  types.LEADS_FILTERS,
  (payload: any) => payload
);

/**
 * Paginate leads Update
 */
export const paginateLeadsUpdate = createAction(
  types.LEADS_PAGINATE,
  (payload: any) => payload
);

/**
 * Modal update
 */
export const modalUpdate = createAction(
  types.LEAD_MODAL,
  (payload: any) => payload
);

/**
 * Delete leads
 */
export const deleteLeads = createAction(
  types.REQUESTS_ACTIONS.DELETE_LEADS,
  (payload: any) => payload
);
export const deleteLeadsFulfilled = createAction(
  types.RESPONSES_ACTIONS.DELETE_LEADS_FULFILLED,
  (payload: any) => payload
);
export const rejectedDeleteLeads = createAction(
  types.RESPONSES_ACTIONS.DELETE_LEADS_REJECTED,
  (payload: any) => payload
);

/* CSV matching error*/
export const notifyCsvMatchingError = createAction(types.CSV_MATCHING_ERROR);

/**
 * Load leads from hubspot
 */
export const loadHubspotLeads = createAction(
  types.REQUESTS_DATA.LOAD_HUBSPOT_LEADS,
  (payload: any) => payload
);
export const loadHubspotLeadsFulfilled = createAction(
  types.RESPONSES_DATA.LOAD_HUBSPOT_LEADS_FULFILLED,
  (payload: any) => payload
);
export const loadHubspotLeadsCancelled = createAction(
  types.RESPONSES_DATA.LOAD_HUBSPOT_LEADS_CANCELLED
);
export const rejectedHubspotLoadLeads = createAction(
  types.RESPONSES_DATA.LOAD_HUBSPOT_LEADS_REJECTED,
  (payload: any) => payload
);

/**
 * Load leads from pipedrive
 */
export const loadPipedriveLeads = createAction(
  types.REQUESTS_DATA.LOAD_PIPEDRIVE_LEADS,
  (payload: any) => payload
);
export const loadPipedriveLeadsFulfilled = createAction(
  types.RESPONSES_DATA.LOAD_PIPEDRIVE_LEADS_FULFILLED,
  (payload: any) => payload
);
export const loadPipedriveLeadsCancelled = createAction(
  types.RESPONSES_DATA.LOAD_PIPEDRIVE_LEADS_CANCELLED
);
export const rejectedPipedriveLoadLeads = createAction(
  types.RESPONSES_DATA.LOAD_PIPEDRIVE_LEADS_REJECTED,
  (payload: any) => payload
);

/**
 * Load lead campaign logs
 */

export const loadLeadCampaignLogs = createAction(
  types.REQUESTS_DATA.LOAD_LEAD_CAMPAIGN_LOGS,
  (payload: any) => payload
);
export const loadLeadCampaignLogsFulfilled = createAction(
  types.RESPONSES_DATA.LOAD_LEAD_CAMPAIGN_LOGS_FULFILLED,
  (payload: any) => payload
);
export const rejectedLoadLeadCampaignLogs = createAction(
  types.RESPONSES_DATA.LOAD_LEAD_CAMPAIGN_LOGS_REJECTED,
  (payload: any) => payload
);
export const cancelledLoadLeadCampaignLogs = createAction(
  types.RESPONSES_DATA.LOAD_LEAD_CAMPAIGN_LOGS_CANCELLED,
  (payload: any) => payload
);

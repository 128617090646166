import React, { useEffect } from "react";
import ModalWrapper from "../../../../components/ModalWrapper";
import { useDispatch } from "react-redux";
import useSelector from "../../../../redux/useSelector";
import { CampaignAudienceChoice } from "@lagrowthmachine/lgmwebapp";
import * as globalActions from "../../../../redux/actions";

function Modal(props: any) {
  const dispatch = useDispatch();
  const socketReducer = useSelector((state) => state.socketReducer);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(globalActions.socketIsWidgetAvailable());
    }, 4000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [dispatch, socketReducer.widgetAvailable]);

  return (
    <ModalWrapper>
      <CampaignAudienceChoice
        widgetAvailable={socketReducer.widgetAvailable}
        onImport={props.data.onImport}
        onCancel={props.onRequestClose}
      />
    </ModalWrapper>
  );
}
export default Modal;

import React, { useEffect, useState } from "react";
import useSelector from "../../../../redux/useSelector";
import { DeleteAccount } from "@lagrowthmachine/lgmwebapp";
import * as actions from "../../actions";
import { useDispatch } from "react-redux";
import analyticsCall from "../../../../helpers/analyticsCall";

const DeleteAccountComponent = (props: any) => {
  const dispatch = useDispatch();
  const loadingReducer = useSelector((state) => state.loadingReducer);
  const { key, email } = props.match?.params;

  const [result, setResult] = useState(false);

  const apikey = new URLSearchParams(props.location.search).get("apikey");

  useEffect(() => {
    analyticsCall("settings", "settings: end subscription confirmed");
  }, []);

  useEffect(() => {
    dispatch(
      actions.deleteAccount({
        body: { key, email },
        query: { apikey: apikey },
        setResult: setResult,
      })
    );
  }, [dispatch, key, email, apikey]);

  return (
    <div className="auth_delete_account_container">
      <DeleteAccount
        fulfilled={result}
        loading={loadingReducer.btnTypesLoaders.DELETE_ACCOUNT}
      />
    </div>
  );
};

export default DeleteAccountComponent;

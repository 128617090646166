import React from "react";
import ModalWrapper from "../../../../components/ModalWrapper";
import { AudienceNew } from "@lagrowthmachine/lgmwebapp";
import useSelector from "../../../../redux/useSelector";
import * as globalActions from "../../../../redux/actions";
import globalTypes from "../../../../redux/types";
import { useDispatch } from "react-redux";

function Modal(props: any) {
  const loadingReducer = useSelector((state) => state.loadingReducer);
  const dispatch = useDispatch();

  const onEditAudience = async ({
    name,
    description,
  }: {
    name: string;
    description: string;
  }) => {
    dispatch(
      globalActions.editAudience({
        query: {},
        params: { id: props.data.audience.id },
        body: {
          audience: {
            name: name,
            description: description,
          },
          type: ["LINKEDIN", "SALES_NAV", "CSV", "CLASSIC"].includes(
            props.data?.audience?.type
          )
            ? props.data?.audience?.type
            : "CLASSIC",
        },
      })
    );
  };

  const onRequestClose = () => {
    dispatch(globalActions.modalUpdate({ name: "reset", data: {} }));
  };

  return (
    <ModalWrapper>
      <AudienceNew
        leadsNumber={0}
        onNewAudience={(obj: { name: string; description: string }) =>
          console.log()
        }
        onEditAudience={onEditAudience}
        preventBack
        onAddToAudience={() => {}}
        audiences={[]}
        action="edit"
        originalAudiences={[]}
        onCancel={onRequestClose}
        btnSaveDisabled={
          loadingReducer.btnTypesLoaders[
            globalTypes.REQUESTS_ACTIONS.EDIT_AUDIENCE
          ]
        }
        btnSaveIsLoading={
          loadingReducer.btnTypesLoaders[
            globalTypes.REQUESTS_ACTIONS.EDIT_AUDIENCE
          ]
        }
        audience={props.data.audience}
      />
    </ModalWrapper>
  );
}
export default Modal;

import React, { useEffect } from "react";
import ModalWrapper from "../../../../components/ModalWrapper";
import { CampaignLaunch } from "@lagrowthmachine/lgmwebapp";
import { useDispatch } from "react-redux";
import useSelector from "../../../../redux/useSelector";
import types from "../../types";
import * as authActions from "../../../Auth/actions";

function Modal(props: any) {
  const loadingReducer = useSelector((state) => state.loadingReducer);
  const dispatch = useDispatch();

  const onLaunch = () => {
    props.onAction("campaignStart", props.data);
  };
  const authReducer = useSelector((state) => state.authReducer);

  useEffect(() => {
    dispatch(authActions.loadUser({}));
  }, [dispatch]);

  return (
    <ModalWrapper>
      <CampaignLaunch
        btnDisabled={
          loadingReducer.btnTypesLoaders[
            types.REQUESTS_ACTIONS.EDIT_SEQUENCE
          ] ||
          loadingReducer.btnTypesLoaders[
            types.REQUESTS_ACTIONS.EDIT_CAMPAIGN_STATE
          ] ||
          loadingReducer.btnTypesLoaders[
            types.REQUESTS_ACTIONS.CAMPAIGN_REVIEW
          ] ||
          authReducer.user.campaignPermissions?.campaignLimitReached
        }
        btnIsLoading={
          loadingReducer.btnTypesLoaders[
            types.REQUESTS_ACTIONS.EDIT_SEQUENCE
          ] ||
          loadingReducer.btnTypesLoaders[
            types.REQUESTS_ACTIONS.EDIT_CAMPAIGN_STATE
          ] ||
          loadingReducer.btnTypesLoaders[types.REQUESTS_ACTIONS.CAMPAIGN_REVIEW]
        }
        plansLimits={
          authReducer.user.campaignPermissions?.maxRunningCampaigns !==
          "unlimited"
            ? {
                number: authReducer.user.campaignPermissions?.running,
                max: authReducer.user.campaignPermissions?.maxRunningCampaigns,
              }
            : undefined
        }
        permission={{ label: "BUSINESS", available: ["BUSINESS"] }}
        review={
          authReducer.user.campaignPermissions?.canAskForCampaignReview
            ? {
                picsUrl: [
                  "/pics/brice.png",
                  "/pics/agathe.png",
                  "/pics/adrien.png",
                ],
                onAskForReview: props.data.onAskForReview,
                onWatchReview: props.data.onWatchReview,
              }
            : undefined
          /*{
              permissionReview: {
                label:
                  authReducer.user.campaignPermissions
                    ?.campaignReviewPermission[0],
                available:
                  authReducer.user.campaignPermissions
                    ?.campaignReviewPermission,
              },
              picsUrl: [
                "/pics/brice.png",
                "/pics/agathe.png",
                "/pics/adrien.png",
              ],
              onWatchReview: () => props.data.onWatchReview,
            }*/
        }
        onClose={props.onRequestClose}
        onLaunch={onLaunch}
        onUpgrade={props.data.onUpgrade}
      />
    </ModalWrapper>
  );
}
export default Modal;

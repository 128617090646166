import React from "react";
import ModalWrapper from "../../../../components/ModalWrapper";
import { CampaignAudienceNew } from "@lagrowthmachine/lgmwebapp";
import useSelector from "../../../../redux/useSelector";
import * as globalActions from "../../../../redux/actions";
import globalTypes from "../../../../redux/types";
import { useDispatch } from "react-redux";

function Modal(props: any) {
  const loadingReducer = useSelector((state) => state.loadingReducer);
  const dispatch = useDispatch();
  const onNewAudience = async ({
    name,
    description,
  }: {
    name: string;
    description: string;
  }) => {
    dispatch(
      globalActions.createAudience({
        body: {
          audience: {
            name,
            description,
            type: "CLASSIC",
          },
        },
        setLeadFilters: true,
        callback: props.data.callback,
      })
    );
  };

  const onRequestClose = () => {
    dispatch(globalActions.modalUpdate({ name: "reset", data: {} }));
  };

  return (
    <ModalWrapper>
      <CampaignAudienceNew
        audience={{ name: "", description: "" }}
        onNewAudience={onNewAudience}
        btnSaveDisabled={
          loadingReducer.btnTypesLoaders[
            globalTypes.REQUESTS_ACTIONS.CREATE_AUDIENCE
          ]
        }
        btnSaveIsLoading={
          loadingReducer.btnTypesLoaders[
            globalTypes.REQUESTS_ACTIONS.CREATE_AUDIENCE
          ]
        }
        onCancel={onRequestClose}
      />
    </ModalWrapper>
  );
}
export default Modal;

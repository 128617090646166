import React, { useEffect, useState } from "react";
import { ConnectingChannel, type } from "@lagrowthmachine/lgmwebapp";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import useSelector from "../../../../redux/useSelector";
import * as globalActions from "../../../../redux/actions";
import * as authActions from "../../../Auth/actions";
import * as identitiesActions from "../../../Identities/actions";
import analyticsCall from "../../../../helpers/analyticsCall";
import { loadLoginUrl, refreshSpfDkim } from "../../../Identities/functions";
import Modal, { types as modalTypes } from "../Modals";

const ConnectingChannelComponent = (props: any) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const identitiesReducer = useSelector((state) => state.identitiesReducer);
  const authReducer = useSelector((state) => state.authReducer);
  const socketReducer = useSelector((state) => state.socketReducer);
  const modalsReducer = useSelector((state) => state.modalsReducer);

  const channel = props.match.params.page.split("_")[0].toUpperCase();

  const [channelLoader, setChannelLoader] = useState("");

  useEffect(() => {
    if (channelLoader)
      setTimeout(() => {
        setChannelLoader("");
      }, 5000);
  }, [channelLoader]);

  useEffect(() => {
    if (["LINKEDIN", "TWITTER", "EMAIL"].includes(channel)) {
      const _channel = channel.toLowerCase();
      analyticsCall("signup flow", "signup flow " + _channel.toLowerCase());
    }
  }, [channel]);

  const channelsConnected = {
    LINKEDIN: identitiesReducer.list[0]?.linkedinData
      ? {
          subChannel: "LINKEDIN" as type.ChannelType,
          connectedAs:
            String(identitiesReducer.list[0]?.firstname) +
            " " +
            String(identitiesReducer.list[0]?.lastname),
        }
      : false,
    EMAIL: identitiesReducer.list[0]?.emailData
      ? {
          subChannel: identitiesReducer.list[0]?.emailData
            .type as type.ChannelType,
          connectedAs: identitiesReducer.list[0]?.emailData.tokens.email,
        }
      : false,
    TWITTER: identitiesReducer.list[0]?.twitterData
      ? {
          subChannel: "TWITTER" as type.ChannelType,
          connectedAs: "@" + identitiesReducer.list[0]?.twitterData.screen_name,
        }
      : false,
  };

  const [channelsConnectedDebounce, setChannelsConnectedDebounce] = useState(
    channelsConnected
  );
  const [visibility, setVisibility] = useState(document.visibilityState);

  const onSetVisibility = () => {
    setVisibility(document.visibilityState);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(globalActions.socketIsWidgetAvailable());
      if (!socketReducer.widgetAvailable) {
        history.push({
          pathname: "/onboarding/widget_download",
          state: { page: "waiting" },
        });
      }
    }, 1000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [dispatch, history, socketReducer.widgetAvailable]);

  useEffect(() => {
    document.addEventListener("visibilitychange", onSetVisibility);
    return function cleanupListener() {
      document.removeEventListener("visibilitychange", onSetVisibility);
    };
  }, []);

  useEffect(() => {
    if (
      visibility === "visible" &&
      JSON.stringify(channelsConnectedDebounce) !==
        JSON.stringify(channelsConnected)
    ) {
      let timeout = setTimeout(() => {
        setChannelsConnectedDebounce(channelsConnected);
        clearTimeout(timeout);
      }, 800);
    }
  }, [JSON.stringify(channelsConnected), visibility]);

  useEffect(() => {
    dispatch(authActions.loadUser({}));
    dispatch(identitiesActions.loadIdentities({ query: { limit: false } }));
  }, [dispatch, channel, JSON.stringify(channelsConnected)]);

  if (authReducer?.user?.onboardingDone) {
    history.push("/campaigns");
  }

  const onSkip = () => {
    if (channel === "LINKEDIN") {
      history.push("/onboarding/email_connect");
    }
    if (channel === "EMAIL") {
      history.push("/onboarding/twitter_connect");
    }
    if (channel === "TWITTER") {
      history.push("/onboarding/crm_connect");
    }
  };

  if (channel === "LINKEDIN" && channelsConnectedDebounce.LINKEDIN) {
    window.focus();
    let timeout = setTimeout(() => {
      history.push("/onboarding/email_connect");
      clearTimeout(timeout);
    }, 1000);
  }

  if (channel === "EMAIL" && channelsConnectedDebounce.EMAIL) {
    window.focus();
    let timeout = setTimeout(() => {
      history.push("/onboarding/twitter_connect");
      clearTimeout(timeout);
    }, 1000);
  }

  if (channel === "TWITTER" && channelsConnectedDebounce.TWITTER) {
    window.focus();
    let timeout = setTimeout(() => {
      history.push("/onboarding/crm_connect");
      clearTimeout(timeout);
    }, 1000);
  }

  const handleModal = ({ name, data }: { name: string; data: any }) => {
    dispatch(globalActions.modalUpdate({ name, data }));
  };

  const onConnect = (_channel: string) => {
    if (["LINKEDIN", "TWITTER", "GMAIL"].includes(_channel)) {
      //WIDGET CONNECTION
      if (!socketReducer.widgetAvailable) {
        dispatch(
          globalActions.setNotificationSmall({
            notification: {
              type: "error",
              text: "Your LGM Widget needs to be opened",
            },
            format: "basic",
          })
        );
        return;
      }
      setChannelLoader(_channel);
      dispatch(
        globalActions.socketConnectChannel({
          identityId: identitiesReducer.list[0]?.id,
          channel: (_channel === "GMAIL"
            ? "GOOGLE"
            : _channel) as type.ChannelType,
        })
      );
    } else if (["OUTLOOK"].includes(_channel)) {
      //GET OUTLOOK URL and open it in a new tab
      setChannelLoader(_channel);
      loadLoginUrl({
        authReducer,
        channel: _channel.toLowerCase(),
        identityId: identitiesReducer.list[0]?.id,
      })
        .then((response) => {
          window.open(response.url, "_blank");
        })
        .catch((e) => setChannelLoader(""));
    } else if (["SMTP"].includes(_channel)) {
      //OPEN SMTP/IMAP MODAL
      handleModal({
        name: modalTypes.imapSmtp,
        data: {
          identityId: identitiesReducer.list[0]?.id,
          channel: _channel.toLowerCase(),
        },
      });
    }
  };

  return (
    <React.Fragment>
      <Modal
        name={modalsReducer.name}
        data={modalsReducer.data}
        onRequestClose={() => handleModal({ name: modalTypes.reset, data: {} })}
      />
      <div className="auth_onboarding_container">
        <ConnectingChannel
          loading={channelLoader as type.ChannelType}
          channel={channel}
          onSkip={onSkip}
          onConnect={onConnect}
          channelsConnected={channelsConnectedDebounce}
        />
      </div>
    </React.Fragment>
  );
};

export default ConnectingChannelComponent;

import React, { useState, useEffect } from "react";
import ModalWrapper from "../../../../components/ModalWrapper";
import asyncRequest from "../../../../helpers/asyncRequest";
import globalEndpoints from "../../../../endpoints";
import globalTypes from "../../../../redux/types";
import uploadFileAsync from "../../../../helpers/uploadFileAsync";
import endpointsAuth from "../../../Auth/endpoints";
import { CsvImport, CampaignAudienceNew } from "@lagrowthmachine/lgmwebapp";
import useSelector from "../../../../redux/useSelector";
import { v4 as uuidv4 } from "uuid";
import * as globalActions from "../../../../redux/actions";
import * as leadsActions from "../../../Leads/actions";
import authTypes from "../../../Auth/types";
import { useDispatch } from "react-redux";
import analyticsCall from "../../../../helpers/analyticsCall";
import config from "../../../../config";

function Modal(props: any) {
  const dispatch = useDispatch();
  const loadingReducer = useSelector((state) => state.loadingReducer);
  const [fileLoaded, setFile] = useState();
  const [leadsNumber, setLeadsNumber] = useState(0);
  const [fileName, setFileName] = useState("");
  const authReducer = useSelector((state) => state.authReducer);
  // const selectedLeads: any = [];

  useEffect(() => {
    analyticsCall("leads new", `leads csv import`);
  }, []);

  const handleOnImport = (csvContent: any, file: any) => {
    const newLine = 1;

    const getSeparator = (sep: string) => {
      switch (sep) {
        case ",":
          return 1;
        case ";":
          return 2;
        case "\t":
          return 3;
        case "\n":
          return 4;
        default:
          return "null";
      }
    };

    const fileId = uuidv4();
    const separator = getSeparator(csvContent.data[0]?.meta?.delimiter);
    const memberId = String(authReducer.user.memberId);
    const nameFile = `${csvContent.headers.join(
      "-"
    )}_${newLine}_${fileId}_${new Date().getTime()}_${separator}_${
      csvContent.containHeader
    }_${memberId}`;

    // selectedLeads.length =
    //   csvContent.data.length - (csvContent.containHeader ? 1 : 0);

    const trimmedCsvLength = csvContent.data.filter(
      (data: any) => !(data.data.length === 1 && data.data[0].trim() === "")
    ).length;
    setLeadsNumber(trimmedCsvLength - (csvContent.containHeader ? 1 : 0));
    setFile(file);
    setFileName(nameFile);
  };

  const onNewAudience = async ({
    name,
    description,
  }: {
    name: string;
    description: string;
  }) => {
    dispatch(
      globalActions.updateBtnLoaders({
        name: globalTypes.REQUESTS_ACTIONS.CREATE_AUDIENCE,
        status: true,
      })
    );
    const responseAudience = await asyncRequest(globalEndpoints, {
      apiVersion: authReducer?.user?.apiVersion,
      name: "createAudience",
      body: {
        audience: {
          name,
          description,
          type: "CSV",
        },
      },
      query: {
        apikey: authReducer?.user?.apikey,
      },
    });
    dispatch(
      globalActions.updateBtnLoaders({
        name: globalTypes.REQUESTS_ACTIONS.CREATE_AUDIENCE,
        status: false,
      })
    );

    if (responseAudience?.data?.audienceCreated?.id) {
      props.data.setAssignAudienceToCampaign(
        responseAudience?.data?.audienceCreated?.id
      );
      onAddToAudience(responseAudience?.data?.audienceCreated?.id);
      dispatch(globalActions.loadAudiences({ query: {} }));
    }
  };

  const onAddToAudience = async (audienceId: string | null) => {
    if (fileName) {
      dispatch(
        globalActions.updateBtnLoaders({
          name: authTypes.REQUESTS_ACTIONS.UPLOAD_FILE,
          status: true,
        })
      );
      const x: any = fileLoaded;
      console.log(x);
      await uploadFileAsync(
        fileLoaded,
        authReducer.user.apikey,
        endpointsAuth,
        config.bucketsv2.utils,
        `${audienceId}_${fileName}.csv`,
        "private",
        authReducer?.user?.apiVersion
      ).catch((err) => {
        dispatch(
          globalActions.setNotificationSmall({
            notification: {
              type: "error",
              text: err?.response?.data?.error || "Unable to upload",
            },
            format: "basic",
          })
        );
      });
      //alert(audienceId);
      dispatch(
        globalActions.updateBtnLoaders({
          name: authTypes.REQUESTS_ACTIONS.UPLOAD_FILE,
          status: false,
        })
      );
      onRequestClose();
      return;
    }
  };
  const onRequestClose = () => {
    dispatch(globalActions.modalUpdate({ name: "reset", data: {} }));
  };

  const onNotifyError = () => {
    dispatch(leadsActions.notifyCsvMatchingError());
  };
  return (
    <ModalWrapper>
      {fileName ? (
        <CampaignAudienceNew
          leadsNumber={leadsNumber || 0}
          audience={{ name: "", description: "" }}
          onNewAudience={onNewAudience}
          btnSaveDisabled={
            loadingReducer.btnTypesLoaders[
              globalTypes.REQUESTS_ACTIONS.CREATE_AUDIENCE
            ]
          }
          btnSaveIsLoading={
            loadingReducer.btnTypesLoaders[
              globalTypes.REQUESTS_ACTIONS.CREATE_AUDIENCE
            ]
          }
          onCancel={onRequestClose}
        />
      ) : (
        <CsvImport
          onNotifyError={onNotifyError}
          onClose={onRequestClose}
          onImport={handleOnImport}
        />
      )}
    </ModalWrapper>
  );
}
export default Modal;

const keepFields = [
  "firstname",
  "lastname",
  "companyUrl",
  "companyName",
  "location",
  "jobTitle",
  "customAttribute1",
  "customAttribute2",
  "customAttribute3",
  "customAttribute4",
  "customAttribute5",
  "customAttribute6",
  "customAttribute7",
  "customAttribute8",
  "customAttribute9",
  "customAttribute10",
  "parameters",
  "limitsSettings",
  "gender",
];

export const adapterEditOUT = (identity: any) => {
  let obj = {
    ...identity,
    firstname: identity.firstName,
    lastname: identity.lastName,
    companyUrl: identity.companyURL,
    companyName: identity.companyName || "",
    location: identity.location || "",
    jobTitle: identity.jobTitle || "",
    customAttribute1: identity.customAttribute1 || "",
    customAttribute2: identity.customAttribute2 || "",
    customAttribute3: identity.customAttribute3 || "",
    customAttribute4: identity.customAttribute4 || "",
    customAttribute5: identity.customAttribute5 || "",
    customAttribute6: identity.customAttribute6 || "",
    customAttribute7: identity.customAttribute7 || "",
    customAttribute8: identity.customAttribute8 || "",
    customAttribute9: identity.customAttribute9 || "",
    customAttribute10: identity.customAttribute10 || "",
    parameters: {
      ...identity.parameters,
      workingDays: identity.workingHours || identity.parameters.workingDays,
      timezone: identity.timezone,
    },
    limitsSettings: {
      ...identity.userLimits,
    },
  };

  Object.keys(obj).some((key: string) => {
    if (!keepFields.includes(String(key))) {
      delete obj[key];
    }
    return null;
  });

  if (obj.gender !== "man" && obj.gender !== "woman") {
    delete obj.gender;
  }
  return obj;
};

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SignUp2, type } from "@lagrowthmachine/lgmwebapp";
import * as actions from "../../actions";
import types from "../../types";
import useSelector from "../../../../redux/useSelector";
import analyticsCall from "../../../../helpers/analyticsCall";
import config from "../../../../config";
import { ReCaptcha, loadReCaptcha } from "react-recaptcha-v3";

const RegisterComponent = (props: any) => {
  let recaptchaRef: any = React.createRef();
  const [captcha, setCaptcha] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const loadingReducer = useSelector((state) => state.loadingReducer);
  const authReducer = useSelector((state) => state.authReducer);
  const socketReducer = useSelector((state) => state.socketReducer);

  const onRedirectSignIn = () => history.push("/login");
  const onRedirectForgotPassword = () => history.push("/reset-password");
  const onSignUp = (body: type.SignUpStateT) => {
    const _body: any = {
      ...body,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      lang:
        (window.navigator as any).userLanguage === "fr-FR" ||
        (window.navigator as any).language === "fr-FR"
          ? "french"
          : "english",
    };

    dispatch(
      actions.registerUser({ body: { ..._body, ...{ captcha: captcha } } })
    );

    setCaptcha("");
    recaptchaRef.execute();
  };

  useEffect(() => {
    if (authReducer?.user?.apikey) {
      //loadUser will set authReducer?.user.id
      dispatch(actions.loadUser({}));
    }
  }, [dispatch, authReducer?.user?.apikey]);

  useEffect(() => {
    loadReCaptcha(config.recaptchaKey);
    if (!Boolean(authReducer?.user?.id)) {
      analyticsCall("authentication", "authentication: signup");
    } else {
      analyticsCall("authentication", "authentication: signup success");
    }
    return () => {
      Array.prototype.filter.call(
        document.getElementsByClassName("grecaptcha-badge"),
        function (el: any) {
          el.style.visibility = "hidden";
        }
      );
    };
  }, [authReducer?.user?.id]);

  useEffect(() => {
    if (socketReducer.connected && authReducer?.user?.id) {
      //having a socket connection will redirect to widget_download
      history.push("/onboarding/widget_download");
    }
  }, [dispatch, socketReducer.connected, authReducer?.user?.id]);

  useEffect(() => {
    //remove captcha on page left
    Array.prototype.filter.call(
      document.getElementsByClassName("grecaptcha-badge"),
      function (el: any) {
        el.style.visibility = "visible";
      }
    );
  }, [recaptchaRef]);

  const onNotifyTc = () => {
    dispatch(actions.notifyTc({}));
  };

  var verifyCallback = function (response: any) {
    setCaptcha(response);
  };

  return (
    <div className="auth_register_container">
      <SignUp2
        btnIsLoading={
          loadingReducer.btnTypesLoaders[types.REQUESTS_ACTIONS.REGISTER_USER]
        }
        btnDisabled={
          captcha === "" ||
          loadingReducer.btnTypesLoaders[types.REQUESTS_ACTIONS.REGISTER_USER]
        }
        onSignUp={onSignUp}
        redirectSignIn={onRedirectSignIn}
        redirectForgotPassword={onRedirectForgotPassword}
        notifyTc={onNotifyTc}
        backUrl="https://lagrowthmachine.com/"
        firstName={""}
        lastName={""}
        companyName={""}
        email={""}
      />
      <ReCaptcha
        ref={(ref) => (recaptchaRef = ref)}
        sitekey={config.recaptchaKey}
        action="signUp"
        verifyCallback={verifyCallback}
      />
    </div>
  );
};

export default RegisterComponent;

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import createdStore, { history } from "./redux/store";
import * as serviceWorker from "./serviceWorker";
import Router from "./router";
import "./styles/index.scss";

ReactDOM.render(
  <Provider store={createdStore.store}>
    <PersistGate loading={null} persistor={createdStore.persistor}>
      <Router history={history} />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

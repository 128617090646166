import { ActionsObservable, ofType, Epic } from "redux-observable";
import { map, filter, takeUntil, mergeMap, catchError } from "rxjs/operators";
import { tag } from "rxjs-spy/operators/tag";
import IAction from "../../interfaces/IAction";
import { of } from "rxjs/internal/observable/of";
// EMPTY
import apiService from "../../services/apis";
import getEndpoint from "../../endpoints";

import types from "../types";
import leadTypes from "../../Containers/Leads/types";

import * as actions from "../actions";
import * as leadActions from "../../Containers/Leads/actions";
import * as campaignsActions from "../../Containers/Campaigns/actions";

/**
 * A VOIR si utile de mettre des epics d'audiences ici
 */

export const checkAudienceAssignationEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.RESPONSES_ACTIONS.CREATE_AUDIENCE_FULFILLED),
    mergeMap((action) => {
      return of(
        campaignsActions.checkAssignation({
          id: action.payload?.audienceCreated.id,
        })
      );
    })
  );
};

export const resetAudienceAssignationEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(
      types.RESPONSES_ACTIONS.CREATE_AUDIENCE_CANCELLED,
      types.RESPONSES_ACTIONS.CREATE_AUDIENCE_REJECTED
    ),
    mergeMap((action) => {
      return of(campaignsActions.resetAssignation());
    })
  );
};

export const loadAudiencesEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  // spy.log("loadSequence");
  return action$.pipe(
    ofType(
      types.REQUESTS_DATA.LOAD_AUDIENCES,
      leadTypes.REQUESTS_DATA.LOAD_LEADS,
      leadTypes.RESPONSES_ACTIONS.EDIT_LEADS_FULFILLED,
      types.RESPONSES_ACTIONS.CREATE_AUDIENCE_FULFILLED,
      types.RESPONSES_ACTIONS.EDIT_AUDIENCE_FULFILLED,
      types.RESPONSES_ACTIONS.DELETE_AUDIENCE_FULFILLED,
      leadTypes.RESPONSES_ACTIONS.DELETE_LEADS_FULFILLED
    ),
    mergeMap((action) => {
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "loadAudiences",
          params: action.payload?.params,
          query: {
            apikey: store.value.authReducer?.user?.apikey,
            // ...action.payload?.query,
          },
        })
      )
        .pipe(
          tag("loadAudiences"),
          map((xhr) =>
            actions.loadAudiencesFulfilled({
              ...xhr.response,
              setLeadFilters: action.payload?.setLeadFilters,
            })
          ),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type === types.RESPONSES_DATA.LOAD_AUDIENCES_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedLoadAudiences(e?.response));
          })
        );
    })
  );
};

export const setLeadFiltersAfterAudienceCreatedEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  // spy.log("loadSequence");
  return action$.pipe(
    ofType(types.RESPONSES_DATA.LOAD_AUDIENCES_FULFILLED),
    mergeMap((action) => {
      if (action.payload.setLeadFilters) {
        return of(
          leadActions.filterUpdate({
            audiences: [action.payload.setLeadFilters],
            keywords: "",
            firstname: "",
            lastname: "",
            email: "",
            company: "",
            gender: "",
            inMultipleAudienceOnly: false,
            withNoAudienceOnly: false,
          })
        );
      }
      return of({ type: "" });
    })
  );
};

export const createAudienceEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  // spy.log("loadSequence");
  return action$.pipe(
    ofType(types.REQUESTS_ACTIONS.CREATE_AUDIENCE),
    mergeMap((action) => {
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "createAudience",
          params: action.payload?.params,
          body: action.payload?.body,
          query: {
            apikey: store.value.authReducer?.user?.apikey,
            ...action.payload?.query,
          },
        })
      )
        .pipe(
          tag("createAudience"),
          map((xhr) => {
            if (action.payload?.callback) {
              action.payload.callback(xhr.response?.audienceCreated?.id);
            }

            return actions.createAudienceFulfilled({
              ...xhr.response,
              setLeadFilters: action.payload?.setLeadFilters
                ? xhr.response?.audienceCreated?.id
                : null,
            });
          }),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_ACTIONS.CREATE_AUDIENCE_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedCreateAudience(e?.response));
          })
        );
    })
  );
};

export const editAudienceEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  // spy.log("loadSequence");
  return action$.pipe(
    ofType(types.REQUESTS_ACTIONS.EDIT_AUDIENCE),
    mergeMap((action) => {
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "editAudience",
          params: action.payload?.params,
          body: action.payload?.body,
          query: {
            apikey: store.value.authReducer?.user?.apikey,
            ...action.payload?.query,
          },
        })
      )
        .pipe(
          map((xhr) =>
            actions.editAudienceFulfilled({
              ...xhr.response,
            })
          ),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_ACTIONS.EDIT_AUDIENCE_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedEditAudience(e?.response));
          })
        );
    })
  );
};

export const deleteAudienceEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  // spy.log("loadSequence");
  return action$.pipe(
    ofType(types.REQUESTS_ACTIONS.DELETE_AUDIENCE),
    mergeMap((action) => {
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "deleteAudience",
          params: action.payload?.params,
          query: {
            apikey: store.value.authReducer?.user?.apikey,
            ...action.payload?.query,
          },
        })
      )
        .pipe(
          map((xhr) =>
            actions.deleteAudienceFulfilled({
              ...xhr.response,
            })
          ),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_ACTIONS.DELETE_AUDIENCE_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedDeleteAudience(e?.response));
          })
        );
    })
  );
};

export const reloadLeadsAfterAudienceEditOrDeleteEpic: Epic<
  IAction,
  IAction
> = (action$: ActionsObservable<IAction>, store: any) => {
  // spy.log("loadSequence");
  return action$.pipe(
    ofType(
      types.RESPONSES_ACTIONS.EDIT_AUDIENCE_FULFILLED,
      types.RESPONSES_ACTIONS.DELETE_AUDIENCE_FULFILLED
    ),
    mergeMap((action) => {
      return of(leadActions.loadLeads({}));
    })
  );
};

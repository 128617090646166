import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Pricing } from "@lagrowthmachine/lgmwebapp";
import { useDispatch } from "react-redux";
import * as authActions from "../../../Auth/actions";
import * as actions from "../../../Settings/actions";
import useSelector from "../../../../redux/useSelector";
import analyticsCall from "../../../../helpers/analyticsCall";

const SettingsPricingComponent = (props: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loadingReducer = useSelector((state) => state.loadingReducer);
  const authReducer = useSelector((state) => state.authReducer);
  const settingsReducer = useSelector((state) => state.settingsReducer);

  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    setFirstLoad(false);
  }, []);

  useEffect(() => {
    analyticsCall("settings", "settings: billing pricing page");
  }, []);

  useEffect(() => {
    dispatch(authActions.loadUser({ query: {} }));
    dispatch(actions.loadPlans());
  }, [dispatch]);

  const selectPlan = (billing: any, plan: any, situation: any) => {
    let state: any = {};
    if (authReducer.user.plan === "TRIAL") {
      state.billing = billing;
    }
    if (situation === "upgrade" || situation === "get") {
      history.push("/settings/payment/" + plan.toLowerCase(), state);
    } else if (situation === "downgrade") {
      history.push({
        pathname: "/settings/downgrade",
        state: { plan: plan },
      });
    }
  };

  if (settingsReducer.retryPayment) {
    history.push("/settings/billing");
    return null;
  }

  const onQuestion = () => {
    (window as any).Intercom("showNewMessage");
  };

  return (
    <div className="settings_pricing_container">
      <Pricing
        plan={authReducer.user.plan}
        billing="monthly"
        prices={settingsReducer.plans}
        onQuestion={onQuestion}
        onCancel={() => history.goBack()}
        selectPlan={selectPlan}
        loading={
          firstLoad ||
          !Boolean(settingsReducer.plans) ||
          settingsReducer.plans?.length === 0 ||
          loadingReducer.dataTypesLoaders.LOAD_USER ||
          loadingReducer.dataTypesLoaders.LOAD_PLANS
        }
      />
    </div>
  );
};

export default SettingsPricingComponent;

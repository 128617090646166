import React from "react";
import ModalWrapper from "../../../../components/ModalWrapper";
import { AddTeammates } from "@lagrowthmachine/lgmwebapp";
import * as settingsFunctions from "../../functions";
import useSelector from "../../../../redux/useSelector";
import { useDispatch } from "react-redux";

function Modal(props: any) {
  const authReducer = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();
  const createInvitation = settingsFunctions.createInvitation(
    dispatch,
    authReducer
  );

  const onInvite = async (emailsList: any) => {
    return createInvitation({ emailsList })
      .then((response) => {
        props.data?.callback();
        return true;
      })
      .catch((e) => {
        props.onRequestClose();
        throw "Error";
      });
  };

  return (
    <ModalWrapper>
      <AddTeammates onInvite={onInvite} onClose={props.onRequestClose} />
    </ModalWrapper>
  );
}

export default Modal;

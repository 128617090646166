/**
 * Containers epics
 */
import * as AuthEpics from "../../Containers/Auth/epics";
import * as CampaignsEpics from "../../Containers/Campaigns/epics";
import * as LeadsEpics from "../../Containers/Leads/epics";
import * as SettingsEpics from "../../Containers/Settings/epics";
import * as IdentitiesEpics from "../../Containers/Identities/epics";

/**
 * Global epics
 */
import * as NotificationsEpics from "./notifications";
import * as AudiencesEpics from "./audiences";
import * as WebhooksEpics from "./webhooks";
import * as ModalsEpics from "./modals";
import * as LoadingEpics from "./loading";
import * as ScrollEpics from "./scroll";
/**
 * Merge all epics
 */
const epics = {
  /**
   * Container Epics
   */
  ...CampaignsEpics,
  ...AuthEpics,
  ...LeadsEpics,
  ...SettingsEpics,
  ...IdentitiesEpics,

  /**
   * Globals epics
   */

  ...NotificationsEpics,
  ...AudiencesEpics,
  ...WebhooksEpics,
  ...ModalsEpics,
  ...LoadingEpics,
  ...ScrollEpics,
};

export default epics;

import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { format } from "date-fns";
import {
  SettingsTopBar,
  SettingsActionsBar,
  Plan,
  CardsList,
  IdentitiesList,
  Invoices,
  DeleteBar,
} from "@lagrowthmachine/lgmwebapp";
import Modal, { types as modalTypes } from "../Modals";
//import authTypes from "../../../Auth/types";
import types from "../../types";
import { useDispatch } from "react-redux";
//import useDebounce from "../../../../helpers/useDebounce";
import * as authActions from "../../../Auth/actions";
import * as identitiesActions from "../../../Identities/actions";
import * as actions from "../../../Settings/actions";
import * as globalActions from "../../../../redux/actions";
import useSelector from "../../../../redux/useSelector";
import { afterPayments } from "../../helpers/paymentsResolve";
import analyticsCall from "../../../../helpers/analyticsCall";
import barNavItems from "../../helpers/barNavItems";

import config, { APP_ENV } from "../../../../config";
import constants from "../../../../config/constants";
import { loadStripe } from "@stripe/stripe-js";

import { Elements } from "@stripe/react-stripe-js";
import { ElementsConsumer } from "@stripe/react-stripe-js";

const stripeKey = config.stripeKeys[APP_ENV];
const stripePromise = loadStripe(stripeKey);

const SettingsBillingComponent = (props: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const modalsReducer = useSelector((state) => state.modalsReducer);
  const loadingReducer = useSelector((state) => state.loadingReducer);
  const authReducer = useSelector((state) => state.authReducer);
  const identitiesReducer = useSelector((state) => state.identitiesReducer);
  const settingsReducer = useSelector((state) => state.settingsReducer);

  const bar = barNavItems(authReducer);

  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    setFirstLoad(false);
  }, []);

  useEffect(() => {
    dispatch(authActions.loadUser({ query: {} }));
    dispatch(identitiesActions.loadIdentities({ query: {} }));
    dispatch(actions.loadInvoices({ query: {} }));
    dispatch(actions.loadCards({ query: {} }));
    dispatch(actions.loadPlans());
  }, [dispatch]);

  useEffect(() => {
    analyticsCall("settings", "settings: billing settings");
  }, []);

  const handleActions = (action: string, data?: any) => {
    switch (action) {
      case "input":
        dispatch(authActions.signOutUser());
        break;
      default:
        break;
    }
  };

  const handleModal = (action: string, data?: any) => {
    switch (action) {
      case "add_card":
        dispatch(
          globalActions.modalUpdate({
            name: "addCard",
            data: null,
          })
        );
        break;
      case "sca_ahead":
        dispatch(
          globalActions.modalUpdate({
            name: "scaAhead",
            data: data,
          })
        );
        break;
      case "payment_failed":
        dispatch(
          globalActions.modalUpdate({
            name: "paymentFailed",
            data: data,
          })
        );
        break;
      case "close":
        dispatch(
          globalActions.modalUpdate({
            name: modalTypes.reset,
          })
        );
        break;
      default:
        break;
    }
  };

  const loadInvoicesFrom = (query: any) => {
    dispatch(actions.loadInvoices({ query }));
  };

  const deleteIdModal = (id: string) => {
    let identity = identitiesReducer.list.find(
      (_identity: any) => _identity.id === id
    );
    if (identity) {
      const identityName =
        identity.firstname || identity.lastname
          ? (identity.firstname || "") + " " + (identity.lastname || "")
          : "Identity missing name";

      dispatch(
        globalActions.modalUpdate({
          name: "deleteIdentity",
          data: {
            title: "Stop " + identityName,
            HtmlContent:
              "<strong>" +
              identityName +
              "</strong> will be canceled at the end of its billing cycle. Campaigns using this identity will be stopped aswell.",
            deleteText: "Yes, cancel",
            cancelText: "Cancel",
            loading: true,
            onDelete: () =>
              dispatch(actions.deleteIdentity({ params: { identityId: id } })),
          },
        })
      );
    }
  };

  const onChangeBilling = (stripe: any, billing: any, identities: any) => {
    if (billing === "monthly") {
      dispatch(
        globalActions.modalUpdate({
          name: "switchBilling",
          data: {
            identities: identitiesReducer.list.filter((_identity: any) =>
              identities.includes(_identity.id)
            ),
            plan: authReducer.user.plan,
            prices: settingsReducer.plans,
            onCancel: () => handleModal("close"),
            onSwitch: () => {
              const _priceBody = {
                type: "changeBilling",
                coupon: undefined,
                planId: settingsReducer.plans[authReducer.user.plan].monthly.id,
                identities: identities, //[array d'identities id], le id concernés
                count: identities.length, //si pas identities au dessus, alors nb de new identities. Si pas de identities ni count, alors c'est pr tout le compte du user
              };
              dispatch(
                actions.processPayment({
                  body: _priceBody,
                  afterPayment: async (data: any) =>
                    afterPayments(
                      stripe,
                      data.summary.paymentIds,
                      true,
                      history,
                      handleModal
                    ),
                })
              );
            },
            loading: false,
          },
        })
      );
    } else {
      history.push({
        pathname: "/settings/payment/yearly",
        state: { identities: identities },
      });
    }
  };

  const onSelectCard = (cardId: string) => {
    dispatch(actions.setDefaultCard({ cardId: cardId }));
  };

  const onRemoveCard = (cardId: string) => {
    dispatch(actions.deleteCard({ cardId: cardId }));
  };

  const onCardAdded = (paymentMethodId: any) => {
    dispatch(actions.attachCardToUser({ paymentMethodId }));
  };

  const retryLastPayment = async (stripe: any, identityId?: string) => {
    dispatch(
      actions.retryLastPayment({
        query: identityId ? { identityId } : {},
        afterPayment: async (data: any) => {
          afterPayments(stripe, data.paymentIds, false, history, handleModal);
        },
      })
    );
  };

  const onContact = () => {
    (window as any).Intercom("showNewMessage");
  };

  const onReactivateId = (
    identityId: string,
    unCancel: boolean,
    end?: number
  ) => {
    if (authReducer.user.plan === "TRIAL") {
      history.push("/settings/pricing");
    } else if (unCancel) {
      const _end = end ? "on " + format(end, "yyyy/MM/dd") : "soon";
      let result = window.confirm(
        "This identity is marked to be canceled " +
          _end +
          ". Please confirm you want to cancel the upcoming deletion and back to normal billing?"
      );
      if (result) {
        dispatch(
          actions.unCancelId({
            params: {
              identityId,
              //plan: authReducer.user.plan
            },
          })
        );
      } else {
        return;
      }
    } else {
      history.push({
        pathname: "/settings/payment/reactivateid",
        state: { identities: [identityId] },
      });
    }
  };

  const identities = identitiesReducer.list.map(
    (_identity: any, ii: number) => {
      const name =
        _identity.firstname || _identity.lastname
          ? (_identity.firstname || "") + " " + (_identity.lastname || "")
          : "Identity missing name";
      const _deleted =
        (_identity.subscription?.cancelAtPeriodEnd &&
          !_identity.subscription?.nextPlanId) ||
        _identity.subscription?.status === "canceled";
      const incomplete_expired =
        _identity.subscription?.status === "incomplete_expired";
      const _error = ["incomplete", "past_due"].includes(
        _identity.subscription?.status
      )
        ? "Payment failed"
        : "";
      const _end =
        _identity.subscription?.status === "canceled"
          ? _identity.subscriptionCanceledAt
          : _identity.subscription?.currentPeriodEnd * 1000 || 0;
      return {
        id: _identity.id,
        createdAt: _identity.createdAt,
        name: name,
        profilePicture: _identity.profilePicture,
        deleted: _deleted || incomplete_expired, //identity.subscription.cancelAtPeriodEnd: Boolean, pre deleted
        upcomingDeletion:
          !incomplete_expired && _deleted && _end && _end > Date.now(),
        end: !incomplete_expired && _end, //identity.subscription.currentPeriodEnd => date à laquelle on s'arrête
        billing: _identity.subscription?.recurrence || "unknown",
        error: (!_deleted && _error) || undefined,
        idWithoutSubscription: !Boolean(_identity.subscription),
        nextPlanName: _identity.subscription?.nextPlanName,
        //identity.subscriptionCanceledAt => data à laquelle a annulé la sub
        //identity.deleted ???
        //identity.subscription === null ???
      };
    }
  );
  //console.log()
  const identitiesRemaining = identities.filter((_id: any) => !_id.deleted)
    .length;
  return (
    <Elements stripe={stripePromise}>
      <ElementsConsumer>
        {({ elements, stripe }: any) => (
          <div className="settings_billing_container">
            <Modal
              name={modalsReducer.name}
              data={modalsReducer.data}
              stripe={stripe}
              onRequestClose={() => handleModal("close")}
              onCardAdded={onCardAdded}
              btnDisabled={
                loadingReducer.btnTypesLoaders.DELETE_IDENTITY ||
                loadingReducer.btnTypesLoaders.ATTACH_CARD_TO_USER
              }
              btnIsLoading={
                loadingReducer.btnTypesLoaders.DELETE_IDENTITY ||
                loadingReducer.btnTypesLoaders.ATTACH_CARD_TO_USER
              }
            />
            <SettingsTopBar onAction={handleActions} />
            <div className="content_wrapper">
              <SettingsActionsBar
                tabs={bar}
                page={"billing"}
                linkC={Link}
                direction="vertical"
              />
              <div className="in">
                <div className="top">
                  <div className="column">
                    {authReducer.user?.plan !== "TRIAL" && (
                      <Plan
                        /*
                        typeformData={{
                          firstname: authReducer.user.firstname,
                          lastname: authReducer.user.lastname,
                          email: authReducer.user.email,
                          userid: authReducer.user.id,
                          plan: authReducer.user.plan,
                          type: "delete",
                          identity: identitiesRemaining,
                        }}
                        typeformId={constants.typeformId}
                        */
                        identities={{
                          monthly: identities.filter(
                            (_identity: any) =>
                              !_identity.deleted &&
                              _identity.billing === "monthly"
                          ).length,
                          yearly: identities.filter(
                            (_identity: any) =>
                              !_identity.deleted &&
                              _identity.billing === "yearly"
                          ).length,
                        }}
                        allowUpgrade={!settingsReducer.retryPayment}
                        plan={authReducer.user?.plan}
                        prices={settingsReducer.plans}
                        changePlan={() => history.push("/settings/pricing")}
                        //cancelPlan={() => history.push("/settings/goodbye")}
                        loading={
                          firstLoad ||
                          !Boolean(settingsReducer.plans) ||
                          settingsReducer.plans?.length === 0 ||
                          loadingReducer.dataTypesLoaders.LOAD_USER ||
                          loadingReducer.dataTypesLoaders.LOAD_PLANS
                        }
                      />
                    )}
                    <CardsList
                      defaultCardId={
                        settingsReducer.cards?.find(
                          (_card: any) => _card.default
                        )?.id
                      }
                      cards={
                        settingsReducer.cards?.map((_card: any) => ({
                          ..._card,
                          error: Boolean(_card.error),
                          errorMessage: _card.error,
                        })) || []
                      }
                      lastPaymentFailed={settingsReducer.retryPayment}
                      onSelectCard={onSelectCard}
                      onRemoveCard={onRemoveCard}
                      onAddCard={() => handleModal("add_card")}
                      retryLastPayment={() => retryLastPayment(stripe)}
                      retryLoading={
                        loadingReducer.btnTypesLoaders.RETRY_PAYMENT
                      }
                      loading={
                        firstLoad ||
                        !Boolean(settingsReducer.plans) ||
                        loadingReducer.dataTypesLoaders.LOAD_PLANS ||
                        loadingReducer.dataTypesLoaders.LOAD_CARDS ||
                        loadingReducer.btnTypesLoaders.SET_DEFAULT_CARD ||
                        loadingReducer.btnTypesLoaders.DELETE_CARD ||
                        loadingReducer.btnTypesLoaders.RETRY_PAYMENT
                      }
                    />
                  </div>
                  <div className="column">
                    <IdentitiesList
                      typeformId={constants.typeformId}
                      typeformData={{
                        firstname: authReducer.user.firstname,
                        lastname: authReducer.user.lastname,
                        email: authReducer.user.email,
                        userid: authReducer.user.id,
                        plan: authReducer.user.plan,
                        type: "stop",
                        identity: identitiesRemaining,
                      }}
                      identities={identities}
                      onReactivateId={onReactivateId}
                      onRetryPayment={(identityId: string) =>
                        retryLastPayment(stripe, identityId)
                      }
                      plan={authReducer.user.plan}
                      prices={settingsReducer.plans}
                      deleteId={deleteIdModal}
                      addId={() => history.push("/settings/payment/addid")}
                      onChangeBilling={(billing: any, identities: any) =>
                        onChangeBilling(stripe, billing, identities)
                      }
                      loading={
                        firstLoad ||
                        !Boolean(settingsReducer.plans) ||
                        settingsReducer.plans?.length === 0 ||
                        loadingReducer.dataTypesLoaders.LOAD_IDENTITIES ||
                        loadingReducer.dataTypesLoaders.LOAD_USER ||
                        loadingReducer.dataTypesLoaders.LOAD_PLANS ||
                        loadingReducer.btnTypesLoaders.DELETE_IDENTITY ||
                        loadingReducer.btnTypesLoaders.UN_CANCEL_ID
                      }
                    />
                  </div>
                </div>

                <Invoices
                  invoices={(settingsReducer.invoices?.invoices || []).map(
                    (_invoice: any) => ({
                      id: _invoice.id,
                      name: _invoice.name,
                      when: _invoice.createdAt * 1000,
                      price: (_invoice.amount / 100).toFixed(2),
                      status: _invoice.status,
                      type: "add type Invoice",
                      downloadUrl: _invoice.pdf,
                    })
                  )}
                  paginate={{
                    prev: {
                      disabled: !settingsReducer.invoices?.hasLess,
                      onAction: () =>
                        loadInvoicesFrom({
                          endingBeforeId:
                            settingsReducer.invoices.endingBeforeId,
                        }),
                    },
                    next: {
                      disabled: !settingsReducer.invoices?.hasMore,
                      onAction: () =>
                        loadInvoicesFrom({
                          lastInvoiceId: settingsReducer.invoices.lastInvoiceId,
                        }),
                    },
                  }}
                  loading={
                    firstLoad ||
                    loadingReducer.dataTypesLoaders[
                      types.REQUESTS_DATA.LOAD_INVOICES
                    ]
                  }
                />
                <DeleteBar
                  typeformData={{
                    firstname: authReducer.user.firstname,
                    lastname: authReducer.user.lastname,
                    email: authReducer.user.email,
                    userid: authReducer.user.id,
                    plan: authReducer.user.plan,
                    type: "delete",
                    identity: identitiesRemaining,
                  }}
                  typeformId={constants.typeformId}
                  cancelPlan={() => history.push("/settings/goodbye")}
                  contactSupport={onContact}
                />
              </div>
            </div>
          </div>
        )}
      </ElementsConsumer>
    </Elements>
  );
};

export default SettingsBillingComponent;

import { ActionsObservable, ofType, Epic } from "redux-observable";

import { map, delay, mergeMap } from "rxjs/operators";
import types from "../types";
import leadTypes from "../../Containers/Leads/types";
import authTypes from "../../Containers/Auth/types";
import identitiesTypes from "../../Containers/Identities/types";
import campaignsTypes from "../../Containers/Campaigns/types";
import settingsTypes from "../../Containers/Settings/types";

// import { create } from "rxjs-spy";
import { of } from "rxjs/internal/observable/of";
// import { tag } from "rxjs-spy/operators/tag";
import * as actions from "../actions";
// import apiService from "../../../services/apis";
// import getEndpoint from "../endpoints";
import IAction from "../../interfaces/IAction";

// import { of } from "rxjs/internal/observable";

// const spy = create();

export const AutoRemoveSmallNotificationEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>
) => {
  // spy.log("loadSequence");
  console.log("set", new Date());
  return action$.pipe(
    ofType(types.SET_NOTIFICATION),
    delay(5000),
    map((action) => {
      console.log("unset", new Date());
      return actions.removeNotificationSmall({
        id: action.payload.id,
        format: action.payload.format,
      });
    })
  );
};

export const notifErrorEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>
) => {
  return action$.pipe(
    ofType(
      leadTypes.RESPONSES_DATA.LOAD_LEADS_REJECTED,
      leadTypes.RESPONSES_DATA.LOAD_LEADS_REJECTED,
      leadTypes.RESPONSES_ACTIONS.CREATE_LEAD_REJECTED,
      leadTypes.RESPONSES_ACTIONS.EDIT_LEAD_REJECTED,
      leadTypes.RESPONSES_ACTIONS.DELETE_LEADS_REJECTED,
      leadTypes.RESPONSES_ACTIONS.EDIT_LEADS_REJECTED,

      leadTypes.RESPONSES_DATA.EXPORT_LEADS_REJECTED,

      authTypes.RESPONSES_ACTIONS.RESET_PWD_REJECTED,
      authTypes.RESPONSES_ACTIONS.LOGIN_USER_REJECTED,
      authTypes.RESPONSES_ACTIONS.REGISTER_USER_REJECTED,
      authTypes.RESPONSES_ACTIONS.RESET_PWD_REQ_REJECTED,
      authTypes.RESPONSES_ACTIONS.VALIDATION_ACCOUNT_REJECTED,
      authTypes.RESPONSES_DATA.LOAD_USER_REJECTED,
      authTypes.RESPONSES_ACTIONS.EDIT_USER_REJECTED,
      authTypes.RESPONSES_ACTIONS.REFRESH_EXTERN_APIKEY_REJECTED,

      types.RESPONSES_ACTIONS.CREATE_WEBHOOK_REJECTED,
      types.RESPONSES_ACTIONS.EDIT_WEBHOOK_REJECTED,
      types.RESPONSES_ACTIONS.REMOVE_WEBHOOK_REJECTED,

      identitiesTypes.RESPONSES_ACTIONS.EDIT_IDENTITY_REJECTED,
      identitiesTypes.RESPONSES_ACTIONS.EDIT_IDENTITY2_REJECTED,
      identitiesTypes.RESPONSES_DATA.LOAD_IDENTITIES_REJECTED,
      identitiesTypes.RESPONSES_ACTIONS.PAYMENT_IDENTITY_REJECTED,

      types.RESPONSES_ACTIONS.CREATE_AUDIENCE_REJECTED,
      campaignsTypes.RESPONSES_ACTIONS.CREATE_CAMPAIGN_REJECTED,
      campaignsTypes.RESPONSES_ACTIONS.EDIT_CAMPAIGN_REJECTED,
      campaignsTypes.RESPONSES_ACTIONS.EDIT_SEQUENCE_REJECTED,
      campaignsTypes.RESPONSES_ACTIONS.EDIT_CAMPAIGN_STATE_REJECTED,
      campaignsTypes.RESPONSES_ACTIONS.DUPLICATE_CAMPAIGN_REJECTED,
      campaignsTypes.EXPORT_CAMPAIGN_STATS_ERROR,
      campaignsTypes.RESPONSES_ACTIONS.DUPLICATE_MESSAGE_TEMPLATE_REJECTED,
      campaignsTypes.RESPONSES_ACTIONS.CREATE_CAMPAIGN_TEMPLATE_REJECTED,
      campaignsTypes.RESPONSES_ACTIONS.EDIT_CAMPAIGN_TEMPLATE_REJECTED,
      campaignsTypes.RESPONSES_ACTIONS.CAMPAIGN_REVIEW_REJECTED,

      settingsTypes.RESPONSES_ACTIONS.HUBSPOT_MAPPING_EDIT_REJECTED,
      settingsTypes.RESPONSES_ACTIONS.HUBSPOT_LIFECYCLE_EDIT_REJECTED,
      settingsTypes.RESPONSES_ACTIONS.PIPEDRIVE_MAPPING_EDIT_REJECTED,
      settingsTypes.RESPONSES_ACTIONS.PIPEDRIVE_LIFECYCLE_EDIT_REJECTED,
      settingsTypes.RESPONSES_DATA.LOAD_PRICE_REJECTED,
      settingsTypes.RESPONSES_DATA.LOAD_PRICE_REJECTED,
      settingsTypes.RESPONSES_ACTIONS.PROCESS_PAYMENT_REJECTED,
      settingsTypes.RESPONSES_ACTIONS.UN_CANCEL_ID_REJECTED,

      types.RESPONSES_ACTIONS.DELETE_AUDIENCE_REJECTED
    ),
    map((action) => {
      return actions.setNotificationSmall({
        notification: {
          type: "error",
          text: action?.payload?.error || "Unknown error",
        },
        format: "basic",
      });
    })
  );
};

export const notifSuccessEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>
) => {
  return action$.pipe(
    ofType(
      // authTypes.VALIDATION_ACCOUNT_FULFILLED,

      identitiesTypes.RESPONSES_ACTIONS.EDIT_IDENTITY_FULFILLED,
      identitiesTypes.RESPONSES_ACTIONS.EDIT_IDENTITY2_FULFILLED
    ),
    map((action) => {
      return actions.setNotificationSmall({
        notification: {
          type: "success",
          text: "Identity successfully updated",
        },
        format: "basic",
      });
    })
  );
};

export const notifCampaignReviewEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>
) => {
  return action$.pipe(
    ofType(campaignsTypes.RESPONSES_ACTIONS.CAMPAIGN_REVIEW_FULFILLED),
    map((action) => {
      return actions.setNotificationSmall({
        notification: {
          type: "success",
          text: "Campaign Review requested. You'll be contacted soon.",
        },
        format: "basic",
      });
    })
  );
};

export const notifPwdRequestedEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>
) => {
  return action$.pipe(
    ofType(authTypes.RESPONSES_ACTIONS.RESET_PWD_REQ_FULFILLED),
    map((action) => {
      return actions.setNotificationSmall({
        notification: {
          type: "success",
          text: `If ${
            action.payload?.email || "your email"
          } is associated to a LaGrowthMachine account, you will receive an email to reset your password. Although it shouldn't be in the promotion or spam folders, you may want to check those if you don't see it in your regular inbox.`,
        },
        format: "basic",
      });
    })
  );
};

export const notifExportLeadsEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>
) => {
  return action$.pipe(
    ofType(
      leadTypes.RESPONSES_DATA.EXPORT_LEADS_FULFILLED,
      campaignsTypes.RESPONSES_DATA.EXPORT_CAMPAIGN_LEADS_FULFILLED
    ),
    map((action) => {
      return actions.setNotificationSmall({
        notification: {
          type: "success",
          text: "Your file will be available soon",
        },
        format: "basic",
      });
    })
  );
};

export const notifExportStatsEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>
) => {
  return action$.pipe(
    ofType(campaignsTypes.EXPORT_CAMPAIGN_STATS_DONE),
    map((action) => {
      return actions.setNotificationSmall({
        notification: {
          type: "success",
          text: "Your file is available",
        },
        format: "basic",
      });
    })
  );
};

export const notifRegisterSuccessEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>
) => {
  return action$.pipe(
    ofType(authTypes.RESPONSES_ACTIONS.REGISTER_USER_FULFILLED),
    map((action) => {
      return actions.setNotificationSmall({
        notification: {
          type: "success",
          text: "Welcome ! You are now registered and signed in.",
          // text: "You successfully signed up !",
        },
        format: "basic",
      });
    })
  );
};

export const notifSimulateSuccessEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>
) => {
  return action$.pipe(
    ofType(types.RESPONSES_ACTIONS.SIMULATE_WEBHOOK_FULFILLED),
    map((action) => {
      return actions.setNotificationSmall({
        notification: {
          type: "success",
          text: "Webhook successfully sent.",
        },
        format: "basic",
      });
    })
  );
};

export const notifSimulateFailedEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>
) => {
  return action$.pipe(
    ofType(types.RESPONSES_ACTIONS.SIMULATE_WEBHOOK_REJECTED),
    map((action) => {
      return actions.setNotificationSmall({
        notification: {
          type: "error",
          text: "Webhook cannot be sent.",
        },
        format: "basic",
      });
    })
  );
};

export const notifEditWebhookSuccessEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>
) => {
  return action$.pipe(
    ofType(types.RESPONSES_ACTIONS.EDIT_WEBHOOK_FULFILLED),
    map((action) => {
      return actions.setNotificationSmall({
        notification: {
          type: "success",
          text: `Webhook ${action.payload.name} successfully edited.`,
        },
        format: "basic",
      });
    })
  );
};

export const notifRemoveWebhookSuccessEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>
) => {
  return action$.pipe(
    ofType(types.RESPONSES_ACTIONS.REMOVE_WEBHOOK_FULFILLED),
    map((action) => {
      return actions.setNotificationSmall({
        notification: {
          type: "success",
          text: `Webhook ${action.payload.name} successfully deleted.`,
        },
        format: "basic",
      });
    })
  );
};

export const notifUpdatedUserSuccessEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>
) => {
  return action$.pipe(
    ofType(
      authTypes.RESPONSES_ACTIONS.EDIT_USER_FULFILLED,
      authTypes.RESPONSES_ACTIONS.EDIT_MEMBER_FULFILLED
    ),
    map((action) => {
      return actions.setNotificationSmall({
        notification: {
          type: "success",
          text: `Settings have been saved.`,
        },
        format: "basic",
      });
    })
  );
};

export const notifResetPwdSuccessEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>
) => {
  return action$.pipe(
    ofType(authTypes.RESPONSES_ACTIONS.RESET_PWD_FULFILLED),
    map((action) => {
      return actions.setNotificationSmall({
        notification: {
          type: "success",
          text: `Password have been reset, please login using your new credentials.`,
        },
        format: "basic",
      });
    })
  );
};

export const notifCreateCampaignSuccessEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>
) => {
  return action$.pipe(
    ofType(campaignsTypes.RESPONSES_ACTIONS.CREATE_CAMPAIGN_FULFILLED),
    map((action) => {
      return actions.setNotificationSmall({
        notification: {
          type: "success",
          text: `Campaign ${action.payload.campaign?.name} has been created.`,
        },
        format: "basic",
      });
    })
  );
};
export const notifEditCampaignSuccessEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>
) => {
  return action$.pipe(
    ofType(campaignsTypes.RESPONSES_ACTIONS.EDIT_CAMPAIGN_FULFILLED),
    map((action) => {
      return actions.setNotificationSmall({
        notification: {
          type: "success",
          text: `Campaign ${action.payload.campaign?.name} has been edited.`,
        },
        format: "basic",
      });
    })
  );
};

export const notifEditCampaignStateSuccessEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>
) => {
  return action$.pipe(
    ofType(campaignsTypes.RESPONSES_ACTIONS.EDIT_CAMPAIGN_STATE_FULFILLED),
    map((action) => {
      const campaignName = action.payload.campaign?.name || "";
      return actions.setNotificationSmall({
        notification: {
          type: "success",
          text: `Campaign ${campaignName} state has been edited.`,
        },
        format: "basic",
      });
    })
  );
};

export const notifDeleteLeadsSuccessEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>
) => {
  return action$.pipe(
    ofType(leadTypes.RESPONSES_ACTIONS.DELETE_LEADS_FULFILLED),
    map((action) => {
      return actions.setNotificationSmall({
        notification: {
          type: "success",
          text: `${action.payload.nbCount} successfully deleted.`,
        },
        format: "basic",
      });
    })
  );
};
export const notifEditLeadsSuccessEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>
) => {
  return action$.pipe(
    ofType(leadTypes.RESPONSES_ACTIONS.EDIT_LEADS_FULFILLED),
    map((action) => {
      return actions.setNotificationSmall({
        notification: {
          type: "success",
          text: `${action.payload.nbCount} leads successfully edited.`,
        },
        format: "basic",
      });
    })
  );
};
export const notifEditLeadsuccessEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>
) => {
  return action$.pipe(
    ofType(leadTypes.RESPONSES_ACTIONS.EDIT_LEAD_FULFILLED),
    map((action) => {
      if (action.payload?.options?.preventNotification) {
        return { type: "" };
      }
      return actions.setNotificationSmall({
        notification: {
          type: "success",
          text: `1 lead successfully edited.`,
        },
        format: "basic",
      });
    })
  );
};

export const notifCreateCampaignTemplateSuccessEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>
) => {
  return action$.pipe(
    ofType(campaignsTypes.RESPONSES_ACTIONS.CREATE_CAMPAIGN_TEMPLATE_FULFILLED),
    map((action) => {
      return actions.setNotificationSmall({
        notification: {
          type: "success",
          text: `Message ${action.payload.response.name} successfully created.`,
        },
        format: "basic",
      });
    })
  );
};

export const notifEditCampaignTemplateSuccessEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>
) => {
  return action$.pipe(
    ofType(campaignsTypes.RESPONSES_ACTIONS.EDIT_CAMPAIGN_TEMPLATE_FULFILLED),
    map((action) => {
      return actions.setNotificationSmall({
        notification: {
          type: "success",
          text: `${action.payload.response.name} successfully edited.`,
        },
        format: "basic",
      });
    })
  );
};

export const notifDuplicateTemplateSuccessEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>
) => {
  return action$.pipe(
    ofType(
      campaignsTypes.RESPONSES_ACTIONS.DUPLICATE_MESSAGE_TEMPLATE_FULFILLED
    ),
    map((action) => {
      return actions.setNotificationSmall({
        notification: {
          type: "success",
          text: `Message successfully duplicated into ${action.payload.response.name}.`,
        },
        format: "basic",
      });
    })
  );
};

export const notifDuplicateCampaignSuccessEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>
) => {
  return action$.pipe(
    ofType(campaignsTypes.RESPONSES_ACTIONS.DUPLICATE_CAMPAIGN_FULFILLED),
    map((action) => {
      return actions.setNotificationSmall({
        notification: {
          type: "success",
          text: `${
            action.payload?.campaign?.title || action.payload?.campaign?.name
          } successfully duplicated. You should probably update its name !`,
        },
        format: "basic",
      });
    })
  );
};

export const notifEditSequenceSuccessEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>
) => {
  return action$.pipe(
    ofType(campaignsTypes.RESPONSES_ACTIONS.EDIT_SEQUENCE_FULFILLED),
    map((action) => {
      const sequenceName =
        action.payload?.response?.campaigns[0]?.name || "Sequence";
      return actions.setNotificationSmall({
        notification: {
          type: "success",
          text: `${sequenceName} successfully edited.`,
        },
        format: "basic",
      });
    })
  );
};

export const notifRefreshApiKeySuccessEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>
) => {
  return action$.pipe(
    ofType(authTypes.RESPONSES_ACTIONS.REFRESH_EXTERN_APIKEY_FULFILLED),
    map((action) => {
      return actions.setNotificationSmall({
        notification: {
          type: "success",
          text: `External API Key successfully updated.`,
        },
        format: "basic",
      });
    })
  );
};

export const notifCopiedApiKeySuccessEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>
) => {
  return action$.pipe(
    ofType(settingsTypes.LOGS_EXTERNAL_KEY_COPIED),
    map((action) => {
      return actions.setNotificationSmall({
        notification: {
          type: "success",
          text: `External API Key copied to clipboard.`,
        },
        format: "basic",
      });
    })
  );
};

export const notifCreateAudienceLinkedinLeadsEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>
) => {
  return action$.pipe(
    ofType(
      types.RESPONSES_ACTIONS.CREATE_AUDIENCE_FULFILLED,
      types.RESPONSES_ACTIONS.EDIT_AUDIENCE_FULFILLED
    ),
    mergeMap((action: any) => {
      if (
        !action?.payload?.audienceCreated?.sourceUrl &&
        !action?.payload?.sourceUrl
      )
        return of({ type: "" });
      return of(
        actions.setNotificationSmall({
          notification: {
            type: "success",
            text: "Your linkedin import will start soon",
          },
          format: "basic",
        })
      );
    })
  );
};

export const notifCreateAudienceEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>
) => {
  return action$.pipe(
    ofType(types.RESPONSES_ACTIONS.CREATE_AUDIENCE_FULFILLED),
    mergeMap((action: any) => {
      if (
        action?.payload?.audienceCreated?.sourceUrl ||
        action?.payload?.sourceUrl
      )
        return of({ type: "" });
      return of(
        actions.setNotificationSmall({
          notification: {
            type: "success",
            text: "Audience successfully created",
          },
          format: "basic",
        })
      );
    })
  );
};

export const deleteAudienceEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>
) => {
  return action$.pipe(
    ofType(types.RESPONSES_ACTIONS.DELETE_AUDIENCE_CANCELLED),
    mergeMap((action: any) => {
      return of(
        actions.setNotificationSmall({
          notification: {
            type: "success",
            text: "Audience deleted",
          },
          format: "basic",
        })
      );
    })
  );
};

export const notifEditAudienceEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>
) => {
  return action$.pipe(
    ofType(types.RESPONSES_ACTIONS.EDIT_AUDIENCE_FULFILLED),
    mergeMap((action: any) => {
      if (
        action?.payload?.audienceCreated?.sourceUrl ||
        action?.payload?.sourceUrl
      )
        return of({ type: "" });
      return of(
        actions.setNotificationSmall({
          notification: {
            type: "success",
            text: "Audience successfully updated",
          },
          format: "basic",
        })
      );
    })
  );
};

export const notifSocketDisconnectChannelEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>
) => {
  return action$.pipe(
    ofType(types.SOCKET_DISCONNECT_CHANNEL),
    mergeMap((action: any) => {
      action.payload.channel =
        action.payload.channel === "GOOGLE" ? "EMAIL" : action.payload.channel;
      return of(
        actions.setNotificationSmall({
          notification: {
            type: "success",
            text: `${
              action.payload.channel.charAt(0).toUpperCase() +
              action.payload.channel.toLowerCase().slice(1)
            } will be disconnected soon.`,
          },
          format: "basic",
        })
      );
    })
  );
};

export const notifNotifyTcEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>
) => {
  return action$.pipe(
    ofType(authTypes.NOTIFY_TC),
    mergeMap((action: any) => {
      return of(
        actions.setNotificationSmall({
          notification: {
            type: "error",
            text: "You must agree with Terms and Conditions",
          },
          format: "basic",
        })
      );
    })
  );
};

export const notifHubspotConnectFailedEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>
) => {
  return action$.pipe(
    ofType(settingsTypes.RESPONSES_ACTIONS.HUBSPOT_CONNECT_REJECTED),
    map((action) => {
      return actions.setNotificationSmall({
        notification: {
          type: "error",
          text: "Hubspot connection failed.",
        },
        format: "basic",
      });
    })
  );
};

export const notifPipedriveConnectFailedEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>
) => {
  return action$.pipe(
    ofType(settingsTypes.RESPONSES_ACTIONS.PIPEDRIVE_CONNECT_REJECTED),
    map((action) => {
      return actions.setNotificationSmall({
        notification: {
          type: "error",
          text: "Pipedrive connection failed.",
        },
        format: "basic",
      });
    })
  );
};

export const notifNotifyCsvMatchingErrorEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>
) => {
  return action$.pipe(
    ofType(leadTypes.CSV_MATCHING_ERROR),
    mergeMap((action: any) => {
      return of(
        actions.setNotificationSmall({
          notification: {
            type: "error",
            text:
              "Lead can't be created: You need to add at least an Email, a LinkedIn url, a Twitter url or a combination of firstname + lastname + company to create a new lead.",
          },
          format: "basic",
        })
      );
    })
  );
};

export const notifHubspotConnectFulfilledEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>
) => {
  return action$.pipe(
    ofType(settingsTypes.RESPONSES_ACTIONS.HUBSPOT_CONNECT_FULFILLED),
    map((action) => {
      return actions.setNotificationSmall({
        notification: {
          type: "success",
          text: `Hubspot successfully connected.`,
        },
        format: "basic",
      });
    })
  );
};

export const notifPipedriveConnectFulfilledEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>
) => {
  return action$.pipe(
    ofType(settingsTypes.RESPONSES_ACTIONS.PIPEDRIVE_CONNECT_FULFILLED),
    map((action) => {
      return actions.setNotificationSmall({
        notification: {
          type: "success",
          text: `Pipedrive successfully connected.`,
        },
        format: "basic",
      });
    })
  );
};

export const notifHubspotDisconnectFailedEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>
) => {
  return action$.pipe(
    ofType(settingsTypes.RESPONSES_ACTIONS.HUBSPOT_DISCONNECT_REJECTED),
    map((action) => {
      return actions.setNotificationSmall({
        notification: {
          type: "error",
          text: "Hubspot disconnection failed.",
        },
        format: "basic",
      });
    })
  );
};

export const notifPipedriveDisconnectFailedEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>
) => {
  return action$.pipe(
    ofType(settingsTypes.RESPONSES_ACTIONS.PIPEDRIVE_DISCONNECT_REJECTED),
    map((action) => {
      return actions.setNotificationSmall({
        notification: {
          type: "error",
          text: "Pipedrive disconnection failed.",
        },
        format: "basic",
      });
    })
  );
};

export const notifHubspotDisconnectFulfilledEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>
) => {
  return action$.pipe(
    ofType(settingsTypes.RESPONSES_ACTIONS.HUBSPOT_DISCONNECT_FULFILLED),
    map((action) => {
      return actions.setNotificationSmall({
        notification: {
          type: "success",
          text: `Your hubspot CRM is disconnected.`,
        },
        format: "basic",
      });
    })
  );
};

export const notifPipedriveDisconnectFulfilledEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>
) => {
  return action$.pipe(
    ofType(settingsTypes.RESPONSES_ACTIONS.PIPEDRIVE_DISCONNECT_FULFILLED),
    map((action) => {
      return actions.setNotificationSmall({
        notification: {
          type: "success",
          text: `Your pipedrive CRM is disconnected.`,
        },
        format: "basic",
      });
    })
  );
};

export const notifHubspotMappingEditFulfilledEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>
) => {
  return action$.pipe(
    ofType(settingsTypes.RESPONSES_ACTIONS.HUBSPOT_MAPPING_EDIT_FULFILLED),
    map((action) => {
      return actions.setNotificationSmall({
        notification: {
          type: "success",
          text: `Hubspot Linkedin mapping successfully saved.`,
        },
        format: "basic",
      });
    })
  );
};

export const notifPipedriveMappingEditFulfilledEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>
) => {
  return action$.pipe(
    ofType(settingsTypes.RESPONSES_ACTIONS.PIPEDRIVE_MAPPING_EDIT_FULFILLED),
    map((action) => {
      return actions.setNotificationSmall({
        notification: {
          type: "success",
          text: `Pipedrive mapping successfully saved.`,
        },
        format: "basic",
      });
    })
  );
};

export const notifHubspotLifecycleEditFulfilledEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>
) => {
  return action$.pipe(
    ofType(settingsTypes.RESPONSES_ACTIONS.HUBSPOT_LIFECYCLE_EDIT_FULFILLED),
    map((action) => {
      return actions.setNotificationSmall({
        notification: {
          type: "success",
          text: `Hubspot lifecycles successfully saved.`,
        },
        format: "basic",
      });
    })
  );
};

export const notifPipedriveLifecycleEditFulfilledEpic: Epic<
  IAction,
  IAction
> = (action$: ActionsObservable<IAction>) => {
  return action$.pipe(
    ofType(settingsTypes.RESPONSES_ACTIONS.PIPEDRIVE_LIFECYCLE_EDIT_FULFILLED),
    map((action) => {
      return actions.setNotificationSmall({
        notification: {
          type: "success",
          text: `Pipedrive lifecycles successfully saved.`,
        },
        format: "basic",
      });
    })
  );
};

export const notifUnCancelIdFulfilledEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>
) => {
  return action$.pipe(
    ofType(settingsTypes.RESPONSES_ACTIONS.UN_CANCEL_ID_FULFILLED),
    map((action) => {
      return actions.setNotificationSmall({
        notification: {
          type: "success",
          text: `This identity will not be stopped anymore.`,
        },
        format: "basic",
      });
    })
  );
};

// REQUESTS_ACTIONS: {},
// REQUESTS_DATA: {},
// RESPONSES_ACTIONS: {},
// RESPONSES_DATA: {},
export default {
  REQUESTS_ACTIONS: {
    EDIT_IDENTITY: "EDIT_IDENTITY",
    EDIT_IDENTITY2: "EDIT_IDENTITY2",
    PAYMENT_IDENTITY: "PAYMENT_IDENTITY",
  },
  REQUESTS_DATA: {
    LOAD_IDENTITIES: "LOAD_IDENTITIES",
    LOAD_IDENTITY_LOGS: "LOAD_IDENTITY_LOGS",
    LOAD_IDENTITY_LIMITS: "LOAD_IDENTITY_LIMITS",
  },
  RESPONSES_ACTIONS: {
    EDIT_IDENTITY_FULFILLED: "EDIT_IDENTITY_FULFILLED",
    EDIT_IDENTITY_CANCELLED: "EDIT_IDENTITY_CANCELLED",
    EDIT_IDENTITY_REJECTED: "EDIT_IDENTITY_REJECTED",

    EDIT_IDENTITY2_FULFILLED: "EDIT_IDENTITY2_FULFILLED",
    EDIT_IDENTITY2_CANCELLED: "EDIT_IDENTITY2_CANCELLED",
    EDIT_IDENTITY2_REJECTED: "EDIT_IDENTITY2_REJECTED",

    PAYMENT_IDENTITY_FULFILLED: "PAYMENT_IDENTITY_FULFILLED",
    PAYMENT_IDENTITY_REJECTED: "PAYMENT_IDENTITY_REJECTED",
  },
  RESPONSES_DATA: {
    LOAD_IDENTITIES_FULFILLED: "LOAD_IDENTITIES_FULFILLED",
    LOAD_IDENTITIES_CANCELLED: "LOAD_IDENTITIES_CANCELLED",
    LOAD_IDENTITIES_REJECTED: "LOAD_IDENTITIES_REJECTED",

    LOAD_IDENTITY_LOGS_FULFILLED: "LOAD_IDENTITY_LOGS_FULFILLED",
    LOAD_IDENTITY_LOGS_CANCELLED: "LOAD_IDENTITY_LOGS_CANCELLED",
    LOAD_IDENTITY_LOGS_REJECTED: "LOAD_IDENTITY_LOGS_REJECTED",

    LOAD_IDENTITY_LIMITS_FULFILLED: "LOAD_IDENTITY_LIMITS_FULFILLED",
    LOAD_IDENTITY_LIMITS_CANCELLED: "LOAD_IDENTITY_LIMITS_CANCELLED",
    LOAD_IDENTITY_LIMITS_REJECTED: "LOAD_IDENTITY_LIMITS_REJECTED",
  },

  IDENTITIES_CLEAR: "IDENTITIES_CLEAR",
  IDENTITY_PENDING_ACTION: "IDENTITY_PENDING_ACTION",
  IDENTITY_LOGS_PAGINATE: "IDENTITY_LOGS_PAGINATE",
};

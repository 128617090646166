const getLoginTwitter = (twitter: string) => {
  const twitterRegExp = new RegExp(
    "((https?://)?(www.)?twitter.com/)?(@|#!/)?([A-Za-z0-9_]{1,15})(/([-a-z]{1,20}))?"
  );
  const matchResult = twitterRegExp.exec(twitter);
  if (!matchResult || (matchResult && matchResult[5] === "undefined"))
    return twitter || "";
  return matchResult && matchResult[5];
};

export const arrayOf = (array: any[], type: string) => {
  if (type === "IN") {
    return array.map((item) => adapterIN(item));
  }
  // if (type === "OUT") {
  //   return array.map((item) => adapterOUT(item));
  // }
};

export const adapterTableIN = (lead: any) => {
  if (!lead) return null;
  let _lead = lead;
  _lead = {
    ..._lead,
    ...{
      customAttribute1: _lead.customAttributes[0].content || null,
      customAttribute2: _lead.customAttributes[1].content || null,
      customAttribute3: _lead.customAttributes[2].content || null,
      customAttribute4: _lead.customAttributes[3].content || null,
      customAttribute5: _lead.customAttributes[4].content || null,
      customAttribute6: _lead.customAttributes[5].content || null,
      customAttribute7: _lead.customAttributes[6].content || null,
      customAttribute8: _lead.customAttributes[7].content || null,
      customAttribute9: _lead.customAttributes[8].content || null,
      customAttribute10: _lead.customAttributes[9].content || null,
    },
  };
  delete _lead.customAttributes;
  return _lead;
};

export const adapterIN = (lead: any) => {
  if (!lead) return null;
  const data = {
    id: lead.id || lead._id,
    firstName: lead.firstname,
    lastName: lead.lastname,
    avatar: lead.profilePicture,
    note: lead.note,
    bio: lead.shortBio || "",
    proEmail: lead.proEmail,
    persoEmail: lead.persoEmail,
    company: lead.companyName,
    companyName: lead.companyName,
    job: lead.jobTitle,
    location: lead.location,
    linkedin: lead.linkedinUrl,
    gender: lead.gender,
    phone: lead.phone,
    lastActions: lead.lastActions,
    website: lead.companyUrl,
    industry: lead.industry,
    twitter: lead.twitter,
    pastEmployers: "",
    subtitle: lead.note || "No notes added yet",
    email: lead.proEmail || "none",
    audiences: lead.audiences,
    audiencesWithName: lead.audiencesWithName,
    pausedCampaigns: lead.pausedCampaigns,
    involvedCampaigns: lead.involvedCampaigns,
    involvedCampaignsLogs: lead.involvedCampaignsLogs,
    customAttributes: [
      { content: lead.customAttribute1 },
      { content: lead.customAttribute2 },
      { content: lead.customAttribute3 },
      { content: lead.customAttribute4 },
      { content: lead.customAttribute5 },
      { content: lead.customAttribute6 },
      { content: lead.customAttribute7 },
      { content: lead.customAttribute8 },
      { content: lead.customAttribute9 },
      { content: lead.customAttribute10 },
    ],
    hubspot:
      lead.hubspot && Object.keys(lead.hubspot).length !== 0
        ? lead.hubspot
        : null,
    pipedrive:
      lead.pipedrive && Object.keys(lead.pipedrive).length !== 0
        ? lead.pipedrive
        : null,
  };
  //console.log("adapterIN");
  //console.log(data);
  return data;
};

export const adapterCreateOUT = ({ lead }: { lead: any }) => {
  if (!lead.customAttributes) {
    lead.customAttributes = [];
  }
  const data = {
    ...lead,
    firstname: lead.firstName,
    lastname: lead.lastName,
    jobTitle: lead.job,
    companyName: lead.companyName || lead.company,
    profilePicture: lead.avatar,
    linkedinUrl: lead.linkedin,
    companyUrl: lead.website,
    shortBio: lead.bio,
    customAttribute1:
      lead.customAttributes[0] && lead.customAttributes[0].content,
    customAttribute2:
      lead.customAttributes[1] && lead.customAttributes[1].content,
    customAttribute3:
      lead.customAttributes[2] && lead.customAttributes[2].content,
    customAttribute4:
      lead.customAttributes[3] && lead.customAttributes[3].content,
    customAttribute5:
      lead.customAttributes[4] && lead.customAttributes[4].content,
    customAttribute6:
      lead.customAttributes[5] && lead.customAttributes[5].content,
    customAttribute7:
      lead.customAttributes[6] && lead.customAttributes[6].content,
    customAttribute8:
      lead.customAttributes[7] && lead.customAttributes[7].content,
    customAttribute9:
      lead.customAttributes[8] && lead.customAttributes[8].content,
    customAttribute10:
      lead.customAttributes[9] && lead.customAttributes[9].content,
    hubspot: lead.hubspot ? { ...lead.hubspot, lead: undefined } : {},
    pipedrive: lead.pipedrive ? { ...lead.pipedrive, lead: undefined } : {},
  };

  if (data.twitter) {
    data.twitter = lead.twitter;
    // data.twitter = getLoginTwitter(lead.twitter);
  }
  delete data.firstName;
  delete data.lastName;
  delete data.job;
  delete data.company;
  delete data.linkedin;
  delete data.website;
  delete data.customAttributes;
  delete data.pastEmployers;
  return { lead: data };
};

export const adapterEditOUT = ({ lead }: { lead: any }) => {
  if (!lead.customAttributes) {
    lead.customAttributes = [];
  }
  const data = {
    ...lead,
    firstname: lead.firstName,
    lastname: lead.lastName,
    jobTitle: lead.job,
    companyName: lead.companyName || lead.company,
    company: lead.companyName || lead.company,
    profilePicture: lead.avatar,
    linkedinUrl: lead.linkedin,
    companyUrl: lead.website,
    shortBio: lead.bio,
    note: lead.note,
    customAttribute1:
      lead.customAttributes[0] && lead.customAttributes[0].content,
    customAttribute2:
      lead.customAttributes[1] && lead.customAttributes[1].content,
    customAttribute3:
      lead.customAttributes[2] && lead.customAttributes[2].content,
    customAttribute4:
      lead.customAttributes[3] && lead.customAttributes[3].content,
    customAttribute5:
      lead.customAttributes[4] && lead.customAttributes[4].content,
    customAttribute6:
      lead.customAttributes[5] && lead.customAttributes[5].content,
    customAttribute7:
      lead.customAttributes[6] && lead.customAttributes[6].content,
    customAttribute8:
      lead.customAttributes[7] && lead.customAttributes[7].content,
    customAttribute9:
      lead.customAttributes[8] && lead.customAttributes[8].content,
    customAttribute10:
      lead.customAttributes[9] && lead.customAttributes[9].content,
    hubspot: lead.hubspot ? { ...lead.hubspot, lead: undefined } : {},
    pipedrive: lead.pipedrive ? { ...lead.pipedrive, lead: undefined } : {},
  };

  if (data.twitter) {
    data.twitter = getLoginTwitter(lead.twitter);
  }

  delete data.firstName;
  delete data.lastName;
  delete data.job;
  delete data.company;
  delete data.linkedin;
  delete data.website;
  delete data.customAttributes;
  delete data.bio;
  delete data.error;

  // shortBio: body.lead?.bio,
  // proEmail: body.lead?.proEmail,
  // persoEmail: body.lead?.persoEmail,
  // linkedinPublicId: body.lead?.linkedin,
  // companyUrl: body.lead?.website,

  delete data.showCA;
  delete data.showContact;
  delete data.pastEmployers;
  // const cleanData = Object.keys(data).filter((key: string) => {
  //   if (!data[key] && data[key] !== "") {
  //     delete data[key];
  //   }
  // });
  //console.log("adapterEditOUT");
  //console.log(data);
  return { lead: data };
};

import React, { useCallback, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Modal, { types as modalTypes } from "../Modals";
import {
  SettingsTopBar,
  SettingsActionsBar,
  ManageTeammates,
} from "@lagrowthmachine/lgmwebapp";
import analyticsCall from "../../../../helpers/analyticsCall";
import barNavItems from "../../helpers/barNavItems";
import useSelector from "../../../../redux/useSelector";
import { useDispatch } from "react-redux";
import * as globalActions from "../../../../redux/actions";
import * as authActions from "../../../Auth/actions";
import * as settingsFunctions from "../../functions";

const SettingsTeammatesComponent = (props: any) => {
  const authReducer = useSelector((state) => state.authReducer);
  const modalsReducer = useSelector((state) => state.modalsReducer);
  const dispatch = useDispatch();
  const bar = barNavItems(authReducer);
  const history = useHistory();
  const loadInvitations = settingsFunctions.loadInvitations(
    dispatch,
    authReducer
  );

  const createInvitation = settingsFunctions.createInvitation(
    dispatch,
    authReducer
  );

  const removeInvitation = settingsFunctions.removeInvitation(
    dispatch,
    authReducer
  );

  const removeMember = settingsFunctions.removeMember(dispatch, authReducer);
  const loadMembers = settingsFunctions.loadMembers(dispatch, authReducer);
  const [invitations, setInvitations] = useState([] as any[]);
  const [members, setMembers] = useState([] as any[]);

  const onLoadInvitations = async () => {
    return loadInvitations()
      .then((response) => {
        setInvitations(response);
        return true;
      })
      .catch((e) => {
        setInvitations([]);
        throw "Error";
      });
  };

  const onLoadMembers = async () => {
    return loadMembers()
      .then((response) => {
        setMembers(response);
        return true;
      })
      .catch((e) => {
        setInvitations([]);
        throw "Error";
      });
  };

  /*
  useEffect(() => {
    analyticsCall("settings", "settings: api settings");
  }, []);
*/

  const handleModal = useCallback(
    ({ name, data = {} }) => {
      dispatch(globalActions.modalUpdate({ name, data }));
    },
    [dispatch]
  );

  //load initial data
  useEffect(() => {
    onLoadInvitations();
    onLoadMembers();
    dispatch(authActions.loadUser({}));
  }, [dispatch]);

  /*test*/
  const handleActions = (action: string, data?: any) => {
    switch (action) {
      case "input":
        dispatch(authActions.signOutUser());
        break;
      default:
        break;
    }
  };

  const _members = members.map((_mbr: any) => {
    const name =
      ((_mbr.firstname || "") + " " + (_mbr.lastname || "")).trim() ||
      "Member missing name";
    return {
      id: _mbr.id,
      email: _mbr.email,
      name: name,
      picture: _mbr.avatar,
      isMe: authReducer.user.apikey === _mbr.apikey,
    };
  });

  const onRemoveMember = async (memberId: string) => {
    return removeMember({ memberId })
      .then((response) => {
        onLoadMembers().then((response) => {
          return true;
        });
      })
      .catch((e) => {
        throw "Error";
      });
  };

  const onRemoveInvitation = async (invitationId: string) => {
    return removeInvitation({ invitationId })
      .then((response) => {
        onLoadInvitations().then((response) => {
          return true;
        });
      })
      .catch((e) => {
        throw "Error";
      });
  };

  const onInvite = async (emailsList: any) => {
    return createInvitation({ emailsList })
      .then((response) => {
        props.data?.callback();
        return true;
      })
      .catch((e) => {
        props.onRequestClose();
        throw "Error";
      });
  };

  const onResendInvitation = async (email: string) => {
    return createInvitation({ emailsList: [email] })
      .then((response) => {
        return true;
      })
      .catch((e) => {
        throw "Error";
      });
  };

  const allocateTeammate = () => {
    history.push("/settings/widget");
  };

  const newTeammate = () => {
    handleModal({
      name: "addTeammates",
      data: {
        callback: () => {
          onLoadInvitations();
          handleModal({ name: modalTypes.reset });
        },
      },
    });
  };

  return (
    <div className="settings_teammates_container">
      <Modal
        name={modalsReducer.name}
        data={modalsReducer.data}
        onRequestClose={() => handleModal({ name: modalTypes.reset })}
      />
      <SettingsTopBar onAction={handleActions} />
      <div className="content_wrapper">
        <SettingsActionsBar
          tabs={bar}
          page="teammates"
          linkC={Link}
          direction="vertical"
        />
        <ManageTeammates
          teammates={_members}
          invitations={invitations}
          removeTeammate={onRemoveMember}
          removeInvitation={onRemoveInvitation}
          resendInvitation={onResendInvitation}
          allocateTeammate={allocateTeammate}
          newTeammate={newTeammate}
        />
      </div>
    </div>
  );
};

export default SettingsTeammatesComponent;

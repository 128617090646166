import React from "react";
import ModalWrapper from "../../../../components/ModalWrapper";
import { ModalBasic } from "@lagrowthmachine/lgmwebapp";
import { useDispatch } from "react-redux";
import * as actions from "../../actions";
import useSelector from "../../../../redux/useSelector";
import types from "../../types";

function Modal(props: any) {
  const dispatch = useDispatch();
  const loadingReducer = useSelector((state) => state.loadingReducer);
  const campaign = props.data.campaign;

  const handleStop = () => {
    dispatch(
      actions.editCampaignState({
        callback: props.data?.refresh,
        params: {
          id: campaign.id,
          status: "CANCEL",
        },
      })
    );
  };

  return (
    <ModalWrapper>
      <ModalBasic
        type="warning"
        subtitle="This action cannot be undone. Are you sure you want to stop this campaign ?"
        onAction={handleStop}
        onCancel={props.onRequestClose}
        title={`Stoping ${campaign.name}`}
        cancelTextBtn="Cancel"
        actionTextBtn="Stop"
        btnIsLoading={
          loadingReducer.btnTypesLoaders[
            types.REQUESTS_ACTIONS.EDIT_CAMPAIGN_STATE
          ]
        }
        btnDisabled={
          loadingReducer.btnTypesLoaders[
            types.REQUESTS_ACTIONS.EDIT_CAMPAIGN_STATE
          ]
        }
      />
    </ModalWrapper>
  );
}

export default Modal;

import { encodeQuery } from "../helpers/encodeQuery";
import config, { APP_ENV } from "../config";
import MethodType from "../interfaces/IMethod";
import { IEndpointParams } from "../interfaces/IEndpoint";
import getApiUrl from "../helpers/getApiUrl";

/**
 * GLOBAL ENDPOINTS
 */

export default function getEndpoint({
  name,
  body,
  query,
  params,
  apiVersion,
}: IEndpointParams): {
  url: string;
  method: MethodType;
  headers?: { [name: string]: string };
  body?: any;
  crossDomain?: boolean;
} {
  let queryEncoded: any;

  if (query) {
    queryEncoded = encodeQuery(query);
  }
  switch (name) {
    case "loadAudiences":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.audiences
        }?${queryEncoded}`,
        method: "GET",
        crossDomain: true,
      };
    case "createAudience":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.audiences
        }?${queryEncoded}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body,
        crossDomain: true,
      };
    case "editAudience":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${config.endpoints.audiences}${
          params.id
        }?${queryEncoded}`,
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body,
        crossDomain: true,
      };

    case "deleteAudience":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${config.endpoints.audiences}${
          params.id
        }?${queryEncoded}`,
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        crossDomain: true,
      };
    case "loadWebhooks":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.webhooks
        }?${queryEncoded}`,
        method: "GET",
        crossDomain: true,
      };
    case "createWebhook":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.webhooks
        }?${queryEncoded}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body,
        crossDomain: true,
      };
    case "editWebhook":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${config.endpoints.webhooks}${
          params.id
        }?${queryEncoded}`,
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body,
        crossDomain: true,
      };
    case "removeWebhook":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${config.endpoints.webhooks}${
          params.id
        }?${queryEncoded}`,
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        crossDomain: true,
      };
    case "simulateWebhook":
      return {
        url: `${getApiUrl(APP_ENV, apiVersion)}${
          config.endpoints.webhooks
        }simulate?${queryEncoded}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body,
        crossDomain: true,
      };
    default:
      return {
        url: ``,
        method: "GET",
      };
  }
}

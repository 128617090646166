export const arrayOfGraphAudiences = (array: any[], type: string) => {
  if (type === "IN") {
    return array.map((item) => adapterGraphAudienceIN(item));
  }
};

export const adapterGraphAudienceIN = (audience: any) => {
  return {
    id: audience.id,
    name: audience.name,
    size: audience.size,
  };
};

export const APP_ENV = process.env.REACT_APP_ENV || "DEVELOPMENT";

const getBuckets = (v: number = 1) => {
  switch (APP_ENV) {
    case "PREPROD":
      return {
        utils: `lgm-utils-v${v}-preprod`,
      };
    case "PRODUCTION":
      return {
        utils: `lgm-utils-v${v}-prod`,
      };
    default:
      return {
        utils: `lgm-utils-v${v}-dev-2`,
      };
  }
};

const config = {
  removeSidebarPages: [
    "/settings/payment/starter",
    "/settings/payment/pro",
    "/settings/payment/business",
    "/settings/payment/addid",
    "/settings/payment/yearly",
    "/settings/payment/monthly",
    //"/settings/downgrade",
    //"/settings/goodbye",
    "/settings/pricing",
    "/onboarding/widget_download",
    "/onboarding/email_connect",
    "/onboarding/linkedin_connect",
    "/onboarding/twitter_connect",
    "/onboarding/crm_connect",
  ],
  public_name: "LaGrowthMachine",
  apiUrlV2: {
    PRODUCTION: "https://apiv2.lagrowthmachine.com/",
    PREPROD: "https://apiv2-preprod.lagrowthmachine.xyz/",
    DEVELOPMENT: "https://apiv2-dev-2.lagrowthmachine.xyz/",
    LOCAL: "http://localhost:8080/",
  },
  bugsnagKey: "5568beaec4330e547343060f4cfaea89",
  endpoints: {
    users: `usersv1/`,
    members: `membersv1/`,
    invitations: `invitationsv1/`,
    identities: `identitiesv1/`,
    sequences: `sequencesv1/`,
    leads: `leadsv1/`,
    webhooks: `webhooksv1/`,
    audiences: `audiencesv1/`,
    templates: `templatesv1/`,
    filters: `filtersv1/`,
    campaigns: `campaignsv1/`,
    logs: `logsv1/`,
    crm: `crmv1/`,
  },
  socketUrl2: {
    PRODUCTION: "wss://apiv2.lagrowthmachine.com/",
    PREPROD: "wss://apiv2-preprod.lagrowthmachine.xyz/",
    DEVELOPMENT: "https://apiv2-dev-2.lagrowthmachine.xyz/",
    LOCAL: "ws://localhost:8080/",
  },
  stripeKeys: {
    PRODUCTION: "pk_live_3C0EfGduuPAtmv3uOccxHImS",
    PREPROD: "pk_test_jGGntxxF5DbpJdsD7MeURdXg",
    DEVELOPMENT:
      "pk_test_51JzcfhBpMbxdLupQ2PP8wLXSwrDxstzXJAakQLgDk8f1yW8KSw6kACDNRmrWwxUgVklHWnXjELDbmQYs32uTV7Hm00hpF2nlJ6",
    LOCAL: "pk_test_GmnapV6tH8RhNOPQ6IzwDCr5",
  },
  limitLeads: 25,
  limitCampaigns: 8,
  limitLogs: 25,
  buckets: getBuckets(1),
  bucketsv2: getBuckets(2),
  maxNotifications: 10,
  recaptchaKey: "6LfTTuEZAAAAALus3lz917WvUFizuewA6MOsrMJQ",
  frontVersion: "9",
};
export default config;

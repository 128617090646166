import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import {
  SettingsTopBar,
  SettingsActionsBar,
  SettingsUser,
  SettingsTeammate,
} from "@lagrowthmachine/lgmwebapp";
import Modal, { types as modalTypes } from "../Modals";
import authTypes from "../../../Auth/types";
import { useDispatch } from "react-redux";
import * as authActions from "../../../Auth/actions";
import * as globalActions from "../../../../redux/actions";
import generateRandomAvatar from "../../helpers/generateRandomAvatar";
import useSelector from "../../../../redux/useSelector";
import barNavItems from "../../helpers/barNavItems";
import analyticsCall from "../../../../helpers/analyticsCall";
import * as settingsFunctions from "../../functions";

const SettingsMemberComponent = (props: any) => {
  const dispatch = useDispatch();
  const modalsReducer = useSelector((state) => state.modalsReducer);
  const loadingReducer = useSelector((state) => state.loadingReducer);
  const [imgSrc, setImgSrc] = useState("");
  const authReducer = useSelector((state) => state.authReducer);
  const uploadUrl = authReducer?.uploadUrl;
  const futurUploadedUrl = authReducer?.futurUploadedUrl;

  const bar = barNavItems(authReducer);
  const resendLink = settingsFunctions.resendLink(dispatch, authReducer);
  useEffect(() => {
    dispatch(authActions.loadUser({ query: {} }));
  }, [dispatch]);

  useEffect(() => {
    analyticsCall("settings", "settings: user settings");
  }, []);

  useEffect(() => {
    dispatch(globalActions.scrollToTop());
  }, [dispatch]);

  const handleActions = (action: string, data?: any) => {
    switch (action) {
      case "input":
        dispatch(authActions.signOutUser());
        break;
      case "saveUserSettings":
        dispatch(
          authActions.editUser({
            query: {},
            body: data,
          })
        );
        break;
      case "uploadNewAvatar":
        document.getElementById("avatarInput")?.click();
        break;
      default:
        break;
    }
  };

  const readFile = (file: any) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  };

  const handleModal = (data: any) => {
    dispatch(globalActions.modalUpdate(data));
  };

  const memoizedCallback = useCallback(
    (src: string) => {
      let canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");

      canvas.width = 100; // target width
      canvas.height = 100; // target height

      let image = new Image();
      image.onload = function (e) {
        ctx?.drawImage(
          image,
          0,
          0,
          image.width,
          image.height,
          0,
          0,
          canvas.width,
          canvas.height
        );

        var resampledImage = new Image();
        resampledImage.src = canvas.toDataURL();
        const buf = new Buffer(
          resampledImage.src.replace(/^data:image\/\w+;base64,/, ""),
          "base64"
        );
        dispatch(
          authActions.uploadFile({
            query: { url: uploadUrl, futurUrl: futurUploadedUrl },
            body: buf,
          })
        );
      };

      image.src = src;
    },
    [dispatch, futurUploadedUrl, uploadUrl]
  );

  useEffect(() => {
    if (uploadUrl && imgSrc) {
      memoizedCallback(imgSrc);
    }
  }, [uploadUrl, imgSrc, memoizedCallback]);

  const onFileChange = async (e: any) => {
    setImgSrc("");
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const imageDataUrl: string | any = await readFile(file);

      dispatch(
        authActions.getUploadUrl({
          body: {
            bucket: "avatar-v2-users",
            acl: "public-read",
            fileName: `${generateRandomAvatar(30)}.jpeg`,
          },
        })
      );

      handleModal({
        name: "cropAvatar",
        data: { imageSrc: imageDataUrl },
      });
    }
  };

  const onCropped = (img: string) => {
    setImgSrc(img);
  };

  const onSubmit = (data: any) => {
    dispatch(
      authActions.editMember({
        query: {},
        body: data,
      })
    );
  };

  const uploadAvatar = () => {
    document.getElementById("avatarInput")?.click();
  };

  const onResendLink = async () => {
    return resendLink()
      .then((response) => {
        return true;
      })
      .catch((e) => {
        throw "Error";
      });
  };

  return (
    <div className="settings_user_container">
      <Modal
        name={modalsReducer.name}
        data={modalsReducer.data}
        onRequestClose={() => handleModal({ name: modalTypes.reset })}
        onCropped={onCropped}
      />

      <SettingsTopBar onAction={handleActions} />
      <div className="content_wrapper">
        <SettingsActionsBar
          tabs={bar}
          page="member"
          linkC={Link}
          direction="vertical"
        />
        <div className="settings_container">
          <input id="avatarInput" type="file" onChange={onFileChange} hidden />
          <SettingsTeammate
            onSubmit={onSubmit}
            uploadAvatar={uploadAvatar}
            resendLink={onResendLink}
            emailValidated={Boolean(authReducer.user?.emailVerified)}
            avatar={authReducer.user?.avatar || undefined}
            firstName={authReducer.user?.firstname}
            lastName={authReducer.user?.lastname}
            email={authReducer.user?.email}
            saveLoading={
              loadingReducer.btnTypesLoaders[
                authTypes.REQUESTS_ACTIONS.EDIT_USER
              ]
            }
            resendLoading={loadingReducer.currentActions.includes("resendLink")}
          />
        </div>
      </div>
    </div>
  );
};

export default SettingsMemberComponent;

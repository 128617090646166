import axios from "axios";
import MethodType from "../interfaces/IMethod";

export default async function (
  getEndpoint: (payload: {
    body?: any;
    params?: any;
    query?: any;
    name: string;
    apiVersion?: string;
  }) => {
    url: string;
    method: MethodType;
    headers?: { [name: string]: string };
    body?: any;
  },
  payload: any
) {
  const endpoint = getEndpoint(payload);
  if (typeof endpoint === "object") {
    return axios({
      url: endpoint.url,
      data: endpoint.body,
      headers: endpoint.headers,
      method: endpoint.method,
    });
  }
  return null;
}

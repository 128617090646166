/**
 * Global Types
 */

export default {
  REQUESTS_ACTIONS: {
    CREATE_AUDIENCE: "CREATE_AUDIENCE",
    EDIT_AUDIENCE: "EDIT_AUDIENCE",
    DELETE_AUDIENCE: "DELETE_AUDIENCE",
    CREATE_WEBHOOK: "CREATE_WEBHOOK",
    EDIT_WEBHOOK: "EDIT_WEBHOOK",
    REMOVE_WEBHOOK: "REMOVE_WEBHOOK",
    SIMULATE_WEBHOOK: "SIMULATE_WEBHOOK",
  },
  REQUESTS_DATA: {
    LOAD_AUDIENCES: "LOAD_AUDIENCES",
    LOAD_WEBHOOKS: "LOAD_WEBHOOKS",
  },
  RESPONSES_ACTIONS: {
    CREATE_AUDIENCE_FULFILLED: "CREATE_AUDIENCE_FULFILLED",
    CREATE_AUDIENCE_CANCELLED: "CREATE_AUDIENCE_CANCELLED",
    CREATE_AUDIENCE_REJECTED: "CREATE_AUDIENCE_REJECTED",

    EDIT_AUDIENCE_FULFILLED: "EDIT_AUDIENCE_FULFILLED",
    EDIT_AUDIENCE_CANCELLED: "EDIT_AUDIENCE_CANCELLED",
    EDIT_AUDIENCE_REJECTED: "EDIT_AUDIENCE_REJECTED",

    DELETE_AUDIENCE_FULFILLED: "DELETE_AUDIENCE_FULFILLED",
    DELETE_AUDIENCE_CANCELLED: "DELETE_AUDIENCE_CANCELLED",
    DELETE_AUDIENCE_REJECTED: "DELETE_AUDIENCE_REJECTED",

    CREATE_WEBHOOK_FULFILLED: "CREATE_WEBHOOK_FULFILLED",
    CREATE_WEBHOOK_CANCELLED: "CREATE_WEBHOOK_CANCELLED",
    CREATE_WEBHOOK_REJECTED: "CREATE_WEBHOOK_REJECTED",

    EDIT_WEBHOOK_FULFILLED: "EDIT_WEBHOOK_FULFILLED",
    EDIT_WEBHOOK_CANCELLED: "EDIT_WEBHOOK_CANCELLED",
    EDIT_WEBHOOK_REJECTED: "EDIT_WEBHOOK_REJECTED",

    REMOVE_WEBHOOK_FULFILLED: "REMOVE_WEBHOOK_FULFILLED",
    REMOVE_WEBHOOK_CANCELLED: "REMOVE_WEBHOOK_CANCELLED",
    REMOVE_WEBHOOK_REJECTED: "REMOVE_WEBHOOK_REJECTED",

    SIMULATE_WEBHOOK_FULFILLED: "SIMULATE_WEBHOOK_FULFILLED",
    SIMULATE_WEBHOOK_REJECTED: "SIMULATE_WEBHOOK_REJECTED",
    SIMULATE_WEBHOOK_CANCELLED: "SIMULATE_WEBHOOK_CANCELLED",
  },
  RESPONSES_DATA: {
    LOAD_AUDIENCES_FULFILLED: "LOAD_AUDIENCES_FULFILLED",
    LOAD_AUDIENCES_CANCELLED: "LOAD_AUDIENCES_CANCELLED",
    LOAD_AUDIENCES_REJECTED: "LOAD_AUDIENCES_REJECTED",

    LOAD_WEBHOOKS_FULFILLED: "LOAD_WEBHOOKS_FULFILLED",
    LOAD_WEBHOOKS_CANCELLED: "LOAD_WEBHOOKS_CANCELLED",
    LOAD_WEBHOOKS_REJECTED: "LOAD_WEBHOOKS_REJECTED",
  },

  ASSIGNATION_WEBHOOK: "ASSIGNATION_WEBHOOK",
  ASSIGNATION_WEBHOOK_CLEAR: "ASSIGNATION_WEBHOOK_CLEAR",

  ASSIGNATION_AUDIENCE: "ASSIGNATION_AUDIENCE",
  ASSIGNATION_AUDIENCE_CLEAR: "ASSIGNATION_AUDIENCE_CLEAR",

  UPDATE_MODAL: "UPDATE_MODAL",
  NOTIFICATION_CLEAR: "NOTIFICATION_CLEAR",
  AUDIENCE_CLEAR: "AUDIENCE_CLEAR",
  WEBHOOK_CLEAR: "WEBHOOK_CLEAR",

  SET_NOTIFICATION_PERSIST: "SET_NOTIFICATION_PERSIST",
  SET_NOTIFICATION: "SET_NOTIFICATION",
  REMOVE_NOTIFICATION: "REMOVE_NOTIFICATION",
  SET_NOTIFICATION_BIG: "SET_NOTIFICATION_BIG",
  REMOVE_NOTIFICATION_BIG: "REMOVE_NOTIFICATION_BIG",

  LOADING_CLEAR: "LOADING_CLEAR",
  UPDATE_BTN_LOADERS: "UPDATE_BTN_LOADERS",
  UPDATE_DATA_LOADERS: "UPDATE_DATA_LOADERS",

  ADD_ACTION: "ADD_ACTION",
  REMOVE_ACTION: "REMOVE_ACTION",

  SOCKET_INIT: "SOCKET/INIT",
  SOCKET_DESTROY: "SOCKET/DESTROY",
  SOCKET_CONNECT_CHANNEL: "SOCKET/CONNECT_CHANNEL",
  SOCKET_DISCONNECT_CHANNEL: "SOCKET/DISCONNECT_CHANNEL",
  SOCKET_OPEN_BROWSER: "SOCKET/OPEN_BROWSER",
  SOCKET_ONBOARDING_FINISHED: "SOCKET/ONBOARDING_FINISHED",
  SOCKET_WIDGET_IS_AVAILABLE: "SOCKET_WIDGET_IS_AVAILABLE",

  WIDGET_AVAILABLE: "WIDGET_AVAILABLE",
  WIDGET_NOT_AVAILABLE: "WIDGET_NOT_AVAILABLE",
  SCROLL_TO_TOP: "SCROLL_TO_TOP",
};

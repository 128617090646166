import React, { useState, useEffect } from "react";
import { Sidebar, IdWidget } from "@lagrowthmachine/lgmwebapp";
import { type } from "@lagrowthmachine/lgmwebapp";
import { Link, useLocation } from "react-router-dom";
import useSelector from "../../redux/useSelector";
import { IStateSockets } from "../../interfaces/IState";
import * as globalActions from "../../redux/actions";
import * as leadActions from "../../Containers/Leads/actions";
import { useDispatch } from "react-redux";

const pages = ["campaigns", "sequences", "leads", "identities", "settings"];

const LayoutComponent = (props: any) => {
  const dispatch = useDispatch();
  const notificationReducer = useSelector((state) => state.notificationReducer);
  const authReducer = useSelector((state) => state.authReducer);
  const socketReducer: IStateSockets = useSelector(
    (state) => state.socketReducer
  );
  const loadingReducer = useSelector((state) => state.loadingReducer);
  const [page, setPage] = useState("campaigns");
  const location = useLocation();

  const handleCloseNotifcationCta = (id: string) => {
    dispatch(
      globalActions.removeNotificationSmall({
        id,
        format: "cta",
      })
    );
  };

  const onAction = ({
    data,
    name,
    notifId,
  }: {
    data: any;
    name: string;
    notifId: string;
  }) => {
    switch (name) {
      case "viewLead":
        // if (
        //   loadingReducer.dataTypesLoaders[leadTypes.REQUESTS_DATA.LOAD_LEADS]
        // ) {
        //   dispatch(leadActions.loadLeadsCancelled());
        // }
        dispatch(
          leadActions.filterUpdate({
            audiences: [],
            keywords: "",
            firstname: "",
            lastname: "",
            email: "",
            company: "",
            gender: "",
            inMultipleAudienceOnly: false,
            leadId: data.id === "" ? undefined : data.id,
          })
        );
        handleCloseNotifcationCta(notifId);
        break;
      default:
        break;
    }
  };

  if (
    authReducer.user?.id &&
    authReducer.user?.apikey &&
    !socketReducer.connected
  ) {
    dispatch(globalActions.socketInit());
  }

  useEffect(() => {
    getPage(location.pathname);
  }, [location.pathname]);

  const getPage = (pathname: string) => {
    pages.find((p) => {
      if (pathname.search(p) === 1) {
        setPage(p);
      }
      return false;
    });
  };

  const checkAppLoading = () => {
    if (
      Object.keys(loadingReducer.dataTypesLoaders).find(
        (e: string) => loadingReducer.dataTypesLoaders[e]
      ) ||
      Object.keys(loadingReducer.btnTypesLoaders).find(
        (e: string) => loadingReducer.btnTypesLoaders[e]
      ) ||
      loadingReducer.currentActions.length !== 0
    ) {
      return true;
    }
    return false;
  };

  return (
    <React.Fragment>
      <Sidebar
        removeSidebar={props.removeSidebar}
        linkC={Link}
        pages={["campaigns", "leads", "identities", "settings"]}
        page={page as type.PagePropsType}
        isLoading={checkAppLoading()}
        warnings={[]}
        avatar={authReducer.user?.avatar}
        connected_as={
          authReducer.impersonate
            ? {
                firstName: authReducer.user?.firstname || "",
                lastName: authReducer.user?.lastname || "",
              }
            : undefined
        }
        notifications={[
          ...notificationReducer.basic.map((_notif: any) => ({
            format: "basic",
            type: _notif.type || "error",
            topHtmlContent: _notif.text || JSON.stringify(_notif),
          })),
          ...notificationReducer.cta.map((_notif: any) => ({
            format: "cta",
            type: _notif.type || "info",
            topHtmlContent: _notif.topHtmlContent || "",
            bottomHtmlContent: _notif.bottomHtmlContent || "",
            onClose: !_notif.canCancel
              ? () => handleCloseNotifcationCta(_notif.id)
              : undefined,
            btns: (_notif.btns || []).map((_btn: any) => ({
              label: _btn.label,
              role: _btn.role,
              onAction: () => onAction({ ..._btn.action, notifId: _notif.id }),
            })),
          })),
          ...notificationReducer.big
            .filter((_notif: any) => _notif.type === "lead_action")
            .map((_notif: any) => ({
              format: "big",
              type: "loading",
              topCompContent: (
                <IdWidget
                  id={_notif.widget.id}
                  firstName={_notif.widget.firstName}
                  lastName={_notif.widget.lastName}
                  avatar={_notif.widget.avatar}
                  has_circle={true}
                  is_disconnected={false}
                />
              ),
              bottomCompContent: (
                <React.Fragment>
                  {_notif.text1}
                  <br />
                  <br />
                  {_notif.text2}
                </React.Fragment>
              ),
              onClose: undefined,
              btns: [],
            })),
        ]}
      >
        {props.children}
      </Sidebar>
    </React.Fragment>
  );
};

export default LayoutComponent;

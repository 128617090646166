import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import {
  SettingsTopBar,
  SettingsActionsBar,
  DwnldWidget,
  WidgetAllocation,
} from "@lagrowthmachine/lgmwebapp";

import { useDispatch } from "react-redux";
import * as globalActions from "../../../../redux/actions";
import * as authActions from "../../../Auth/actions";
import * as identitiesActions from "../../../Identities/actions";
import isMacintosh from "../../../../helpers/isMacintosh";
import isWindows from "../../../../helpers/isWindows";
import useSelector from "../../../../redux/useSelector";
import analyticsCall from "../../../../helpers/analyticsCall";
import barNavItems from "../../helpers/barNavItems";
import constants from "../../../../config/constants";
import * as settingsFunctions from "../../functions";

const WidgetComponent = (props: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const authReducer = useSelector((state) => state.authReducer);
  const socketReducer = useSelector((state) => state.socketReducer);
  const identitiesReducer = useSelector((state) => state.identitiesReducer);
  const loadingReducer = useSelector((state) => state.loadingReducer);
  const bar = barNavItems(authReducer);
  const loadMembers = settingsFunctions.loadMembers(dispatch, authReducer);
  const reassignWidget = settingsFunctions.reassignWidget(
    dispatch,
    authReducer
  );

  const [members, setMembers] = useState([] as any[]);

  const onLoadMembers = async () => {
    return loadMembers()
      .then((response) => {
        setMembers(response);
        return true;
      })
      .catch((e) => {
        throw "Error";
      });
  };

  useEffect(() => {
    onLoadMembers();
    dispatch(identitiesActions.loadIdentities({ query: { limit: false } }));
    dispatch(authActions.loadUser({ query: {} }));
  }, [dispatch]);

  /*test*/
  const handleActions = (action: string, data?: any) => {
    switch (action) {
      case "input":
        dispatch(authActions.signOutUser());
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    analyticsCall("authentication", "authentication: email validation");
  }, []);

  useEffect(() => {
    dispatch(globalActions.scrollToTop());
  }, [dispatch]);

  const getDownloadLink = () => {
    if (isMacintosh()) {
      return constants.widgetProdUrl.mac;
    }
    if (isWindows()) {
      return constants.widgetProdUrl.windows;
    }
  };

  const onDownload = () => {
    window.open(getDownloadLink(), "_blank");
  };

  const identities: any =
    identitiesReducer?.list
      ?.filter(
        (_identity: any) =>
          ![
            "incomplete",
            "past_due",
            "incomplete_expired",
            "canceled",
          ].includes(_identity.subscription?.status)
      )
      .map((_identity: any) => ({
        id: _identity.id,
        name: _identity.name || "Identity missing name",
        picture: _identity.profilePicture,
        label: _identity.linkedinData?.type || "Basic",
        timezone: _identity.parameters.timezone || "",
        teammateId: _identity.memberId,
      })) || [];

  const timeDifference = (difference: number) => {
    const ranges = {
      "year(s) ago": 29030400000,
      "month(s) ago": 2419200000,
      "week(s) ago": 604800000,
      "day(s) ago": 86400000,
      "hour(s) ago": 3600000,
      "minute(s) ago": 60000,
      "sec(s) ago": 1000,
      now: 1,
    };
    const key =
      Object.keys(ranges).find((_key: string) => difference >= ranges[_key]) ||
      "now";
    return String(Math.floor(difference / ranges[key]) + " " + key);
  };

  const teammates =
    members.map((_member: any) => {
      let name = (
        (_member.firstname || "") +
        " " +
        (_member.lastname || "")
      ).trim();
      return {
        value: _member.id,
        label: name || "Member missing name",
        picture: _member.avatar,
        lastConnected: _member.lastSocketConnectedElapsed
          ? timeDifference(_member.lastSocketConnectedElapsed)
          : "",
        error: _member.socketConnected ? "" : "reconnect",
      };
    }) || [];

  const manageTeammate = () => {
    history.push("/settings/teammates");
  };

  const onChange = (
    obj: { identityId: string; teammateId?: string },
    action: string
  ) => {
    const identity = identities.find(
      (_identity: any) => _identity.id === obj.identityId
    );
    const member = teammates.find(
      (_member: any) => _member.value === obj.teammateId
    );
    if (action === "clear") {
      let confirm = window.confirm(
        'You are about to remove "' +
          identity!.name +
          '" from a running widget. Do you confirm?'
      );
      if (!confirm) return;
      reassignWidget({ identityId: obj.identityId })
        .then((response) => {
          dispatch(
            identitiesActions.loadIdentities({ query: { limit: false } })
          );
          return true;
        })
        .catch((e) => {
          throw "Error";
        });
    } else if (action === "select-option" && obj.teammateId) {
      let confirm = window.confirm(
        'You are about to run "' +
          identity!.name +
          '" on "' +
          member!.label +
          '" widget. Do you confirm?'
      );
      if (!confirm) return;
      reassignWidget({ identityId: obj.identityId, memberId: obj.teammateId })
        .then((response) => {
          dispatch(
            identitiesActions.loadIdentities({ query: { limit: false } })
          );
          return true;
        })
        .catch((e) => {
          throw "Error";
        });
    }
  };
  const isLoading =
    loadingReducer.currentActions.includes("reassignWidget") ||
    loadingReducer.dataTypesLoaders.LOAD_IDENTITIES;

  return (
    <div className="settings_widget_container">
      <SettingsTopBar onAction={handleActions} />

      <div className="content_wrapper">
        <SettingsActionsBar
          tabs={bar}
          page={"widget"}
          linkC={Link}
          direction="vertical"
        />
        <div className={"in " + (isLoading ? "loading" : "")}>
          <DwnldWidget
            connected={socketReducer.widgetAvailable}
            onDownload={onDownload}
            isMacintosh={isMacintosh()}
            isWindows={isWindows()}
          />
          <WidgetAllocation
            identities={identities}
            teammates={teammates}
            onChange={onChange}
            manageTeammate={manageTeammate}
          />
        </div>
      </div>
    </div>
  );
};

export default WidgetComponent;

import { ActionsObservable, ofType, Epic } from "redux-observable";
import config from "../../../config";
import { map, filter, takeUntil, mergeMap, catchError } from "rxjs/operators";

import types from "../types";
import { History } from "history";
import { tag } from "rxjs-spy/operators/tag";
import * as actions from "../actions";
import * as campaignActions from "../../Campaigns/actions";
import apiService from "../../../services/apis";
import getEndpoint from "../endpoints";
import IAction from "../../../interfaces/IAction";
import * as adapterLead from "../adapters/lead";
import { of } from "rxjs/internal/observable/of";
// import { IStoreEpic } from "../../../redux/configure";

export const loadLeadsAfterEditManyEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any,
  deps: { history: History }
) => {
  return action$.pipe(
    ofType(types.RESPONSES_ACTIONS.EDIT_LEADS_FULFILLED),
    map(() => {
      if (deps?.history?.location?.pathname.indexOf("/overview") !== -1) {
        return campaignActions.loadCampaignLeads({});
      }
      return actions.loadLeads({});
    })
  );
};

export const leadsFilterEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>
) => {
  return action$.pipe(
    ofType(types.LEADS_FILTERS),
    map((action) => {
      return actions.loadLeads(action.payload);
    })
  );
};

export const loadLeadsEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(
      types.REQUESTS_DATA.LOAD_LEADS,
      types.LEADS_PAGINATE,
      types.RESPONSES_ACTIONS.DELETE_LEADS_FULFILLED
    ),
    mergeMap((action) => {
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "loadLeads",
          params: action.payload?.params,
          query: {
            ...store.value.leadsReducer.filters,
            apikey: store.value.authReducer?.user?.apikey,
            // audiences: action.payload?.query?.audiences || "",

            limit: config.limitLeads,
            skip:
              (store.value.leadsReducer?.paginate?.current || 0) *
              config.limitLeads,
            ...action.payload?.query,
            leadId:
              store.value.leadsReducer?.filters?.leadId ||
              (action.payload?.leadId === ""
                ? undefined
                : action.payload?.leadId),
          },
        })
      )
        .pipe(
          tag("loadLeads"),
          map((xhr) =>
            actions.loadLeadsFulfilled({
              leads: adapterLead.arrayOf(xhr.response.leads, "IN"),
              total: xhr.response.total,
            })
          ),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type === types.REQUESTS_DATA.LOAD_LEADS ||
                  action.type === types.LEADS_FILTERS ||
                  action.type === types.RESPONSES_DATA.LOAD_LEADS_CANCELLED
              )
            )
          )
          // takeUntil(
          //   action$.pipe(
          //     filter(
          //       (action) =>
          //         action.type === types.RESPONSES_DATA.LOAD_LEADS_CANCELLED
          //     )
          //   )
          // )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedLoadLeads(e?.response));
          })
        );
    })
  );
};

export const exportLeadsEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_DATA.EXPORT_LEADS),
    mergeMap((action) => {
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "loadLeads",
          query: {
            ...store.value.leadsReducer.filters,
            apikey: store.value.authReducer?.user?.apikey,
            // audiences: action.payload?.query?.audiences || "",
            limit: config.limitLeads,
            skip: 0,
            shouldExport: true,
            // ...action.payload?.query,
          },
        })
      )
        .pipe(
          tag("loadLeads"),
          map((xhr) =>
            actions.exportLeadsFulfilled({
              url: xhr.response.url,
            })
          ),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type === types.RESPONSES_DATA.EXPORT_LEADS_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedExportLeads(e?.response));
          })
        );
    })
  );
};

export const loadLeadEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_DATA.LOAD_LEAD),
    mergeMap((action) => {
      const setLead = action.payload?.setLead;
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "loadLead",
          query: {
            apikey: store.value.authReducer?.user?.apikey,
            ...action.payload?.query,
          },
          params: action.payload?.params,
        })
      )
        .pipe(
          // tag("loadCampaign"),
          map((xhr) =>
            actions.loadLeadFulfilled({
              lead: adapterLead.adapterIN(xhr.response.lead),
              setLead: setLead,
            })
          ),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type === types.RESPONSES_DATA.LOAD_LEAD_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedLoadLead(e?.response));
          })
        );
    })
  );
};

export const loadLeadFulfilledEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.RESPONSES_DATA.LOAD_LEAD_FULFILLED),
    map((action) => {
      const setLead = action.payload.setLead;
      setLead(action.payload.lead);
      return { type: "" };
    })
  );
};

export const createLeadEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_ACTIONS.CREATE_LEAD),
    mergeMap((action) => {
      const callback = action.payload?.callback;
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "createLead",
          query: {
            apikey: store.value.authReducer?.user?.apikey,
            ...action.payload?.query,
          },
          params: action.payload?.params,
          body: adapterLead.adapterCreateOUT(action.payload?.body),
        })
      )
        .pipe(
          // tag("loadCampaign"),
          map((xhr) => {
            return actions.createLeadFulfilled({
              response: xhr.response,
              callback: callback,
            });
          }),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type === types.RESPONSES_ACTIONS.CREATE_LEAD_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            //callback();
            return of(actions.rejectedCreateLead(e?.response));
          })
        );
    })
  );
};

export const editLeadEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_ACTIONS.EDIT_LEAD),
    mergeMap((action) => {
      const callback = action.payload?.callback;
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "editLead",
          query: {
            apikey: store.value.authReducer?.user?.apikey,
            ...action.payload?.query,
          },
          params: action.payload?.params,
          body: adapterLead.adapterEditOUT(action.payload?.body),
        })
      )
        .pipe(
          // tag("loadCampaign"),
          map((xhr) => {
            callback();
            return actions.editLeadFulfilled({
              ...xhr.response,
              options: action.payload?.options,
              callback: callback,
            });
          }),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type === types.RESPONSES_ACTIONS.EDIT_LEAD_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            //callback();
            return of(actions.rejectedEditLead(e?.response));
          })
        );
    })
  );
};

export const callbackCreateOrEditLeadFulfilledEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>
) => {
  return action$.pipe(
    ofType(
      types.RESPONSES_ACTIONS.EDIT_LEAD_FULFILLED,
      types.RESPONSES_ACTIONS.CREATE_LEAD_FULFILLED
    ),
    map((action) => {
      action.payload.callback();
      return { type: "" };
    })
  );
};

export const editLeadsEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_ACTIONS.EDIT_LEADS),
    mergeMap((action) => {
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "editLeads",
          query: {
            apikey: store.value.authReducer?.user?.apikey,
            ...action.payload?.query,
          },
          body: action.payload?.body,
        })
      )
        .pipe(
          // tag("loadCampaign"),
          map((xhr) =>
            actions.editLeadsFulfilled({
              nbCount: action.payload?.body?.leadIds.length,
            })
          ),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type === types.RESPONSES_ACTIONS.EDIT_LEADS_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedEditLeads(e?.response));
          })
        );
    })
  );
};

export const deleteLeadsEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_ACTIONS.DELETE_LEADS),
    mergeMap((action) => {
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "editLeads",
          query: {
            apikey: store.value.authReducer?.user?.apikey,
            ...action.payload?.query,
          },
          params: action.payload?.params,
          body: action.payload?.body,
        })
      )
        .pipe(
          // tag("loadCampaign"),
          map((xhr) =>
            actions.deleteLeadsFulfilled({
              nbCount: action.payload?.body?.leadIds.length,
            })
          ),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type === types.RESPONSES_ACTIONS.DELETE_LEADS_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedDeleteLeads(e?.response));
          })
        );
    })
  );
};

export const closeLeadModalEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>
) => {
  return action$.pipe(
    ofType(types.RESPONSES_ACTIONS.DELETE_LEADS_FULFILLED),
    map((action) => {
      return actions.modalUpdate({
        actionName: "",
        data: {},
      });
    })
  );
};

export const loadHubspotLeads: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_DATA.LOAD_HUBSPOT_LEADS),
    mergeMap((action) => {
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "loadHubspotLeads",
          query: {
            apikey: store.value.authReducer?.user?.apikey,
            ...action.payload?.query,
            q: action.payload?.q,
          },
          params: action.payload?.params,
        })
      )
        .pipe(
          // tag("loadHubspotLead"),
          map((xhr) =>
            actions.loadHubspotLeadsFulfilled({
              data: xhr.response.contacts,
              setHubspotOptions: action.payload.setHubspotOptions,
              setHubspotLoading: action.payload.setHubspotLoading,
            })
          ),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_DATA.LOAD_HUBSPOT_LEADS_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            action.payload.setHubspotLoading(false);
            return of(actions.rejectedHubspotLoadLeads(e?.response));
          })
        );
    })
  );
};

export const loadPipedriveLeads: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_DATA.LOAD_PIPEDRIVE_LEADS),
    mergeMap((action) => {
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "loadPipedriveLeads",
          query: {
            apikey: store.value.authReducer?.user?.apikey,
            ...action.payload?.query,
            q: action.payload?.q,
          },
          params: action.payload?.params,
        })
      )
        .pipe(
          // tag("loadHubspotLead"),
          map((xhr) =>
            actions.loadPipedriveLeadsFulfilled({
              data: xhr.response.contacts,
              setPipedriveOptions: action.payload.setPipedriveOptions,
              setPipedriveLoading: action.payload.setPipedriveLoading,
            })
          ),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_DATA.LOAD_PIPEDRIVE_LEADS_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            action.payload.setPipedriveLoading(false);
            return of(actions.rejectedPipedriveLoadLeads(e?.response));
          })
        );
    })
  );
};

export const loadHubspotLeadsFulfilledEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>
) => {
  return action$.pipe(
    ofType(types.RESPONSES_DATA.LOAD_HUBSPOT_LEADS_FULFILLED),
    map((action) => {
      action.payload.setHubspotOptions(
        action.payload.data.map((_data: any) => ({
          ..._data,
          value: _data.id,
          hubspotId: undefined,
          id: _data.hubspotId,
        }))
      );
      action.payload.setHubspotLoading(false);
      return { type: "" };
    })
  );
};

export const loadPipedriveLeadsFulfilledEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>
) => {
  return action$.pipe(
    ofType(types.RESPONSES_DATA.LOAD_PIPEDRIVE_LEADS_FULFILLED),
    map((action) => {
      action.payload.setPipedriveOptions(
        action.payload.data.map((_data: any) => ({
          ..._data,
          value: _data.id,
          pipedriveId: undefined,
          id: _data.pipedriveId,
        }))
      );
      action.payload.setPipedriveLoading(false);
      return { type: "" };
    })
  );
};

export const loadHubspotLeadsCancelledEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>
) => {
  return action$.pipe(
    ofType(types.RESPONSES_DATA.LOAD_HUBSPOT_LEADS_CANCELLED),
    map((action) => {
      action.payload.setHubspotLoading(false);
      return { type: "" };
    })
  );
};

export const loadPipedriveLeadsCancelledEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>
) => {
  return action$.pipe(
    ofType(types.RESPONSES_DATA.LOAD_PIPEDRIVE_LEADS_CANCELLED),
    map((action) => {
      action.payload.setPipedriveLoading(false);
      return { type: "" };
    })
  );
};

export const loadLeadCampaignLogsEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>,
  store: any
) => {
  return action$.pipe(
    ofType(types.REQUESTS_DATA.LOAD_LEAD_CAMPAIGN_LOGS),
    mergeMap((action) => {
      return apiService(
        getEndpoint({
          apiVersion: store.value.authReducer?.user?.apiVersion,
          name: "loadLeadCampaignLogs",
          query: {
            apikey: store.value.authReducer?.user?.apikey,
            ...action.payload?.query,
          },
          params: action.payload?.params,
        })
      )
        .pipe(
          map((xhr) =>
            actions.loadLeadCampaignLogsFulfilled({
              data: xhr.response,
              logs: action.payload?.logs,
              setLogs: action.payload?.setLogs,
              params: action.payload?.params,
            })
          ),
          takeUntil(
            action$.pipe(
              filter(
                (action) =>
                  action.type ===
                  types.RESPONSES_DATA.LOAD_LEAD_CAMPAIGN_LOGS_CANCELLED
              )
            )
          )
        )
        .pipe(
          catchError((e) => {
            console.log("Catch Error $1", e);
            return of(actions.rejectedLoadLeadCampaignLogs(e?.response));
          })
        );
    })
  );
};

export const loadLeadCampaignLogsFulfilledEpic: Epic<IAction, IAction> = (
  action$: ActionsObservable<IAction>
) => {
  return action$.pipe(
    ofType(types.RESPONSES_DATA.LOAD_LEAD_CAMPAIGN_LOGS_FULFILLED),
    map((action) => {
      let _logs = action.payload.logs;
      if (
        action.payload.data.total ===
          _logs[action.payload.data.campaignId].total ||
        _logs[action.payload.data.campaignId].total === 0
      ) {
        let newLogs = action.payload.data.logs;
        newLogs = newLogs.map((_log: any) => ({
          ..._log,
          type: _log.type
            .replace("GOOGLE_", "EMAIL_")
            .replace("EMAIL_SEND_EMAIL", "SEND_EMAIL"),
        }));
        _logs[action.payload.data.campaignId].loading = false;
        _logs[action.payload.data.campaignId].total = action.payload.data.total;
        _logs[action.payload.data.campaignId].logs = _logs[
          action.payload.data.campaignId
        ].logs.concat(newLogs);
        action.payload.setLogs(_logs);
        return { type: "" };
      } else {
        _logs[action.payload.data.campaignId].loading = true;
        _logs[action.payload.data.campaignId].total = action.payload.data.total;
        _logs[action.payload.data.campaignId].logs = [];
        action.payload.setLogs(_logs);
        return actions.loadLeadCampaignLogs({
          params: action.payload.params,
          query: {
            campaignId: action.payload.data.campaignId,
            skip: 0,
          },
          logs: _logs,
          setLogs: action.payload.setLogs,
          campaignId: action.payload.data.campaignId,
        });
      }
    })
  );
};

//loadLeadCampaignLogsCancelledEpic => empty logs[campaignId]
//loadLeadCampaignLogsRejectedEpic => empty logs[campaignId]

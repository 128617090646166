import React, { useEffect, useCallback, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import useSelector from "../../../../redux/useSelector";
import * as actions from "../../actions";
import * as authActions from "../../../Auth/actions";
import * as globalActions from "../../../../redux/actions";
import * as identitiesActions from "../../../Identities/actions";
import { ICampaignState } from "../../interfaces/IState";
import { IStateWebhooks, IStateAudiences } from "../../../../interfaces/IState";
import * as adapterMsgTemplate from "../../adapters/messageTemplate";
import * as adapterWebhook from "../../adapters/webhook";
import * as adapterAudience from "../../adapters/audience";
import { Graph } from "@lagrowthmachine/lgmwebapp";
import Modal, { types as modalTypes } from "../Modals";
import types from "../../types";
import * as adaptersSequence from "../../adapters/sequence";
import { attributes } from "../../data/configGraph";
import constants from "../../../../config/constants";
import analyticsCall from "../../../../helpers/analyticsCall";
//import useFirstRender from '../../../../helpers/useFirstRender';
import usePrevious from "../../../../helpers/usePrevious";
import { defaultValues } from "../../data/configTemplate";
import uploadFileAsync from "../../../../helpers/uploadFileAsync";
import endpointsAuth from "../../../Auth/endpoints";
import {
  createCampaignTemplate,
  editCampaignTemplate,
  duplicateMessageTemplate,
} from "../../functions";

const errorInTemplate =
  "There seems to be some un-matched variables in your messages. They won't be replaced. Are you sure you want to save this message ?";
const promptMessage =
  "You have unsaved changes. Are you sure you want to leave?";

export default function CampaignEditComponent(props: any) {
  var graphRef: any;
  const params: any = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  //const firstRender = useFirstRender();
  const [assignAudienceToCampaign, setAssignAudienceToCampaign] = useState("");
  const [nodeAssigned, setNodeAssigned] = useState(undefined);
  const [ceAssigned, setCeAssigned] = useState(undefined);
  const [graphEdited, setGraphEdited] = useState(false);
  const loadingReducer = useSelector((state) => state.loadingReducer);
  const campaignReducer: ICampaignState = useSelector(
    (state) => state.campaignReducer
  );
  const webhookReducer: IStateWebhooks = useSelector(
    (state) => state.webhookReducer
  );
  const audiencesReducer: IStateAudiences = useSelector(
    (state) => state.audienceReducer
  );

  const identitiesReducer = useSelector((state) => state.identitiesReducer);
  const authReducer = useSelector((state) => state.authReducer);

  const modalsReducer = useSelector((state) => state.modalsReducer);

  const crmAvailable = authReducer?.user?.crm?.find((crm: any) =>
    ["PIPEDRIVE", "HUBSPOT"].includes(crm.type)
  )?.type;

  const templates: any =
    adapterMsgTemplate.arrayOfGraphTemplate(campaignReducer.templates, "IN") ||
    [];

  const otherCampaignTemplates: any =
    adapterMsgTemplate.arrayOfGraphTemplate(
      campaignReducer.otherCampaignTemplates,
      "IN_SMALL"
    ) || [];

  const webhooks =
    adapterWebhook.arrayOfGraphWebhooks(webhookReducer?.list, "IN") || [];

  const audiences =
    adapterAudience.arrayOfGraphAudiences(audiencesReducer?.list, "IN") || [];

  const identities = identitiesReducer.list.map((_identity: any) => {
    let _idName =
      (_identity.firstname || "") + " " + (_identity.lastname || "");
    if (!_idName.trim()) {
      _idName = "Identity missing name";
    }
    let channelsConnected = ["TWITTER", "EMAIL", "LINKEDIN"];
    if (_identity.reconnect?.twitter || !Boolean(_identity.twitterData)) {
      channelsConnected = channelsConnected.filter(
        (_c: string) => _c !== "TWITTER"
      );
    }
    if (_identity.reconnect?.email || !Boolean(_identity.emailData)) {
      channelsConnected = channelsConnected.filter(
        (_c: string) => _c !== "EMAIL"
      );
    }
    if (_identity.reconnect?.linkedin || !Boolean(_identity.linkedinData)) {
      channelsConnected = channelsConnected.filter(
        (_c: string) => _c !== "LINKEDIN"
      );
    }
    return {
      value: _identity.id,
      label: _idName,
      picture: _identity.profilePicture,
      channelsConnected: channelsConnected,
    };
  });

  const campaign =
    campaignReducer.selected?.id === params?.id && campaignReducer.selected;

  useEffect(() => {
    if (campaign.id === params?.id && campaign.pauseWarning) {
      const stopped = {
        crm: campaign.pauseWarning?.warnings?.includes("crm") && {
          availablePlans: authReducer.user.campaignPermissions?.crmPermissions,
        },
        enrich: campaign.pauseWarning?.warnings?.includes("enrich") && {
          availablePlans:
            authReducer.user.campaignPermissions?.enrichPermission,
        },
      };
      const warningId = campaign.pauseWarning.id;
      const warningsSeen =
        localStorage?.getItem("warnings_seen")?.split("___") || [];
      if (!warningsSeen.includes(warningId)) {
        dispatch(
          globalActions.modalUpdate({
            name: "campaignStopped",
            data: { stopped, warningId },
          })
        );
      }
    }
  }, [campaign.id]);

  useEffect(() => {
    if (campaign?.name && campaign.id === params?.id)
      analyticsCall("campaign", `campaign edit: ${campaign.name}`);
  }, [campaign]);

  const campaignCrm = campaign?.hubspot
    ? "HUBSPOT"
    : campaign?.pipedrive
    ? "PIPEDRIVE"
    : undefined;
  const crmError =
    campaignCrm && campaign.reconnect?.crm
      ? "disconnected"
      : campaign.reconnect?.crmMapping
      ? "mapping"
      : campaign.reconnect?.crmLifecycle
      ? "lifecycle"
      : undefined;

  const [firstLoad, setFirstLoad] = useState(true);
  useEffect(() => {
    setFirstLoad(false);
  }, []);

  const [campaignStatus, setCampaignStatus] = useState("");
  const prevCampaignStatus = usePrevious(campaignStatus);

  useEffect(() => {
    if (campaign?.id && campaign?.id === params?.id) {
      if (campaign.status === "RUNNING" && prevCampaignStatus === "READY") {
        history.push("/campaigns/" + campaign?.id + "/overview");
      } else {
        setCampaignStatus(campaign.status);
      }
    }
  }, [campaign?.id, campaign.status]);

  const [sequenceParams, setSequenceParams] = useState({
    enrich: false,
    language: undefined,
    name: undefined,
    identityId: undefined,
    audienceId: undefined,
    ending: undefined,
    customEnding: undefined,
    seqV2: undefined,
    hubspot: false,
    pipedrive: false,
    allowEdit: false, //to save from editing on first useEffet
    launchAfterSave: false, //save and chain with launch
  } as any);

  const prevSequenceParams = usePrevious(sequenceParams);

  useEffect(() => {
    if (campaign?.id && campaign?.id === params?.id) {
      setSequenceParams({
        ...sequenceParams,
        enrich: campaign.enrich,
        language: campaign.language,
        name: campaign.name,
        identityId: campaign.identity.id,
        audienceId: campaign.audience?.id,
        ending: campaign.sequence.ending,
        customEnding: JSON.parse(
          JSON.stringify(campaign.sequence.customEnding || [])
        ),
        seqV2: JSON.parse(JSON.stringify(campaign.sequence.seqV2)),
        hubspot: campaign.hubspot,
        pipedrive: campaign.pipedrive,
      } as any);
    }
  }, [campaign?.id]);

  useEffect(() => {
    if (campaign?.name && campaign?.id === params?.id) {
      analyticsCall("campaign edit", `campaign edit: ${campaign.name}`);
    }
  }, [campaign?.id]);

  useEffect(() => {
    if (params?.id) {
      dispatch(actions.loadCampaign({ query: {}, params: { id: params?.id } }));
      dispatch(actions.loadCampaignsName({}));
      dispatch(
        actions.loadCampaignTemplates({ query: {}, params: { id: params?.id } })
      );
    }
    dispatch(globalActions.loadAudiences({ query: {} }));
    dispatch(globalActions.loadWebhooks({ query: {} }));
    dispatch(identitiesActions.loadIdentities({ query: {} }));
    dispatch(authActions.loadUser({}));
  }, [dispatch, params?.id]);

  const handleModal = useCallback(
    ({ name, data = {} }) => {
      dispatch(globalActions.modalUpdate({ name, data }));
    },
    [dispatch]
  );

  useEffect(() => {
    history.block(() => {
      if (graphEdited) return promptMessage;
    });
    return () => {
      history.block(() => {});
    };
  }, [history, graphEdited]);

  useEffect(() => {
    window.onbeforeunload = function (e: Event) {
      if (graphEdited) {
        e.preventDefault();
        e.returnValue = true; // IE & Firefox
        return promptMessage;
      }
    };
    return () => {
      handleModal("close");
      window.onbeforeunload = function () {};
    };
  }, [graphEdited]);

  useEffect(() => {
    if (campaignReducer.checkAssignation) {
      if (nodeAssigned) {
        graphRef.nodeAssign(nodeAssigned, campaignReducer.checkAssignation);
        //setGraphEdited(true);
      }
      if (typeof ceAssigned !== "undefined") {
        graphRef.ceAssign(ceAssigned, campaignReducer.checkAssignation);
        //setGraphEdited(true);
      }
    } else {
      if (nodeAssigned) {
        setNodeAssigned(undefined);
      }
      if (setCeAssigned) {
        setCeAssigned(undefined);
      }
    }
  }, [campaignReducer.checkAssignation]);

  useEffect(() => {
    if (assignAudienceToCampaign) {
      graphRef.audAssign(assignAudienceToCampaign);
      setAssignAudienceToCampaign("");
    }
  }, [assignAudienceToCampaign]);

  useEffect(() => {
    if (
      modalsReducer.name === "" ||
      modalsReducer.name === "reset" ||
      !modalsReducer.name
    ) {
      if (nodeAssigned) {
        setNodeAssigned(undefined);
      }
      if (setCeAssigned) {
        setCeAssigned(undefined);
      }
    }
  }, [modalsReducer.name]);

  const uploadPic = async (file: any) => {
    return uploadFileAsync(
      file,
      authReducer.user.apikey,
      endpointsAuth,
      undefined,
      undefined,
      undefined,
      authReducer?.user?.apiVersion
    );
  };

  //BTN SAVE OR SAVE_AND_LAUNCH FOR SEQUENCE (GRAPH)
  const handleChangeGraph = (sequenceV2: any, launch?: boolean) => {
    if (!launch) {
      setSequenceParams({
        ...sequenceParams,
        allowEdit: true,
        seqV2: JSON.parse(JSON.stringify(sequenceV2)),
      });
    } else {
      handleAction("save_and_launch", { sequenceV2 });
    }
  };

  //EDIT fields that belong to sequence object
  useEffect(() => {
    if (
      sequenceParams.allowEdit &&
      !(
        (prevSequenceParams as any)?.launchAfterSave &&
        !sequenceParams.launchAfterSave
      )
    ) {
      const sequenceV1 = adaptersSequence.seqV2toV1(
        sequenceParams.seqV2,
        templates,
        audiences,
        webhooks,
        attributes,
        sequenceParams.ending || "none",
        sequenceParams.customEnding || []
      );

      dispatch(
        actions.editSequence({
          setGraphEdited: setGraphEdited,
          body: {
            seq: sequenceV1,
            seq_v2: sequenceParams.seqV2,
            ending: sequenceParams.ending,
            customEnding: sequenceParams.customEnding,
          },
          query: {},
          params: {
            campaignId: campaign?.id,
            sequenceId: campaign?.sequence.id,
            launchAfterSave: sequenceParams.launchAfterSave,
          },
        })
      );
    }
    if (sequenceParams.launchAfterSave) {
      setSequenceParams({ ...sequenceParams, launchAfterSave: false });
    }
  }, [
    sequenceParams.launchAfterSave,
    JSON.stringify(sequenceParams.seqV2),
    JSON.stringify(sequenceParams.ending),
    JSON.stringify(sequenceParams.customEnding),
  ]);

  //CHANGES MOST FOR CAMPAIGN SETTINGS. setSequenceParams is not updated with seqV1/2 ----1-----
  const handleEditSequence = (seq: any) => {
    setSequenceParams({
      ...sequenceParams,
      allowEdit: true,
      enrich: seq.enrich,
      language: seq.language === "en" ? "english" : "french",
      name: seq.name,
      identityId: seq.identityId,
      audienceId: seq.audienceId,
      ending: seq.ending,
      customEnding: seq.customEnding,
      hubspot: seq.crm === "HUBSPOT",
      pipedrive: seq.crm === "PIPEDRIVE",
    });
  };

  //EDIT CAMPAIGN SETTINGS when changes in sequenceParams ----2-----
  useEffect(() => {
    if (sequenceParams.allowEdit) {
      dispatch(
        actions.editCampaign({
          body: {
            enrich: sequenceParams.enrich,
            language: sequenceParams.language,
            name: sequenceParams.name,
            identityId: sequenceParams.identityId,
            audienceId: sequenceParams.audienceId,
            hubspot: sequenceParams.hubspot,
            pipedrive: sequenceParams.pipedrive,
          },
          query: {},
          params: { id: campaign.id },
        })
      );
    }
  }, [
    dispatch,
    String(sequenceParams.enrich),
    sequenceParams.language,
    sequenceParams.name,
    sequenceParams.identityId,
    sequenceParams.audienceId,
    String(sequenceParams.hubspot),
    String(sequenceParams.pipedrive),
  ]);

  const saveTemplate = async (data: any, pbsNumber: number) => {
    if (pbsNumber !== 0 && !window.confirm(errorInTemplate)) {
      dispatch(
        globalActions.setNotificationSmall({
          notification: {
            type: "error",
            text: "Please remove un-matched variables to save this template.",
          },
          format: "basic",
        })
      );
      throw "Error";
    }

    const campaignId = params?.id;
    let templateId = data.data?.id;
    const isEmail = ["GMAIL", "GOOGLE"].includes(data?.data?.channel);
    if (templateId) {
      const body = {
        name: data?.data?.name || "",
        subjectState: data?.data?.subjectState || undefined,
        contentState: data?.data?.contentState,
        cc: (isEmail && data.data?.cc) || undefined,
        bcc: (isEmail && data.data?.bcc) || undefined,
        subjectHtml: (isEmail && data.data?.subjectHTML) || undefined,
        contentHtml: data.data?.contentHTML || "",
        followUp: isEmail ? Boolean(data.data?.sameThread) : undefined,
      };
      return editCampaignTemplate({ authReducer, body, templateId })
        .then((response) => {
          setGraphEdited(true);
          dispatch(
            actions.loadCampaignTemplates({
              query: {},
              params: { id: campaignId },
            })
          );
          return { templateId };
        })
        .catch((e) => {
          dispatch(
            globalActions.setNotificationSmall({
              notification: {
                type: "error",
                text: String(e),
              },
              format: "basic",
            })
          );
          throw "Error";
        });
    } else {
      const body = {
        campaignId,
        name: data?.data?.name || "",
        channel: isEmail ? "GOOGLE" : data?.data?.channel,
        subjectState: data?.data?.subjectState,
        contentState: data?.data?.contentState,
        cc: (isEmail && data.data?.cc) || undefined,
        bcc: (isEmail && data.data?.bcc) || undefined,
        subjectHtml: (isEmail && data.data?.subjectHTML) || undefined,
        contentHtml: data.data?.contentHTML || "",
        followUp: isEmail ? Boolean(data.data?.sameThread) : undefined,
      };
      return createCampaignTemplate({ authReducer, body })
        .then((response) => {
          setGraphEdited(true);
          dispatch(
            actions.loadCampaignTemplates({
              query: {},
              params: { id: campaignId },
            })
          );
          templateId = response.id;
          return { templateId };
        })
        .catch((e) => {
          dispatch(
            globalActions.setNotificationSmall({
              notification: {
                type: "error",
                text: String(e),
              },
              format: "basic",
            })
          );
          throw "Error";
        });
    }
  };

  const onDuplicateTemplate = async (data: any) => {
    const campaignId = params?.id;
    const nodeId = data.nodeId;
    const templateId = data.templateId;
    const withName = data.withName;
    const body = { campaignId, withName };

    return duplicateMessageTemplate({ authReducer, body, templateId })
      .then((response) => {
        setGraphEdited(true);
        dispatch(
          actions.loadCampaignTemplates({
            query: {},
            params: { id: campaignId },
          })
        );
        const newTemplateId = response.id;
        return { templateId: newTemplateId };
      })
      .catch((e) => {
        dispatch(
          globalActions.setNotificationSmall({
            notification: {
              type: "error",
              text: String(e),
            },
            format: "basic",
          })
        );
        throw "Error";
      });
  };

  const handleModalActions = (action: string, data?: any) => {
    switch (action) {
      case "campaignStart":
        setSequenceParams({
          ...sequenceParams,
          launchAfterSave: true,
          allowEdit: true,
          seqV2: data.seqV2,
        });
        break;
      case "createAudience":
        dispatch(
          globalActions.createAudience({
            query: {},
            body: { audience: data.audience },
          })
        );
        break;
      case "editAudience":
        dispatch(
          globalActions.editAudience({
            query: {},
            params: { id: data.audience.id },
            body: {
              audience: {
                name: data.audience.name,
                description: data.audience.description,
              },
              type: data.audience.type,
            },
          })
        );
        break;
      case "createWebhook":
        dispatch(
          globalActions.createWebhook({
            query: {},
            body: data.webhook,
          })
        );
        break;
      case "editWebhook":
        dispatch(
          globalActions.editWebhook({
            params: { id: data.id },
            body: data.webhook,
          })
        );
        break;
      case "testWebhook":
        dispatch(
          globalActions.simulateWebhook({
            query: {},
            body: data,
          })
        );
        break;
      case "close":
        dispatch(globalActions.modalUpdate({ name: "", data: {} }));
        break;
      default:
        break;
    }
  };

  const onWatchReview = () => {
    handleModal({ name: modalTypes.reset });
    triggerTour("review");
  };

  const onAskForReview = () => {
    dispatch(actions.campaignReview({ params: { id: campaign.id } }));
  };

  const handleAction = (action: string, data?: any) => {
    if (data?.for === "node") {
      setNodeAssigned(data?.nodeId);
    }
    if (data?.for === "ce") {
      setCeAssigned(data?.customEndingIndex);
    }
    switch (action) {
      case "save_and_launch":
        handleModal({
          name: modalTypes.campaignStart,
          data: {
            seqV2: data.sequenceV2,
            onAskForReview,
            onWatchReview,
            onUpgrade,
            /*
            onAction: () => {
              setSequenceParams({
                ...sequenceParams,
                launchAfterSave: true,
                seqV2: JSON.parse(JSON.stringify(data.seqV2)),
              });
              //onChangeStatus("START");
            },*/
          },
        });
        break;
      case "delete":
        handleModal({
          name: modalTypes.campaignDelete,
          data: { campaign: campaign },
        });
        break;
      case "duplicate":
        let leave = true;
        if (graphEdited) {
          leave = window.confirm(promptMessage);
        }
        if (leave) {
          setGraphEdited(false);
          dispatch(
            actions.duplicateCampaign({
              query: {},
              params: { id: campaign.id },
            })
          );
        }
        break;
      case "create_audience":
        dispatch(
          globalActions.modalUpdate({
            name: "newAudience",
            data: { name: data?.name },
          })
        );
        break;
      case "edit_audience":
        const audience: any = audiencesReducer?.list.find(
          (t: any) => t.id === data?.id
        );

        dispatch(
          globalActions.modalUpdate({
            name: "editAudience",
            data: {
              id: audience.id,
              name: audience.name,
              description: audience.description,
            },
          })
        );
        break;
      case "create_campaign_audience":
        dispatch(
          globalActions.modalUpdate({
            name: "campaignAudienceChoice",
            data: {
              onImport: (from: "LINKEDIN" | "CSV" | "EMPTY") => {
                if (from === "EMPTY") {
                  dispatch(
                    globalActions.modalUpdate({
                      name: "newCampaignAudience",
                      data: {
                        createCampaignAudience: (obj: any) => {
                          dispatch(
                            globalActions.createAudience({
                              callback: (audienceId: string) => {
                                setAssignAudienceToCampaign(audienceId);
                              },
                              query: {},
                              body: { audience: obj.audience },
                            })
                          );
                        },
                      },
                    })
                  );
                } else if (from === "LINKEDIN") {
                  analyticsCall("leads new", `leads linkedin import`);
                  dispatch(globalActions.socketOpenBrowser());
                } else if (from === "CSV") {
                  handleModal({
                    name: modalTypes.importAudienceCSV,
                    data: { setAssignAudienceToCampaign },
                  });
                }
              },
            },
          })
        );
        break;
      case "create_webhook":
        dispatch(
          globalActions.modalUpdate({
            name: "createWebhook",
            data: { name: data?.name },
          })
        );
        break;
      case "edit_webhook":
        const webhook: any = webhookReducer?.list.find(
          (t: any) => t.id === data?.id
        );
        dispatch(
          globalActions.modalUpdate({
            name: "editWebhook",
            data: webhook,
          })
        );
        break;
      case "archive":
        dispatch(
          globalActions.modalUpdate({
            name: "campaignArchive",
            data: {
              name: campaign.name,
              campaign: campaign,
            },
          })
        );
        break;
      default:
        break;
    }
  };

  const position = campaign.sequence?.seqV2?.position || {
    pan: { x: 0, y: 0 },
    scale: 1,
  };

  let graph;
  if (Boolean(campaign.sequence?.seqV2?.graph)) {
    graph = campaign.sequence?.seqV2?.graph;
  } else if (
    Boolean(campaign) &&
    campaign.id === params?.id &&
    !Boolean(campaign.sequence?.seqV2?.graph)
  ) {
    history.push("/campaigns");
  }

  const lookForCampaignMessages = (campaignId: string) => {
    dispatch(
      actions.loadOtherCampaignTemplates({
        query: {},
        params: { id: campaignId },
      })
    );
  };

  const onIdReconnect = (identityId: string) => {
    history.push("/identities");
  };

  const onCrmConnect = () => {
    history.push("/settings/crm");
  };

  const onCrmSettings = () => {
    history.push("/settings/crm" + (campaignCrm ? "?crm=" + campaignCrm : ""));
  };

  const onChangeStatus = (nextStatus: "START" | "PAUSE" | "CANCEL") => {
    dispatch(
      actions.editCampaignState({
        callback: () => dispatch(authActions.loadUser({})),
        params: {
          id: campaign.id,
          status: nextStatus,
        },
      })
    );
  };

  const triggerTour = (feature: string) => {
    const tourId = constants.intercomTourIds[feature][authReducer.user.plan];
    if (tourId) {
      (window as any).Intercom("startTour", tourId);
    }
  };

  const onUpgrade = () => {
    handleModal({ name: modalTypes.reset });
    window.open("/settings/pricing");
  };

  return (
    <React.Fragment>
      <Modal
        name={modalsReducer.name}
        data={modalsReducer.data}
        setAssignAudienceToCampaign={(audienceId: string) => {
          setAssignAudienceToCampaign(audienceId);
        }}
        onRequestClose={() => handleModal({ name: modalTypes.reset })}
        onAction={handleModalActions}
        btnDisabled={
          loadingReducer.btnTypesLoaders[
            types.REQUESTS_ACTIONS.EDIT_SEQUENCE
          ] ||
          loadingReducer.btnTypesLoaders[
            types.REQUESTS_ACTIONS.EDIT_CAMPAIGN
          ] ||
          loadingReducer.btnTypesLoaders[
            types.REQUESTS_ACTIONS.EDIT_CAMPAIGN_STATE
          ] ||
          loadingReducer.btnTypesLoaders[
            types.REQUESTS_ACTIONS.DUPLICATE_CAMPAIGN
          ]
        }
        btnIsLoading={
          loadingReducer.btnTypesLoaders[
            types.REQUESTS_ACTIONS.EDIT_SEQUENCE
          ] ||
          loadingReducer.btnTypesLoaders[
            types.REQUESTS_ACTIONS.EDIT_CAMPAIGN
          ] ||
          loadingReducer.btnTypesLoaders[
            types.REQUESTS_ACTIONS.EDIT_CAMPAIGN_STATE
          ] ||
          loadingReducer.btnTypesLoaders[
            types.REQUESTS_ACTIONS.DUPLICATE_CAMPAIGN
          ]
        }
      />
      {graph && position && !firstLoad && (
        <div key="graph_main_container" className="graph_main_container">
          <Graph
            canEditStep={authReducer.user.campaignPermissions?.canEditStep}
            editStepPermission={
              authReducer.user.campaignPermissions?.editStepPermission
            }
            enrichAvailable={authReducer.user.campaignPermissions?.canEnrich}
            enrichPermission={
              authReducer.user.campaignPermissions?.enrichPermission
            }
            editCeAvailable={true}
            cePermission={undefined}
            campaignLimitExceeded={
              authReducer.user.campaignPermissions?.campaignLimitReached
            }
            isCustom={campaign.isCustom}
            triggerTour={triggerTour}
            onUpgrade={onUpgrade}
            ref={(_ref) => (graphRef = _ref)}
            wasModified={() => setGraphEdited(true)}
            graphEdited={graphEdited}
            sequence={{
              name: campaign?.name,
              ending: campaign?.sequence.ending || "none",
              customEnding: campaign?.sequence?.customEnding || [],
              language: campaign.language === "french" ? "fr" : "en",
              description: campaign?.description,
              enrich: campaign?.enrich, //CHOUBI
              identityId: campaign?.identity?.id,
              audienceId: campaign?.audience?.id,
              crm: campaignCrm,
            }}
            campaignStatus={campaign?.status}
            crm={crmAvailable}
            onCrmConnect={onCrmConnect}
            crmError={crmError}
            onCrmSettings={onCrmSettings}
            saveGraph={handleChangeGraph}
            preview={false}
            graph={graph}
            position={position}
            fitOnLoad={true}
            templates={templates}
            attributes={attributes}
            webhooks={webhooks}
            audiences={audiences}
            identities={identities}
            campaigns={campaignReducer.campaignsName.map((_campaign: any) => ({
              ..._campaign,
              isPresent: _campaign.id === campaign.id,
            }))}
            campaignMessages={otherCampaignTemplates}
            lookForCampaignMessages={lookForCampaignMessages}
            messageBeingDuplicated={
              loadingReducer.btnTypesLoaders[
                types.REQUESTS_ACTIONS.DUPLICATE_MESSAGE_TEMPLATE
              ]
            }
            changeStateLoading={
              loadingReducer.btnTypesLoaders[
                types.REQUESTS_ACTIONS.EDIT_CAMPAIGN_STATE
              ]
            }
            onIdReconnect={onIdReconnect}
            messagesLoading={
              loadingReducer.dataTypesLoaders.LOAD_OTHER_CAMPAIGN_TEMPLATES
            }
            onEditSequence={handleEditSequence}
            onDuplicateSequence={() => handleAction("duplicate")}
            onDeleteSequence={() => handleAction("delete")}
            onStartCreatingObject={(params) =>
              handleAction("create_" + params.type, { ...params, for: "node" })
            }
            onStartCreatingObjectForCE={(params) =>
              handleAction("create_" + params.type, { ...params, for: "ce" })
            }
            onEditObj={(params) => {
              handleAction("edit_" + params.type, { ...params, for: "node" });
            }}
            pause={() => onChangeStatus("PAUSE")}
            resume={() => onChangeStatus("START")}
            onStopSequence={() => onChangeStatus("CANCEL")}
            onDuplicateTemplate={onDuplicateTemplate}
            //nodeAssigned={nodeAssigned}
            //ceAssigned={ceAssigned}
            onCreateAudience={() => handleAction("create_campaign_audience")}
            defaultValues={defaultValues}
            uploadPic={uploadPic}
            saveTemplate={saveTemplate}
          />
        </div>
      )}
    </React.Fragment>
  );
}

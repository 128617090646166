import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";

import { CampaignSearchBar, GraphSample } from "@lagrowthmachine/lgmwebapp";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import useSelector from "../../../../redux/useSelector";
import { ICampaignState } from "../../interfaces/IState";
import * as actions from "../../actions";
import * as authActions from "../../../Auth/actions";
import * as globalActions from "../../../../redux/actions";
import useDebounce from "../../../../helpers/useDebounce";
import Modal, { types as modalTypes } from "../Modals";
import types from "../../types";
import analyticsCall from "../../../../helpers/analyticsCall";

const CampaignRecommendedComponent = (props: any) => {
  const choices = [
    { id: "LINKEDIN_GOOGLE_TWITTER", text: "Linkedin + Email + Twitter" },
    { id: "LINKEDIN_GOOGLE", text: "Linkedin + Email" },
    { id: "LINKEDIN", text: "Linkedin" },
    { id: "GOOGLE", text: "Email" },
  ];

  const refs = {
    LINKEDIN_GOOGLE_TWITTER: useRef<HTMLDivElement>(null),
    LINKEDIN_GOOGLE: useRef<HTMLDivElement>(null),
    LINKEDIN: useRef<HTMLDivElement>(null),
    GOOGLE: useRef<HTMLDivElement>(null),
  };

  const dispatch = useDispatch();
  const history = useHistory();
  const campaignReducer: ICampaignState = useSelector(
    (state) => state.campaignReducer
  );
  const modalsReducer = useSelector((state) => state.modalsReducer);
  const loadingReducer = useSelector((state) => state.loadingReducer);
  const authReducer = useSelector((state) => state.authReducer);

  useEffect(() => {
    analyticsCall("campaign", "campaign recommended");
  }, []);

  const initialFilters = {
    choice: "LINKEDIN_GOOGLE_TWITTER",
    search: "",
    //channels: [] as any[],
  };

  const [filters, setFilters] = useState(initialFilters);
  const [filtersDisplay, setFiltersDisplay] = useState(initialFilters);

  const debouncedSearch = useDebounce(filters.search, 500);
  //const debouncedChannels = useDebounce(filters.channels.join("-"), 500);

  useEffect(() => {
    setFiltersDisplay({
      choice: filters.choice,
      search: debouncedSearch || "",
      //channels: debouncedChannels ? debouncedChannels.split("-") : [],
    });
  }, [filters.choice /*, debouncedChannels*/, debouncedSearch]);

  useEffect(() => {
    dispatch(actions.loadCampaignsStore({}));
    dispatch(authActions.loadUser({}));
  }, [dispatch]);

  const onDuplicateCampaign = useCallback(
    (campaign: any) => {
      dispatch(
        actions.duplicateCampaign({
          data: { closeModal: () => console.log("") },
          query: {},
          params: { id: campaign.id },
        })
      );
    },
    [dispatch]
  );

  const openPreview = useCallback(
    (campaignId: string) => {
      return dispatch(
        globalActions.modalUpdate({
          name: "previewGraph",
          data: { campaignId },
        })
      );
    },
    [dispatch]
  );

  const store = useMemo(() => {
    let _store = {};
    (campaignReducer.store || []).some((_campaign: any) => {
      _store[_campaign.id] = (
        <GraphSample
          key={_campaign.id}
          attributes={[]}
          graph={_campaign.seqV2.graph}
          campaignName={_campaign.name}
          onSelect={() => onDuplicateCampaign(_campaign)}
          onShowPreview={() => openPreview(_campaign.id)}
        />
      );
      return null;
    });
    return _store;
  }, [campaignReducer.store, onDuplicateCampaign, openPreview]);

  useEffect(() => {
    if (refs[filters.choice]?.current) {
      refs[filters.choice].current.scrollIntoView({ behavior: "smooth" });
    }
  }, [refs]);

  const handleModal = ({ name, data }: { name: string; data?: any }) => {
    dispatch(globalActions.modalUpdate({ name, data }));
  };

  const createCustom = () => {
    dispatch(
      actions.createCampaign({
        query: {},
        body: {},
        params: {},
      })
    );
  };

  const onUpgrade = () => {
    history.push("/settings/pricing");
  };

  const filtersLoader =
    JSON.stringify(filters) !== JSON.stringify(filtersDisplay);
  return (
    <div className="campaigns_recommended">
      <Modal
        name={modalsReducer.name}
        data={modalsReducer.data}
        onRequestClose={() => handleModal({ name: modalTypes.reset })}
        btnDisabled={
          loadingReducer.btnTypesLoaders[
            types.REQUESTS_ACTIONS.DUPLICATE_CAMPAIGN
          ]
        }
        btnIsLoading={
          loadingReducer.btnTypesLoaders[
            types.REQUESTS_ACTIONS.DUPLICATE_CAMPAIGN
          ]
        }
      />
      <CampaignSearchBar
        loading={
          loadingReducer.btnTypesLoaders[types.REQUESTS_ACTIONS.CREATE_CAMPAIGN]
        }
        canEditStep={authReducer.user.campaignPermissions.canEditStep}
        editStepPermission={
          authReducer.user.campaignPermissions.editStepPermission
        }
        onUpgrade={onUpgrade}
        key="1"
        choices={choices}
        defaultChoices={{
          choice: filtersDisplay.choice,
          search: filtersDisplay.search,
          //channels: filtersDisplay.channels,
        }}
        updateCampaigns={setFilters}
        onBack={() => history.push("/campaigns")}
        onBuild={createCustom}
      />

      {!loadingReducer.dataTypesLoaders.LOAD_CAMPAIGNS_STORE && (
        <div
          key="2"
          className={"scroll-container " + (filtersLoader ? "loading" : "")}
        >
          {[
            "LINKEDIN_GOOGLE_TWITTER",
            "LINKEDIN_GOOGLE",
            "LINKEDIN",
            "GOOGLE",
          ].map((_choice: string) => (
            <React.Fragment key={_choice}>
              <div key="1" ref={refs[_choice]} className="store-category">
                {choices.find((__choice: any) => __choice.id === _choice)
                  ?.text || ""}
              </div>
              {(() => {
                const selectedCampaignsId =
                  (campaignReducer.store &&
                    campaignReducer.store
                      .filter(
                        (_campaign: any) =>
                          JSON.stringify(_campaign.channels.sort()) ===
                          JSON.stringify(
                            _choice.split("_").concat(["LGM"]).sort()
                          )
                      )
                      .filter(
                        (_campaign: any) =>
                          filters.search === "" ||
                          _campaign.name
                            .toUpperCase()
                            .includes(filtersDisplay.search.toUpperCase()) ||
                          _campaign.description
                            .toUpperCase()
                            .includes(filtersDisplay.search.toUpperCase())
                      )
                      .sort((_campaignA: any, _campaignB: any) =>
                        _campaignA.order > _campaignB ? 1 : -1
                      )
                      .map((_sequence: any) => _sequence.id)) ||
                  [];
                return selectedCampaignsId.length !== 0 ? (
                  <div key="2" className="lgm-container-graph-sample">
                    {selectedCampaignsId.map(
                      (_campaignId: any) => store[_campaignId]
                    )}
                  </div>
                ) : (
                  <div key="2" className="store-empty">
                    No template in this category matches your filters
                  </div>
                );
              })()}
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  );
};

export default CampaignRecommendedComponent;

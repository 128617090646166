import React, { useEffect } from "react";
import ModalWrapper from "../../../../components/ModalWrapper";
import { WebhookModal } from "@lagrowthmachine/lgmwebapp";
import useSelector from "../../../../redux/useSelector";
import analyticsCall from "../../../../helpers/analyticsCall";

function Modal(props: any) {
  const loadingReducer = useSelector((state) => state.loadingReducer);

  useEffect(() => {
    if (props.data.name) analyticsCall("settings", "settings: webhook edit");
    if (!props.data.name) analyticsCall("settings", "settings: webhook new");
  }, [props.data]);

  return (
    <ModalWrapper>
      <WebhookModal
        name={props.data.name || ""}
        btnDisabled={
          loadingReducer.btnTypesLoaders.CREATE_WEBHOOK ||
          loadingReducer.btnTypesLoaders.EDIT_WEBHOOK
        }
        btnIsLoading={
          loadingReducer.btnTypesLoaders.CREATE_WEBHOOK ||
          loadingReducer.btnTypesLoaders.EDIT_WEBHOOK
        }
        onAction={(action: string, data?: any) => props.onAction(action, data)}
        {...props.data}
      />
    </ModalWrapper>
  );
}
export default Modal;

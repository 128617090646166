const types = {
  START: "Start campaign",
  LINKEDIN_STATS_SSI: "Stats SSI",
  GOOGLE_CREATE_LABEL: "Create label",
  GOOGLE_CHECK_FOR_REPLY: "Check for reply",
  LINKEDIN_ACCEPT_REQUEST: "Accept Contact Request",
  CREATE_AUDIENCE_FROM_LINKEDIN: "Create Linkedin Audience from search",
  LINKEDIN_NO_VISIT_BACK: "Did Not Visit Back",
  LINKEDIN_STATS: 'Export last visits and last "new" contacts"',
  LINKEDIN_DENY_REQUEST: "Refused Contact Request",
  LINKEDIN_DIRECT_MESSAGE: "Send Direct Message",
  LINKEDIN_HAS_REPLY: "Has replied to previous message ?",
  LINKEDIN_HAS_NOT_REPLY: "Has not replied to previous message ?",
  LINKEDIN_CHECK_FOR_REPLY: "Check for reply",
  LINKEDIN_ADD_CONTACT: "Send a Contact Request",
  LINKEDIN_VISIT_BACK: "Visit Back",
  LINKEDIN_VISIT_ENRICH: "Visit and Enrich Profile",
  LINKEDIN_VISIT_PROFILE: "Visit Profile",
  LINKEDIN_LEAD_HAS_VISIT: "Visit your profile",
  LINKEDIN_CHECK_REQUEST_ACCEPTED: "Is lead a relation ?",
  LINKEDIN_ENRICH_CONTACT: "Enrich contact",

  TWITTER_FAVOURITE: "Add a Tweet to Favorites",
  TWITTER_DONT_FOLLOW_BACK: "Did Not Follow Back",
  TWITTER_FOLLOW: "Follow a User",
  TWITTER_FOLLOW_BACK: "Follow Back",
  TWITTER_TWEET: "Publish a Tweet",
  TWITTER_RETWEET: "Retweet a Tweet",
  GOOGLE_SEND_EMAIL: "Send Email",
  TWITTER_SEND_MESSAGE: "Send DM",
  TWITTER_UNFOLLOW: "Unfollow a User",

  LGM_ADD_STATUS: "Add Custom Status",
  LGM_WEBHOOK: "Send webhook",
  LGM_ADD_DELAY: "Add Delay",
  LGM_END_CAMPAIGN: "End campaign",
  LGM_CONVERTED: "Goal reached !",
  LGM_HAS_NOT_ATTRIBUTE: "Lead does not have attribute ?",
  LGM_HAS_ATTRIBUTE: "Lead has attribute ?",
  LGM_ADD_TO_CAMPAIGN: "Add lead to campaign",

  GOOGLE_GET_LAST_MESSAGES: "Get last email messages",
  GOOGLE_HAS_CLICKED: "Email Clicked",
  GOOGLE_CHECK_BOUNCE: "Email Has Bounced ?",
  GOOGLE_HAS_NOT_CLICKED: "Email Not Clicked",
  GOOGLE_NOT_OPENED: "Email Not Open",
  GOOGLE_NO_REPLY: "Email Not Replied",
  GOOGLE_OPENED: "Email Opened",
  GOOGLE_REPLY: "Email Replied",
  GOOGLE_ON_BOUNCED: "On Email Bounce",
  GOOGLE_ON_CLICKED: "On Email Clicked",
  GOOGLE_ON_OPEN: "On Email Opened",
  GOOGLE_ON_SENT: "On Email Sent",
  GOOGLE_ON_REPLY: "On Reply",

  LINKEDIN_NEW_RELATION: "Linkedin new relation",
  LGM_CAMPAIGN_PAUSED: "Lead campaign paused",
  LGM_CAMPAIGN_RESUMED: "Lead campaign resumed",
};

const convertTypeIntoText = (type: string) => {
  if (types[type]) return types[type];
  return type;
};

export default convertTypeIntoText;

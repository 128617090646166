import React from "react";
import Webhook from "./Webhook";
import CropAvatar from "./CropAvatar";
import ConfirmationDelete from "../../../../components/Modals/ConfirmationDelete";
import ApiLogDetailModal from "./ApiLogDetailModal";
import AddCard from "./AddCard";
import SwitchBilling from "./SwitchBilling";
import PaymentSuccess from "./PaymentSuccess";
import PaymentFailed from "./PaymentFailed";
import ScaAhead from "./ScaAhead";
import AddTeammates from "./AddTeammates";

export const types = {
  createWebhook: "createWebhook",
  editWebhook: "editWebhook",
  cropAvatar: "cropAvatar",
  confirmationDelete: "confirmationDelete",
  regenerateKey: "regenerateKey",
  logShowDetails: "logShowDetails",
  hubspotConfirmDisconnect: "hubspotConfirmDisconnect",
  pipedriveConfirmDisconnect: "pipedriveConfirmDisconnect",
  addCard: "addCard",
  switchBilling: "switchBilling",
  deleteIdentity: "deleteIdentity",
  terminateUser: "terminateUser",
  paymentSuccess: "paymentSuccess",
  paymentFailed: "paymentFailed",
  scaAhead: "scaAhead",
  addTeammates: "addTeammates",
  reset: "",
};

function Modals(props: any) {
  switch (props.name) {
    case types.confirmationDelete:
    case types.regenerateKey:
    case types.hubspotConfirmDisconnect:
    case types.pipedriveConfirmDisconnect:
      return <ConfirmationDelete {...props} />;
    case types.deleteIdentity:
      return <ConfirmationDelete {...props} onDelete={props.data.onDelete} />;
    case types.terminateUser:
      return <ConfirmationDelete {...props} onDelete={props.data.onDelete} />;
    case types.createWebhook:
      return <Webhook {...props} />;
    case types.editWebhook:
      return <Webhook {...props} />;
    case types.cropAvatar:
      return <CropAvatar {...props} />;
    case types.logShowDetails:
      return <ApiLogDetailModal {...props} />;
    case types.addCard:
      return <AddCard {...props} />;
    case types.switchBilling:
      return <SwitchBilling {...props} />;
    case types.paymentSuccess:
      return <PaymentSuccess {...props} />;
    case types.paymentFailed:
      return <PaymentFailed {...props} />;
    case types.scaAhead:
      return <ScaAhead {...props} />;
    case types.addTeammates:
      return <AddTeammates {...props} />;
    default:
      return null;
  }
}

export default Modals;

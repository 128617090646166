import React, { useEffect, useState } from "react";
import { DownloadingWidget } from "@lagrowthmachine/lgmwebapp";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import useSelector from "../../../../redux/useSelector";
import * as globalActions from "../../../../redux/actions";
import analyticsCall from "../../../../helpers/analyticsCall";
import isMacintosh from "../../../../helpers/isMacintosh";
import isWindows from "../../../../helpers/isWindows";
import constants from "../../../../config/constants";

const WidgetDownload = (props: any) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const socketReducer = useSelector((state) => state.socketReducer);
  const authReducer = useSelector((state) => state.authReducer);
  const [page, setPage] = useState(props.location?.state?.page || "start");

  const getOs = () => {
    if (isMacintosh()) {
      return "mac";
    }
    if (isWindows()) {
      return "windows";
    }
  };

  if (authReducer?.user?.onboardingDone) {
    history.push("/campaigns");
  }
  const os = getOs();

  useEffect(() => {
    analyticsCall("signup flow", "signup flow wigdet");
  }, []);

  const downloadFrom = (_os: "windows" | "mac") => {
    //window.open(constants.widgetProdUrl[_os]);
    const filePath = constants.widgetProdUrl[_os];
    var link = document.createElement("a");
    link.href = filePath;
    link.download = filePath.substr(filePath.lastIndexOf("/") + 1);
    link.click();

    var timeout = setTimeout(() => {
      setPage("waiting");
      clearTimeout(timeout);
    }, 1500);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(globalActions.socketIsWidgetAvailable());
      if (socketReducer.widgetAvailable) {
        setPage("received");
        clearInterval(interval);
        var timeout = setTimeout(() => {
          history.push("/onboarding/linkedin_connect");
          clearTimeout(timeout);
        }, 1500);
      }
    }, 1000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [dispatch, socketReducer.widgetAvailable, history]);

  useEffect(() => {
    if (
      os &&
      !Boolean(authReducer?.user?.onboardingDone) &&
      page !== "waiting"
    ) {
      var timeout = setTimeout(() => {
        downloadFrom(os);
        clearTimeout(timeout);
      }, 1500);
    }
  }, []);

  return (
    <div className="auth_onboarding_container">
      <DownloadingWidget
        os={os || "windows"}
        state={(page === "start" ? undefined : page) as any}
        onDownloadStart={downloadFrom}
      />
    </div>
  );
};

export default WidgetDownload;

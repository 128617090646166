import { IIdentitiesState } from "../interfaces/IState";
import types from "../types";

const initialState: IIdentitiesState = {
  error: null,
  list: [],
  logs: [],
  paginateL: {
    current: 0,
    total: 0,
  },
};

export const identitiesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.REQUESTS_DATA.LOAD_IDENTITIES:
      return {
        ...state,
        error: null,
      };
    case types.RESPONSES_DATA.LOAD_IDENTITIES_FULFILLED:
      return {
        ...state,
        error: null,
        list: action.payload.identities.map((identity: any) => {
          const _limits = state!.list?.find(
            (_identity: any) => _identity.id === identity.id
          )?.limits;
          let name = (
            (identity.firstname || "") +
            " " +
            (identity.lastname || "")
          ).trim();
          return {
            ...identity,
            name: name,
            limits:
              identity.limits.length !== 0 ? identity.limits : _limits || [],
            limitsLoading: false,
          };
        }),
      };
    case types.RESPONSES_DATA.LOAD_IDENTITIES_CANCELLED:
      return {
        ...state,
        list: [],
      };
    case types.IDENTITY_LOGS_PAGINATE:
      return {
        ...state,
        paginateL: action.payload,
      };
    case types.RESPONSES_DATA.LOAD_IDENTITY_LOGS_FULFILLED:
      return {
        ...state,
        error: null,
        // loading: false,
        logs: action.payload.logs,
        paginateL: {
          current: state.paginateL?.current || 0,
          total: action.payload.total,
        },
      };
    case types.RESPONSES_DATA.LOAD_IDENTITY_LOGS_REJECTED:
      return {
        ...state,
        error: action.payload?.error,
        logs: [],
        paginateL: initialState.paginateL,
      };
    case types.REQUESTS_DATA.LOAD_IDENTITY_LIMITS:
      return {
        ...state,
        error: null,
        list: state?.list?.map((identity: any) => ({
          ...identity,
          limitsLoading: identity.id === action.payload.params.identityId,
        })),
      };
    case types.RESPONSES_DATA.LOAD_IDENTITY_LIMITS_FULFILLED:
      return {
        ...state,
        error: null,
        list: state?.list?.map((identity: any) =>
          identity.id === action.payload.identityId
            ? {
                ...identity,
                limits: action.payload.limits.limits,
                limitsLoading: false,
              }
            : identity
        ),
      };
    case types.RESPONSES_DATA.LOAD_IDENTITY_LIMITS_REJECTED:
      return {
        ...state,
        error: action.payload?.error,
        list: state?.list?.map((identity: any) => ({
          ...identity,
          limitsLoading: false,
        })),
      };
    case types.RESPONSES_DATA.LOAD_IDENTITY_LIMITS_CANCELLED:
      return {
        ...state,
        error: null,
        list: state?.list?.map((identity: any) => ({
          ...identity,
          limitsLoading: false,
        })),
      };
    case types.IDENTITIES_CLEAR:
      return initialState;
    default:
      return state;
  }
};

export const adapterEditOUT = (payload: any) => {
  const data = {
    ...payload,
    firstname: payload.firstName,
    lastname: payload.lastName,
  };
  if (payload.firstName) {
    data.firstname = payload.firstName;
    delete data.firstName;
  }
  if (payload.lastName) {
    data.lastname = payload.lastName;
    delete data.lastName;
  }
  return data;
};

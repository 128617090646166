import React from "react";
import ModalWrapper from "../../../../components/ModalWrapper";
import { AudienceNew } from "@lagrowthmachine/lgmwebapp";
import useSelector from "../../../../redux/useSelector";
import * as globalActions from "../../../../redux/actions";
import globalTypes from "../../../../redux/types";
import { useDispatch } from "react-redux";
import analyticsCall from "../../../../helpers/analyticsCall";

const getTypeFromUrl = (url: string) => {
  if (url.includes("com/sales/")) {
    return "SALES_NAV";
  }
  return "LINKEDIN";
};

function Modal(props: any) {
  const loadingReducer = useSelector((state) => state.loadingReducer);
  const audienceReducer = useSelector((state) => state.audienceReducer);
  const dispatch = useDispatch();

  const onNewAudience = async ({
    name,
    description,
  }: {
    name: string;
    description: string;
  }) => {
    const sourceUrl = props.data.sourceUrl;
    const type = getTypeFromUrl(sourceUrl);

    dispatch(
      globalActions.createAudience({
        body: {
          identityId: props.data.identityId,
          audience: {
            name,
            description,
            type,
            sourceUrl: props.data.sourceUrl,
          },
        },
      })
    );
  };

  const onRequestClose = () => {
    dispatch(globalActions.modalUpdate({ name: "reset", data: {} }));
  };

  const onAddToAudience = (audienceId: string | null) => {
    const sourceUrl = props.data.sourceUrl;
    const type = getTypeFromUrl(sourceUrl);

    analyticsCall("leads new", `leads linkedin import`);
    dispatch(
      globalActions.editAudience({
        body: {
          identityId: props.data.identityId,
          // audienceId,
          type,
          sourceUrl: props.data.sourceUrl,
        },
        params: {
          id: audienceId,
        },
      })
    );
  };

  return (
    <ModalWrapper>
      <AudienceNew
        leadsNumber={0}
        linkedinImport
        onNewAudience={onNewAudience}
        onAddToAudience={onAddToAudience}
        btnSaveDisabled={
          loadingReducer.btnTypesLoaders[
            globalTypes.REQUESTS_ACTIONS.CREATE_AUDIENCE
          ] ||
          loadingReducer.btnTypesLoaders[
            globalTypes.REQUESTS_ACTIONS.EDIT_AUDIENCE
          ]
        }
        btnSaveIsLoading={
          loadingReducer.btnTypesLoaders[
            globalTypes.REQUESTS_ACTIONS.CREATE_AUDIENCE
          ] ||
          loadingReducer.btnTypesLoaders[
            globalTypes.REQUESTS_ACTIONS.EDIT_AUDIENCE
          ]
        }
        audiences={audienceReducer.list.map((aud: any) => {
          return {
            ...aud,
            active: true,
          };
        })}
        action="add"
        originalAudiences={[]}
        onCancel={onRequestClose}
      />
    </ModalWrapper>
  );
}
export default Modal;

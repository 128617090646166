export const arrayOf = (array: any[], type: string) => {
  if (type === "IN") {
    return array.map((item) => adapterIN(item));
  }

  if (type === "OUT") {
    return array.map((item) => createOUT(item));
  }
};

export const arrayOfGraphTemplate = (array: any[], type: string) => {
  if (!array) {
    return [];
  }
  if (type === "IN") {
    return array.map((item) => adapterGraphTemplateIN(item));
  }
  if (type === "IN_SMALL") {
    return array.map((item) => adapterGraphTemplateIN_SMALL(item));
  }
};

export const adapterGraphTemplateIN = (template: any) => {
  return {
    id: template.id,
    channel: template.channel,
    name: template.name,
    bcc: template.bcc,
    cc: template.cc,
    sameThread: template.followUp,
    subjectHTML: template.subjectHtml,
    contentHTML: template.contentHtml,
    contentState: template.contentState,
    subjectState: template.subjectState,
  };
};

export const adapterGraphTemplateIN_SMALL = (template: any) => {
  return {
    id: template.id,
    channel: template.channel === "GOOGLE" ? "GMAIL" : template.channel,
    bcc: template.bcc,
    cc: template.cc,
    name: template.name,
    subjectHTML: template.subjectHtml,
    contentHTML: template.contentHtml,
  };
};

export const adapterIN = (message: any) => {
  const channel = message.channel === "GOOGLE" ? "GMAIL" : message.channel;
  return {
    // ...message,
    id: message.id,
    name: message.name,
    bcc: message.bcc,
    cc: message.cc,
    campaigns: message.campaigns,
    sequences: message.sequences,
    createdAt: message.createdAt,
    channel,
    subjectState: message.subjectState,
    subjectHTML: message.subjectHtml,
    contentState: message.contentState,
    contentHTML: message.contentHtml,
    customEntities: message.contentCustomEntities,
    sameThread: message.followUp,
    description: message.description,
  };
};

export const editOUT = (message: any) => {
  if (message.channel === "LINKEDIN" || message.channel === "TWITTER") {
    return {
      name: message.name,
      contentState: message.contentState,
      contentHtml: message.contentHTML,
      subjectCustomEntities: { entities: [] },
      contentCustomEntities: message.customEntities,
      campaigns: message.campaigns,
    };
  }
  return {
    name: message.name,
    bcc: message.bcc,
    cc: message.cc,
    subjectState: message.subjectState,
    subjectHtml: message.subjectHTML,
    contentState: message.contentState,
    contentHtml: message.contentHTML,
    subjectCustomEntities: { entities: [] },
    contentCustomEntities: message.customEntities,
    campaigns: message.campaigns,
    followUp: message.sameThread || false,
  };
};

export const createOUT = (message: any) => {
  const channel = message.channel === "GMAIL" ? "GOOGLE" : message.channel;

  if (message.channel === "LINKEDIN" || message.channel === "TWITTER") {
    return {
      // ...message,
      name: message.name,
      channel,
      // to: message.to,
      bcc: message.bcc,
      cc: message.cc,
      subjectState: message.subjectState,
      subjectHtml: message.subjectHTML,
      contentState: message.contentState,
      contentHtml: message.contentHTML,
      subjectCustomEntities: { entities: [] },
      contentCustomEntities: message.customEntities,
      campaigns: message.campaigns,
      campaignId: message.campaignId,
    };
  }
  return {
    // ...message,
    name: message.name,
    channel,
    // to: message.to,
    bcc: message.bcc,
    cc: message.cc,
    subjectState: message.subjectState,
    subjectHtml: message.subjectHTML,
    contentState: message.contentState,
    contentHtml: message.contentHTML,
    subjectCustomEntities: { entities: [] },
    contentCustomEntities: message.customEntities,
    campaigns: message.campaigns,
    followUp: message.sameThread || false,
    campaignId: message.campaignId,
  };
};

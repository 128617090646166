import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  SettingsTopBar,
  SettingsActionsBar,
  ApiStart,
  ApiLogs,
  Paginate,
  ApiUpgrade,
  Zapier,
  CaptainData,
  PhantomBuster,
} from "@lagrowthmachine/lgmwebapp";
import Modal, { types as modalTypes } from "../Modals";
import authTypes from "../../../Auth/types";
import { useDispatch } from "react-redux";
import useDebounce from "../../../../helpers/useDebounce";
import * as authActions from "../../../Auth/actions";
import * as actions from "../../../Settings/actions";
import * as globalActions from "../../../../redux/actions";
import useSelector from "../../../../redux/useSelector";
import analyticsCall from "../../../../helpers/analyticsCall";
import barNavItems from "../../helpers/barNavItems";
import config from "../../../../config";
import constants from "../../../../config/constants";

const SettingsApiComponent = (props: any) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const modalsReducer = useSelector((state) => state.modalsReducer);
  const loadingReducer = useSelector((state) => state.loadingReducer);
  const authReducer = useSelector((state) => state.authReducer);
  const settingsReducer = useSelector((state) => state.settingsReducer);

  const bar = barNavItems(authReducer);

  useEffect(() => {
    dispatch(authActions.loadUser({ query: {} }));
  }, [dispatch]);

  useEffect(() => {
    if (authReducer.user.hasAcessTo?.apis) {
      dispatch(actions.loadLogs({ query: {} }));
    }
  }, [dispatch]);

  useEffect(() => {
    analyticsCall("settings", "settings: api settings");
  }, []);

  useEffect(() => {
    dispatch(globalActions.scrollToTop());
  }, [dispatch]);

  /*test*/
  const handleActions = (action: string, data?: any) => {
    switch (action) {
      case "input":
        dispatch(authActions.signOutUser());
        break;
      case "new_api_key":
        dispatch(authActions.refreshApiKey({ query: {} }));
        break;
      default:
        break;
    }
  };

  const handleLogsShowErrors = (logsShowErrors: boolean) => {
    dispatch(actions.logsShowErrors({ logsShowErrors: logsShowErrors }));
  };

  const handleModal = (data: any) => {
    switch (data.name) {
      case "regenerateKey":
        dispatch(
          globalActions.modalUpdate({
            name: data.name,
            data: {
              title: "Regenerate key",
              subtitle:
                "The previous key will be not be authorized anymore. Any applications using the current key will lose access to the API.",
              deleteText: "Regenerate key",
              cancelText: "Cancel",
            },
          })
        );
        break;
      case "logShowDetails":
        dispatch(
          globalActions.modalUpdate({
            name: "logShowDetails",
            data: null,
          })
        );
        dispatch(actions.loadLogDetail({ logId: data.logId }));
        break;
      default:
        dispatch(
          globalActions.modalUpdate({
            name: "",
            data: {},
          })
        );
        break;
    }
  };

  const handleChangePage = (page: number) => {
    dispatch(
      actions.logsActionsPaginate({
        current: page,
      })
    );
  };
  const handleKeyCopied = () => {
    dispatch(actions.logsKeyCopied({}));
  };

  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    if (debouncedSearchTerm !== settingsReducer.logsKeywords) {
      dispatch(actions.updateLogsKeywords({ keywords: debouncedSearchTerm }));
    } else if (!debouncedSearchTerm && settingsReducer.logsKeywords !== "") {
      dispatch(actions.updateLogsKeywords({ keywords: "" }));
    }
  }, [dispatch, debouncedSearchTerm, settingsReducer.logsKeywords]);

  const totalPaginate =
    Math.ceil((settingsReducer.logsPaginate?.total || 0) / config.limitLeads) ||
    1;

  const onSeeDocumentation = () => {
    (window as any).open(constants.postman_documentation);
  };

  const onLaunch = (provider: string) => {
    (window as any).open(constants.providers_url[provider]);
  };

  const logs = settingsReducer.externalLogs?.logs;
  return (
    <div className="settings_api_container">
      {modalsReducer.name === "regenerateKey" && (
        <Modal
          name={modalsReducer.name}
          data={modalsReducer.data}
          onDelete={() => handleActions("new_api_key")}
          onRequestClose={() => handleModal({ name: modalTypes.reset })}
        />
      )}

      {modalsReducer.name === "logShowDetails" && (
        <Modal
          name={modalsReducer.name}
          data={modalsReducer.data}
          onRequestClose={() => handleModal({ name: modalTypes.reset })}
        />
      )}

      <SettingsTopBar onAction={handleActions} />
      <div className="content_wrapper">
        <SettingsActionsBar
          tabs={bar}
          page={"api"}
          linkC={Link}
          direction="vertical"
        />
        <div className="in">
          {authReducer.user.campaignPermissions?.apis ? (
            <React.Fragment>
              <ApiStart
                permission={
                  authReducer.user.campaignPermissions?.apisPermissions
                    ? {
                        label:
                          authReducer.user.campaignPermissions
                            ?.apisPermissions[0],
                        available:
                          authReducer.user.campaignPermissions?.apisPermissions,
                      }
                    : undefined
                }
                onGenerateHash={() =>
                  authReducer.user.externalApikeyId
                    ? handleModal({ name: "regenerateKey" })
                    : handleActions("new_api_key")
                }
                hash={authReducer.user.externalApikey}
                keyCopied={handleKeyCopied}
                generateBtnIsLoading={
                  loadingReducer.btnTypesLoaders[
                    authTypes.REQUESTS_ACTIONS.REFRESH_EXTERN_APIKEY
                  ]
                }
                generateBtnIsDisabled={
                  loadingReducer.btnTypesLoaders[
                    authTypes.REQUESTS_ACTIONS.REFRESH_EXTERN_APIKEY
                  ]
                }
              />

              <Zapier
                permission={
                  authReducer.user.campaignPermissions?.apisPermissions
                    ? {
                        label:
                          authReducer.user.campaignPermissions
                            ?.apisPermissions[0],
                        available:
                          authReducer.user.campaignPermissions?.apisPermissions,
                      }
                    : undefined
                }
                isStatic={false}
                launch={onLaunch}
              />
              <div className="provider_line">
                <CaptainData isStatic={false} launch={onLaunch} />
                <PhantomBuster isStatic={false} launch={onLaunch} />
              </div>

              {logs && (
                <ApiLogs
                  docUrl={constants.postman_documentation}
                  onFilter={setSearchTerm}
                  onLogDetail={(logId) =>
                    handleModal({ name: "logShowDetails", logId: logId })
                  }
                  onCheckError={(logsShowErrors) =>
                    handleLogsShowErrors(logsShowErrors)
                  }
                  seeError={settingsReducer.logsShowErrors}
                  logs={logs.map((log: any) => ({
                    id: log.id,
                    apiName: log.apiName || "none",
                    valid: log.statusCode === 200,
                    execution: log.responseText || "none",
                    time: log.createdAt / 1000,
                    tooltip: String(log.statusCode),
                  }))}
                />
              )}
              {
                <Paginate
                  goToPage={handleChangePage}
                  current={settingsReducer?.logsPaginate?.current || 0}
                  total={totalPaginate > 0 ? totalPaginate : 1}
                />
              }
            </React.Fragment>
          ) : (
            <ApiUpgrade
              onUpgrade={() => history.push("/settings/pricing")}
              permission={{
                label: authReducer.user.campaignPermissions?.apisPermissions[0],
                available:
                  authReducer.user.campaignPermissions?.apisPermissions,
              }}
              onSeeDocumentation={onSeeDocumentation}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SettingsApiComponent;

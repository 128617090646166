export const arrayOf = (array: any[], type: string) => {
  if (type === "IN") {
    return array.map((item) => adapterIN(item));
  }
  // if (type === "OUT") {
  //   return array.map((item) => adapterOUT(item));
  // }
};

export const adapterIN = (action: any) => {
  return {
    ...action,
    channel: action.channel === "GOOGLE" ? "GMAIL" : action.channel,
  };
};

import { IAuthState } from "../interfaces/IState";
import types from "../types";
import config from "../../../config";

const initialState: IAuthState = {
  error: null,
  user: null,
  globalCount: null,
  uploadUrl: null,
  futurUploadedUrl: null,
  coupon: null,
  impersonate: false,
  frontVersion: config.frontVersion,
};

export const authReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.REQUESTS_DATA.LOAD_USER:
      return {
        ...state,
        error: null,
      };
    case types.REQUESTS_ACTIONS.LOGIN_USER:
      return {
        ...state,
        error: null,
      };

    case types.RESPONSES_DATA.LOAD_USER_FULFILLED:
      return {
        ...state,
        user: action.payload.user,
        error: null,
      };
    case types.RESPONSES_ACTIONS.LOGIN_USER_FULFILLED:
      return {
        ...state,
        user: action.payload.user,
        globalCount: action.payload.globalCount,
        error: null,
      };
    case types.RESPONSES_DATA.LOAD_USER_CANCELLED:
    case types.RESPONSES_ACTIONS.LOGIN_USER_CANCELLED:
    case types.RESPONSES_ACTIONS.LOGIN_USER_REJECTED:
      return {
        ...state,
        error: action.payload?.error,
        user: null,
      };

    case types.REQUESTS_ACTIONS.REGISTER_USER:
      return {
        ...state,
        error: null,
      };

    case types.REQUESTS_ACTIONS.SET_APIKEY:
      return {
        ...state,
        user: {
          apikey: action.payload?.apikey,
          //id: action.payload?.id,
        },
      };

    case types.RESPONSES_ACTIONS.GET_COUPON_FULFILLED:
      return {
        ...state,
        coupon: action.payload.coupon,
      };

    case types.CLEAR_GET_COUPON:
      return {
        ...state,
        coupon: null,
      };
    case types.RESPONSES_ACTIONS.GET_COUPON_REJECTED:
      return {
        ...state,
        coupon: { error: action.payload.error },
      };

    case types.RESPONSES_ACTIONS.REGISTER_USER_CANCELLED:
    case types.RESPONSES_ACTIONS.REGISTER_USER_REJECTED:
      return {
        ...state,
        error: action.payload?.error,
      };

    case types.REQUESTS_ACTIONS.UPLOAD_URL_USER:
      return {
        ...state,
        uploadUrl: null,
        futurUploadedUrl: null,
      };

    case types.RESPONSES_ACTIONS.UPLOAD_URL_USER_FULFILLED:
      return {
        ...state,
        uploadUrl: action.payload?.url,
        futurUploadedUrl: action.payload?.futurUrl,
      };
    case types.AUTH_CLEAR:
      return initialState;
    case types.AUTH_ADMIN:
      return {
        ...state,
        impersonate: true,
      };
    default:
      return state;
  }
};

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Details,
  CardsPayment,
  Summary,
  LgmHeader,
} from "@lagrowthmachine/lgmwebapp";
import Modal, { types as modalTypes } from "../Modals";
//import authTypes from "../../../Auth/types";
import { useDispatch } from "react-redux";
//import useDebounce from "../../../../helpers/useDebounce";
import * as authActions from "../../../Auth/actions";
import * as identitiesActions from "../../../Identities/actions";
import * as actions from "../../../Settings/actions";
import * as globalActions from "../../../../redux/actions";
import useSelector from "../../../../redux/useSelector";
import analyticsCall from "../../../../helpers/analyticsCall";
import analyticsTrack from "../../../../helpers/analyticsTrack";
import { afterPayments } from "../../helpers/paymentsResolve";
import config, { APP_ENV } from "../../../../config";
import { loadStripe } from "@stripe/stripe-js";

import { Elements } from "@stripe/react-stripe-js";
import { ElementsConsumer } from "@stripe/react-stripe-js";

const stripeKey = config.stripeKeys[APP_ENV];
const stripePromise = loadStripe(stripeKey);

const SettingsPaymentComponent = (props: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const modalsReducer = useSelector((state) => state.modalsReducer);
  const loadingReducer = useSelector((state) => state.loadingReducer);
  const authReducer = useSelector((state) => state.authReducer);
  const settingsReducer = useSelector((state) => state.settingsReducer);
  const identitiesReducer = useSelector((state) => state.identitiesReducer);

  const identities = props.location?.state?.identities || [];

  const [pricesUpdateCount, setPricesUpdateCount] = useState(0);
  const [firstLoad, setFirstLoad] = useState(true);
  const [prices, setPrices] = useState({
    type: props.type,
    planTo: props.planTo || authReducer.user.plan,
    billing: props.billing || props.location?.state?.billing || "yearly",
    count: 1,
    coupon: undefined,
    couponResult: undefined,
    vat: { rate: 0, amount: 0 },
    credit: 0,
    nextTotal: 0,
    total: 0,
    nextBillingDate: undefined,
    daysUntilNextBilling: 0,
    identities: identities,
    details: undefined,
    nextPlanId: undefined,
    prorated: undefined,
  });

  const pricesBody = () => {
    const planId =
      settingsReducer.plans &&
      settingsReducer.plans[prices.planTo] &&
      settingsReducer.plans[prices.planTo][prices.billing].id;
    return {
      type: prices.type, //"changePlan" | "addId" | "changeBilling" | "reactivateId". tu l'auras tjs dans la requête
      coupon: prices.coupon, //string
      planId: planId,
      identities: prices.identities || [], //[array d'identities id], le id concernés
      count: prices.count, //si pas identities au dessus, alors nb de new identities. Si pas de identities ni count, alors c'est pr tout le compte du user
    };
  };

  useEffect(() => {
    const _pricesBody = pricesBody();
    if (_pricesBody.planId) {
      dispatch(
        actions.loadPrice({
          body: pricesBody(),
          prices: prices,
          setPrices: setPrices,
        })
      );
    }
  }, [
    prices.count,
    prices.billing,
    prices.coupon,
    authReducer.user?.billingInfos?.country,
    authReducer.user?.billingInfos?.vatNumber,
    JSON.stringify(settingsReducer.plans),
  ]);

  useEffect(() => {
    if (pricesUpdateCount === 1) {
      analyticsTrack("Funnel Payment Started", {
        type: prices.type,
        selected_identity_nb: prices.count,
        plan: prices.planTo,
        total_price:
          ((prices.details as any)?.monthly?.count || 0) *
            ((prices.details as any)?.monthly?.amount || 0) +
          ((prices.details as any)?.yearly?.count || 0) *
            ((prices.details as any)?.yearly?.amount || 0),
        due_totay_with_vat: prices.total,
      });
    }

    setPricesUpdateCount(pricesUpdateCount + 1);
  }, [JSON.stringify(prices)]);

  const purchase = (stripe: any, pricesBody: any, ignorePmIds: boolean) => {
    dispatch(
      actions.processPayment({
        body: pricesBody,
        afterPayment: async (data: any) =>
          afterPayments(
            stripe,
            data.summary.paymentIds,
            ignorePmIds,
            history,
            handleModal
          ),
      })
    );
  };

  useEffect(() => {
    setFirstLoad(false);
  }, []);

  useEffect(() => {
    dispatch(actions.loadPlans());
    dispatch(authActions.loadUser({ query: {} }));
    dispatch(identitiesActions.loadIdentities({ query: {} }));
    dispatch(actions.loadCards({ query: {} }));
  }, [dispatch]);

  useEffect(() => {
    const translations = {
      changePlan: "change plan",
      addId: "add ID",
      changeBilling: "Switch to yearly",
      reactivateId: "Reactivate ID",
    };
    analyticsCall("settings", `settings: payment, ${translations[props.type]}`);
  }, []);

  const selectBilling = (billing: "yearly" | "monthly") => {
    let _prices = JSON.parse(JSON.stringify(prices));
    _prices.billing = billing;
    setPrices(_prices);
  };

  const updateCount = (count: number) => {
    let _prices = JSON.parse(JSON.stringify(prices));
    _prices.count = count;
    setPrices(_prices);
  };

  const applyDiscount = (code: string) => {
    let _prices = JSON.parse(JSON.stringify(prices));
    _prices.coupon = code;
    setPrices(_prices);
  };

  const removeDiscount = () => {
    let _prices = JSON.parse(JSON.stringify(prices));
    delete _prices.coupon;
    delete _prices.couponResult;
    setPrices(_prices);
  };

  const onSaveBilling = (data: any) => {
    const _data = { billingInfos: data, company: data.company };
    if (_data.billingInfos.vatNumber === "") {
      delete _data.billingInfos.vatNumber;
    }
    delete _data.billingInfos.company;
    dispatch(
      authActions.editUser({
        query: {},
        body: _data,
      })
    );
  };

  const handleModal = (action: string, data?: any) => {
    switch (action) {
      case "add_card":
        dispatch(
          globalActions.modalUpdate({
            name: "addCard",
            data: null,
          })
        );
        break;
      case "payment_success":
        dispatch(
          globalActions.modalUpdate({
            name: "paymentSuccess",
            data: null,
          })
        );
        break;
      case "sca_ahead":
        dispatch(
          globalActions.modalUpdate({
            name: "scaAhead",
            data: data,
          })
        );
        break;
      case "payment_failed":
        dispatch(
          globalActions.modalUpdate({
            name: "paymentFailed",
            data: data,
          })
        );
        break;
      case "close":
        dispatch(
          globalActions.modalUpdate({
            name: modalTypes.reset,
          })
        );
        break;
      default:
        break;
    }
  };

  const onSelectCard = (cardId: string) => {
    dispatch(actions.setDefaultCard({ cardId: cardId }));
  };

  const onRemoveCard = (cardId: string) => {
    dispatch(actions.deleteCard({ cardId: cardId }));
  };

  const onCardAdded = (paymentMethodId: any) => {
    dispatch(actions.attachCardToUser({ paymentMethodId }));
  };

  if (
    (authReducer.user?.plan === "TRIAL" && props.type !== "changePlan") || //can only upgrade during trial
    (authReducer.user?.plan === props.planTo && props.type === "changePlan") //can't upgrade on same plan
  ) {
    history.push("/settings/pricing");
    return null;
  }
  if (
    //can't upgrade on lowest plan
    (authReducer.user?.plan === "PRO" &&
      props.planTo === "STARTER" &&
      props.type === "changePlan") ||
    (authReducer.user?.plan === "BUSINESS" &&
      ["PRO", "STARTER"].includes(props.planTo) &&
      props.type === "changePlan")
  ) {
    history.push("/settings/downgrade", { plan: props.planTo });
    return null;
  }

  if (settingsReducer.retryPayment && props.type === "changePlan") {
    //can't upgrade if pending payment
    history.push("/settings/billing");
    return null;
  }
  const _pricesBody = pricesBody();

  const nextPlanType =
    prices.nextPlanId &&
    Object.keys(settingsReducer.plans).find((planType: string) =>
      [
        settingsReducer.plans[planType]?.monthly?.id,
        settingsReducer.plans[planType]?.yearly?.id,
      ].includes(prices.nextPlanId)
    );
  const nextPlanWarning =
    (prices.nextPlanId &&
      prices.nextBillingDate && {
        presentPlanType: authReducer.user.plan,
        nextPlanType: nextPlanType,
        switchOn: (prices.nextBillingDate || 0) * 1000,
      }) ||
    undefined;

  return (
    <Elements stripe={stripePromise}>
      <ElementsConsumer>
        {({ elements, stripe }: any) => (
          <div className="settings_payment_container">
            <Modal
              name={modalsReducer.name}
              data={modalsReducer.data}
              stripe={stripe}
              onRequestClose={() => handleModal("close")}
              onCardAdded={onCardAdded}
            />
            <div className="column">
              <LgmHeader />
              <div className="title">1.&nbsp;Billing&nbsp;details</div>
              <Details
                company={authReducer.user?.company}
                address={authReducer.user?.billingInfos?.address}
                city={authReducer.user?.billingInfos?.city}
                postalCode={authReducer.user?.billingInfos?.postalCode}
                state={authReducer.user?.billingInfos?.state}
                country={authReducer.user?.billingInfos?.country}
                vatNumber={authReducer.user?.billingInfos?.vatNumber}
                complement={authReducer.user?.billingInfos?.complement}
                onSave={onSaveBilling}
              />
              <div className="title">2.&nbsp;Credit&nbsp;cards</div>
              <CardsPayment
                defaultCardId={
                  settingsReducer.cards?.find((_card: any) => _card.default)?.id
                }
                cards={
                  settingsReducer.cards?.map((_card: any) => ({
                    ..._card,
                    error: Boolean(_card.error),
                    errorMessage: _card.error,
                  })) || []
                }
                onSelectCard={onSelectCard}
                onRemoveCard={onRemoveCard}
                onAddCard={() => handleModal("add_card")}
                purchase={() => purchase(stripe, _pricesBody, false)}
                purchaseLoading={loadingReducer.btnTypesLoaders.PROCESS_PAYMENT}
                loading={
                  firstLoad ||
                  loadingReducer.dataTypesLoaders.LOAD_CARDS ||
                  loadingReducer.btnTypesLoaders.SET_DEFAULT_CARD ||
                  loadingReducer.btnTypesLoaders.DELETE_CARD ||
                  loadingReducer.dataTypesLoaders.LOAD_PRICE
                }
              />
            </div>
            <div className="column">
              <div style={{ visibility: "hidden" }}>
                <LgmHeader />
              </div>
              <div className="title">Summary</div>
              <Summary
                nextPlanWarning={nextPlanWarning}
                type={prices.type}
                planTo={prices.planTo}
                billing={prices.billing}
                selectBilling={selectBilling}
                allowBillingChange={
                  (prices.type === "changePlan" &&
                    authReducer.user.plan === "TRIAL") ||
                  prices.type === "addId" ||
                  prices.type === "reactivateId"
                }
                prorated={prices.prorated}
                details={prices.details}
                updateCount={updateCount}
                coupon={prices.couponResult}
                applyDiscount={applyDiscount}
                removeDiscount={removeDiscount}
                yearlyDiscount={
                  (
                    settingsReducer.plans &&
                    settingsReducer.plans[prices.planTo]
                  )?.yearly?.discount || ""
                }
                monthlyPrices={{
                  monthly:
                    (settingsReducer.plans &&
                      settingsReducer.plans[prices.planTo]?.monthly?.price) ||
                    0,
                  yearly:
                    (settingsReducer.plans &&
                      settingsReducer.plans[prices.planTo]?.yearly?.price) ||
                    0,
                }}
                identities={
                  ["changeBilling", "reactivateId"].includes(props.type)
                    ? identitiesReducer.list
                        .filter(
                          (_identity: any) =>
                            identities.includes(_identity.id) ||
                            typeof identities === "undefined"
                        )
                        .map((_identity: any) => ({
                          id: _identity.id,
                          name:
                            _identity.firstname || _identity.lastname
                              ? (_identity.firstname || "") +
                                " " +
                                (_identity.lastname || "")
                              : "Identity missing name",
                          profilePicture: _identity.profilePicture,
                        }))
                    : undefined
                }
                credit={prices.credit}
                vat={prices.vat}
                total={prices.total}
                nextTotal={prices.nextTotal}
                daysUntilNextBilling={prices.daysUntilNextBilling}
                /*
                  trialEnd={
                    (authReducer.user?.plan === "trial" &&
                      prices.nextBillingDate) ||
                    undefined
                  }
                  */
                loading={
                  firstLoad ||
                  loadingReducer.dataTypesLoaders.LOAD_IDENTITIES ||
                  loadingReducer.dataTypesLoaders.LOAD_PRICE ||
                  loadingReducer.dataTypesLoaders.LOAD_USER ||
                  loadingReducer.dataTypesLoaders.LOAD_PLANS
                }
              />
            </div>
          </div>
        )}
      </ElementsConsumer>
    </Elements>
  );
};

export default SettingsPaymentComponent;

import getEndpoint from "../endpoints";

export const loadLoginUrl = async ({
  authReducer,
  channel,
  identityId,
}: {
  authReducer: any;
  channel: string;
  identityId: string;
}) => {
  const endpoint = getEndpoint({
    apiVersion: authReducer?.user?.apiVersion,
    name: "loadLoginUrl",
    query: {
      apikey: authReducer?.user?.apikey,
    },
    params: {
      channel: channel.toUpperCase(),
      identityId,
    },
  });

  const response = await fetch(endpoint.url, {
    headers: endpoint.headers,
    method: endpoint.method,
  });
  const json = await response.json();
  if (!!!json.url) {
    throw json.error || "Unknow error";
  }
  return json;
};

export const refreshSpfDkim = async ({
  authReducer,
  channel,
  identityId,
}: {
  authReducer: any;
  channel: string;
  identityId: string;
}) => {
  const endpoint = getEndpoint({
    apiVersion: authReducer?.user?.apiVersion,
    name: "refreshSpfDkim",
    query: {
      apikey: authReducer?.user?.apikey,
    },
    params: {
      identityId,
    },
  });
  const response = await fetch(endpoint.url, {
    headers: endpoint.headers,
    method: endpoint.method,
  });
  const json = await response.json();
  if (json.error) {
    throw json.error || "Unknow error";
  }
  return json;
};

export const verifyImapSmpt = async ({
  authReducer,
  channel,
  identityId,
  body,
}: {
  authReducer: any;
  channel: string;
  identityId: string;
  body: any;
}) => {
  const endpoint = getEndpoint({
    apiVersion: authReducer?.user?.apiVersion,
    name: "verifyImapSmpt",
    query: {
      apikey: authReducer?.user?.apikey,
    },
    params: {
      channel,
      identityId,
    },
    body: JSON.stringify(body),
  });
  const response = await fetch(endpoint.url, {
    headers: endpoint.headers,
    method: endpoint.method,
    body: endpoint.body,
  });
  const json = await response.json();
  if (json.error) {
    throw json.error || "Unknow error";
  }
  return json;
};

export const addImapSmpt = async ({
  authReducer,
  channel,
  identityId,
  body,
}: {
  authReducer: any;
  channel: string;
  identityId: string;
  body: any;
}) => {
  const endpoint = getEndpoint({
    apiVersion: authReducer?.user?.apiVersion,
    name: "addImapSmpt",
    query: {
      apikey: authReducer?.user?.apikey,
    },
    params: {
      channel,
      identityId,
    },
    body: JSON.stringify(body),
  });
  const response = await fetch(endpoint.url, {
    headers: endpoint.headers,
    method: endpoint.method,
    body: endpoint.body,
  });
  const json = await response.json();
  if (json.error) {
    throw json.error || "Unknow error";
  }
  return json;
};

export const revokeImapSmtp = async ({
  authReducer,
  channel,
  identityId,
}: {
  authReducer: any;
  channel: string;
  identityId: string;
}) => {
  const endpoint = getEndpoint({
    apiVersion: authReducer?.user?.apiVersion,
    name: "revokeImapSmtp",
    query: {
      apikey: authReducer?.user?.apikey,
    },
    params: {
      channel: channel.toUpperCase(),
      identityId,
    },
  });
  const response = await fetch(endpoint.url, {
    headers: endpoint.headers,
    method: endpoint.method,
  });
  const json = await response.json();
  if (json.error) {
    throw json.error || "Unknow error";
  }
  return json;
};

export const autodiscover = async ({
  authReducer,
  channel,
  identityId,
  body,
}: {
  authReducer: any;
  channel: string;
  identityId: string;
  body: any;
}) => {
  const endpoint = getEndpoint({
    apiVersion: authReducer?.user?.apiVersion,
    name: "autodiscover",
    query: {
      apikey: authReducer?.user?.apikey,
    },
    params: {
      channel,
      identityId,
    },
    body: JSON.stringify(body),
  });

  const response = await fetch(endpoint.url, {
    headers: endpoint.headers,
    method: endpoint.method,
    body: endpoint.body,
  });
  const json = await response.json();
  if (json.error) {
    throw json.error || "Unknow error";
  }
  return json;
};

// import { createReducer } from "@reduxjs/toolkit";
import { IStateLoading } from "../../interfaces/IState";
import types from "../types";
import campaignTypes from "../../Containers/Campaigns/types";
import leadTypes from "../../Containers/Leads/types";
import identityTypes from "../../Containers/Identities/types";
import authTypes from "../../Containers/Auth/types";

const getTypes = (types: { [key: string]: string }) => {
  return Object.assign(
    {},
    ...Object.keys(types).map((key: string) => ({
      [key]: false,
    }))
  );
};

const campaignBtnTypesLoaders = getTypes(campaignTypes.REQUESTS_ACTIONS);
const campaignDataTypesLoaders = getTypes(campaignTypes.REQUESTS_DATA);

const leadBtnTypesLoaders = getTypes(leadTypes.REQUESTS_ACTIONS);
const leadDataTypesLoaders = getTypes(leadTypes.REQUESTS_DATA);

const identityBtnTypesLoaders = getTypes(identityTypes.REQUESTS_ACTIONS);
const identityDataTypesLoaders = getTypes(identityTypes.REQUESTS_DATA);

const authBtnTypesLoaders = getTypes(authTypes.REQUESTS_ACTIONS);
const authDataTypesLoaders = getTypes(authTypes.REQUESTS_DATA);

const globalBtnTypesLoaders = getTypes(types.REQUESTS_ACTIONS);
const globalDataTypesLoaders = getTypes(types.REQUESTS_DATA);

const initialState: IStateLoading = {
  currentActions: [],
  idsInAction: [],
  btnTypesLoaders: {
    ...campaignBtnTypesLoaders,
    ...leadBtnTypesLoaders,
    ...identityBtnTypesLoaders,
    ...authBtnTypesLoaders,
    ...globalBtnTypesLoaders,
  },
  dataTypesLoaders: {
    ...campaignDataTypesLoaders,
    ...leadDataTypesLoaders,
    ...identityDataTypesLoaders,
    ...authDataTypesLoaders,
    ...globalDataTypesLoaders,
  },
};

export const loadingReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.ADD_ACTION:
      return {
        ...state,
        currentActions: state.currentActions.concat([action.payload]),
      };
    case types.REMOVE_ACTION:
      return {
        ...state,
        currentActions: state.currentActions.filter(
          (_action: string) => _action !== action.payload
        ),
      };
    case types.UPDATE_BTN_LOADERS:
      return {
        ...state,
        btnTypesLoaders: {
          ...state.btnTypesLoaders,
          [action.payload.name]: action.payload.status,
        },
      };
    case types.UPDATE_DATA_LOADERS:
      return {
        ...state,
        dataTypesLoaders: {
          ...state.dataTypesLoaders,
          [action.payload.name]: action.payload.status,
        },
      };
    case types.LOADING_CLEAR:
      return initialState;
    default:
      return state;
  }
};

import React from "react";
import ModalWrapper from "../../../../components/ModalWrapper";
import { CampaignDowngrade } from "@lagrowthmachine/lgmwebapp";
import useSelector from "../../../../redux/useSelector";

function Modal(props: any) {
  const authReducer = useSelector((state) => state.authReducer);
  //const loadingReducer = useSelector((state) => state.loadingReducer);

  const onGotIt = () => {
    let warningsSeen =
      localStorage?.getItem("warnings_seen")?.split("___") || [];
    warningsSeen = warningsSeen.concat([props.data.warningId]);
    localStorage?.setItem("warnings_seen", warningsSeen.join("___"));
    props.onRequestClose();
  };

  return (
    <ModalWrapper>
      <CampaignDowngrade
        plan={authReducer.user.plan}
        stopped={props.data.stopped}
        onGotIt={onGotIt}
        onCancel={props.onRequestClose}
      />
    </ModalWrapper>
  );
}
export default Modal;
